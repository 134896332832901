import {
  getEsgStrategyFrequencyRange,
  getEsgStrategyPlanningTaskList,
  setEsgStrategySelectedTask,
} from "actions/phase-3/task/esgStrategyTask/esgStrategyTaskAction";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useEsgStrategyPlanningAPIs = (
  keyword = "",
  limit = 5,
  page = 1
) => {
  const { entity_id } = useSelector((state) => state.user);
  const { esgStrategyTaskList } = useSelector((state) => state.esgStrategyTask);

  const dispatch = useDispatch();

  const fetchAssets = () => {
    dispatch(getEsgStrategyPlanningTaskList(entity_id));
  };

  const handleSelectTask = async (task) => {
    await dispatch(
      getEsgStrategyFrequencyRange(
        task?.esg_strategy_planning_assessment_id,
        task?.materiality_assessment_id,
        task?.frequency
      )
    );

    dispatch(setEsgStrategySelectedTask(task));
  };

  useEffect(() => {
    fetchAssets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, page]);

  return {
    // // states & data
    esgStrategyTaskList,
    // // funcs
    handleSelectTask,
  };
};
