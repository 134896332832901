import { Box, ButtonBase, IconButton, Typography } from "@mui/material";

// icons
import FollowUpAnswer from "images/phase-3/GPTWise/FollowUpAnswer.png";
import { FaArrowUp } from "react-icons/fa6";

// component imports
import ResourceHeader from "./CommonComps/ResourceHeader";
import { useDispatch } from "react-redux";
import { setFollowUpQues } from "actions/phase-3/gptWise/gptWiseAction";

const FollowupQues = ({ promptData }) => {
  const dispatch = useDispatch();

  return (
    <Box display={"flex"} flexDirection={"column"} style={{ gap: 20 }}>
      {/* followup header */}
      <ResourceHeader
        icon={FollowUpAnswer}
        title={"Follow up Questions"}
        showView={false}
      />

      <Box maxWidth={"70%"} width={"100%"}>
        {/* followup prompts */}
        {promptData?.map((prompt, index) => (
          <ButtonBase
            key={index}
            component="div"
            focusRipple
            style={{
              backgroundColor: "#F6F6F6",
              padding: 10,
              borderRadius: 8,

              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "15px 0px",
              textAlign: "left",
            }}
            onClick={() => dispatch(setFollowUpQues(prompt))}
          >
            <Typography
              style={{
                width: "50em",
                color: "#242424",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "20px",
              }}
            >
              {prompt}
            </Typography>

            <IconButton
              disableRipple
              style={{
                color: "#3374B9",
                marginLeft: "auto",
                backgroundColor: "#FBFBFB",
                boxShadow: "1px 1px 4px 1px  #EDF2F3",
                borderRadius: "10px",
                padding: "12px",
              }}
            >
              <FaArrowUp size={15} />
            </IconButton>
          </ButtonBase>
        ))}
      </Box>
    </Box>
  );
};
export default FollowupQues;
