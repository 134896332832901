import { Box, Skeleton, Stack, Tabs, Tab } from "@mui/material";
import LeftSectionLayout from "components/layout/twoSectionLayout/LeftSectionLayout";
import MainLayoutSection from "components/layout/twoSectionLayout/MainLayoutSection";
import RightSectionLayout from "components/layout/twoSectionLayout/RightSectionLayout";
import ComplianceHeader from "components/widgets/phase-3/Task/compliance/ComplianceHeader";
import ComplianceLeft from "components/widgets/phase-3/Task/compliance/left/ComplianceLeft";
import ComplianceRight from "components/widgets/phase-3/Task/compliance/right/ComplinaceRight";
import useTabChanges from "customHooks/useTabChanges";
import {
  getEsgComplianceTaskAssignedMetrics,
  getEsgComplianceTaskMetricsStatus,
} from "actions/phase-3/task/esgCompliance/esgComplianceTaskAction";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
const tabs = [
  {
    label: "Contributor",
  },
  {
    label: "Approver",
  },
];
function ComplianceMain() {
  const { selectedTab, handleChange } = useTabChanges();
  const { esgComplianceSelectedTask } = useSelector(
    (state) => state.esgComplianceTask
  );

  const {
    data: { result: esgComplianceAssessmentTask = [] } = {}, // Default to empty object and values
    isLoading,
  } = useQuery({
    queryKey: [
      "esgComplianceAssessmentTaskDetails",
      esgComplianceSelectedTask?.esg_compliance_id,
      esgComplianceSelectedTask?.framework_id,
      selectedTab,
    ],
    queryFn: () =>
      getEsgComplianceTaskAssignedMetrics({
        esg_compliance_id: esgComplianceSelectedTask?.esg_compliance_id,
        framework_id: esgComplianceSelectedTask?.framework_id,
        assigned_as: selectedTab === 1 ? "APPROVER" : "CONTRIBUTOR",
        starting_after: 0,
        limit: 30,
      }),

    enabled: !!esgComplianceSelectedTask?.esg_compliance_id,
    // these for know query that data is fresh and API not call until we manually fetch or invalidate it
    staleTime: Infinity,
    cacheTime: 0,
  });

  const {
    data: { result: esgComplianceAssessmentTaskStatus = {} } = {}, // Default to empty object and values
    isLoading: statusLoading,
  } = useQuery({
    queryKey: [
      "esgComplianceAssessmentTaskStatus",
      esgComplianceSelectedTask?.esg_compliance_id,
      esgComplianceSelectedTask?.framework_id,
      selectedTab,
    ],
    queryFn: () =>
      getEsgComplianceTaskMetricsStatus({
        esg_compliance_id: esgComplianceSelectedTask?.esg_compliance_id,
        framework_id: esgComplianceSelectedTask?.framework_id,
        assigned_as: selectedTab === 1 ? "APPROVER" : "CONTRIBUTOR",
      }),

    enabled: !!esgComplianceSelectedTask?.esg_compliance_id,
    // these for know query that data is fresh and API not call until we manually fetch or invalidate it
    staleTime: Infinity,
    cacheTime: 0,
  });


  return (
    <Box sx={{ overflow: "hidden" }}>
      {/* Tabs */}
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          aria-label="supplier tabs"
          sx={{ marginBottom: "15px" }}
        >
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab.label} />
          ))}
        </Tabs>
        {/* <SelectAndOption
          heading={""}
          label="Select Year"
          options={["2022", "2023", "2024"]}
          fullWidth={true}
          width="10%"
        /> */}
      </Stack>
      {/* Header section */}
      {statusLoading ? (
        <Stack direction={"row"} flex={1} gap={2}>
          <Skeleton sx={{ flex: 1 }} height={100} />
          <Skeleton sx={{ flex: 1 }} height={100} />
          <Skeleton sx={{ flex: 1 }} height={100} />
          <Skeleton sx={{ flex: 1 }} height={100} />
        </Stack>
      ) : (
        <ComplianceHeader widgetsData={esgComplianceAssessmentTaskStatus} />
      )}
      {/* Main content */}
      <Box
        sx={{ height: "63vh", overflow: "scroll", padding: "20px 5px 0px 5px" }}
      >
        <MainLayoutSection
          height="98%"
          // left={
          //   <LeftSectionLayout>
          //     <ComplianceLeft />
          //   </LeftSectionLayout>
          // }
          // rightHidden={true}
          leftHidden={true}
          rightWidth="100%"
          right={
            <RightSectionLayout>
              {isLoading ? (
                <Skeleton sx={{ height: "100%" }} width={"100%"} />
              ) : (
                <ComplianceRight
                  esgComplianceAssessmentTask={esgComplianceAssessmentTask}
                  selectedTab={selectedTab}
                />
              )}
            </RightSectionLayout>
          }
          // rightWidth="73%"
        />
      </Box>
    </Box>
  );
}

export default ComplianceMain;
