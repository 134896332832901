import { useState } from "react";

import {
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  Stack,
  Skeleton,
} from "@mui/material";
import RenderEntity from "./AssignComps/Components/RenderEntity";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const TopicAccordion = ({ topic, handleCheck, selectedLevels }) => {
  const { _id, topic_name, metric_name, children = [], is_leaf_level } = topic;
  // Handle the checkbox click and propagate the check to child topics
  const handleCheckboxChange = (event) => {
    handleCheck(_id, is_leaf_level, children, event.target.checked);
  };

  // Determine if the current checkbox is selected based on the selectedLevels state
  const isChecked = selectedLevels[_id] || false;
  return (
    <Accordion
      // disabled={is_leaf_level}
      disableGutters={true}
      sx={{
        backgroundColor: "transparent",
        boxShadow: "none",
        margin: 0, // Remove margin from AccordionSummary
        padding: 0,
      }}
      slotProps={{ transition: { unmountOnExit: true } }}
    >
      <AccordionSummary
        sx={{
          backgroundColor: "transparent",
          boxShadow: "none",
          margin: 0, // Remove margin from AccordionSummary
          padding: 0,
          "& .MuiAccordionSummary-content": {
            margin: 0, // Remove margin from AccordionSummary content
          },
        }}
        expandIcon={!is_leaf_level ? <ArrowDropDownIcon /> : null}
        aria-controls="panel-content"
        id="panel-header"
      >
        <Stack direction={"row"} alignItems={"center"} gap={2}>
          <Checkbox
            checked={isChecked} // Bind checked state
            onClick={(event) => event.stopPropagation()}
            onChange={handleCheckboxChange}
          />
          <Typography>{topic_name || metric_name}</Typography>
        </Stack>
      </AccordionSummary>
      {!is_leaf_level && (
        <AccordionDetails>
          {/* Recursively render children until leave_level is true */}
          {children.map((child) => (
            <TopicAccordion
              key={child._id}
              topic={child}
              handleCheck={handleCheck}
              selectedLevels={selectedLevels}
              is_checked={isChecked}
            />
          ))}
        </AccordionDetails>
      )}
    </Accordion>
  );
};

const SelectActivity = ({
  activities = [],
  selectedActivities,
  handleCheckboxChange,
  isLoading,
}) => {
  const [selectedLastLevelIds, setSelectedLastLevelIds] = useState([]);
  const [selectedLevels, setSelectedLevels] = useState({}); // Track selected states for all levels

  // Recursive function to collect last-level IDs
  const collectLastLevelIds = (children) => {
    const lastLevelIds = new Set(); // Use a Set to store unique last-level IDs

    const traverseChildren = (childNodes) => {
      childNodes.forEach((child) => {
        if (child.is_leaf_level) {
          lastLevelIds.add(child._id); // Add last-level id to the Set
        } else {
          traverseChildren(child.children || []); // Recursively traverse child nodes
        }
      });
    };

    traverseChildren(children); // Start traversing the input children
    return Array.from(lastLevelIds); // Convert the Set back to an array
  };

  // Handle the checkbox selection logic
  const handleCheck = (id, isLastLevel, children, isChecked) => {
    const updatedSelectedLevels = { ...selectedLevels, [id]: isChecked }; // Update current checkbox state

    const updateChildSelection = (childTopics, checked) => {
      childTopics.forEach((child) => {
        updatedSelectedLevels[child._id] = checked; // Update checked state for each child
        if (!child.is_leaf_level && child.children) {
          // Recursively check/uncheck all children if not last level
          updateChildSelection(child.children, checked);
        }
      });
    };

    // If the current topic is a parent, check all children
    if (!isLastLevel) {
      // Update all descendants when a parent is checked/unchecked
      updateChildSelection(children, isChecked);
      // Collect last-level IDs
      const childLastLevelIds = collectLastLevelIds(children);
      if (isChecked) {
        setSelectedLastLevelIds((prevIds) => {
          const uniqueIds = new Set([...prevIds, ...childLastLevelIds]);
          handleCheckboxChange(Array.from(uniqueIds));
          return Array.from(uniqueIds); // Convert back to array to set unique IDs
        });
      } else {
        // Remove child last-level IDs if parent is unchecked
        setSelectedLastLevelIds((prevIds) => {
          const removeIds = prevIds.filter(
            (id) => !childLastLevelIds.includes(id)
          );
          handleCheckboxChange(removeIds);

          return removeIds;
        });
      }
    } else {
      // If it's the last level, just add/remove its ID
      setSelectedLastLevelIds((prevIds) => {
        const uniqueIds = isChecked
          ? [...prevIds, id]
          : prevIds.filter((existingId) => existingId !== id);

        handleCheckboxChange(uniqueIds);
        return uniqueIds;
      });
    }

    setSelectedLevels(updatedSelectedLevels); // Update the selected levels state
  };

  return (
    <Box>
      {/* Head */}
      <Box width={"100%"} bgcolor={"primary.lighter"} p={2}>
        <Typography variant="subtitle2" color={"primary.darker"}>
          Topic List
        </Typography>
      </Box>

      {/* Checkboxes */}
      <Box p={2} display="flex" flexDirection="column" gap={1}>
        {activities.map((activity) => {
          return (
            <TopicAccordion
              key={activity._id}
              topic={activity}
              handleCheck={handleCheck}
              selectedLevels={selectedLevels} // Pass down selected state
            />
          );
        })}
      </Box>

      {isLoading && (
        <Stack gap={3} mt={5}>
          <Skeleton variant="rectangular" width={"100%"} height={50} />
          <Skeleton variant="rectangular" width={"100%"} height={50} />
          <Skeleton variant="rectangular" width={"100%"} height={50} />

          <Skeleton variant="rectangular" width={"100%"} height={50} />
          <Skeleton variant="rectangular" width={"100%"} height={50} />
          <Skeleton variant="rectangular" width={"100%"} height={50} />
        </Stack>
      )}
    </Box>
  );
};

export default SelectActivity;
