import React from "react";
import { useLocation } from "react-router-dom";
import "../router/router.css";
import NavCustomDrawer from "../UI/drawer/drawer";
import CustomAppBar from "../UI/appBar/appBar";
import MobileDrawer from "../UI/menuBar/menuBar";

const Layout = ({
  children,
  isOpen,
  setIsOpen,
  logOut,
  login,
  surveyListing,
  viewAssessments,
  viewAssignedAssessments,
  setCurrentOrganzation,
  getNotifications,
  setNotification,
  setTotalNotification,
  setCurrentOrganisationUserType,
  setIsNewCompany,
  setSelectedOraganization,
  setSignupProcessNo,
  setIsSubscriptionActive,
  setRedirectValue,
  getOrganizations,
}) => {
  const location = useLocation();

  return (
    <div className="div-post-login">
      <NavCustomDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        logOut={logOut}
        token={login.token}
        user_type={login.user_type}
        login={login}
        redirect={surveyListing.redirect_value}
        setRedirectValue={setRedirectValue}
        current_organisation={login.current_organisation}
      />
      <CustomAppBar
        logOut={logOut}
        token={login.token}
        userName={login.name}
        userEmail={login.email}
        isOpen={isOpen}
        login={login.isLoggedin}
        organisation_details={surveyListing.organisation_details}
        parent_organisation={login.parent_organisation}
        viewAssessments={viewAssessments}
        surveyListing={surveyListing}
        viewAssignedAssessments={viewAssignedAssessments}
        user_type={login.user_type}
        setCurrentOrganzation={setCurrentOrganzation}
        loginDetails={login}
        getNotifications={getNotifications}
        setNotification={setNotification}
        setTotalNotification={setTotalNotification}
        setCurrentOrganisationUserType={setCurrentOrganisationUserType}
        setIsNewCompany={setIsNewCompany}
        setSelectedOraganization={setSelectedOraganization}
        setSignupProcessNo={setSignupProcessNo}
        setIsSubscriptionActive={setIsSubscriptionActive}
        getOrganizations={getOrganizations}
      />
      <MobileDrawer
        logOut={logOut}
        token={login.token}
        user_type={login.user_type}
        current_organisation={login.current_organisation}
      />
      {children}
    </div>
  );
};

export default Layout;
