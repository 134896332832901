import React, { useState, useEffect, useCallback } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import SnackbarCont from "../../containers/snackbar/snackbarCont";
import LoaderCon from "../../containers/loader/loader_cont";
import UnauthenticatedRoutes from "./routes/UnauthenticatedRoutes";
import SignupProcessRoutes from "./routes/SignupProcessRoutes";
import AuthenticatedRoutes from "./routes/AuthenticatedRoutes";
import Layout from "../layout/AuthRouteLayout";
import Controller from "../../containers/router/controllerCont";
import ErrorBoundary from "./ErrorBoundary";
import { useSelector } from "react-redux";

export const MContext = React.createContext();

const Router = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [addNewCompany, setAddNewCompany] = useState(false);
  const [redirectHandled, setRedirectHandled] = useState(false);

  const user = useSelector((state) => state.user);

  const cb = useCallback((isOpen) => {
    setIsOpen(isOpen);
  }, []);

  const autoMateLogout = () => {
    const { setLogOut, set_snack_bar } = props;
    localStorage.removeItem("user_token");
    setLogOut();
    set_snack_bar(true, "Please login again");
    return <Navigate to="/login" />;
  };

  useEffect(() => {
    if (
      props.login.isLoggedin &&
      window.location.pathname === "/add_company" &&
      !addNewCompany
    ) {
      setAddNewCompany(true);
    }
  }, [props.login.isLoggedin, addNewCompany]);

  useEffect(() => {
    if (
      props.snackbar.response_received &&
      props.snackbar.message === "Invalid user"
    ) {
      autoMateLogout();
    }
  }, [props.snackbar]);

  const userType = localStorage.getItem("current_organisation_user_type");

  // Helper function to determine routes
  const getRoutes = () => {
    if (!user?.isLoggedin) {
      return <UnauthenticatedRoutes />;
    } else if (
      user?.isLoggedin &&
      (user?.signup_process === 2 || user?.signup_process === 3)
    ) {
      return <SignupProcessRoutes />;
    } else {
      return (
        <Layout isOpen={isOpen} setIsOpen={cb} {...props}>
          <AuthenticatedRoutes
            login={props.login}
            userType={user?.user_type}
            {...props}
          />
        </Layout>
      );
    }
  };

  useEffect(() => {
    if (!redirectHandled) {
      setRedirectHandled(true);
    }
  }, [redirectHandled]);

  return (
    <div style={{ overflow: "hidden" }}>
      <BrowserRouter>
        <SnackbarCont />
        <LoaderCon />
        {!redirectHandled && <Controller />}
        {redirectHandled && (
          <ErrorBoundary>
            <Routes>
              {/* Root Route */}
              {/* <Route path="/" element={getRoutes()} /> */}
              {/* Wildcard Route */}
              <Route path="/*" element={getRoutes()} />
            </Routes>
          </ErrorBoundary>
        )}
      </BrowserRouter>
    </div>
  );
};

export default Router;
