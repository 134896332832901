import React from "react";

import { useState } from "react";

import { Box, Button } from "@mui/material";

// components
import DynamicDrawer from "components/UI/phase-3/Drawer/DynamicDrawer";
import LayoutStepper from "components/layout/stepperLayout/LayoutStepper";
import { AssignStakeholders, SelectActivity } from "./DrawerSteps";
import { useSelectActivity } from "./APIs/SelectActivityApi";
import { useAssignStakeholders } from "./APIs/AssignStakeholdersApi";
import { useFinalStepAPI } from "./APIs/finalAssign";

const AssignAuditDrawer = ({ open, onClose, selectedTab }) => {
  const steps = ["Select Auditor", "Configure Settings"];

  const [activeStep, setActiveStep] = useState(0);

  // hooks APIs
  const {
    activities,
    selectedActivities,
    // disableComplete,
    handleCheckboxChange,
    resetActivites,
    selectAllActivities,
  } = useSelectActivity(selectedTab, open); // Step 1 API
  const {
    checkedStakeholders,
    selectedStakeholders,
    // disableStakeholder,
    handleCheckboxChange: handleStakeholders,
    handleAddSelection,
    handleRemoveSelection,
    resetStakeHolderSelection,
  } = useAssignStakeholders(); // Step 2 API

  const { handleAssignStrategy } = useFinalStepAPI();

  const assignedAgainst = ["ACTIVITY", "KPI"];

  // Function to handle the "Complete List" button logic
  const handleCompleteList = async () => {
    if (activeStep === steps.length - 1) {
      const result = await handleAssignStrategy(
        selectedStakeholders[0]?._id,
        assignedAgainst[selectedTab],
        selectedActivities.map((task) => task?._id)
      ); // Reset to first step if last step is reached
      result?.status && resetFunction();
    } else {
      setActiveStep((prevStep) => prevStep + 1); // Move to next step
    }
  };

  // Function to handle going back to the previous step
  const handleBack = () => {
    setActiveStep((prevStep) => (prevStep > 0 ? prevStep - 1 : prevStep));
  };

  const renderStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <AssignStakeholders
            checkedStakeholders={checkedStakeholders}
            handleCheckboxChange={handleStakeholders}
            selectedStakeholders={selectedStakeholders}
            handleAddSelection={handleAddSelection}
            handleRemoveSelection={handleRemoveSelection}
          />
        );
      case 1:
        return (
         <></>
        );
      // case 2:
      //   return <SelectFrequency data={formData} handleData={setFormData} />;
      default:
        return null;
    }
  };

  // Render action buttons based on the active step
  const renderActionButtons = () => {
    if (activeStep === 0) {
      return (
        <>
          <Button onClick={() => resetActivites()}>Clear Selection</Button>
          {/* <Button onClick={() => selectAllActivities()}>Select All</Button> */}
          <Button
            variant="contained"
            onClick={handleCompleteList}
            // disabled={disableComplete}
          >
            Set configuration
          </Button>
        </>
      );
    } else if (activeStep === 1) {
      return (
        <>
          <Button onClick={handleBack}>Back</Button>
          <Button
            variant="contained"
            onClick={handleCompleteList}
            // disabled={disableStakeholder}
          >
            Send For Audit
          </Button>
        </>
      );
    }

    return null;
  };

  function resetFunction() {
    onClose();
    setActiveStep(0);
    resetActivites();
    resetStakeHolderSelection();
  }

  return (
    <DynamicDrawer
      hasDrawerOpen={open}
      handleClose={resetFunction}
      heading={"Send For Audit"}
      centerHead={
        <Box width={"50%"}>
          <LayoutStepper steps={steps} activeStep={activeStep} />
        </Box>
      }
      hasCustomActionButton={
        <Box width={"100%"}>
          <Box display={"flex"} justifyContent={"flex-end"} gap={2}>
            {renderActionButtons()}
          </Box>
        </Box>
      }
      hasDefaultActionButton={false}
      sx={{ px: 0 }}
    >
      <Box
        overflow={"hidden"}
        sx={{ width: { xs: "80vw", sm: "70vw", md: "50vw" } }}
      >
        {/* Main Render */}
        {renderStepContent(activeStep)}
      </Box>
    </DynamicDrawer>
  );
};

export default AssignAuditDrawer;
