import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import { Button, Stack, TextField } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import materialityAssessmentLeftStyles from "./materialityTopicStyles";

function CustomTopicDialog({
  open,
  onClose,
  materialityMainTopics = [],
  handleCustomTopic,
}) {
  const classes = materialityAssessmentLeftStyles();
  const [customTopic, setCustomtopic] = useState({
    topic_name: "",
    parent_topic_id: "",
  });
  const topicConfig = {
    "Social Capital": {
      className: classes.topicNameChipSocial,
      selectedClassName: classes.selectedTopicNameChipSocial,
    },
    "Human Capital": {
      className: classes.topicNameChipHuman,
      selectedClassName: classes.selectedTopicNameChipHuman,
    },
    Environmental: {
      className: classes.topicNameChipEnvironement,
      selectedClassName: classes.selectedTopicNameChipEnvironement,
    },
    "Leadership & Governance": {
      className: classes.topicNameChipGovernance,
      selectedClassName: classes.selectedTopicNameChipGovernance,
    },
    "Business Model & Innovation": {
      className: classes.topicNameChipBusiness,
      selectedClassName: classes.selectedTopicNameChipBusiness,
    },
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography className={classes.Typography3}>Custom topic</Typography>
      </DialogTitle>
      <DialogContent>
        <TextField
          name="topic_name"
          label="Description Title"
          variant="outlined"
          size="small"
          sx={{ marginTop: "15px" }}
          value={customTopic?.topic_name}
          onChange={(e) =>
            setCustomtopic((pre) => ({
              ...pre,
              [e.target.name]: e.target.value,
            }))
          }
          helperText="hint : location of operation, Diversity or Emission control Etc.."
        />

        <Typography variant="subtitle2" mt={1}>
          Category related to the topic
        </Typography>
        <Stack mt={1} gap={2}>
          {materialityMainTopics.map((topic, index) => {
            const config = topicConfig[topic.topic_name];

            if (!config) return null; // Skip rendering if the topic name isn't in the config

            return (
              <Chip
                key={topic._id}
                className={
                  topic._id !== customTopic?.parent_topic_id
                    ? config.className
                    : config.selectedClassName
                }
                icon={<FiberManualRecordIcon className={classes.circleIcon} />}
                label={topic.topic_name}
                onClick={(e) =>
                  setCustomtopic((pre) => ({
                    ...pre,
                    parent_topic_id: topic?._id,
                  }))
                }
              />
            );
          })}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack
          direction={"row"}
          gap={1}
          justifyContent={"center"}
          alignItems={"center"}
          width={"100%"}
        >
          <Button color="primary" variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <Button
            color="primary"
            variant="contained"
            disabled={
              customTopic?.topic_name === "" ||
              customTopic?.parent_topic_id === ""
            }
            onClick={async () => {
              const response = await handleCustomTopic(customTopic);
              response?.status && onClose();
            }}
          >
            Add Topic
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

export default CustomTopicDialog;
