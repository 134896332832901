import { Stack, Avatar, Typography } from "@mui/material";

function Messages({
  imgSrc = "https://i.pravatar.cc/300",
  userName,
  date,
  info,
  children,
}) {
  return (
    <>
      <Stack sx={{ width: "100%" }} direction={"row"} gap={2}>
        <Avatar alt="Remy Sharp" src={imgSrc} />
        <Stack gap={1} sx={{ width: "100%" }} justifyContent={"center"}>
          <Stack direction={"row"} gap={2} alignItems={"center"}>
            <Typography variant="subtitle1">{userName}</Typography>
            <Typography variant="subtitle2" color="#878787">
              {date}
            </Typography>
          </Stack>
          <Typography variant="body2">{info}</Typography>

          {children}
        </Stack>
      </Stack>
    </>
  );
}

export default Messages;
