import { Box, Typography, TextField, Chip, Autocomplete } from "@mui/material";
import { createStakeholders } from "actions/phase-3/orgDetails/orgDetailsAction";
import DynamicDrawer from "components/UI/phase-3/Drawer/DynamicDrawer";
import requiredField from "components/UI/phase-3/inputField/requiredShow";
import ImageUplaod from "components/UI/phase-3/ImageUpload/ImageUplaod";

// hooks
import useFormValidation from "customHooks/useFormValidation";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePaginationSearch } from "customHooks/usePaginationSearch";
import { useEntityListAPIs } from "components/screen/phase-3/OrgDetailsScreens/APIs/EntryListApis";
import { useUnitsAPIs } from "components/screen/phase-3/OrgDetailsScreens/APIs/UnitsApis";

const StakeholderDrawer = ({ open, onClose, fetchStakeholders }) => {
  const dispatch = useDispatch();

  const [image, setImage] = useState(null);
  const [selectedTypeID, setSelectedTypeID] = useState("");

  const { parent_id } = useSelector((state) => state.user);

  const initialState = {
    name: "",
    email: "",
    stakeholder_id: "",
    user_type: "",
    category: "",
    employee_type: "",
    type: "entity",
  };

  const validationRules = {
    name: { maxCharacters: 30, required: true },
    email: { type: "email", required: true },
    stakeholder_id: { maxCharacters: 30, required: true },
    user_type: { required: true },
    category: { required: true },
    employee_type: { required: true },
    type: { required: true },
  };

  const { formData, errors, handleChange, setFormData, hasErrors } =
    useFormValidation(initialState, validationRules);

  // Handle search keyword and fetch entity list
  const { keyword, handleSearch } = usePaginationSearch(true);
  const { entity_list } = useEntityListAPIs(keyword, 50, 1, true, true);

  const { units_list } = useUnitsAPIs(keyword, 50, 1, true, true);

  const disableSubmit =
    !formData.name ||
    !formData.email ||
    !formData.stakeholder_id ||
    !formData.user_type ||
    !formData.category ||
    !formData.employee_type ||
    !formData.type ||
    !selectedTypeID ||
    !image ||
    hasErrors;

  const handleSubmit = async () => {
    if (!disableSubmit) {
      // dispatch action
      await dispatch(
        createStakeholders(
          parent_id,
          formData.name,
          formData.email,
          formData.stakeholder_id,
          formData.user_type,
          formData.category,
          formData.employee_type,
          formData.type,
          formData.type === "entity" ? selectedTypeID : undefined,
          formData.type === "unit" ? selectedTypeID : undefined,
          { file: image[0] },
          fetchStakeholders
        )
      );

      // clear data
      setTimeout(() => {
        setFormData(initialState);
        setImage(null);
        setSelectedTypeID("");
        handleSearch("");
        onClose();
      }, 1000);
    }
  };

  const handleStakeholderType = (user_type) => {
    setFormData((prev) => ({ ...prev, user_type }));
  };

  const handleCategoryClick = (category) => {
    setFormData((prev) => ({ ...prev, category }));
  };

  const handleEmployeeClick = (employee_type) => {
    setFormData((prev) => ({ ...prev, employee_type }));
  };

  const handleTypeClick = (type) => {
    setFormData((prev) => ({ ...prev, type }));
    setSelectedTypeID("");
  };

  return (
    <DynamicDrawer
      hasDrawerOpen={open}
      handleClose={onClose}
      heading={"Add Stakeholders"}
      buttonText={"Add Stakeholders"}
      submitClick={handleSubmit}
      disableSubmit={disableSubmit}
    >
      <Box display={"flex"} flexDirection={"column"} gap={2}>
        {/* Stakeholders Name */}
        <Box>
          <Typography variant="body1" color="action.active" gutterBottom>
            Stakeholders Name{" "}
            {requiredField(Boolean(errors.name) || !formData.name)}
          </Typography>
          <TextField
            name="name"
            placeholder="Enter Name"
            value={formData.name}
            onChange={handleChange}
            error={Boolean(errors.name)}
            helperText={errors.name}
            required
            fullWidth
          />
        </Box>

        {/* Email */}
        <Box>
          <Typography variant="body1" color="action.active" gutterBottom>
            Email {requiredField(Boolean(errors.email) || !formData.email)}
          </Typography>
          <TextField
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            error={Boolean(errors.email)}
            helperText={errors.email}
            required
            fullWidth
          />
        </Box>

        {/* Stakeholders ID */}
        <Box>
          <Typography variant="body1" color="action.active" gutterBottom>
            Stakeholders ID{" "}
            {requiredField(
              Boolean(errors.stakeholder_id) || !formData.stakeholder_id
            )}
          </Typography>
          <TextField
            name="stakeholder_id"
            placeholder="Enter Stakeholder ID"
            value={formData.stakeholder_id}
            onChange={handleChange}
            error={Boolean(errors.stakeholder_id)}
            helperText={errors.stakeholder_id}
            required
            fullWidth
          />
        </Box>

        {/* Stakeholder Type */}
        <Box maxWidth={"40em"}>
          <Typography variant="body1" color="action.active" gutterBottom>
            Stakeholder Type{" "}
            {requiredField(Boolean(errors.user_type) || !formData.user_type)}
          </Typography>
          <Box display="flex" flexWrap="wrap" gap={1}>
            {["ADMIN", "GENERAL"].map((type) => (
              <Chip
                key={type}
                label={type}
                onClick={() => handleStakeholderType(type)}
                color={formData.user_type === type ? "primary" : "default"}
              />
            ))}
          </Box>
        </Box>

        {/* Category */}
        <Box maxWidth={"40em"}>
          <Typography variant="body1" color="action.active" gutterBottom>
            Category{" "}
            {requiredField(Boolean(errors.category) || !formData.category)}
          </Typography>
          <Box display="flex" flexWrap="wrap" gap={1}>
            {[
              "Board of Directors",
              "Executive Team",
              "Employees",
              "Investors & Shareholders",
              "Suppliers & Vendors",
              "Consultants",
              "Customers",
              "Government Agencies",
            ].map((cat) => (
              <Chip
                key={cat}
                label={cat}
                onClick={() => handleCategoryClick(cat)}
                color={formData.category === cat ? "primary" : "default"}
              />
            ))}
          </Box>
        </Box>

        {/* Employee Type */}
        <Box>
          <Typography variant="body1" color="action.active" gutterBottom>
            Employee Type{" "}
            {requiredField(
              Boolean(errors.employee_type) || !formData.employee_type
            )}
          </Typography>
          <Box display="flex" flexWrap="wrap" gap={1}>
            {["external", "internal"].map((type) => (
              <Chip
                key={type}
                label={type}
                onClick={() => handleEmployeeClick(type)}
                color={formData.employee_type === type ? "primary" : "default"}
                sx={{ textTransform: "capitalize" }}
              />
            ))}
          </Box>
        </Box>

        {/* Entity or Units */}
        <Box>
          <Typography variant="body1" color="action.active" gutterBottom>
            Select Data Type{" "}
            {requiredField(Boolean(errors.type) || !formData.type)}
          </Typography>
          <Box display="flex" gap={1}>
            <Chip
              label="entity"
              onClick={() => handleTypeClick("entity")}
              color={formData.type === "entity" ? "primary" : "default"}
            />
            <Chip
              label="unit"
              onClick={() => handleTypeClick("unit")}
              color={formData.type === "unit" ? "primary" : "default"}
            />
          </Box>
        </Box>

        {formData?.type === "entity" ? (
          <Box>
            {/* Entity ID */}
            <Typography variant="body1" color="action.active" gutterBottom>
              Select Entity ID {requiredField(!selectedTypeID)}
            </Typography>
            <Autocomplete
              options={entity_list?.entity_list || []} // Ensure options is an array
              getOptionLabel={(option) => option.entity_name}
              onInputChange={(event, value) => handleSearch(value)} // Update search keyword
              onChange={(event, newValue) =>
                setSelectedTypeID(newValue ? newValue?._id : "")
              } // Store selected entity's id
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search and select entity name"
                  fullWidth
                />
              )}
            />
          </Box>
        ) : (
          <Box>
            {/* Unit ID */}
            <Typography variant="body1" color="action.active" gutterBottom>
              Select Unit ID
            </Typography>
            <Autocomplete
              options={units_list?.unit_list || []} // Ensure options is an array
              getOptionLabel={(option) => option.unit_name}
              onInputChange={(event, value) => handleSearch(value)} // Update search keyword
              onChange={(event, newValue) =>
                setSelectedTypeID(newValue ? newValue?._id : "")
              } // Store selected entity's id
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search and select unit name"
                  fullWidth
                />
              )}
            />
          </Box>
        )}

        <Typography variant="body2" color={"text.faded"}>
          The unit in creation will be placed under this parent
        </Typography>

        {/* Image */}
        <ImageUplaod image={image} setImage={setImage} />
      </Box>
    </DynamicDrawer>
  );
};

export default StakeholderDrawer;
