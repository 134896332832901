export const SET_ESG_STRATEGY_PLANNING_ASSESSMENT_LIST =
  "SET_ESG_STRATEGY_PLANNING_ASSESSMENT_LIST";
export const SET_ESG_STRATEGY_PLANNING_ASSESSMENT_DETAILS =
  "SET_ESG_STRATEGY_PLANNING_ASSESSMENT_DETAILS";
export const SET_ORIGINAL_ESG_STRATEGY_PLANNING_ASSESSMENT_DETAILS =
  "SET_ORIGINAL_ESG_STRATEGY_PLANNING_ASSESSMENT_DETAILS";
export const SET_ESG_SELECTED_TOPIC = "SET_ESG_SELECTED_TOPIC";
export const SET_ESG_ACTIVITY = "SET_ESG_ACTIVITY";
export const SET_ESG_KPIS = "SET_ESG_KPIS";
export const SET_ESG_RISKS = "SET_ESG_RISKS";
export const SET_ESG_POLICIES = "SET_ESG_POLICIES";
export const SET_STRUCTURED_STAKEHOLDERS = "SET_STRUCTURED_STAKEHOLDERS";
export const SET_ESG_STRATEGY_PLAN_MONITORING_LIST = "SET_ESG_STRATEGY_PLAN_MONITORING_LIST"
export const SET_UNASSIGNED_STRATEGY_TOPIC = "SET_UNASSIGNED_STRATEGY_TOPIC"