import apiBolerplate from "utils/_helpers/apiBolerplate";
import {
  SET_ESG_STRATEGY_PLANNING_ASSESSMENT_LIST,
  SET_ESG_STRATEGY_PLANNING_ASSESSMENT_DETAILS,
  SET_ORIGINAL_ESG_STRATEGY_PLANNING_ASSESSMENT_DETAILS,
  SET_ESG_SELECTED_TOPIC,
  SET_ESG_ACTIVITY,
  SET_ESG_KPIS,
  SET_ESG_POLICIES,
  SET_ESG_RISKS,
  SET_STRUCTURED_STAKEHOLDERS,
  SET_ESG_STRATEGY_PLAN_MONITORING_LIST,
  SET_UNASSIGNED_STRATEGY_TOPIC,
} from "constants/phase-3/esgStrategyPlanning/esgStrategyPlanningConst";
import { setMaterialityAssessmentsYearList } from "../materialityAssessment/materialityAssessmentAction";

// ----------------- Payloads -----------------
export function setEsgStrategyPlanningAssessmentList(payload) {
  return {
    type: SET_ESG_STRATEGY_PLANNING_ASSESSMENT_LIST,
    payload: payload,
  };
}

export function setEsgStrategyPlanningAssessmentDetails(payload) {
  return {
    type: SET_ESG_STRATEGY_PLANNING_ASSESSMENT_DETAILS,
    payload: payload,
  };
}

export function setOriginalEsgStrategyPlanningDetails(payload) {
  return {
    type: SET_ORIGINAL_ESG_STRATEGY_PLANNING_ASSESSMENT_DETAILS,
    payload: payload,
  };
}

export function setEsgSelectedTopic(payload) {
  return {
    type: SET_ESG_SELECTED_TOPIC,
    payload,
  };
}

export function setEsgActivity(payload) {
  return {
    type: SET_ESG_ACTIVITY,
    payload,
  };
}

export function setEsgKpis(payload) {
  return {
    type: SET_ESG_KPIS,
    payload,
  };
}

export function setEsgPolicies(payload) {
  return {
    type: SET_ESG_POLICIES,
    payload,
  };
}

export function setEsgRisks(payload) {
  return {
    type: SET_ESG_RISKS,
    payload,
  };
}

export function setStructuredStakeholders(payload) {
  return {
    type: SET_STRUCTURED_STAKEHOLDERS,
    payload,
  };
}

export function setEsgStrategyPlanMonitoringList(payload) {
  return {
    type: SET_ESG_STRATEGY_PLAN_MONITORING_LIST,
    payload,
  };
}

export function setUnAssignedStrategyTopic(payload) {
  return {
    type: SET_UNASSIGNED_STRATEGY_TOPIC,
    payload,
  };
}

// ----------------- Actions -----------------

export function getEsgStrategyPlanningAssessmentList(
  entity_id,
  limit,
  starting_after,
  keyword
) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/esg_strategy_plan/get_esg_strategy_plan_assessments",
      bodyData: { entity_id, limit, starting_after, keyword },
      callback: (data) => {
        dispatch(
          setEsgStrategyPlanningAssessmentList({
            result: data?.result,
            total: data?.total || 0,
          })
        );
        dispatch(setEsgStrategyPlanningAssessmentDetails({}));
        dispatch(setOriginalEsgStrategyPlanningDetails({}));
        dispatch(setMaterialityAssessmentsYearList([]));
        dispatch(setEsgSelectedTopic(null));
      },
    });

    return data; // Return the data for further use
  };
}

export function createEsgStrategyPlanningAssessment(basicDetail) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/esg_strategy_plan/create_esg_strategy_plan_assessment",
      bodyData: { ...basicDetail },
      callback: (data) => {
        // final actions
        dispatch(setEsgStrategyPlanningAssessmentDetails(data?.result));
        dispatch(setOriginalEsgStrategyPlanningDetails(data?.result));
      },
    });

    return data; // Return the data for further use
  };
}

export function getEsgStrategyPlanningAssessmentDetails(
  esg_strategy_planning_assessment_id
) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/esg_strategy_plan/get_esg_strategy_plan_assessment_details",
      bodyData: { esg_strategy_planning_assessment_id },
      callback: (data) => {
        // final actions
        dispatch(setEsgStrategyPlanningAssessmentDetails(data?.result));
        dispatch(setOriginalEsgStrategyPlanningDetails(data?.result));
      },
    });

    return data; // Return the data for further use
  };
}

export function updateEsgStrategyPlanningAssessment(basicDetail) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/esg_strategy_plan/update_esg_strategy_plan_assessment",
      bodyData: {
        ...basicDetail,
        esg_strategy_planning_assessment_id: basicDetail?._id,
      },
      callback: (data) => {
        // final actions
      },
    });

    return data; // Return the data for further use
  };
}

// activity

export function addEsgActivity({ file, ...basicDetail }) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/esg_strategy_plan/add_activity",
      files: { file },
      bodyData: { ...basicDetail },
      callback: (data) => {
        // final actions
        dispatch(
          getEsgActivity(
            basicDetail?.materiality_topic_id,
            basicDetail?.topic_id,
            basicDetail?.esg_strategy_planning_assessment_id
          )
        );
      },
    });

    return data; // Return the data for further use
  };
}

export function getEsgActivity(
  materiality_topic_id,
  topic_id,
  esg_strategy_planning_assessment_id
) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/esg_strategy_plan/get_activities",
      bodyData: {
        materiality_topic_id,
        topic_id,
        esg_strategy_planning_assessment_id,
      },
      callback: (data) => {
        // final actions
        dispatch(setEsgActivity(data?.result));
      },
    });

    return data; // Return the data for further use
  };
}

export function updateEsgActivity(basicIds, { file, ...basicDetail }) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/esg_strategy_plan/edit_activity",
      files: { file },
      bodyData: {
        ...basicDetail,
      },
      callback: (data) => {
        // final actions
        dispatch(
          getEsgActivity(
            basicIds?.materiality_topic_id,
            basicIds?.topic_id,
            basicIds?.esg_strategy_planning_assessment_id
          )
        );
      },
    });

    return data; // Return the data for further use
  };
}

export function deleteEsgActivity(basicIds, activity_id) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/esg_strategy_plan/delete_activity",
      bodyData: {
        ...activity_id,
      },
      callback: (data) => {
        // final actions
        dispatch(
          getEsgActivity(
            basicIds?.materiality_topic_id,
            basicIds?.topic_id,
            basicIds?.esg_strategy_planning_assessment_id
          )
        );
      },
    });

    return data; // Return the data for further use
  };
}

//  Kpi

export function addEsgKpi({ file, ...basicDetail }) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/esg_strategy_plan/add_kpi",
      files: { file },
      bodyData: { ...basicDetail },
      callback: (data) => {
        // final actions
        dispatch(
          getEsgKpis(
            basicDetail?.materiality_topic_id,
            basicDetail?.topic_id,
            basicDetail?.esg_strategy_planning_assessment_id
          )
        );
      },
    });

    return data; // Return the data for further use
  };
}

export function getEsgKpis(
  materiality_topic_id,
  topic_id,
  esg_strategy_planning_assessment_id
) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/esg_strategy_plan/get_kpis",
      bodyData: {
        materiality_topic_id,
        topic_id,
        esg_strategy_planning_assessment_id,
      },
      callback: (data) => {
        // final actions
        dispatch(setEsgKpis(data?.result));
      },
    });

    return data; // Return the data for further use
  };
}

export function updateEsgKpi(basicIds, { file, ...basicDetail }) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/esg_strategy_plan/edit_kpi",
      files: { file },
      bodyData: {
        ...basicDetail,
      },
      callback: (data) => {
        // final actions
        dispatch(
          getEsgKpis(
            basicIds?.materiality_topic_id,
            basicIds?.topic_id,
            basicIds?.esg_strategy_planning_assessment_id
          )
        );
      },
    });

    return data; // Return the data for further use
  };
}

export function deleteEsgKpi(basicIds, activity_id) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/esg_strategy_plan/delete_kpi",
      bodyData: {
        ...activity_id,
      },
      callback: (data) => {
        // final actions
        dispatch(
          getEsgKpis(
            basicIds?.materiality_topic_id,
            basicIds?.topic_id,
            basicIds?.esg_strategy_planning_assessment_id
          )
        );
      },
    });

    return data; // Return the data for further use
  };
}

// policies

export function addEsgPolicies(basicDetail) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/esg_strategy_plan/add_policy",
      bodyData: {
        ...basicDetail,
      },
      callback: (data) => {
        // final actions
        dispatch(
          getEsgPolicies(
            basicDetail?.materiality_topic_id,
            basicDetail?.topic_id,
            basicDetail?.esg_strategy_planning_assessment_id
          )
        );
      },
    });

    return data; // Return the data for further use
  };
}

export function getEsgPolicies(
  materiality_topic_id,
  topic_id,
  esg_strategy_planning_assessment_id
) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/esg_strategy_plan/get_policies",
      bodyData: {
        materiality_topic_id,
        topic_id,
        esg_strategy_planning_assessment_id,
      },
      callback: (data) => {
        // final actions
        dispatch(setEsgPolicies(data?.result));
      },
    });

    return data; // Return the data for further use
  };
}

export function updateEsgPolicies(basicIds, basicDetails) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/esg_strategy_plan/edit_policy",
      bodyData: {
        ...basicDetails,
      },
      callback: (data) => {
        // final actions
        dispatch(
          getEsgPolicies(
            basicIds?.materiality_topic_id,
            basicIds?.topic_id,
            basicIds?.esg_strategy_planning_assessment_id
          )
        );
      },
    });

    return data; // Return the data for further use
  };
}

export function deleteEsgPolicies(basicIds, activity_id) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/esg_strategy_plan/delete_policy",
      bodyData: {
        ...activity_id,
      },
      callback: (data) => {
        // final actions
        dispatch(
          getEsgPolicies(
            basicIds?.materiality_topic_id,
            basicIds?.topic_id,
            basicIds?.esg_strategy_planning_assessment_id
          )
        );
      },
    });

    return data; // Return the data for further use
  };
}

// risk

export function addEsgRisk(basicDetail) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/esg_strategy_plan/add_risk",
      bodyData: {
        ...basicDetail,
      },
      callback: (data) => {
        // final actions
        dispatch(
          getEsgRisks(
            basicDetail?.materiality_topic_id,
            basicDetail?.topic_id,
            basicDetail?.esg_strategy_planning_assessment_id
          )
        );
      },
    });

    return data; // Return the data for further use
  };
}

export function getEsgRisks(
  materiality_topic_id,
  topic_id,
  esg_strategy_planning_assessment_id
) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/esg_strategy_plan/get_risks",
      bodyData: {
        materiality_topic_id,
        topic_id,
        esg_strategy_planning_assessment_id,
      },
      callback: (data) => {
        // final actions
        dispatch(setEsgRisks(data?.result));
      },
    });

    return data; // Return the data for further use
  };
}

export function updateEsgRisk(basicIds, basicDetails) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/esg_strategy_plan/edit_risk",
      bodyData: {
        ...basicDetails,
      },
      callback: (data) => {
        // final actions
        dispatch(
          getEsgRisks(
            basicIds?.materiality_topic_id,
            basicIds?.topic_id,
            basicIds?.esg_strategy_planning_assessment_id
          )
        );
      },
    });

    return data; // Return the data for further use
  };
}

export function deleteEsgRisk(basicIds, activity_id) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/esg_strategy_plan/delete_risk",
      bodyData: {
        ...activity_id,
      },
      callback: (data) => {
        // final actions
        dispatch(
          getEsgRisks(
            basicIds?.materiality_topic_id,
            basicIds?.topic_id,
            basicIds?.esg_strategy_planning_assessment_id
          )
        );
      },
    });

    return data; // Return the data for further use
  };
}

// plan monitoring

export function getStructuredStakeholders(entity_id) {
  return async (dispatch) => {
    await apiBolerplate({
      dispatch,
      url: "/stakeholder/get_structured_stakeholders",
      bodyData: {
        entity_id,
      },
      hideLoader: true,
      callback: (data) => {
        // final actions
        dispatch(setStructuredStakeholders(data?.result));
      },
    });
  };
}

export function getEsgStrategyPlanMonitoring(
  esg_strategy_planning_assessment_id,
  materiality_assessment_id,
  starting_after,
  limit,
  for_activity = false
) {
  return async (dispatch) => {
    await apiBolerplate({
      dispatch,
      url: "/esg_strategy_plan/get_esg_strategy_plan_monitoring",
      bodyData: {
        esg_strategy_planning_assessment_id,
        materiality_assessment_id,
        starting_after,
        limit,
        for_activity,
      },
      callback: (data) => {
        // final actions
        dispatch(setEsgStrategyPlanMonitoringList(data?.result));
      },
    });
  };
}

export function assignStrategyPlanTopic(
  entity_id,
  esg_strategy_planning_assessment_id,
  materiality_assessment_id,
  assigned_user_id,
  assigned_against,
  task_ids,
  receiving_date,
  frequency,
  ends,
  ends_on_date
) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/assign_stakeholder/assign_in_esg_plan_strategy",
      bodyData: {
        entity_id,
        esg_strategy_planning_assessment_id,
        materiality_assessment_id,
        assigned_user_id,
        assigned_against,
        task_ids,
        receiving_date,
        frequency,
        ends,
        ends_on_date,
      },
      callback: (data) => {
        dispatch(
          getEsgStrategyPlanMonitoring(
            esg_strategy_planning_assessment_id,
            materiality_assessment_id,
            0,
            5,
            assigned_against === "ACTIVITY" ? true : false
          )
        );
        // dispatch(setMaterialityAssessmentsYearList(data?.result));
      },
    });

    return data; // Return the data for further use
  };
}

export function getUnAssignedStrategyPlanTopic(
  esg_strategy_planning_assessment_id,
  materiality_assessment_id,
  for_activity
) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/esg_strategy_plan/get_unassigned_data",
      bodyData: {
        esg_strategy_planning_assessment_id,
        materiality_assessment_id,
        for_activity,
      },
      callback: (data) => {
        dispatch(setUnAssignedStrategyTopic(data?.result));
      },
    });

    return data; // Return the data for further use
  };
}
