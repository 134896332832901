import { useNavigate } from "react-router-dom";
import DynamicTable, {
  textStyle,
} from "components/UI/phase-3/dynamicTable/DynamicTable";
import { Box, Chip, Icon, TableCell, TableRow ,Stack,Skeleton} from "@mui/material";
import file from "images/FileVerticle.png";
import MoreVert from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import {
  getEsgComplianceTaskList,
  setEsgComplianceSelectedTask,
} from "actions/phase-3/task/esgCompliance/esgComplianceTaskAction";
import {  useQuery } from "@tanstack/react-query";


function ComplianceTask() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { entity_id } = useSelector((state) => state.user);

  // React Query for fetching the materiality assessment list
  const {
    data: { result: esgComplianceAssessmentTaskList = [] } = {}, // Default to empty object and values
    isLoading,
  } = useQuery({
    queryKey: ["esgComplianceAssessmentListTask", entity_id],
    queryFn: () => getEsgComplianceTaskList(entity_id),

    enabled: !!entity_id,
    // these for know query that data is fresh and API not call until we manually fetch or invalidate it
    staleTime: Infinity,
    cacheTime: 0,
  });


  return (
    <>
      <DynamicTable
        headings={[
          "Name Of The Report",
          // "Frequency",
          "Year Of Assessment",
          "Status",
          "Action",
        ]}
      >
        {esgComplianceAssessmentTaskList.map((rowData, rowIndex) => (
          <TableRow
            key={rowData?.esg_compliance_id}
            //   className={classes.tableRowHover}
            onClick={async () => {
              // dispatch(setSelectedCorporate(rowData));

        
              dispatch(setEsgComplianceSelectedTask(rowData));
              navigate(
                `/task/ESG_Compliance/${rowData.assessment_name?.replaceAll(" ", "_")}`
              );
            }}
            sx={{
              borderBottom: "1px solid #EBF1F8",
              cursor: "pointer",
            }}
            hover
          >
            <TableCell style={textStyle("main")} width={"60%"}>
              <Box display={"flex"} alignItems={"center"} sx={{ gap: 2 }}>
                <Icon
                  component={"img"}
                  fontSize="small"
                  src={file}
                  color="primary"
                ></Icon>
                {rowData.assessment_name}
              </Box>
            </TableCell>
            {/* <TableCell style={textStyle()}>{rowData.frequency}</TableCell> */}
            <TableCell style={textStyle()}>
              {rowData.year_of_assessment}
            </TableCell>

            <TableCell style={textStyle()}>
              <Chip
                sx={(theme) => ({
                  backgroundColor:
                    rowData.status === "active"
                      ? theme.palette.primary.lighter
                      : theme.palette.secondary.lighter,
                  padding: "6px 10px",
                  fontSize: "12px",
                  color:
                    rowData.status === "active"
                      ? theme.palette.primary.dark
                      : theme.palette.secondary.dark,
                  borderRadius: "80px",
                })}
                label={rowData.status || "Active"}
              />
            </TableCell>
            <TableCell style={textStyle()}>
              <MoreVert />
            </TableCell>
          </TableRow>
        ))}
      </DynamicTable>

      {isLoading && (
        <Stack gap={3} mt={5}>
          <Skeleton variant="rectangular" width={"100%"} height={30} />
          <Skeleton variant="rectangular" width={"100%"} height={30} />
          <Skeleton variant="rectangular" width={"100%"} height={30} />
        </Stack>
      )}

    </>
  );
}

export default ComplianceTask;
