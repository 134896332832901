import { useMutation, useQuery } from "@tanstack/react-query";

import {
  getMaterialityAssessmentDetails,
  getMaterialityAssessmentList,
  setMaterialityAssessmentDetails,
  setOriginalMaterialityDetails,
  setMaterialityAssessmentsYearList,
} from "actions/phase-3/materialityAssessment/materialityAssessmentAction";
import { useDispatch, useSelector } from "react-redux";

export const useMaterialityListAPIs = (keyword = "", limit = 5, page = 0) => {
  const { entity_id } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  // React Query for fetching the materiality assessment list
  const {
    data: {
      result: materialityAssessmentList = [],
      total: totalMaterialityAssessments = 0,
    } = {}, // Default to empty object and values
    isSuccess,
    ...param
    // isLoading,
  } = useQuery({
    queryKey: ["materialityAssessmentList", entity_id, limit, page, keyword],
    queryFn: () =>
      getMaterialityAssessmentList(
        entity_id,
        limit,
        (page - 1) * limit,
        keyword
      ),

    enabled: !!entity_id,
    // these for know query that data is fresh and API not call until we manually fetch or invalidate it
    staleTime: Infinity,
    cacheTime: 0,
  });


  
  const resetDetailState = () => {
    dispatch(setMaterialityAssessmentDetails({}));
    dispatch(setOriginalMaterialityDetails({}));
  dispatch(setMaterialityAssessmentsYearList([]));

  };


  const totalPages = materialityAssessmentList
    ? Math.ceil(totalMaterialityAssessments / limit)
    : 0;

  // Mutation for creating a materiality assessment
  const { mutateAsync } = useMutation({
    mutationFn: getMaterialityAssessmentDetails,
    onSuccess: (data) => {
      dispatch(setMaterialityAssessmentDetails(data?.result));
      dispatch(setOriginalMaterialityDetails(data?.result));
    },
    onError: (error) => {
      // Handle error
      console.error("Create failed:", error);
    },
    staleTime: Infinity,
    cacheTime: 0,
  });

  const fetchMaterialityAssessmentDetails = async (
    materiality_assessment_id
  ) => {
    let response = await mutateAsync(materiality_assessment_id);

    return response;
  };

  return {
    // states & data
    materialityAssessmentList,
    totalMaterialityAssessments,
    totalPages,
    // funcs
    // fetchMaterialityList,/
    fetchMaterialityAssessmentDetails,
    resetDetailState
  };
};
