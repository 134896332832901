import React from "react";
import { makeStyles, withStyles } from "@mui/styles";
import { Stepper, StepConnector, Step, StepLabel } from "@mui/material";

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#A1BFDF",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#A1BFDF",
    },
  },
  line: {
    borderColor: "#A1BFDF",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    textTransform: "capitalize",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  completedStep: {
    "& .MuiStepIcon-root.Mui-completed": {
      color: "#32A05F",
    },

    // Set your desired color for completed steps
  },
  inCompletedStep: {
    "& .MuiStepIcon-root.MuiStepIcon-active": {
      color: "#3374B9",
    },
  },
}));

export default function LayoutStepper({ steps = [], activeStep, orientation = "horizontal" }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} connector={<QontoConnector />} orientation={orientation}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          return (
            <Step key={label} {...stepProps}>
              <StepLabel
                className={
                  index < activeStep
                    ? classes.completedStep
                    : classes.inCompletedStep
                }
                {...labelProps}
              >
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}
