import {
  Avatar,
  Box,
  Button,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import YearPicker from "components/UI/phase-3/datePicker/YearPicker";
import Heading from "components/UI/phase-3/heading/Heading";
import InputField from "components/UI/phase-3/inputField/InputField";
import SelectAndOption from "components/UI/phase-3/selectAndOption/SelectAndOption";
import { useEsgComplianceBasicDetail } from "../APIs/HandleBasicDetailApi";
import { useMemo } from "react";
import useFormValidation from "customHooks/useFormValidation";
import { setEsgComplianceAssessmentDetails } from "actions/phase-3/esgCompliance/esgComplianceAction";
import { countries } from "components/UI/phase-3/countryInput/CountryData";
import OptimizeInitialRender from "../../supplier/right/companySetup/OptimizeInitialRender";
import { usePaginationSearch } from "customHooks/usePaginationSearch";
import FrameworksDialog from "components/widgets/reportdataleft/frameworksDialog";

function EsgComplianceBasicDetails() {
  const {
    esgComlianceAssessmentDetail,
    disableButton,
    frameWorkList,
    filteredFrameWorkList,
  } = useEsgComplianceBasicDetail();
  const { openDrawer, toggleDrawer, setOpenDrawer } = usePaginationSearch();
  const initialAssessmentState = useMemo(
    () => ({
      assessment_name: "",
      framework_id: "",
      country: "",
      year_of_assessment: "",
      scope_of_reporting: "",
      sustainability_team_message: "",
      esg_goal_overall: "",
      about_report: "",
    }),
    []
  );

  const validationRules = useMemo(
    () => ({
      assessment_name: { maxCharacters: 30, required: true },
      framework_id: { required: true },
      country: { required: true },
      year_of_assessment: { required: true },
      scope_of_reporting: { maxCharacters: 300, required: true },
      sustainability_team_message: {
        maxCharacters: 200,
        required: true,
      },
      esg_goal_overall: { required: true },
      about_report: { maxCharacters: 300, required: true },
    }),
    []
  );

  const { formData, errors, handleChange, setFormData, hasErrors } =
    useFormValidation(
      disableButton ? esgComlianceAssessmentDetail : initialAssessmentState,
      validationRules,
      setEsgComplianceAssessmentDetails
    );

  const handleFramworkClose = (id, template, defaultFilter) => {
    handleChange({
      target: {
        name: "framework_id",
        value: id,
      },
    });
    handleChange({
      target: {
        name: "template_type",
        value: template ? template : 'TEMPLATE_1',
      },
    });
    toggleDrawer();
  };

  const ComponentArray = [
    {
      item: (
        <InputField
          heading={"Assessment name"}
          label={"Enter name here"}
          value={formData?.assessment_name}
          error={errors.assessment_name}
          name={"assessment_name"}
          handleChange={handleChange}
        />
      ),
    },
    // {
    //   item: (
    //     <YearPicker
    //       heading={"Pick the year of assessment"}
    //       label="Select to open year picker"
    //       marginBottom="10px"
    //       marginTop="0"
    //       value={formData?.year_of_assessment}
    //       error={errors.year_of_assessment}
    //       name={"year_of_assessment"}
    //       handleChange={setFormData}
    //       actionCreator={setEsgComplianceAssessmentDetails}
    //     />
    //   ),
    // },
    {
      item: (
        <Stack direction={"row"} gap={2} alignItems={"center"}>
          <SelectAndOption
            heading={"Scope of reporting"}
            marginBottom="10px"
            marginTop="0"
            options={["Consolidated", "Standalone"]}
            value={formData?.scope_of_reporting}
            error={errors.scope_of_reporting}
            name={"scope_of_reporting"}
            handleChange={handleChange}
          />
          <YearPicker
            heading={"Pick the year of assessment"}
            label="Select to open year picker"
            marginBottom="10px"
            marginTop="0"
            value={formData?.year_of_assessment}
            error={errors.year_of_assessment}
            name={"year_of_assessment"}
            handleChange={setFormData}
            actionCreator={setEsgComplianceAssessmentDetails}
          />
        </Stack>
      ),
    },
    {
      item: (
        <Stack direction={"row"} gap={2} alignItems={"center"}>
          <SelectAndOption
            heading={"Country"}
            options={countries}
            value={formData?.country}
            error={errors.country}
            name={"country"}
            handleChange={handleChange}
            autocompleteEnabled={true}
            valueKey="country_code"
            labelKey="country_name"
            imgSrc="country_flag"
          />
          {openDrawer ? (
            <FrameworksDialog
              open={openDrawer}
              framework={filteredFrameWorkList}
              setOpen={setOpenDrawer}
              onClose={handleFramworkClose}
              template={""}
              // onClose={handleFramworkClose}
            />
          ) : (
            <Box
              style={{
                marginTop: "10px",
                marginBottom: "20px",
                width: "100%",
              }}
            >
              <Heading
                text={"Frameworks"}
                size="13px"
                marginBottom="10px"
                color="#999"
              />
              <TextField
                // placeholder={"label"}
                // label="Level"
                error={Boolean(errors.framework_id)}
                helperText={errors.framework_id}
                fullWidth
                variant="outlined"
                size="small"
                onClick={() => setOpenDrawer(true)}
                InputProps={{
                  startAdornment: (
                    <Stack
                      direction={"row"}
                      width={"100%"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      gap={1}
                    >
                      <Avatar
                        alt="selected"
                        src={
                          frameWorkList.find(
                            (f) => f._id === formData?.framework_id
                          )?.image
                        }
                        style={{
                          marginRight: 8,
                          width: "20px",
                          height: "20px",
                        }}
                      />

                      {
                        frameWorkList.find(
                          (f) => f._id === formData?.framework_id
                        )?.framework_name
                      }
                    </Stack>
                  ),
                }}
              />
            </Box>
          )}
        </Stack>
      ),
    },
    // {
    //   item: openDrawer ? (
    //     <FrameworksDialog
    //       open={openDrawer}
    //       framework={filteredFrameWorkList}
    //       setOpen={setOpenDrawer}
    //       onClose={handleFramworkClose}
    //       template={""}
    //       // onClose={handleFramworkClose}
    //     />
    //   ) : (
    //     // <Button onClick={() => setOpenDrawer(true)}>Open FrameWork</Button>
    //     <TextField
    //       // placeholder={"label"}
    //       error={Boolean(errors.framework_id)}
    //       helperText={errors.framework_id}
    //       fullWidth
    //       variant="outlined"
    //       size="small"
    //       onClick={() => setOpenDrawer(true)}
    //       InputProps={{
    //         startAdornment: (
    //           <Stack
    //             direction={"row"}
    //             width={"100%"}
    //             justifyContent={"flex-start"}
    //             alignItems={"center"}
    //             gap={1}
    //           >
    //             <Avatar
    //               alt="selected"
    //               src={
    //                 frameWorkList.find((f) => f._id === formData?.framework_id)
    //                   ?.image
    //               }
    //               style={{
    //                 marginRight: 8,
    //                 width: "20px",
    //                 height: "20px",
    //               }}
    //             />

    //             {
    //               frameWorkList.find((f) => f._id === formData?.framework_id)
    //                 ?.framework_name
    //             }
    //           </Stack>
    //         ),
    //       }}
    //     />
    //     // <SelectAndOption
    //     //   heading={"Type of Frame Work"}
    //     //   options={frameWorkList}
    //     //   value={formData?.framework_id}
    //     //   error={errors.framework_id}
    //     //   name={"framework_id"}
    //     //   handleChange={handleChange}
    //     //   autocompleteEnabled={true}
    //     //   valueKey="_id"
    //     //   labelKey="framework_name"
    //     //   imgSrc="image"
    //     // />
    //   ),
    // },

    {
      item: (
        <InputField
          heading={"Sustainability Team Message"}
          label={"Enter here"}
          rows={3}
          value={formData?.sustainability_team_message}
          error={errors.sustainability_team_message}
          name={"sustainability_team_message"}
          handleChange={handleChange}
        />
      ),
    },
    {
      item: (
        <InputField
          heading={"ESG Goals Overall"}
          label={"Enter here"}
          rows={3}
          value={formData?.esg_goal_overall}
          error={errors.esg_goal_overall}
          name={"esg_goal_overall"}
          handleChange={handleChange}
        />
      ),
    },
    {
      item: (
        <InputField
          heading={"About Report"}
          label={"Enter here"}
          rows={3}
          value={formData?.about_report}
          error={errors.about_report}
          name={"about_report"}
          handleChange={handleChange}
        />
      ),
    },
  ];

  return (
    <Box>
      <Heading
        text={"Basic Assessment Details"}
        size={"15px"}
        marginBottom={"8px"}
        bg
      />
      <Box className="scroll">
        <OptimizeInitialRender chunkSize={1}>
          {ComponentArray.map((c) => c.item)}
        </OptimizeInitialRender>
      </Box>
    </Box>
  );
}

export default EsgComplianceBasicDetails;
