import { useState } from "react";
import {
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";
import GPTChats from "../esgGPT/GPTLeft/GPTChats";

const getStartedStyle = makeStyles((theme) => ({
  listItem: {
    "&.Mui-selected": { backgroundColor: "#EBF1F8" },
    "&.Mui-selected:hover": { backgroundColor: "#dbebfc" },
    "&:hover": { backgroundColor: "#EBF1F8" },
    borderRadius: "10px",
    marginBottom: "10px",

    "&:hover $icon": {
      backgroundColor: "#3374B9",
      color: "#fff",
    },
    "&.Mui-selected $icon": {
      backgroundColor: "#3374B9",
      color: "#fff",
    },
  },
  icon: {
    height: "25px",
    width: "25px",
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50px",
  },

  ListItemText: {
    fontSize: "12px",
  },
}));

function GetStartedLeft({ selectedIndex, setSelectedIndex }) {
  const classes = getStartedStyle();

  const [open, setOpen] = useState(false);

  const handleClick = (index) => {
    if (index !== 4) {
      setSelectedIndex(index);
    }
    if (index === 4) {
      setOpen((prev) => !prev);
    }
  };

  return (
    <Grid item container md={2}>
      <List
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButton
          button
          selected={selectedIndex === 1}
          className={classes.listItem}
          onClick={() => handleClick(1)}
        >
          <ListItemIcon className={classes.icon}>1</ListItemIcon>
          <ListItemText
            primary="Onboarding Process"
            primaryTypographyProps={{
              style: { fontSize: "14px", fontWeight: 600 },
            }}
          />
        </ListItemButton>
        <ListItemButton
          button
          selected={selectedIndex === 2}
          className={classes.listItem}
          onClick={() => handleClick(2)}
        >
          <ListItemIcon className={classes.icon}>2</ListItemIcon>
          <ListItemText
            primary="User Guide"
            primaryTypographyProps={{
              style: { fontSize: "14px", fontWeight: 600 },
            }}
          />
        </ListItemButton>
        <ListItemButton
          button
          selected={selectedIndex === 3}
          className={classes.listItem}
          onClick={() => handleClick(4)}
        >
          <ListItemIcon className={classes.icon}>3</ListItemIcon>
          <ListItemText
            primary="ESG Wise Assistance"
            primaryTypographyProps={{
              style: { fontSize: "14px", fontWeight: 600 },
            }}
          />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {/* GPT Chats List */}
          <GPTChats classes={classes} handleClick={handleClick} />
        </Collapse>
      </List>
    </Grid>
  );
}

export default GetStartedLeft;
