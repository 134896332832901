import React from "react";

import { Box, Button, Chip, TableCell, TableRow } from "@mui/material";
import DynamicTable, {
  textStyle,
} from "components/UI/phase-3/dynamicTable/DynamicTable";
import { NoDataComponent } from "../../esgMaturity/shareSurvey/NoData";
import { usePaginationSearch } from "customHooks/usePaginationSearch";
import OverViewDrawer from "./overViewDrawer/OverViewDrawer";
import { useEsgStrategyPlanMonitoring } from "../APIs/HandlePlanMoitoringApi";

function KpiTable() {
  const { openDrawer, toggleDrawer } = usePaginationSearch();
  const { planMonitoringList, setSelectedIndex, selectedIndex } =
    useEsgStrategyPlanMonitoring(false);

  return (
    <Box sx={{}}>
      {planMonitoringList?.length > 0 && (
        <DynamicTable
          headings={[
            "Sl.no.",
            "Name of the Kpi",
            "person assigned",
            "material topic",
            "Status",
            "Baseline",
            "Target",
            "Overview",
          ]}
        >
          {planMonitoringList.map((rowData, rowIndex) => (
            <TableRow
              key={rowIndex}
              //   className={classes.tableRowHover}
              onClick={() => {
                // dispatch(setSelectedCorporate(rowData));
                // history.push(
                //   `/supplier_task/${rowData.company_name.replace(/ /g, "_")}`
                // );
              }}
              sx={{ borderBottom: "1px solid #EBF1F8" }}
            >
              <TableCell style={textStyle()}>{rowIndex + 1}</TableCell>
              <TableCell style={textStyle("main")}>
                <Box display={"flex"} alignItems={"center"} sx={{ gap: 10 }}>
                  {rowData?.title}
                </Box>
              </TableCell>
              <TableCell style={textStyle()}>
                {rowData?.personAssigned || "-"}
              </TableCell>
              <TableCell style={textStyle()}>
                {rowData?.material_topic}
              </TableCell>

              <TableCell style={textStyle()}>
                <Chip
                  sx={(theme) => ({
                    backgroundColor:
                      rowData.status === "In Progress"
                        ? theme.palette.primary.lighter
                        : theme.palette.secondary.lighter,
                    padding: "6px 10px",
                    fontSize: "12px",
                    color:
                      rowData.status === "In Progress"
                        ? theme.palette.primary.dark
                        : theme.palette.secondary.dark,
                    borderRadius: "80px",
                  })}
                  label={rowData.status}
                />
              </TableCell>
              <TableCell style={textStyle()}>{rowData.baseline}</TableCell>
              <TableCell style={textStyle()}>{rowData.target}</TableCell>
              <TableCell style={textStyle()}>
                <Button
                  onClick={() => {
                    setSelectedIndex(rowIndex);
                    toggleDrawer();
                  }}
                  variant="text"
                >
                  View
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </DynamicTable>
      )}

      {planMonitoringList?.length === 0 && (
        <NoDataComponent mainTitle={"No KPI's Created"} />
      )}

      {/*  overview drawer */}

      <OverViewDrawer
        open={openDrawer}
        handleClose={() => {
          toggleDrawer();
          setSelectedIndex(null);
        }}
        source={"KPI"}
        data={planMonitoringList[selectedIndex]}
      />
    </Box>
  );
}

export default KpiTable;
