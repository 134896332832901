import React from "react";

import {
  Box,
  Button,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Heading from "components/UI/phase-3/heading/Heading";
import { TopicBar } from "./component/TopicBar";
import { useMaterialityTopic } from "../../APIs/HandleTopicApi";
import NoListItem from "./component/NoListItem";
import { usePaginationSearch } from "customHooks/usePaginationSearch";
import CustomTopicDialog from "./component/CustomTopicDialog";
import OptimizeInitialRender from "components/widgets/phase-3/supplier/right/companySetup/OptimizeInitialRender";

const MaterialityMappingTopicLeft = () => {
  const { openDrawer, toggleDrawer } = usePaginationSearch();
  const {
    materialityTopics,
    materialityMainTopics,
    handleSelectTopic,
    handleSelectAllTopics,
    handleLoadMore,
    topicLimit,
    topicCount,
    handleCustomTopic,
  } = useMaterialityTopic(true);
  return (
    <Box sx={{ height: "100%" }}>
      <Heading text="Select Topics" />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextField
          name="topicFilter"
          variant="outlined"
          size="small"
          select
          sx={{ width: "40%" }}
          // InputLabelProps={{
          //   classes: {
          //     root: classes.label,
          //   },
          // }}
          // className={classes.search2}
          // SelectProps={{
          //   classes: { icon: classes.icon },
          // }}
          value={"All"}
          // onChange={(e) => {
          //   handleFilterChange(e.target.value);
          // }}
        >
          {[
            { filter: "All Topics", value: "All" },
            { filter: "Recommended", value: "recommended" },
          ].map((item) => (
            <MenuItem value={item?.value} key={item}>
              {item?.filter}
            </MenuItem>
          ))}
        </TextField>
        <Button
          sx={{ marginLeft: "auto" }}
          onClick={() => handleSelectAllTopics()}
          variant="text"
        >
          Select All
        </Button>
        <Button variant="text" onClick={toggleDrawer}>
          Custom Topic
        </Button>
      </Box>
      <Typography variant="body2" color={"#676767"} marginTop={"5px"}>
        The topics you see are recommended carefully based on your industry
        standard. Go through each to add or remove them accordingly. You can
        even add custom topic if required.
      </Typography>
      {materialityTopics.length > 0 && (
        <Box
          sx={{
            overflow: "scroll",
            height: "70%",
          }}
        >
          <OptimizeInitialRender chunkSize={2}>
            {materialityTopics.map((topic, index) => (
              <Box key={index} sx={{ padding: "10px 0px" }}>
                <TopicBar
                  handleAddTopic={handleSelectTopic}
                  topic={topic}
                  index={index}
                  materialityMainTopics={materialityMainTopics}
                />
              </Box>
            ))}
          </OptimizeInitialRender>
        </Box>
      )}
      {materialityTopics.length === 0 && <NoListItem message="No More Topic" />}
      {topicCount > topicLimit && (
        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Button variant="contained" onClick={handleLoadMore}>
            Load More
          </Button>
        </Stack>
      )}
      <CustomTopicDialog
        open={openDrawer}
        onClose={toggleDrawer}
        materialityMainTopics={materialityMainTopics}
        handleCustomTopic={handleCustomTopic}
      />
    </Box>
  );
};

export default MaterialityMappingTopicLeft;
