import { Box, Chip, Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { esgComplianceMetricGetProgress } from "actions/phase-3/esgCompliance/esgComplianceAction";
import { TopicProgress } from "components/widgets/phase-3/esgStrategyAndPlanning/planMonitoring/overViewDrawer/OverViewDrawer";
import { ViewTopicData } from "components/widgets/phase-3/Task/strategyPlanning/right/ExploreTopic";
import moment from "moment";
import { useSelector } from "react-redux";
import { BiSolidLabel } from "react-icons/bi";
import { useTheme } from "@mui/styles";
import Messages from "components/widgets/phase-3/Task/compliance/right/chatThread/chat/Messages";

const topicDetailData = [
  { key: "Activity Name", value: "title", type: false },
  { key: "Material Topic", value: "material_topic", type: false },
  { key: "Person Assigned", value: "person_assigned", type: false },
  { key: "Start Date", value: "start_date", type: true },
  { key: "End Date", value: "due_date", type: true },
  { key: "Frequency", value: "frequency", type: false },
  { key: "Currency", value: "currency", type: false },
  { key: "Budget Amount", value: "budget", type: false },
];

export default function OverviewMain({ status, _id }) {
  const theme = useTheme();

  const { esgComlianceAssessmentDetail } = useSelector(
    (state) => state?.escCompliance
  );

  const {
    data: { result: esgComplianceMetricProgressList = [] } = {},
    isLoading,
  } = useQuery({
    queryKey: [
      "esgComplianceMetricGetProgress",
      esgComlianceAssessmentDetail?._id,
      _id,
    ],
    queryFn: () =>
      esgComplianceMetricGetProgress(esgComlianceAssessmentDetail?._id, _id),
    enabled: !!esgComlianceAssessmentDetail?._id,
    // these for know query that data is fresh and API not call until we manually fetch or invalidate it
    staleTime: Infinity,
    cacheTime: 0,
  });

  let activeStep = 0;

  const handleSteps = () => {
    if (status === "Yet to Assign") activeStep = 0;
    if (status === "In Progress") activeStep = 1;
    if (status === "Recorded") activeStep = 3;
    return activeStep;
  };

  const progressSteps = [
    <>
      <Typography variant="subtitle1">Activity Assigned</Typography>
      <Typography variant="subtitle2" color={"primary"}></Typography>
    </>,
    <>
      <Typography variant="subtitle1">In Progress</Typography>
      <Typography variant="subtitle2" color={"primary"}></Typography>
    </>,
    <>
      <Typography variant="subtitle1">Recorded</Typography>
      {status === "In Progress" && (
        <Typography variant="subtitle1" color={"primary"}>
          -
        </Typography>
      )}
    </>,
  ];
  return (
    <Stack>
      <Stack direction={"row"} gap={2} mt={1}>
        <Stack flex={2}>
          {topicDetailData.map((topic) => (
            <ViewTopicData property={topic?.key} data={"--- x "} />
          ))}
        </Stack>
        <Stack flex={1}>
          <TopicProgress
            status={status}
            handleSteps={handleSteps}
            progressSteps={progressSteps}
          />
        </Stack>
      </Stack>

      {esgComplianceMetricProgressList.map(
        ({
          _id,
          organization_name,
          status,
          assigned_as,
          user_profile_img,
          user_name,
        }) => (
          <Box
            key={_id}
            sx={{
              border: `1px solid ${assigned_as === "CONTRIBUTOR" ? theme.palette.primary.light : theme.palette.secondary.light}`,
              borderRadius: "8px",
            }}
            mb={2}
          >
            <Stack direction={"row"} gap={2} alignItems={"flex-start"} p={2}>
              <BiSolidLabel
                style={{
                  color:
                    assigned_as === "CONTRIBUTOR"
                      ? theme.palette.primary.light
                      : theme.palette.secondary.light,
                }}
              />
              <Stack gap={2}>
                <Typography variant="h4">{organization_name}</Typography>
                <Chip
                  sx={(theme) => ({
                    backgroundColor:
                      status !== "In Progress"
                        ? theme.palette.primary.lighter
                        : theme.palette.warning.lighter,
                    // padding: "6px 10px",
                    fontSize: "12px",
                    color:
                      status !== "In Progress"
                        ? theme.palette.primary.dark
                        : theme.palette.warning.dark,
                    borderRadius: "80px",
                    alignSelf:"flex-start"
                  })}
                  size="small"
                  label={status}
                />
                <Messages
                  imgSrc={user_profile_img?.url || ""}
                  userName={user_name}
                  info={assigned_as}
                />
              </Stack>
            </Stack>
          </Box>
        )
      )}
    </Stack>
  );
}
