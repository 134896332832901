import Heading from "components/UI/phase-3/heading/Heading";
import { Box, Typography, Chip, Stack } from "@mui/material";
import RadioButtonsGroup from "components/UI/phase-3/RadioButtonGroup/RadioButtonGroup";
import InputField from "components/UI/phase-3/inputField/InputField";

const questions = [
  {
    question: (topic) => `What's the impact of ${topic} on Environment ?`,
    option: [
      { value: 1, option: "Strongly Disagree" },
      { value: 2, option: "Disagree" },
      { value: 3, option: "Neutral" },
      { value: 4, option: "Agree" },
      { value: 5, option: "Strongly Agree" },
    ],
    name: "weightage",
  },
  {
    question: (topic) => `How material is ${topic} to the company ?`,
    option: [
      { value: 1, option: "Strongly Disagree" },
      { value: 2, option: "Disagree" },
      { value: 3, option: "Neutral" },
      { value: 4, option: "Agree" },
      { value: 5, option: "Strongly Agree" },
    ],
    name: "relevance",
  },
];

function MaterialityQuestion({
  index = "",
  dataMain = "",
  hasEditable = false,
  // dynamic props
  formData = {},
  error,
  handleChange,
  setFormData,
}) {
  return (
    <>
      <Heading
        text={`${index + 1}.  ${dataMain?.topic_name}`}
        size="14px"
        weight={600}
        horizontalLine={true}
      />

      {questions.map((data) => (
        <>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              justifyContent: "space-between",
              alignItems: "center",
              pointerEvents: !hasEditable && "none",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                border: "1px solid #E9E9E9",
                borderRadius: "8px",
                padding: "16px",
                width: "100%",
              }}
            >
              {data?.question(dataMain?.topic_name)}
            </Typography>
            {/* <Chip
              sx={{
                backgroundColor: "#EBF1F8",
                padding: "6px 10px",
                fontSize: "12px",
                color: "#3374B9",
                borderRadius: "80px",
              }}
              label={data?.weightage}
            /> */}
          </Box>
          <Box sx={{ pointerEvents: !hasEditable && "none", padding: "10px" }}>
            <RadioButtonsGroup
              name={data?.name}
              handleChange={handleChange}
              value={formData[data?.name] || ""}
              option={data?.option}
            />
          </Box>
        </>
      ))}

      <Box sx={{ padding: "10px", pointerEvents: !hasEditable && "none" }}>
        <Heading
          text={"Topic Category"}
          size="13px"
          marginBottom="10px"
          color="#999"
        />

        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          gap={3}
        >
          <Chip
            sx={(theme) =>
              formData?.level === "Risk" && {
                backgroundColor: theme.palette.error.lighter,
                padding: "6px 10px",
                fontSize: "12px",
                color: theme.palette.error.dark,
                borderRadius: "80px",
              }
            }
            onClick={() => {
              setFormData((pre) => ({ ...pre, level: "Risk" }));
            }}
            label={"Risk"}
          />
          <Chip
            sx={(theme) =>
              formData?.level === "Opportunity" && {
                backgroundColor: theme.palette.secondary.lighter,
                padding: "6px 10px",
                fontSize: "12px",
                color: theme.palette.secondary.dark,
                borderRadius: "80px",
              }
            }
            onClick={() =>
              setFormData((pre) => ({ ...pre, level: "Opportunity" }))
            }
            label={"Opportunity"}
          />
        </Stack>
        <InputField
          heading={`${formData?.level} to this topic`}
          label={"Enter here"}
          rows={3}
          value={formData?.overview}
          error={error?.overview}
          name={"overview"}
          handleChange={handleChange}
        />

        <InputField
          heading={"Type of Approach"}
          label={"Enter here"}
          rows={3}
          value={formData?.approach}
          error={error?.approach}
          name={"approach"}
          handleChange={handleChange}
        />
        <InputField
          heading={"Type of Mitigation"}
          label={"Enter here"}
          rows={3}
          value={formData?.mitigation}
          error={error?.mitigation}
          name={"mitigation"}
          handleChange={handleChange}
        />
      </Box>
    </>
  );
}

export default MaterialityQuestion;
