import { Avatar, Checkbox, ListItem, Stack, Typography } from "@mui/material";

const ShowStakeholder = ({ stakeholder, isChecked, handleCheckboxChange }) => {
  return (
    <ListItem sx={{ ml: 2.2 }}>
      <Checkbox
        checked={isChecked(stakeholder._id)}
        onChange={() => handleCheckboxChange(stakeholder)}
      />
      <Avatar
        src={stakeholder.profile_img?.url}
        alt={stakeholder.name}
        sx={{ mr: 1 }}
      />
      <Stack>
        <Typography variant="subtitle2" fontWeight={600}>
          {stakeholder.name}
        </Typography>
        <Typography variant="body2" color={"action.active"}>
          {stakeholder.category}
        </Typography>
      </Stack>
    </ListItem>
  );
};
export default ShowStakeholder;
