import { Button, Icon, Stack } from "@mui/material";

import Heading from "components/UI/phase-3/heading/Heading";
import SearchTextField from "components/UI/phase-3/textfield/searchTextField";
import plugIn from "images/plugIn.png";

function ApiIntegrationMain() {
  return (
    <>
      {/* Heading */}
      <Heading text="">
        <Stack direction={"row"} alignItems={"center"} gap={2}>
          <SearchTextField sx={{ minWidth: "30em" }} />
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<Icon component={"img"} src={plugIn} />}
          >
            Download Sheet
          </Button>
        </Stack>
      </Heading>
    </>
  );
}

export default ApiIntegrationMain;
