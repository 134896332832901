import { Box, Chip, Stack, Typography } from "@mui/material";
import LayoutStepper from "components/layout/stepperLayout/LayoutStepper";
import DynamicDrawer from "components/UI/phase-3/Drawer/DynamicDrawer";
import Heading from "components/UI/phase-3/heading/Heading";
import SearchTextField from "components/UI/phase-3/textfield/searchTextField";
import { ViewTopicData } from "components/widgets/phase-3/Task/strategyPlanning/right/ExploreTopic";
import { BiSolidLabel } from "react-icons/bi";
import moment from "moment";
import Messages from "components/widgets/phase-3/Task/compliance/right/chatThread/chat/Messages";
import { useTheme } from "@mui/styles";
import DocumentUpload from "components/UI/phase-3/documentUpload/DocumentUpload";

const propertyObject = {
  Activity: [
    { key: "Activity Name", value: "title", type: false },
    { key: "Material Topic", value: "material_topic", type: false },
    { key: "Person Assigned", value: "person_assigned", type: false },
    { key: "Start Date", value: "start_date", type: true },
    { key: "End Date", value: "due_date", type: true },
    { key: "Frequency", value: "frequency", type: false },
    { key: "Currency", value: "currency", type: false },
    { key: "Budget Amount", value: "budget", type: false },
  ],
  KPI: [
    { key: "Name of the KPI", value: "title", type: false },
    { key: "Material Topic", value: "material_topic", type: false },
    { key: "Person Assigned", value: "person_assigned", type: false },
    { key: "Baseline Value", value: "baseline", type: false },
    { key: "Target Value", value: "target", type: false },
    { key: "Unit", value: "unit", type: false },

    { key: "Start Date", value: "start_date", type: true },
    { key: "End Date", value: "due_date", type: true },
    { key: "Frequency", value: "frequency", type: false },
    { key: "Currency", value: "currency", type: false },
    { key: "Budget Amount", value: "budget", type: false },
  ],
};

export const TopicProgress = ({ status, handleSteps, progressSteps }) => (
  <Stack>
    <Chip
      sx={(theme) => ({
        backgroundColor:
          status === "In Progress"
            ? theme.palette.primary.lighter
            : theme.palette.secondary.lighter,
        padding: "6px 10px",
        fontSize: "12px",
        color:
          status === "In Progress"
            ? theme.palette.primary.dark
            : theme.palette.secondary.dark,
        borderRadius: "80px",
        alignSelf: "flex-start",
      })}
      label={status}
    />
    <LayoutStepper
      steps={progressSteps}
      orientation={"vertical"}
      activeStep={handleSteps()}
    />
  </Stack>
);

function OverViewDrawer({ open, handleClose, source, data = {} }) {
  const theme = useTheme();
  let activeStep = 0;

  const handleSteps = () => {
    if (data?.status === "Yet to Assign") activeStep = 0;
    if (data?.status === "In Progress") activeStep = 1;
    if (data?.status === "Completed") activeStep = 3;
    return activeStep;
  };

  const progressSteps = [
    <>
      <Typography variant="subtitle1">Activity Assigned on</Typography>
      <Typography variant="subtitle2" color={"primary"}>
        {data?.assigned_on
          ? moment(data?.assigned_on).format("DD MMMM YYYY")
          : "-"}
      </Typography>
    </>,
    <>
      <Typography variant="subtitle1">Progress last updated</Typography>
      <Typography variant="subtitle2" color={"primary"}>
        {data?.progress_last_updated
          ? moment(data?.progress_last_updated).format("DD MMMM YYYY")
          : "-"}
      </Typography>
    </>,
    <>
      <Typography variant="subtitle1">Completion</Typography>
      {data.status === "In Progress" && (
        <Typography variant="subtitle1" color={"primary"}>
          -
        </Typography>
      )}
    </>,
  ];
  return (
    <>
      <DynamicDrawer
        hasDrawerOpen={open}
        handleClose={handleClose}
        heading={`${source} Overview`}
        hasDefaultActionButton={false}
      >
        <Box width={"85vw"} mt={1} height={"98%"}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            height={"100%"}
          >
            {/* left side detail data */}

            <Stack width={"48%"} sx={{ overflow: "scroll" }}>
              {propertyObject[source].map(({ key, value, type }) => (
                <ViewTopicData
                  property={key}
                  data={data[value] || "-"}
                  hasDate={type}
                />
              ))}
              <DocumentUpload viewOnly={true} value={data["file"] || ""} />
            </Stack>
            <Stack
              justifyContent={"space-between"}
              width={"51%"}
              height={"100%"}
              p={1}
              gap={2}
            >
              {/* progress bar */}
              <TopicProgress
                status={data?.status}
                handleSteps={handleSteps}
                progressSteps={progressSteps}
              />
              {/* frequency widgets */}
              <Stack height={"62%"}>
                <Heading text={`${source} Frequency`} />
                <SearchTextField />
                <Stack
                  mt={2}
                  height={"100%"}
                  gap={1}
                  sx={{ overflow: "scroll" }}
                >
                  {data?.submissions &&
                    data?.submissions.map((suggestions) => (
                      <Box
                        sx={{
                          border: "1px solid #E9E9E9",
                          padding: "10px",
                          borderRadius: "8px",
                        }}
                      >
                        <Stack direction={"row"} gap={3}>
                          <BiSolidLabel
                            size={"30px"}
                            style={{ color: theme.palette.primary.light }}
                          />
                          <Messages
                            imgSrc={data?.user_profile?.url || ""}
                            userName={data?.person_assigned}
                            date={moment(suggestions?.submitted_on).format(
                              "DD MMM YY"
                            )}
                            info={suggestions?.activity_update}
                          >
                            <DocumentUpload
                              viewOnly={true}
                              value={suggestions?.file || ""}
                            />
                          </Messages>
                        </Stack>
                      </Box>
                    ))}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </DynamicDrawer>
    </>
  );
}

export default OverViewDrawer;
