import { getStructuredStakeholders } from "actions/phase-3/esgstrategyPlanning/esgstrategyPlanningAction";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useAssignStakeholders = () => {
  const [checkedStakeholders, setCheckedStakeholders] = useState([]);
  const [selectedStakeholders, setSelectedStakeholders] = useState([]);
  const [selectedApprovers, setSelectedApprovers] = useState([]);

  const dispatch = useDispatch();
  const { entity_id } = useSelector((state) => state.user);
  const { structured_stakeholders } = useSelector(
    (state) => state.esgStrategyPlanning
  );

  const handleCheckboxChange = (item, isStakeholder = false) => {
    const { _id, stakeholders } = item;

    if (isStakeholder) {
      // For individual stakeholder selection
      const isChecked = checkedStakeholders.some(
        (stakeholder) => stakeholder._id === _id
      );

      if (isChecked) {
        // Remove the stakeholder if it's already checked
        setCheckedStakeholders((prevChecked) =>
          prevChecked.filter((stakeholder) => stakeholder._id !== _id)
        );
      } else {
        // Add the stakeholder
        setCheckedStakeholders((prevChecked) => [
          ...prevChecked,
          { _id, ...item },
        ]);
      }
    } else if (_id && stakeholders?.length) {
      // For entity selection
      const isEntityChecked = stakeholders.every((stakeholder) =>
        checkedStakeholders.some((s) => s._id === stakeholder._id)
      );

      if (isEntityChecked) {
        // Remove all the stakeholders under this entity
        const stakeholdersIds = stakeholders.map((s) => s._id);
        setCheckedStakeholders((prevChecked) =>
          prevChecked.filter(
            (stakeholder) => !stakeholdersIds.includes(stakeholder._id)
          )
        );
      } else {
        // Add all the stakeholders under this entity
        const newStakeholders = stakeholders.filter(
          (s) => !checkedStakeholders.some((checked) => checked._id === s._id)
        );
        setCheckedStakeholders((prevChecked) => [
          ...prevChecked,
          ...newStakeholders,
        ]);
      }
    }
  };

  const handleAddSelection = (source) => {
    // Add checkedStakeholders to selectedStakeholders without duplicating
    // const newSelections = checkedStakeholders.filter(
    //   (checked) => !selectedStakeholders.some((sel) => sel._id === checked._id)
    // );
    if (source === "contributor") {
      setSelectedStakeholders(() => [...checkedStakeholders]);
    } else {
      setSelectedApprovers(() => [...checkedStakeholders]);
    }

    setCheckedStakeholders([])

  };

  const handleRemoveSelection = (stakeholder, source) => {
    if (source === "contributor") {
      // Remove from both selectedStakeholders and checkedStakeholders
      setSelectedStakeholders((prevSelected) =>
        prevSelected.filter((s) => s._id !== stakeholder._id)
      );
    } else {
      // Remove from both selectedStakeholders and checkedStakeholders
      setSelectedApprovers((prevSelected) =>
        prevSelected.filter((s) => s._id !== stakeholder._id)
      );
    }
    handleCheckboxChange(stakeholder, true); // Remove it from checkedStakeholders as well
  };

  const disableStakeholder = selectedStakeholders?.length === 0;

  useEffect(() => {
    if (!structured_stakeholders || structured_stakeholders?.length === 0) {
      dispatch(getStructuredStakeholders(entity_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity_id]);

  const resetStakeHolderSelection = () => {
    setSelectedStakeholders([]);
    setCheckedStakeholders([]);
    setSelectedApprovers([])
  };

  return {
    checkedStakeholders,
    selectedStakeholders,
    selectedApprovers,
    disableStakeholder,
    handleCheckboxChange,
    handleAddSelection,
    handleRemoveSelection,
    resetStakeHolderSelection,
  };
};
