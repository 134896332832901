import {
  SET_ASSIGNED_STAKEHOLDERS,
  SET_STAKEHOLDERS,
  SET_MATURITY_SURVEY,
  SET_MATURITY_SURVEY_ID,
  SET_ESG_MATURITY_SCORE,
} from "constants/phase-3/esgMaturity/esgMaturityConst";

const initial_state = {
  assigned_stakeholders: [],
  stakeholders_list: [],
  maturity_survey: [],
  survey_id: null,
  esg_maturity_score: null,
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case SET_ASSIGNED_STAKEHOLDERS:
      return (state = {
        ...state,
        assigned_stakeholders: action.payload,
      });

    case SET_STAKEHOLDERS:
      return (state = {
        ...state,
        stakeholders_list: action.payload,
      });

    case SET_MATURITY_SURVEY:
      return (state = {
        ...state,
        maturity_survey: action.payload,
      });

    case SET_MATURITY_SURVEY_ID:
      return (state = {
        ...state,
        survey_id: action.payload,
      });

    case SET_ESG_MATURITY_SCORE:
      return (state = {
        ...state,
        esg_maturity_score: action.payload,
      });

    default:
      return state;
  }
}

// ------------------- Important Key Points -------------------
