import { Tabs, Tab, Stack } from "@mui/material";
import useTabChanges from "customHooks/useTabChanges";
import SelectAndOption from "components/UI/phase-3/selectAndOption/SelectAndOption";
import useESgStrategyTaskTabs from "./API/HandleEsgStrategyTaskTabsApi";

function EsgStrategyPlanningHeader() {
  const { selectedTab, handleChange } = useTabChanges();
  const {
    getYearOption,
    selectedYear,
    handleChangeYear,
    selectedTabs: tabs = [],
    
  } = useESgStrategyTaskTabs(selectedTab);

  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          aria-label="supplier tabs"
          sx={{ marginBottom: "15px" }}
        >
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab} />
          ))}
        </Tabs>
        <SelectAndOption
          value={selectedYear}
          heading={""}
          label="Select Year"
          options={getYearOption}
          fullWidth={true}
          width="10%"
          handleChange={handleChangeYear}
        />
      </Stack>
    </>
  );
}

export default EsgStrategyPlanningHeader;
