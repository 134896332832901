import { getEsgStrategyAssignedTasks } from "actions/phase-3/task/esgStrategyTask/esgStrategyTaskAction";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const useESgStrategyTaskTabs = (selectedTab) => {
  const dispatch = useDispatch();
  const {
    esgStrategyFrequencyRange = {},
    esgStrategySelectedTask,
    selectedTopicCategory,
  } = useSelector((state) => state?.esgStrategyTask);

  const getYearOption = Object.keys(esgStrategyFrequencyRange);

  const [selectedYear, setSelectedYear] = useState(getYearOption[0] || "");

  const handleChangeYear = (e) => {
    setSelectedYear(e?.target.value);
  };


  useEffect(() => {
    selectedYear &&
      dispatch(
        getEsgStrategyAssignedTasks(
          esgStrategySelectedTask?.esg_strategy_planning_assessment_id,
          esgStrategySelectedTask?.materiality_assessment_id,
          esgStrategyFrequencyRange[selectedYear][selectedTab],
          selectedYear,
          selectedTopicCategory === "Activities" ? true : false
        )
      );
  }, [selectedYear, selectedTab, selectedTopicCategory]);

  return {
    getYearOption,
    selectedYear,
    handleChangeYear,
    selectedTabs: esgStrategyFrequencyRange[selectedYear],
    selectedTopicCategory,
  };
};

export default useESgStrategyTaskTabs;
