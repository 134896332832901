import { Box } from "@mui/material";
import Heading from "components/UI/phase-3/heading/Heading";
import EsgScore from "./EsgScore";
import EsgBreakdown from "./EsgBreakdown";
import Stakeholders from "./Stakeholders";
import Suggestions from "./Suggestions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMaturityMatrix } from "actions/phase-3/esgMaturity/esgMaturityAction";

function EsgMaturityDisclosure() {
  const dispatch = useDispatch();
  const { entity_id } = useSelector((state) => state.user);
  const { survey_id } = useSelector((state) => state.esgMaturity?.survey_id);

  useEffect(() => {
    dispatch(getMaturityMatrix({ entity_id, survey_id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Heading
        text={"ESG Maturity Disclosure"}
        size={"15px"}
        marginBottom={"8px"}
        bg
      />
      <Box className="scroll">
        {/* ESG Score */}
        <Heading
          text={"ESG Score"}
          marginTop={"8px"}
          size={"13px"}
          horizontalLine
        />
        <EsgScore />

        {/* ESG Breakdown */}
        <Heading
          text={"ESG Breakdown"}
          marginTop={"30px"}
          size={"13px"}
          horizontalLine
        />
        <EsgBreakdown />

        {/* Stakeholders */}
        <Heading
          text={"Stakeholders"}
          size={"13px"}
          marginTop={"30px"}
          horizontalLine
        />
        <Stakeholders />

        {/* Suggestions */}
        <Heading
          text={"Suggestions"}
          size={"13px"}
          marginTop={"30px"}
          horizontalLine
        />
        <Suggestions />
      </Box>
    </Box>
  );
}

export default EsgMaturityDisclosure;
