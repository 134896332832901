import { Chip, Skeleton, Stack, Typography } from "@mui/material";

const topicWidgetData = [
  { key: "Total Disclosures", value: "total_disclosures", type: 'primary' },

  {
    key: "Total Assigned Disclosures",
    value: "total_assigned_disclosures",
    type: 'primary',
  },
  { key: "Total Recorded", value: "total_recorded", type: 'secondary' },
  { key: "Total Yet To Assign", value: "total_yet_to_assign", type: 'secondary' },
];

const CardWidget = ({
  heading,
  data,
  chipColor,
  chipBgColor,
  hasPrimary,
}) => {
  const { count = 0, percentage } = data || {};
  return (
    <Stack
      sx={(theme) => ({
        // border: `1px solid ${theme.palette.primary.light}`,
        boxShadow: `0px 0px 2px 0px ${theme.palette.primary.light}`,
        borderRadius: "8px",
        padding: "10px 20px",
      })}
      direction={"row"}
      gap={6}
      alignItems={"center"}
      justifyContent={"space-between"}
      flex={1}
    >
      <Typography variant="h4">{heading}</Typography>
      <Stack direction={"row"} gap={1} alignItems={"center"}>
        <Typography variant="h3">{count}</Typography>
        {percentage && (
          <Chip
            sx={(theme) => ({
              color: theme.palette[hasPrimary][chipColor],
              backgroundColor: theme.palette[hasPrimary][chipBgColor],
              padding: "0px",
            })}
            size="small"
            label={`${percentage}%`}
          />
        )}
      </Stack>
    </Stack>
  );
};

function CardWidgets({ reportDataWidgetCounts = {}, isWidgetsLoading }) {
  // skeleton

  if (isWidgetsLoading) {
    return (
      <Stack direction={"row"} gap={3}>
        <Skeleton variant="rectangular" width={"25%"} height={30} />
        <Skeleton variant="rectangular" width={"25%"} height={30} />
        <Skeleton variant="rectangular" width={"25%"} height={30} />
        <Skeleton variant="rectangular" width={"25%"} height={30} />
      </Stack>
    );
  }
  return (
    <Stack direction={"row"} gap={2}>
      {!isWidgetsLoading &&
        topicWidgetData.map((widgets) => (
          <CardWidget
            heading={widgets?.key}
            data={reportDataWidgetCounts[widgets?.value]}
            chipColor={"main"}
            chipBgColor={"lighter"}
            hasPrimary={widgets?.type}
          />
        ))}
    </Stack>
  );
}

export default CardWidgets;
