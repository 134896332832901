import { useEffect } from "react";
import {
  getMaterialityMainTopic,
  getSelectedMaterialityTopic,
} from "actions/phase-3/materialityAssessment/materialityAssessmentAction";

import { useDispatch, useSelector } from "react-redux";
import {
  addEsgActivity,
  getEsgActivity,
  setEsgSelectedTopic,
  updateEsgActivity,
  deleteEsgActivity,
  getEsgKpis,
  addEsgKpi,
  updateEsgKpi,
  deleteEsgKpi,
  getEsgPolicies,
  getEsgRisks,
  addEsgPolicies,
  addEsgRisk,
  updateEsgPolicies,
  updateEsgRisk,
  deleteEsgPolicies,
  deleteEsgRisk,
} from "actions/phase-3/esgstrategyPlanning/esgstrategyPlanningAction";

export const useEsgStrategyPlanningTopic = (
  source = false,
  selectedTab = null,
  tab
) => {
  const {
    esgStrategyPlanningAssessmentDetail,
    esgSelectedTopic,
    esgActivity,
    esgKpi,
    esgPolicies,
    esgRisks,
  } = useSelector((state) => state?.esgStrategyPlanning);

  const { selectedMaterialityTopics, materialityMainTopics } = useSelector(
    (state) => state?.materialityAssessment
  );

  const { materialitySelectedTask } = useSelector(
    (state) => state.materialityTask
  );

  const { topics } = selectedMaterialityTopics || {};
  const dispatch = useDispatch();

  const basicIds = {
    materiality_topic_id: esgSelectedTopic?.materiality_topic_id,
    topic_id: esgSelectedTopic?.topic_id,
    esg_strategy_planning_assessment_id:
      esgStrategyPlanningAssessmentDetail?._id,
    materiality_assessment_id:
      esgStrategyPlanningAssessmentDetail?.materiality_assessment_id,
  };

  const handleMaterialTopic = async (assessment_id, framework_id) => {
    const response = await dispatch(
      getSelectedMaterialityTopic(assessment_id, framework_id)
    );

    if (response?.status && response?.result?.topics?.length > 0) {
      dispatch(setEsgSelectedTopic(response?.result?.topics[0]));
    }
  };

  const handleSelectEsgTopic = (topic) => {
    dispatch(setEsgSelectedTopic(topic));
  };

  // get use for all tabs

  const handleGetEsgActivity = (action) => {
    dispatch(
      action(
        esgSelectedTopic?.materiality_topic_id,
        esgSelectedTopic?.topic_id,
        esgStrategyPlanningAssessmentDetail?._id
      )
    );
  };
  // add use for all tabs

  const handleAddEsgActivity = async (basicDetails) => {
    let action;

    switch (tab) {
      case "ACTIVITY":
        action = addEsgActivity;
        break;
      case "KPI":
        action = addEsgKpi;
        break;
      case "POLICY":
        action = addEsgPolicies;
        break;
      case "RISK":
        action = addEsgRisk;
        break;
      default:
        throw new Error(`Unknown tab: ${tab}`);
    }

    const response = await dispatch(action({ ...basicIds, ...basicDetails }));
    return response;
  };
  // update use for all tabs

  const handleUpdateEsgActivity = async (basicDetails) => {
    let action;

    switch (tab) {
      case "ACTIVITY":
        action = updateEsgActivity;
        break;
      case "KPI":
        action = updateEsgKpi;
        break;
      case "POLICY":
        action = updateEsgPolicies;
        break;
      case "RISK":
        action = updateEsgRisk;
        break;
      default:
        throw new Error(`Unknown tab: ${tab}`);
    }
    const response = await dispatch(action(basicIds, basicDetails));
    return response;
  };
  // delete use for all tabs

  const handleDeleteEsgActivity = async (topicId) => {
    let action;

    switch (tab) {
      case "ACTIVITY":
        action = deleteEsgActivity;
        break;
      case "KPI":
        action = deleteEsgKpi;
        break;
      case "POLICY":
        action = deleteEsgPolicies;
        break;
      case "RISK":
        action = deleteEsgRisk;
        break;
      default:
        throw new Error(`Unknown tab: ${tab}`);
    }

    const response = await dispatch(action(basicIds, topicId));
    return response;
  };

  // get materiality topics

  useEffect(() => {
    if (source && source === "task") {
      materialitySelectedTask?.materiality_assessment_id &&
        handleMaterialTopic(
          materialitySelectedTask?.materiality_assessment_id,
          materialitySelectedTask?.framework_id
        );
    } else if (source && source === "strategy") {
      esgStrategyPlanningAssessmentDetail?.materiality_assessment_id &&
        handleMaterialTopic(
          esgStrategyPlanningAssessmentDetail?.materiality_assessment_id,
          esgStrategyPlanningAssessmentDetail?.framework_id
        );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // get useEffect

  useEffect(() => {
    if (esgSelectedTopic) {
      if (selectedTab === 0) {
        handleGetEsgActivity(getEsgActivity);
      }
      if (selectedTab === 1) {
        handleGetEsgActivity(getEsgKpis);
      }
      if (selectedTab === 2) {
        handleGetEsgActivity(getEsgPolicies);
      }
      if (selectedTab === 3) {
        handleGetEsgActivity(getEsgRisks);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, esgSelectedTopic]);

  useEffect(() => {
    source &&
      materialityMainTopics?.length === 0 &&
      dispatch(
        getMaterialityMainTopic(
          esgStrategyPlanningAssessmentDetail?.framework_type
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    materialityMainTopics,
    topics,
    esgSelectedTopic,
    esgActivity,
    esgKpi,
    esgPolicies,
    esgRisks,
    handleSelectEsgTopic,
    handleAddEsgActivity,
    handleUpdateEsgActivity,
    handleDeleteEsgActivity,
  };
};
