import { Stack, Box } from "@mui/material";
import OrganizationList from "./AssignComps/OrganizationList";
import ContributorsList from "./AssignComps/ContributorsList";
import ApproverList from "./AssignComps/ApproverList";

const AssignStakeholders = ({
  checkedStakeholders,
  handleCheckboxChange,
  selectedStakeholders,
  handleAddSelection,
  handleRemoveSelection,
  selectedApprovers
}) => {
  return (
    <Stack direction={"row"} sx={{height:"90%"}}>
      {/* Organisation List */}
      <Box flex={1}>
        <OrganizationList
          checkedStakeholders={checkedStakeholders}
          handleCheckboxChange={handleCheckboxChange}
        />
      </Box>

      <Stack flex={1} justifyContent={'space-between'}  >
        {/* Contributors */}
        <ContributorsList
          selectedStakeholders={selectedStakeholders}
          handleAddSelection={handleAddSelection}
          handleRemoveSelection={handleRemoveSelection}
        />

        {/* Approvers */}
        <ApproverList
          selectedStakeholders={selectedApprovers}
          handleAddSelection={handleAddSelection}
          handleRemoveSelection={handleRemoveSelection}
        />
      </Stack>
    </Stack>
  );
};

export default AssignStakeholders;
