import apiBolerplate from "utils/_helpers/apiBolerplate";
import {
  SET_MATERIALITY_ASSESSMENT_TASK_LIST,
  SET_MATERIALITY_SELECTED_TASK,
  SET_SUBMITTED_MATERIALITY_DATA,
} from "constants/phase-3/task/materialityMapping/materialityMappingTaskConst";

// ----------------- Payloads -----------------
export function setMaterialityAssessmentTaskList(payload) {
  return {
    type: SET_MATERIALITY_ASSESSMENT_TASK_LIST,
    payload: payload,
  };
}

export function setMaterialitySelectedTask(payload) {
  return {
    type: SET_MATERIALITY_SELECTED_TASK,
    payload: payload,
  };
}

export function setSubmittedMaterialityData(payload) {
  return {
    type: SET_SUBMITTED_MATERIALITY_DATA,
    payload: payload,
  };
}

// ----------------- Actions -----------------

export function getMaterialityAssessmentTaskList(entity_id) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/tasks/get_assigned_materiality_assessment",
      bodyData: { entity_id },
      callback: (data) => {
        dispatch(setMaterialityAssessmentTaskList(data?.result));
      },
    });

    return data; // Return the data for further use
  };
}

export function submitMaterialityTopicData(basicDetails) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/materiality_assessment/submit_materiality_assessment_topic_data",
      bodyData: { ...basicDetails },
      callback: (data) => {},
    });

    return data; // Return the data for further use
  };
}

export function getSubmittedMaterialityTopicData(basicIds) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/materiality_assessment/get_materiality_assessment_topic_submission_data",
      bodyData: { ...basicIds },
      callback: (data) => {
        dispatch(setSubmittedMaterialityData(data?.result));
      },
    });

    return data; // Return the data for further use
  };
}
