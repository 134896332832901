import { getAssignedStakeholder } from "actions/phase-3/esgMaturity/esgMaturityAction";
import { getAssignedMaterialityUsers } from "actions/phase-3/materialityAssessment/materialityAssessmentAction";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useShareSurveyAPIs = (source = "") => {
  const dispatch = useDispatch();
  const { entity_id } = useSelector((state) => state.user);
    const { materialityAssessmentDetails, } =
    useSelector((state) => state?.materialityAssessment);

  // Function to get the action and selector based on the source
  const getSourceConfig = (source) => {
    switch (source) {
      case "materiality_mapping":
        return {
          action: () => dispatch(getAssignedMaterialityUsers(entity_id, materialityAssessmentDetails?._id)),
          selector: (state) => state.materialityAssessment.materialityAssiegnedUsers,
        };
      case "source2":
        return {
          action: () => {},
          selector: (state) => {
            return [];
          },
        };
      default:
        return {
          action: () => dispatch(getAssignedStakeholder(entity_id)),
          selector: (state) => state.esgMaturity.assigned_stakeholders,
        };
    }
  };

  const { action, selector } = getSourceConfig(source);

  // Fetch assigned stakeholders based on the source
  const fetchAssignedStakeholders = () => {
    action();
  };

  // Dynamically get assigned stakeholders from the appropriate part of the state
  const assigned_stakeholders = useSelector(selector);


  useEffect(() => {
    fetchAssignedStakeholders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source]);

  return {
    // states & data
    assigned_stakeholders,
    // funcs
    fetchAssignedStakeholders,
  };
};
