import { useMemo } from "react";

import MainLayoutStepper from "components/layout/stepperLayout/mainLayout";
import MainLayoutSection from "components/layout/twoSectionLayout/MainLayoutSection";
import LeftSectionLayout from "components/layout/twoSectionLayout/LeftSectionLayout";
import EsgStrategyBasicDetails from "components/widgets/phase-3/esgStrategyAndPlanning/esgStrategyBasicDetails/EsgStrategyBasicDetails";
import { useNavigate } from "react-router-dom";
import RightSectionLayout from "components/layout/twoSectionLayout/RightSectionLayout";
import ReportLeftMain from "components/widgets/phase-3/esgCompliance/sustainabilityReport/left/ReportLeftMain";
import ReportRightMain from "components/widgets/phase-3/esgCompliance/sustainabilityReport/right/ReportRightMain";
import EsgComplianceBasicDetails from "components/widgets/phase-3/esgCompliance/basicDetails/EsgComplianceBasicDetails";
import { useEsgComplianceBasicDetail } from "components/widgets/phase-3/esgCompliance/APIs/HandleBasicDetailApi";
import ReportMain from "components/widgets/phase-3/esgCompliance/reportData/ReportMain";
import ApproveAuditMain from "components/widgets/phase-3/esgCompliance/approveAudit/ApproveAuditMain";

function EsgComplianceSteps() {
  const navigate = useNavigate();

  const { handleEsgComplianceStep, disableButton } =
    useEsgComplianceBasicDetail();

  const stepContentMapping = useMemo(
    () => ({
      0: () => (
        <MainLayoutSection
          left={
            <LeftSectionLayout>
              <EsgComplianceBasicDetails />
            </LeftSectionLayout>
          }
          rightHidden={true}
          leftWidth="100%"
        />
      ),
      1: () => (
        <MainLayoutSection
          left={
            <LeftSectionLayout hasShadow={false}>
              <ReportMain />
            </LeftSectionLayout>
          }
          rightHidden={true}
          leftWidth="100%"
        />
      ),
      2: () => (
        <MainLayoutSection
          left={
            <LeftSectionLayout hasShadow={false}>
              <ApproveAuditMain />
            </LeftSectionLayout>
          }
          rightHidden={true}
          leftWidth="100%"
        />
      ),
      3: () => (
        <MainLayoutSection
          left={
            <LeftSectionLayout padding="0px" hasShadow={false}>
              <ReportLeftMain />
            </LeftSectionLayout>
          }
          right={
            <RightSectionLayout>
              <ReportRightMain />
            </RightSectionLayout>
          }
          leftWidth="60%"
          rightWidth="30%"
        />
      ),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [] // Add any dependencies that can change the content
  );

  return (
    <MainLayoutStepper
      steps={[
        "Basic Details",
        "Report Data",
        "Approve & Audit",
        "Sustainability Report",
      ]}
      mainCrumb={""}
      heightAdjust="150px"
      handleFinalStep={() => navigate("/esg_compliance")}
      finalStepText={"Back to Listing Page"}
      stepContentMapping={stepContentMapping}
      handleStepChangeApi={handleEsgComplianceStep}
      handleDisableButton={disableButton}
    />
  );
}

export default EsgComplianceSteps;
