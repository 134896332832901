import { Box } from "@mui/material";
import LeftSectionLayout from "components/layout/twoSectionLayout/LeftSectionLayout";
import MainLayoutSection from "components/layout/twoSectionLayout/MainLayoutSection";
import RightSectionLayout from "components/layout/twoSectionLayout/RightSectionLayout";
import EsgStrategyPlanningLeft from "components/widgets/phase-3/Task/strategyPlanning/left/StrategyPlanningLeft";
import EsgStrategyPlanningRight from "components/widgets/phase-3/Task/strategyPlanning/right/StrategyPlanningRight";
import EsgStrategyPlanningHeader from "components/widgets/phase-3/Task/strategyPlanning/StrategyPlanningHeader";

function EsgStrategyPlanningMain() {
  return (
    <Box sx={{ overflow: "hidden" }}>
      {/* Header section */}
      <EsgStrategyPlanningHeader />
      {/* Main content */}
      <Box
        sx={{ height: "73vh", overflow: "scroll", padding: "20px 5px 0px 5px" }}
      >
        <MainLayoutSection
          height="98%"
          left={
            <LeftSectionLayout hasShadow={false}>
              <EsgStrategyPlanningLeft />
            </LeftSectionLayout>
          }
          leftWidth="25%"
          right={
            <RightSectionLayout>
              <EsgStrategyPlanningRight />
            </RightSectionLayout>
          }
          rightWidth="73%"
        />
      </Box>
    </Box>
  );
}

export default EsgStrategyPlanningMain;
