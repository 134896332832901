import React from "react";
import { Box, Typography, IconButton, Button, Drawer } from "@mui/material";

// icons
import CloseIcon from "@mui/icons-material/CloseOutlined";

const DynamicDrawer = ({
  heading,
  buttonText,
  disableSubmit,
  submitClick,
  hasDrawerOpen,
  handleClose,
  sx,
  centerHead,
  hasDefaultActionButton = true,
  hasCustomActionButton = false,
  hasSecondaryHeader = false,
  children,
}) => {
  return (
    <Drawer anchor="right" open={hasDrawerOpen} onClose={handleClose}>
      {/* Drawer Header */}
      <Box
        sx={{
          boxShadow: "0px 2px 4px #3374B926",
          px: 2,
          py: 1.5,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            position: "relative",
          }}
        >
          <Typography style={{ fontWeight: "600", flexShrink: 0, width:"90%" }}>
            {heading}
          </Typography>
          {centerHead && (
            <div
              style={{
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              {centerHead}
            </div>
          )}
          <IconButton
            onClick={handleClose}
            style={{ position: "absolute", right: 0 }}
          >
            <CloseIcon sx={{ color: "#3374B9" }} />
          </IconButton>
        </div>
      </Box>

      {/* secondary header */}
      {hasSecondaryHeader && (
        <Box
          sx={{
            px: 2,
          }}
        >
          {hasSecondaryHeader}
        </Box>
      )}

      {/* Drawer Contents */}
      <Box
        sx={{
          px: 2,
          height: "90%",
          overflow: "scroll",
          minWidth: "40vw",
          ...sx,
        }}
      >
        <Box
          sx={{
            height: "99%",
            overflow: "scroll",
          }}
        >
          {children}
        </Box>
      </Box>

      {/* Drawer Default action Buttons */}
      {hasDefaultActionButton && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            p: 2,
          }}
        >
          <Button
            variant="text"
            style={{ color: "#3374B9", marginRight: "8px" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={submitClick}
            disabled={disableSubmit}
          >
            {buttonText}
          </Button>
        </Box>
      )}

      {/* Drawer custom action Buttons */}
      {hasCustomActionButton && (
        <Box
          sx={{
            display: "flex",
            p: 2,
          }}
        >
          {hasCustomActionButton}
        </Box>
      )}
    </Drawer>
  );
};

export default DynamicDrawer;
