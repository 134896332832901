import { IconButton } from "@mui/material";
import { set_snack_bar } from "actions/snackbar/snackbar_action";
import { FiCopy } from "react-icons/fi";
import { useDispatch } from "react-redux";

const MoreActionButtons = (props) => {
  const { chatId, answer } = props;
  // const [openFeedBackDialog, setOpenFeedBackDialog] = useState(false);
  // const [dislike, setDislike] = useState(false);
  // console.log("MoreActionButtons", props, chatId);
  const dispatch = useDispatch();

  // const handleCloseFeedBackDialog = (value) => {
  //   if (value) {
  //     setDislike(true);
  //     setOpenFeedBackDialog((preValue) => !preValue);
  //   } else {
  //     setOpenFeedBackDialog((preValue) => !preValue);
  //     setDislike(false);
  //   }
  // };

  const handleCopyText = () => {
    if (answer) {
      navigator.clipboard.writeText(answer);
      dispatch(set_snack_bar(true, "Text copied to clipboard!"));
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        {answer && (
          <IconButton
            variant="contained"
            sx={{
              marginLeft: "10px",
              padding: "5px",
              borderRadius: 5,
              color: "primary.main",
            }}
            size="small"
            onClick={handleCopyText}
          >
            <FiCopy size={20} />
          </IconButton>
        )}
        {/* <IconButton
            variant="contained"
            style={{
              // borderColor: "#FBFBFB",
              // backgroundColor: "#EDF2F3",
              marginLeft: "10px",
              padding: "5px",
  
              borderRadius: 5,
            }}
            size="small"
            onClick={() => handleCloseFeedBackDialog(false)}
          >
            <img src={thumbUp} style={{ cursor: "pointer" }} alt="pdf icon" />
          </IconButton>
          <IconButton
            variant="contained"
            style={{
              // borderColor: "#FBFBFB",
              // backgroundColor: "#EDF2F3",
              marginLeft: "10px",
              padding: "5px",
  
              borderRadius: 5,
            }}
            size="small"
            onClick={() => handleCloseFeedBackDialog(true)}
          >
            <img src={thumbDown} style={{ cursor: "pointer" }} alt="pdf icon" />
          </IconButton> */}
      </div>
      {/* {openFeedBackDialog && (
          <Feedback
            isOpen={openFeedBackDialog}
            handleClose={handleCloseFeedBackDialog}
            additional={dislike}
            chatId={chatId}
            title={"Please Provide Additional Feedback"}
            contentText={
              "What was the issue with the response ? How could it be improved ?What was the issue with the response ? How could it be improved ?"
            }
            extraText={"Let us help you better on the basis of your rating"}
            placeHolderText={
              dislike
                ? "What do you not like about the response ?"
                : "What do you like about the response ?"
            }
          />
        )} */}
    </>
  );
};

export default MoreActionButtons;
