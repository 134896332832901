import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import GPTChatMessageLayout from "./GPTChatMessageLayout";
import { useEffect } from "react";
import {
  getGptChatHistory,
  setChatConversations,
} from "actions/phase-3/gptWise/gptWiseAction";

const GPTOldChat = ({ selectedChat }) => {
  const dispatch = useDispatch();
  const { chats } = useSelector((state) => state.gptWise);

  useEffect(() => {
    dispatch(getGptChatHistory(selectedChat?.chat_id));

    return () => {
      dispatch(setChatConversations([]));
    };
  }, [selectedChat?.chat_id, dispatch]);

  return (
    <Box>
      {chats?.length > 0 ? (
        <Box sx={{ height: "68vh", overflowY: "auto" }}>
          <GPTChatMessageLayout />
        </Box>
      ) : (
        <Typography variant="subtitle2">No Chat</Typography>
      )}
    </Box>
  );
};
export default GPTOldChat;
