export const tempUnit = [
  "Percentage (%)",
  "Rate",
  "Percentage (%) by weight",
  "Number",
  "Reporting currency",
  "Percentage (%) by revenue",
  "Gigajoules (GJ), Percentage (%)",
  "Metric tons (t), Percentage (%) by weight",
  "Thousand cubic meters (m³), Percentage (%)",
  "Number, Percentage (%)",
  "Metric tons (t) CO₂-e",
  "Metric tons (t), Percentage (%)",
  "Reporting currency, Percentage (%)",
  "Metric tons (t) CO₂-e, Percentage (%)",
  "Metric tons (t)",
  "Acres (ac), Percentage (%)",
  "Percentage (%) by annual sales revenue",
  "Number, Days",
  "Million metric tons (Mt)",
  "Metric tons CO₂-e (t), Percentage (%)",
  "Metric tons CO₂-e",
  "Thousand cubic meters (m³), Percentage (%), Metric tons (t)",
  "Number, Barrels (bbls)",
  "Rate, Hours (h)",
  "Million barrels (MMbbls), Million standard cubic feet (MMscf)",
  "Percentage (%) by acreage",
  "Metric tons (t) saleable",
  "Acres (ac)",
  "Percentage (%) by cost",
  "Number, Metric tons (t)",
  "Number, Days idle",
  "Percentage (%) by hectares",
  "Percentage (%) by contract value",
  "Percentage (%), Reporting currency",
  "Percentage (%) by number, Percentage (%) by weight",
  "Number, Reporting currency",
  "Basis points (bps)",
  "Reporting currency, Percentage (%), Months, Number, Reporting currency",
  "Number, Minutes",
  "Number, Hours (h)",
  "Number, Reporting currency, Percentage (%)",
  "Gallons, Tons (U.S.), Miles",
  "Ratio",
  "Percentage (%) by megawatt hours (MWh)",
  "Megawatt hours (MWh)",
  "Minutes, Number",
  "Million British Thermal Units (MMBtu)",
  "Percentage (%) by length",
  "Number, Index score",
  "Percentage (%) by floor area",
  "Percentage (%) by floor area, Square feet (ft²)",
  "Square feet (ft²)",
  "Square feet (ft²), Number",
  "Million British Thermal Units (MMBtu), Percentage (%)",
  "Percentile",
  "Thousand cubic meters (m³)",
  "Cubic meters (m³)",
  "Cubic meters (m³) per day",
  "Number, Cubic meters (m³), Percentage (%)",
  "Grams of CO₂-e per megajoule (MJ)",
  "Percentage (%) of gallons",
  "Number, Rate",
  "Specific energy (Wh/kg)",
  "Hours (h)",
  "Number of cycles",
  "Number, Kilograms (kg)",
  "dB(A)",
  "Metric tons per megawatts (t/MW)",
  "Percentage (%) by revenue, Percentage (%)",
  "Gallons per 1,000 ton-miles",
  "Gallons per hour",
  "Watts per gallon",
  "Grams per kilowatt-hour",
  "Percentage (%) of gaming floor area",
  "Percentage (%) of man-hours",
  "Various, by product category",
  "Megabits per second (Mbps)",
  "Disruptions per customer, Hours per customer",
  "Metric tons (t) CO₂-e per ton-kilometer",
  "Mpg, L/km, gCO₂/km, km/L",
  "Percentage (%) by sales-weighted metric tons (t)",
  "Grams of CO₂ per ton-nautical mile",
  "Number of travel days",
  "Rate, Number",
  "Number, Cubic meters (m³)"
];
