import React from "react";
import {
  Chip,
  Stack,
  Typography,
  IconButton,
  Button,
  List,
  ListItemText,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";

import DynamicDrawer from "components/UI/phase-3/Drawer/DynamicDrawer";
import { MdOutlineDragIndicator } from "react-icons/md";
import { GoEye } from "react-icons/go";
import { GoEyeClosed } from "react-icons/go";
import { IoListOutline } from "react-icons/io5";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import SearchTextField from "components/UI/phase-3/textfield/searchTextField";
import Heading from "components/UI/phase-3/heading/Heading";

function OptionDrawer({
  openDrawer,
  toggleDrawer,
  selectedOption,
  handleSelectedOption,
  tableHeadings = [],
  handleDragStart,
  handleDragEnter,
  handleDrop,
  handleHideStatus,
}) {
  return (
    <DynamicDrawer
      hasDrawerOpen={openDrawer}
      handleClose={() => {
        toggleDrawer();
        handleSelectedOption("");
      }}
      heading={
        selectedOption ? (
          <Stack direction={"row"} alignItems={"center"} gap={1}>
            <IconButton size="small" onClick={() => handleSelectedOption("")}>
              <IoIosArrowBack />
            </IconButton>
            {selectedOption}
          </Stack>
        ) : (
          "Options"
        )
      }
      hasDefaultActionButton={false}
    >
      {selectedOption ? (
        <Stack mt={3} gap={3}>
          <SearchTextField />
          <Heading text="Show in Panel  ( Max 6)" horizontalLine={true}>
            <Button
              variant="text"
              size="small"
              // startIcon={<AiOutlineDownload />}
              onClick={() => {
                //   resetDetailState();
                //   navigate("/esg_compliance/New_Report");
              }}
              sx={{ marginBottom: "10px" }}
            >
              Hide All
            </Button>
          </Heading>

          <List onDragOver={(e) => e.preventDefault()}>
            {tableHeadings.map((item, index) => (
              <ListItemButton
                key={item.id}
                //   sx={{ mb: 1 }}
              >
                <ListItemIcon>
                  <IconButton
                    onDragStart={() => handleDragStart(index)}
                    onDragEnter={() => handleDragEnter(index)}
                    onDragEnd={handleDrop}
                    draggable
                  >
                    <MdOutlineDragIndicator />
                  </IconButton>
                </ListItemIcon>
                <ListItemText primary={item.text} />
                <IconButton onClick={() => handleHideStatus(item.id)}>
                  {item?.hide ? <GoEyeClosed /> : <GoEye />}
                </IconButton>
              </ListItemButton>
            ))}
          </List>
        </Stack>
      ) : (
        <Stack
          width={"100%"}
          gap={3}
          mt={4}
          //   justifyContent={"stretch"}
        >
          <Stack
            //   sx={{ cursor: "pointer" }}
            direction={"row"}
            flex={1}
            alignItems={"center"}
            gap={2}
          >
            <IoListOutline />
            <Typography>Properties</Typography>
            <Chip
              label="6 step"
              sx={(theme) => ({
                color: theme.palette.primary.dark,
                backgroundColor: theme.palette.primary.lighter,
                marginLeft: "auto",
              })}
            />

            <IconButton onClick={() => handleSelectedOption("Properties")}>
              <IoIosArrowForward />
            </IconButton>
          </Stack>
        </Stack>
      )}
    </DynamicDrawer>
  );
}

export default OptionDrawer;
