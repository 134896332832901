import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import UserChatQuestion from "../GPTCommon/MainChat/UserChatQuestion";
import UserChatAnswerLayout from "../GPTCommon/MainChat/UserChatAnswerLayout";
import ChatAnswers from "../GPTCommon/MainChat/ChatAnswers";
import { UserChatAnswerLoading } from "../GPTCommon/MainChat/UserChatAnswerLoading";
import { Box, Typography, Fade } from "@mui/material";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";

const GPTChatMessageLayout = () => {
  const { chats } = useSelector((state) => state.gptWise);
  const [isVisible, setIsVisible] = useState(false);
  const [responseExpand, setResponseExpand] = useState(true);
  const messagesEndRef = useRef(null);
  const scrollRef = useRef(null);

  const handleScroll = () => {
    if (
      scrollRef.current?.scrollTop <
      scrollRef.current?.scrollHeight - scrollRef.current?.clientHeight - 10
    ) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    const containerDiv = scrollRef.current;
    const scrollStep = -containerDiv.scrollTop / 35;
    const scrollInterval = setInterval(() => {
      if (containerDiv.scrollTop !== 0) {
        containerDiv.scrollBy(0, scrollStep);
      } else {
        clearInterval(scrollInterval);
      }
    }, 15);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  const handleExpandResponseDetail = () => {
    setResponseExpand((preValue) => !preValue);
  };

  useEffect(() => {
    chats.length !== 0 && scrollToBottom();
  }, [chats, responseExpand]);

  useEffect(() => {
    scrollRef.current?.addEventListener("scroll", handleScroll);
    return () => {
      scrollRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, [scrollRef?.current]);

  return (
    <>
      <Box
        sx={{
          height: "88%",
          width: "100%",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
        ref={scrollRef}
      >
        <div>
          {chats.map((chat, index) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
              key={index}
            >
              <UserChatQuestion
                question={chat?.question.question || chat?.question}
              />
              <UserChatAnswerLayout isLoading={chat?.isLoading}>
                {chat?.answer ? (
                  <ChatAnswers answer={chat?.answer} chatId={chat?._id} />
                ) : chat?.isLoading ? (
                  <UserChatAnswerLoading
                    chat={chat}
                    handleExpandMore={handleExpandResponseDetail}
                    responseExpand={responseExpand}
                  />
                ) : (
                  !chat?.isLoading &&
                  !chat?.answer && (
                    <Box
                      style={{
                        width: "100%",
                        marginLeft: 10,
                        marginRight: 10,
                        display: "flex",
                        flexDirection: "column",
                        gap: 20,
                      }}
                    >
                      <Box
                        style={{
                          backgroundColor: "#F6F6F6",
                          borderRadius: "8px",
                          padding: "20px",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: 400,
                            color: "#15314E",
                          }}
                        >
                          Unable to generate a response for your message. Please
                          try again.
                        </Typography>
                      </Box>
                    </Box>
                  )
                )}
              </UserChatAnswerLayout>

              <div ref={messagesEndRef} />
            </div>
          ))}
        </div>

        {chats.length !== 0 &&
          chats.filter((v, i) => v.isLoading !== false).length === 0 && (
            <div
              style={{
                display: "flex",
                overflowX: "scroll",
                width: "73vw",
                alignItems: "center",
                gap: "5px",
              }}
            ></div>
          )}
      </Box>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 20,
          height: "70px",
          zIndex: 999999,
        }}
        onMouseLeave={() => setIsVisible(false)}
        onMouseEnter={() => setIsVisible(true)}
      >
        <Fade in={isVisible} timeout={500}>
          <div>
            <FaArrowUp
              style={{ color: "rgb(51, 116, 185)", cursor: "pointer" }}
              size={20}
              onClick={scrollToTop}
            />
          </div>
        </Fade>

        <Fade in={isVisible} timeout={500}>
          <div>
            <FaArrowDown
              style={{ color: "rgb(51, 116, 185)", cursor: "pointer" }}
              size={20}
              onClick={scrollToBottom}
            />
          </div>
        </Fade>
      </div>
    </>
  );
};

export default GPTChatMessageLayout;
