import { SET_ALL_FRAME_WORK_LIST } from "constants/phase-3/frameWork/frameWorkConstant";
import apiBolerplate from "utils/_helpers/apiBolerplate";

// ----------------- Payloads -----------------
export function setAllFrameWorkList(payload) {
  return {
    type: SET_ALL_FRAME_WORK_LIST,
    payload: payload,
  };
}

// ----------------- Actions -----------------

export async function getAllFrameWorkList() {
  const data = await apiBolerplate({
    url: "/frameworks/get_all_frameworks",
    method: "get",
    hideLoader: true,
    hideMsg: true,
  });
  return data;
}

export async function getFilteredFrameWorkList(country_code, organization_id) {
  const data = await apiBolerplate({
    url: "/frameworks/view_filtered_frameworks",
    method: "post",
    hideLoader: true,
    hideMsg: true,
    bodyData: { country_code, organization_id },
  });
  return data;
}
