import { Box, Typography, List } from "@mui/material";
import { useSelector } from "react-redux";
import SearchTextField from "components/UI/textfield/searchTextField";
import RenderEntity from "./Components/RenderEntity";

const OrganizationList = ({ checkedStakeholders, handleCheckboxChange }) => {
  const { structured_stakeholders } = useSelector(
    (state) => state.esgStrategyPlanning
  );

  const handleSearch = (keyword) => {
  };

  return (
    <Box width="100%" >
      <Box bgcolor="primary.lighter" p={2.5}>
        <Typography variant="subtitle2" color="primary.darker">
          Organisation List
        </Typography>
      </Box>

      <Box p={1}>
        <SearchTextField searchClick={handleSearch} fullWidth />
      </Box>

      <Box
        p={1}
        height={"70vh"}
        sx={{ overflowY: "auto", overflowX: "hidden" }}
      >
        <List>
          {structured_stakeholders?.length !== 0 &&
            structured_stakeholders?.map((entity) =>
              RenderEntity(entity, checkedStakeholders, handleCheckboxChange)
            )}
        </List>
      </Box>
    </Box>
  );
};

export default OrganizationList;
