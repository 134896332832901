import React, { forwardRef } from "react";
import { TableRow, TableContainer, Stack, Skeleton } from "@mui/material";

import DynamicTable from "components/UI/phase-3/dynamicTable/DynamicTable";
import { NoDataComponent } from "components/widgets/phase-3/esgMaturity/shareSurvey/NoData";

const TopicTable = forwardRef(
  (
    {
      dynamicHeading = [],
      isLoading = false,
      reportDataList = [],
      height = "75%",

      children,
    },
    ref
  ) => {
    // no data component
    if (!isLoading && reportDataList.length === 0) {
      return <NoDataComponent mainTitle={"No Metric Found"} />;
    }

    return (
      <TableContainer ref={ref} sx={{ height }}>
        <DynamicTable headings={dynamicHeading} stickyHeader={true}>
          {reportDataList.map((r, index) => (
            <TableRow
              key={r?._id}
              sx={{ borderBottom: "1px solid #EBF1F8", cursor: "pointer" }}
              hover
            >
              {/* {children} */}
              {children.map((child) => {
                return child(r, index);
              })}
            </TableRow>
          ))}
        </DynamicTable>

        {/* skeleton */}

        {isLoading && (
          <Stack gap={3} mt={5}>
            <Skeleton variant="rectangular" width={"100%"} height={30} />
            <Skeleton variant="rectangular" width={"100%"} height={30} />
            <Skeleton variant="rectangular" width={"100%"} height={30} />
          </Stack>
        )}
      </TableContainer>
    );
  }
);

export default TopicTable;
