import { Box, Checkbox, TableCell, TableRow } from "@mui/material";
import {
  assignMaturitySurvey,
  getStakeholders,
} from "actions/phase-3/esgMaturity/esgMaturityAction";
import DynamicDrawer from "components/UI/phase-3/Drawer/DynamicDrawer";
import DynamicTable, {
  textStyle,
} from "components/UI/phase-3/dynamicTable/DynamicTable";
import SearchTextField from "components/UI/textfield/searchTextField";
import { useSurveyDrawerAPIs } from "../APIs/shareSurveyApis/surveyDrawerApis";
import { usePaginationSearch } from "customHooks/usePaginationSearch";

const ShareSurveyDrawer = ({
  open,
  toggleDrawer,
  fetchAssignedStakeholders,
  source,
}) => {
  const { keyword, handleSearch } = usePaginationSearch();

  const {
    // states & data
    stakeholders_list,
    selectedStakeholders,
    // funcs
    handleSubmit,
    handleCheckboxClick,
    handleHeadCheckboxClick,
  } = useSurveyDrawerAPIs(
    open,
    toggleDrawer,
    fetchAssignedStakeholders,
    keyword,
    source
  );

  return (
    <DynamicDrawer
      heading={"Share Stakeholders"}
      buttonText={"Share Survey"}
      hasDrawerOpen={open}
      handleClose={toggleDrawer}
      submitClick={async () => {
        const result = await handleSubmit();
        result && toggleDrawer();
      }}
      sx={{ minWidth: "60vw" }}
    >
      <SearchTextField
        searchClick={handleSearch}
        sx={{ width: "100%", maxWidth: "30em", my: 2 }}
      />

      <DynamicTable
        headings={["Name", "Role", "Entity/Unit Name"]}
        hasCheckbox={true}
        checkboxClick={handleHeadCheckboxClick}
        isHeaderCheckboxChecked={
          selectedStakeholders.length === stakeholders_list.length
        }
      >
        {stakeholders_list?.map((rowData) => (
          <TableRow
            key={rowData?._id}
            sx={{ borderBottom: "1px solid #EBF1F8" }}
          >
            <TableCell style={textStyle("main")}>
              <Checkbox
                color="primary"
                checked={selectedStakeholders.includes(rowData?._id)}
                onChange={() => handleCheckboxClick(rowData?._id)}
                inputProps={{
                  "aria-label": `select stakeholder ${rowData?.name}`,
                }}
              />
            </TableCell>
            <TableCell style={textStyle("main")}>
              <Box display={"flex"} alignItems={"center"} sx={{ gap: 10 }}>
                {rowData?.name}
              </Box>
            </TableCell>
            <TableCell style={textStyle()}>{rowData?.category}</TableCell>
            <TableCell style={textStyle()}>
              {rowData?.entity?.entity_name || rowData?.unit?.unit_name}
            </TableCell>
          </TableRow>
        ))}
      </DynamicTable>
    </DynamicDrawer>
  );
};

export default ShareSurveyDrawer;
