import { useMemo } from "react";

import { Box, Button, Stack } from "@mui/material";
import MaterialityQuestion from "components/widgets/phase-3/MaterialityMapping/assign/left/MaterialityQuestion";
import useMaterialityTopicTask from "../API/HandleMaterialityTopicTask";
import useFormValidation from "customHooks/useFormValidation";

function MaterialityTaskRight() {
  const {
    materialitySubmittedData,
    selectedTopic,
    selectedTopicIndex,
    handleSubmitData,
    handlePrevious,
    isLastTopic,
  } = useMaterialityTopicTask();

  const initialAssessmentState = useMemo(
    () => ({
      weightage: "",
      relevance: "",
      level: "",
      overview: "",
      approach: "",
      mitigation: "",
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedTopicIndex]
  );

  const validationRules = useMemo(
    () => ({
      weightage: { required: true },
      relevance: { required: true },
      level: { required: true },
      overview: { required: true },
      approach: {
        required: true,
      },
      mitigation: {
        required: false,
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const hasChangeInitialState = true;

  const { formData, errors, handleChange, validationErrors, setFormData } =
    useFormValidation(
      materialitySubmittedData?._id
        ? materialitySubmittedData
        : initialAssessmentState,
      validationRules,
      false,
      hasChangeInitialState
    );

  return (
    <Stack justifyContent={"space-between"} sx={{ height: "100%" }}>
      <Box sx={{ overflow: "scroll", height: "90%" }} key={selectedTopicIndex}>
        <MaterialityQuestion
          hasEditable={true}
          dataMain={selectedTopic}
          index={selectedTopicIndex}
          formData={formData}
          error={errors}
          handleChange={handleChange}
          setFormData={setFormData}
        />
      </Box>
      <Stack
        direction={"row"}
        justifyContent={"flex-end"}
        alignItems={"center"}
        gap={3}
      >
        <Button disabled={!selectedTopicIndex} onClick={handlePrevious}>
          Previous
        </Button>
        <Button
          variant="contained"
          disabled={Object.keys(validationErrors).length > 0}
          onClick={() => handleSubmitData(formData)}
        >
          {isLastTopic ? "Submit" : "Submit & Next"}
        </Button>
      </Stack>
    </Stack>
  );
}

export default MaterialityTaskRight;
