import { useState } from "react";

import { Box } from "@mui/material";
import TaskListing from "./TaskListing";
import ExploreTopic from "./ExploreTopic";
import { usePaginationSearch } from "customHooks/usePaginationSearch";

export default function EsgStrategyPlanningRight() {
  const { openDrawer, toggleDrawer } = usePaginationSearch();

  const [data, setData] = useState(null);


  const handleExplore = (value, heading) => {
    setData({ result: value, heading });
    toggleDrawer();
  };

  return (
    <Box sx={{ height: "98%" }}>
      <TaskListing toggleExplore={handleExplore} />

      <ExploreTopic
        staticData={data?.result || {}}
        open={openDrawer}
        handleClose={toggleDrawer}
        heading={data?.heading || "Activities"}
      />
    </Box>
  );
}
