import { SET_COMPLIANCE_SELECTED_TASK } from "constants/phase-3/task/esgCompliance/esgComplianceTaskConst";
import apiBolerplate from "utils/_helpers/apiBolerplate";

// ----------------- Payloads -----------------
export function setEsgComplianceSelectedTask(payload) {
  return {
    type: SET_COMPLIANCE_SELECTED_TASK,
    payload: payload,
  };
}

// ----------------- Actions -----------------

export async function getEsgComplianceTaskList(organization_id) {
  const data = await apiBolerplate({
    url: "/tasks/get_assigned_esg_compliance_assessments",
    bodyData: { organization_id },
    hideLoader: true,
    hideMsg: true,
  });

  return data; // Return the data for further use
}

export async function getEsgComplianceTaskAssignedMetrics(taskData) {
  const data = await apiBolerplate({
    url: "/esg_compliance_task/get_assigned_metrics",
    bodyData: { ...taskData },
    hideLoader: true,
    hideMsg: true,
  });

  return data; // Return the data for further use
}

export async function submitEsgComplianceTaskMetric({ file, ...taskData }) {
  const handleFile = file?.url ? { file, ...taskData } : taskData;
  const data = await apiBolerplate({
    url: "/esg_compliance_task/submit_data",
    files: { file: file?.url ? null : file },
    bodyData: { ...handleFile },
    hideLoader: true,
    hideMsg: true,
  });

  return data; // Return the data for further use
}


export async function getEsgComplianceTaskMetricsStatus(taskData) {
  const data = await apiBolerplate({
    url: "/esg_compliance_task/get_status_summery",
    bodyData: { ...taskData },
    hideLoader: true,
    hideMsg: true,
  });

  return data; // Return the data for further use
}
