import {
  createEsgStrategyPlanningAssessment,
  setEsgStrategyPlanningAssessmentDetails,
  setOriginalEsgStrategyPlanningDetails,
  updateEsgStrategyPlanningAssessment,
} from "actions/phase-3/esgstrategyPlanning/esgstrategyPlanningAction";
import {
  getMaterialityTopicYearBase,
  setMaterialityAssessmentDetails,
  setOriginalMaterialityDetails,
  updateMaterialityAssessment,
} from "actions/phase-3/materialityAssessment/materialityAssessmentAction";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useEsgStrategyPlanningBasicDetail = () => {
  const { entity_id } = useSelector((state) => state.user);
  const {
    esgStrategyPlanningAssessmentDetail,
    originalEsgStrategyPlanningAssessmentDetail,
  } = useSelector((state) => state?.esgStrategyPlanning);

  const { materialityAssessmentYearList } = useSelector(
    (state) => state?.materialityAssessment
  );
  const dispatch = useDispatch();

  function areAllFieldsNotEmpty(obj) {
    // Check if the object has no properties
    if (Object.keys(obj).length === 0) {
      return false;
    }

    // Check if all properties are not null, undefined, or empty string
    return Object.values(obj).every(
      (value) => value !== null && value !== undefined && value !== ""
    );
  }

  let disableButton = areAllFieldsNotEmpty(esgStrategyPlanningAssessmentDetail);

  const hasChanges = (original, updated) => {
    return JSON.stringify(original) !== JSON.stringify(updated);
  };

  const handleBasicDetailsAPi = async () => {
    let response;

    if (esgStrategyPlanningAssessmentDetail._id) {
      // Check if there are changes before calling update API
      if (
        hasChanges(
          originalEsgStrategyPlanningAssessmentDetail,
          esgStrategyPlanningAssessmentDetail
        )
      ) {
        dispatch(
          setEsgStrategyPlanningAssessmentDetails({
            ...esgStrategyPlanningAssessmentDetail,
            entity_id,
          })
        );
        dispatch(
          setOriginalEsgStrategyPlanningDetails({
            ...esgStrategyPlanningAssessmentDetail,
            entity_id,
          })
        );

        response = await dispatch(
          updateEsgStrategyPlanningAssessment({
            ...esgStrategyPlanningAssessmentDetail,
            entity_id,
          })
        );
      } else {
        response = { status: true };
      }
    } else {
      // Call create API if _id is not present
      response = await dispatch(
        createEsgStrategyPlanningAssessment({
          ...esgStrategyPlanningAssessmentDetail,
          entity_id,
        })
      );
    }

    return response;
  };

  const getMaterialityTopicWithYear = async (year_of_assessment) => {
    const response = await dispatch(
      getMaterialityTopicYearBase(entity_id, year_of_assessment)
    );
    if (response?.status && response?.result.length === 0) {
      dispatch(
        setEsgStrategyPlanningAssessmentDetails({
          ...esgStrategyPlanningAssessmentDetail,
          materiality_assessment_id: "",
        })
      );
    }
  };

  const handleEsgStrategyPlanningStep = (step) => {
    return step === 0 && handleBasicDetailsAPi();
  };

  return {
    handleEsgStrategyPlanningStep,
    disableButton,
    esgStrategyPlanningAssessmentDetail,
    getMaterialityTopicWithYear,
    materialityAssessmentYearList,
  };
};
