import { useState } from "react";

import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import { Button, IconButton } from "@mui/material";
import { MdDeleteOutline } from "react-icons/md";
const useStyles = makeStyles({
  blueText: {
    fontWeight: "600",
    color: "#3374B9",
    padding: "10px 0px",
  },
  grid: { paddingTop: 20 },
  TableCell: { minWidth: 100 },
  CustomTextfield: { width: "100%" },
  grid1: { marginTop: 20 },
  input: { display: "none" },
  Box1: {
    borderBottom: "2px solid gray",
    cursor: "pointer",
  },
  Box2: { borderBottom: "2px solid #3374B9" },
  pointer: { cursor: "pointer" },
});
const typeTwoData = [
  {
    row: "Row 1",
    columns: [
      { column: "Column A", data: "Data 1-A" },
      { column: "Column B", data: "Data 1-B" },
      { column: "Column C", data: "Data 1-C" },
    ],
  },
  {
    row: "Row 2",
    columns: [
      { column: "Column A", data: "Data 2-A" },
      { column: "Column B", data: "Data 2-B" },
      { column: "Column C", data: "Data 2-C" },
    ],
  },
  {
    row: "Row 3",
    columns: [
      { column: "Column A", data: "Data 3-A" },
      { column: "Column B", data: "Data 3-B" },
      { column: "Column C", data: "Data 3-C" },
    ],
  },
];

function DisclosureTypeTwo({
  typeTwoData = {},
  columns = [],
  rows = [],
  setFormData,
  handleCheckedDataChanged

}) {
  const classes = useStyles();

  const addRow = () => {
    // Get the current number of rows
    const rowCount = Object.keys(typeTwoData).length;

    // Create a new row key (e.g., "row1", "row2", ...)
    const newRowKey = `row${rowCount + 1}`;

    // Add a new empty object for the new row
    setFormData((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        [newRowKey]: {}, // Add the new row with an empty object
      },
    }));

    handleCheckedDataChanged() // call for data change

  };

  const removeRow = (rowIndex) => {
    // Create the row key to remove (e.g., "row1", "row2", ...)
    const rowKeyToRemove = `row${rowIndex + 1}`;

    // Remove the specified row and update the indices
    setFormData((prev) => {
      const updatedData = { ...prev.data };
      delete updatedData[rowKeyToRemove]; // Remove the target row

      // Create a new object to re-index the remaining rows
      const newData = {};
      Object.keys(updatedData).forEach((key, index) => {
        const newKey = `row${index + 1}`; // Update row key to be sequential
        newData[newKey] = updatedData[key]; // Assign old data to new keys
      });

      return {
        ...prev,
        data: newData, // Update the state with the re-indexed data
      };
    });

    handleCheckedDataChanged() // call for data change

  };

  return (
    <>
      {/* {typeTwoData.length > 0 && ( */}
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <Button
                  variant="text"
                  size="small"
                  color="primary"
                  onClick={addRow}
                >
                  Add Row
                </Button>
              </TableCell>
              {columns &&
                columns.map((col, index) => (
                  <TableCell
                    key={index}
                    align="center"
                    className={classes.TableCell}
                  >
                    {col}
                  </TableCell>
                ))}
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(typeTwoData).map((row, rowIndex) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={rowIndex}>
                <TableCell align="center">
                  <Typography>{`Row - ${rowIndex + 1}`}</Typography>
                </TableCell>
                {columns &&
                  columns.map((column, colIndex) => (
                    <TableCell key={colIndex} align="center">
                      <TextField
                        name={`Text_type2${colIndex}`}
                        size="small"
                        variant="outlined"
                        className={classes.CustomTextfield}
                        value={
                          typeTwoData?.[`row${rowIndex + 1}`]?.[
                            `column-${colIndex}`
                          ] || ""
                        }
                        onChange={(e) => {
                          setFormData((prev) => {
                            // Copy the previous data object
                            const updatedRows = { ...prev.data };

                            // Create a key for the row (e.g., "row1", "row2", etc.)
                            const rowKey = `row${rowIndex + 1}`;

                            // Check if the rowKey exists, if not, create an empty object for that row
                            if (!updatedRows[rowKey]) {
                              updatedRows[rowKey] = {};
                            }

                            // Update the column value for the specific row and column
                            updatedRows[rowKey][`column-${colIndex}`] =
                              e.target.value;

                            // Return the updated state with the new row-keyed structure
                            return {
                              ...prev,
                              data: updatedRows, // Update the data with the new row-keyed structure
                            };
                          });

                          handleCheckedDataChanged() // call for data change

                        }}
                        // disabled
                      />
                    </TableCell>
                  ))}

                <TableCell align="right">
                  <IconButton color="error" onClick={() => removeRow(rowIndex)}>
                    <MdDeleteOutline />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* // )} */}
    </>
  );
}

export default DisclosureTypeTwo;
