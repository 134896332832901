import React, { useState, useEffect } from "react";
import {
  List,
  ListItemButton,
  Typography,
  Button,
  IconButton,
  Box,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import { FiMessageSquare } from "react-icons/fi";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { Check, Close } from "@mui/icons-material"; // Checkmark and Cross icons
import EditGeneric from "images/phase-3/GPTWise/editGeneric.svg";
import {
  deleteChatGroup,
  setSelectedGPTChat,
  updateChatName,
  viewChatGroups,
} from "actions/phase-3/gptWise/gptWiseAction";
import { useDispatch, useSelector } from "react-redux";

const GPTChats = ({ classes, handleClick }) => {
  const dispatch = useDispatch();
  const { selectedChat, chatsList } = useSelector((state) => state.gptWise);

  const [menuAnchorEl, setMenuAnchorEl] = useState(null); // For menu position
  const [activeChatId, setActiveChatId] = useState(null); // Track which chat's menu is open
  const [renameChatId, setRenameChatId] = useState(null); // Track chat to rename
  const [editedName, setEditedName] = useState(""); // Temporary edited name

  const handleItemClick = (type, chat_id) => {
    if (Boolean(handleClick)) {
      dispatch(setSelectedGPTChat({ type, chat_id }));
      handleClick(3);
    }
  };

  const handleMenuClick = (event, chat_id) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget); // Set menu position
    setActiveChatId(chat_id); // Set active chat for the menu
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setActiveChatId(null);
  };

  const handleOptionClick = (type, chat_id) => {
    handleMenuClose();
    if (type === "rename") {
      setEditedName(chatsList.find((chat) => chat._id === chat_id)?.group_name);
      setRenameChatId(chat_id); // Set the chat for renaming
    } else if (type === "delete") {
      dispatch(deleteChatGroup({ chat_id }));
    }
  };

  const handleRenameComplete = (chat_id) => {
    dispatch(updateChatName({ chat_id, group_name: editedName }));
    setRenameChatId(null); // Exit renaming mode
  };

  const handleCancelRename = () => {
    setRenameChatId(null); // Exit renaming mode without saving
  };

  const checkSelected = (chatID) => {
    return selectedChat?.type !== "new" && selectedChat?.chat_id === chatID;
  };

  useEffect(() => {
    dispatch(viewChatGroups());
  }, [dispatch]);

  return (
    <List component="div" sx={{ mt: 2 }} disablePadding>
      {/* New Chat */}
      <Button
        variant="outlined"
        startIcon={<img src={EditGeneric} alt="NewChatIcon" />}
        onClick={() => handleItemClick("new", "new-chat")}
        sx={{ color: "primary.main" }}
        fullWidth
      >
        New Chat
      </Button>

      {/* Chats list */}
      <Box height="50vh" overflow="auto" mt={2}>
        <Typography
          component={"p"}
          variant="caption"
          color="action.active"
          textAlign="left"
          my={1}
        >
          Today
        </Typography>

        {chatsList?.map((chat, idx) => (
          <Box key={idx} display="flex" alignItems="center">
            {renameChatId === chat._id ? (
              <TextField
                fullWidth
                value={editedName}
                size="small"
                onChange={(e) => setEditedName(e.target.value)}
                sx={{ my: 2 }}
                onKeyDown={(event) => {
                  if (event.key === "Escape") {
                    handleCancelRename(); // Close the text field when Esc is pressed
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <>
                      {/* Tick Icon */}
                      <IconButton
                        onClick={() => handleRenameComplete(chat._id)}
                        size="small"
                        sx={{ color: "success.main" }}
                      >
                        <Check />
                      </IconButton>
                      {/* Cross Icon */}
                      <IconButton
                        onClick={handleCancelRename}
                        size="small"
                        sx={{ color: "error.main" }}
                      >
                        <Close />
                      </IconButton>
                    </>
                  ),
                }}
              />
            ) : (
              <ListItemButton
                component="div"
                className={classes.listItem}
                onClick={() => handleItemClick("old", chat?._id)}
                selected={checkSelected(chat?._id)}
                alignItems="center"
                sx={{ color: "primary.main" }}
              >
                {/* Start Icon */}
                <FiMessageSquare style={{ marginRight: "10px" }} size={20} />

                <Typography
                  variant={checkSelected(chat?._id) ? "subtitle1" : "body2"}
                  color="text.primary"
                >
                  {chat?.group_name}
                </Typography>

                {/* End Icon */}
                <IconButton
                  aria-label="chat-options"
                  size={"small"}
                  sx={{ ml: "auto", color: "primary.main" }}
                  onClick={(event) => handleMenuClick(event, chat?._id)}
                >
                  <HiOutlineDotsVertical />
                </IconButton>
              </ListItemButton>
            )}

            {/* Dropdown Menu */}
            <Menu
              anchorEl={menuAnchorEl}
              open={Boolean(menuAnchorEl) && activeChatId === chat?._id}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => handleOptionClick("rename", chat?._id)}>
                Rename
              </MenuItem>
              <MenuItem onClick={() => handleOptionClick("delete", chat?._id)}>
                Delete
              </MenuItem>
            </Menu>
          </Box>
        ))}
      </Box>
    </List>
  );
};

export default GPTChats;
