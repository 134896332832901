import { Avatar, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const UserChatQuestion = ({ question }) => {
  // const { chatQuestion } = useSelector((state) => state.esgGptReducer);
  const { name } = useSelector((state) => state.user);

  return (
    <div style={{ display: "flex", alignItems: "center", padding: "10px" }}>
      <Avatar
        style={{
          backgroundColor: "white",
          width: "35px",
          color: "#3374B9",
          fontSize: "16px",
        }}
      >
        {name ? name.slice(0, 2) : "Mm"}
      </Avatar>
      <Typography
        style={{
          fontSize: "13px",
          fontWeight: 500,
          color: "#15314E",
          padding: "10px 15px",
          userSelect: "text",
        }}
      >
        {question?.question || question}
      </Typography>
    </div>
  );
};

export default UserChatQuestion;
