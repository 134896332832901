const { ExpandMore, ExpandLess } = require("@mui/icons-material");
const {
  Box,
  IconButton,
  Checkbox,
  Typography,
  Collapse,
} = require("@mui/material");
const { useState } = require("react");
const { default: ShowStakeholder } = require("./ShowStakeholder");

// Recursive function to render entity, unit, and its children
const RenderEntity = (
  entity,
  checkedStakeholders,
  handleCheckboxChange,
  level = 0
) => {
  const [open, setOpen] = useState(true);

  const handleToggle = () => setOpen(!open);

  const isChecked = (id) => checkedStakeholders.some((s) => s._id === id);

  // Checking if the current entity has children or stakeholders to display the collapse option
  const hasChildrenOrStakeholders =
    entity.children?.length || entity.stakeholders?.length;

  return (
    <Box key={entity._id} sx={{ pl: level === 1 ? 4.5 : level * 2.2 }}>
      <Box display="flex" alignItems="center">
        {/* Dropdown Icon (only show if the entity/unit has children or stakeholders) */}
        {hasChildrenOrStakeholders && (
          <IconButton
            size="small"
            onClick={handleToggle}
            sx={{ color: "primary.light" }}
          >
            {open ? <ExpandMore /> : <ExpandLess />}
          </IconButton>
        )}

        <Checkbox
          checked={entity.stakeholders.every((stakeholder) =>
            isChecked(stakeholder._id)
          )}
          onChange={() => handleCheckboxChange(entity)}
        />

        <Typography variant="h4">
          {entity.entity_name || entity.name}
        </Typography>
      </Box>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box component="div" disablePadding>
          {entity.stakeholders.map((stakeholder) => (
            <ShowStakeholder
              key={stakeholder?._id}
              stakeholder={stakeholder}
              isChecked={isChecked}
              handleCheckboxChange={
                () => handleCheckboxChange(stakeholder, true) // Pass true to indicate this is a stakeholder
              }
            />
          ))}

          {entity.children &&
            entity.children.map((child) =>
              RenderEntity(
                child,
                checkedStakeholders,
                handleCheckboxChange,
                level + 1
              )
            )}
        </Box>
      </Collapse>
    </Box>
  );
};

export default RenderEntity;
