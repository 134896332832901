import {
  Box,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { textStyle } from "components/UI/phase-3/dynamicTable/DynamicTable";
import { useSelector } from "react-redux";

const stakeholdersList = [
  {
    name: "Satyam Parmar",
    role: "Software applications engineer",
    surveyStatus: "Completed",
    employeeType: "Internal",
  },
  {
    name: "Nidhi Jain",
    role: "Database Report writer",
    surveyStatus: "Completed",
    employeeType: "Internal",
  },
  {
    name: "Abdul VishaQ",
    role: "Network architect",
    surveyStatus: "Survey Expired",
    employeeType: "Internal",
  },
  {
    name: "Rashmi Kadam",
    role: "Cloud system engineer",
    surveyStatus: "Completed",
    employeeType: "Internal",
  },
  {
    name: "Naveen Roy",
    role: "Data modeling analyst",
    surveyStatus: "Inprogress",
    employeeType: "Internal",
  },
  {
    name: "Rutvi Pujara",
    role: "Front-end developer",
    surveyStatus: "Survey Expired",
    employeeType: "External",
  },
  {
    name: "Aaron Louis",
    role: "Project manager",
    surveyStatus: "Completed",
    employeeType: "Internal",
  },
  {
    name: "Abinash",
    role: "Database administrator",
    surveyStatus: "Completed",
    employeeType: "Internal",
  },
  {
    name: "Manjusha Praveen",
    role: "Data warehouse programming",
    surveyStatus: "Inprogress",
    employeeType: "Internal",
  },
  {
    name: "Krishanu Mahanta",
    role: "Application security",
    surveyStatus: "Completed",
    employeeType: "External",
  },
  {
    name: "Purnema",
    role: "Program manager",
    surveyStatus: "Completed",
    employeeType: "External",
  },
  {
    name: "Nilam Ghatage",
    role: "Network administrator",
    surveyStatus: "Inprogress",
    employeeType: "External",
  },
];

const useStyle = makeStyles((theme) => ({
  tableHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px 20px",
  },
  tableHeaderTitle: {
    color: "rgb(21, 49, 78)",
  },
  tableHeaderDorpdown: {
    display: "flex",
    alignItems: "center",
    gridGap: 10,
  },
  table: {
    minWidth: "650px",
  },
  tableHead: {
    backgroundColor: "#EBF1F8",
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#EBF1F8",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    cursor: "pointer",
  },
  severityStyle: {
    padding: "2px",
    textAlign: "center",
    borderRadius: 100,
  },
}));

export default function Stakeholders() {
  const classes = useStyle();
  const { assigned_stakeholders } = useSelector((state) => state.esgMaturity);

  const renderChip = (status) => {
    let bgColor = "";
    let color = "";

    switch (status) {
      case "progress":
        bgColor = "#E9E9E9";
        color = "#676767";
        break;

      case "completed":
        bgColor = "#BFE2CD";
        color = "#154328";
        break;

      default:
        // case "assigned":
        bgColor = "#EBF1F8";
        color = "#3374B9";
        break;
    }

    return (
      <Chip
        sx={{
          backgroundColor: bgColor,
          padding: "6px 10px",
          fontSize: "12px",
          color: color,
          borderRadius: "80px",
          textTransform: "capitalize",
        }}
        label={status}
      />
    );
  };

  return (
    <Box sx={{ margin: "10px 0px" }}>
      <TableContainer component="paper">
        <Table className={classes.table}>
          <TableHead className={classes.tableHead}>
            <TableRow>
              {/* <TableCell>ID</TableCell> */}
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Role</TableCell>
              <TableCell align="left" width={200}></TableCell>
              <TableCell align="left" width={200}></TableCell>

              <TableCell align="left" width={200}>
                Survey Status
              </TableCell>

              <TableCell align="center">Employee Type</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {assigned_stakeholders?.map((data, index) => (
              <TableRow
                key={data?._id}
                className={classes.tableRow}
              >
                <TableCell align="left">{data.stakeholder_name}</TableCell>
                <TableCell align="left">{data.stakeholder_role}</TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left"></TableCell>

                <TableCell align="left" style={textStyle()}>
                  {renderChip(data?.survey_status?.toLowerCase())}
                </TableCell>

                <TableCell
                  align="center"
                  sx={{ ...textStyle(), textTransform: "capitalize" }}
                >
                  {data?.stakeholder_employee_type}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
