import { useState } from "react";
import {
  Box,
  IconButton,
  TextField,
  Typography,
  Collapse,
  Checkbox,
  useTheme,
} from "@mui/material";
import { FiChevronsDown, FiSearch, FiChevronRight } from "react-icons/fi";

const StructureSearch = ({ orgData }) => {
  const theme = useTheme();
  const [expandedNodes, setExpandedNodes] = useState({});
  const [searchTerm, setSearchTerm] = useState("");

  const handleExpandClick = (id) => {
    setExpandedNodes((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const renderNode = (node, idPrefix = "") => {
    const nodeId = idPrefix + node._id;
    return (
      <Box key={node._id} mb={1}>
        <Box display="flex" alignItems="center">
          <Box width={24} display="flex" justifyContent="center">
            {node.children?.length > 0 && (
              <IconButton
                size="small"
                onClick={() => handleExpandClick(nodeId)}
                sx={{
                  transform: expandedNodes[nodeId]
                    ? "rotate(90deg)"
                    : "rotate(0deg)",
                  transition: "transform 0.3s",
                }}
              >
                <FiChevronRight size={16} />
              </IconButton>
            )}
          </Box>
          <Checkbox size="small" />
          <Typography variant="body2" ml={1}>
            {node.entity_name || node.name}
          </Typography>
        </Box>

        {node.children && node.children.length > 0 && (
          <Collapse in={expandedNodes[nodeId]}>
            <Box ml={4}>
              {node.children.map((child, childIndex) =>
                renderNode(child, `${nodeId}-child-${childIndex}-`)
              )}
            </Box>
          </Collapse>
        )}
      </Box>
    );
  };

  const handleRootExpandClick = () => {
    const expandAll = !expandedNodes.root;
    const newExpandedNodes = { root: expandAll };

    const recursivelyExpand = (node, parentIndex) => {
      const nodeId = parentIndex + node._id;
      newExpandedNodes[nodeId] = expandAll;
      node.children?.forEach((child, index) =>
        recursivelyExpand(child, `${nodeId}-child-${index}-`)
      );
    };

    orgData.forEach((node, index) => recursivelyExpand(node, "root"));
    setExpandedNodes(newExpandedNodes);
  };

  const filterNodes = (nodes, term) => {
    return nodes
      .filter((node) => {
        const matchesNode = (node.entity_name || node.name)
          .toLowerCase()
          .includes(term.toLowerCase());

        const matchesChildren =
          node.children?.some(
            (child) => filterNodes([child], term).length > 0
          ) || false;

        return matchesNode || matchesChildren;
      })
      .map((node) => ({
        ...node,
        children: filterNodes(node.children || [], term),
      }));
  };

  const filteredData = searchTerm ? filterNodes(orgData, searchTerm) : orgData;

  return (
    <Box
      position={"absolute"}
      top={0}
      left={0}
      p={2}
      width={"30em"}
      bgcolor={"background.paper"}
      boxShadow={"0px 4px 8px -2px #15314E1A"}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        mb={1}
      >
        <Typography variant="body1" fontWeight={600}>
          Structure
        </Typography>
        <IconButton
          sx={{
            color: "primary.main",
            transform: expandedNodes.root ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s",
          }}
          onClick={handleRootExpandClick}
        >
          <FiChevronsDown size={20} />
        </IconButton>
      </Box>

      <TextField
        id="structure-search"
        placeholder="Search"
        size="small"
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        InputProps={{
          endAdornment: (
            <FiSearch size={18} color={theme.palette.primary.main} />
          ),
        }}
      />

      <Collapse in={expandedNodes.root}>
        <Box mt={2}>
          {filteredData?.map((node, index) =>
            renderNode(node, `root-node-${index}-`)
          )}
        </Box>
      </Collapse>
    </Box>
  );
};

export default StructureSearch;
