import { useState, useRef } from "react";

import { Button, TableCell, Chip } from "@mui/material";
import { textStyle } from "components/UI/phase-3/dynamicTable/DynamicTable";

import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { getEsgComplianceReportData } from "actions/phase-3/esgCompliance/esgComplianceAction";

export const useDrawer = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => setIsOpen((prev) => !prev);

  return { isOpen, toggleDrawer };
};

export const useEsgComplianceApproveAudit = () => {
  const { esgComlianceAssessmentDetail } = useSelector(
    (state) => state?.escCompliance
  );

  // First drawer for "Option"
  const { isOpen: optionDrawer, toggleDrawer: optionToggleDrawer } =
    useDrawer();

  // Second drawer for "Assign"
  const { isOpen: assignDrawer, toggleDrawer: assignToggleDrawer } =
    useDrawer();

  // Third drawer for "Explore"
  const { isOpen: exploreDrawer, toggleDrawer: exploreToggleDrawer } =
    useDrawer();

  // Third drawer for "Explore"
  const { isOpen: assignAuditDrawer, toggleDrawer: assignAuditToggleDrawer } =
    useDrawer();
  //  get main table data

  //   const { data: { result: reportDataList = [] } = {}, isLoading } = useQuery({
  //     queryKey: [
  //       "reportsData",
  //       esgComlianceAssessmentDetail?._id,
  //       esgComlianceAssessmentDetail?.framework_id,
  //       "template_1",
  //       0,
  //       5,
  //     ],
  //     queryFn: () =>
  //       getEsgComplianceReportData(
  //         esgComlianceAssessmentDetail?._id,
  //         esgComlianceAssessmentDetail?.framework_id,
  //         "template_1",
  //         0,
  //         5
  //       ),
  //     enabled: !!esgComlianceAssessmentDetail?._id,
  //     // these for know query that data is fresh and API not call until we manually fetch or invalidate it
  //     staleTime: Infinity,
  //     cacheTime: 0,
  //   });

  const [selectedOption, setSelectedOption] = useState("");
  const [selectedRowData, setSelectedRowData] = useState("");

  const [items, setItems] = useState([
    {
      id: "1",
      text: "Section",
      hide: false,
      tableCell: ({ main_topic_name = "" }) => (
        <TableCell style={textStyle()}>{main_topic_name}</TableCell>
      ),
    },
    {
      id: "2",
      text: "Code",
      hide: false,
      tableCell: (rowData, code) => (
        <TableCell style={textStyle()}>{code + 1}</TableCell>
      ),
    },
    {
      id: "3",
      text: "Disclosure",
      hide: false,
      tableCell: ({ metric_name = "" }, code) => (
        <TableCell style={textStyle()}>{metric_name}</TableCell>
      ),
    },
    {
      id: "4",
      text: "Status",
      hide: false,
      tableCell: ({ status = "" }, code) => (
        <TableCell style={textStyle()}>
          <Chip
            sx={(theme) => ({
              backgroundColor:
                status !== "In Progress"
                  ? theme.palette.primary.lighter
                  : theme.palette.warning.lighter,
              padding: "6px 10px",
              fontSize: "12px",
              color:
                status !== "In Progress"
                  ? theme.palette.primary.dark
                  : theme.palette.warning.dark,
              borderRadius: "80px",
            })}
            label={status === "In Progress" ? "In Progress" : "Completed"}
          />
        </TableCell>
      ),
    },
    {
      id: "5",
      text: "Assigned Resource",
      hide: false,
      tableCell: ({ assigned_resource = "" }, code) => (
        <TableCell style={textStyle()}>
          <Chip
            sx={(theme) => ({
              backgroundColor: assigned_resource
                ? theme.palette.primary.lighter
                : theme.palette.warning.lighter,
              padding: "6px 10px",
              fontSize: "12px",
              color: assigned_resource
                ? theme.palette.primary.dark
                : theme.palette.warning.dark,
              borderRadius: "80px",
            })}
            label={
              assigned_resource ? `${assigned_resource}+ Assigned` : "Pending"
            }
          />
        </TableCell>
      ),
    },
    {
      id: "6",
      text: "Comments",
      hide: false,
      tableCell: ({ assigned_resource = "" }, code) => (
        <TableCell style={textStyle()}>-</TableCell>
      ),
    },

    {
      id: "7",
      text: "Action",
      hide: false,
      tableCell: (rowData, code) => (
        <TableCell style={textStyle()}>
          <Button
            variant="text"
            size="small"
            onClick={() => {
              exploreToggleDrawer();
              setSelectedRowData(rowData);
              //   resetDetailState();
              // navigate(`/esg_compliance/${frameworkName}/${metric_name}`);
            }}
          >
            Explore
          </Button>
          {/* <IconButton>
            <IoMdMore />
          </IconButton> */}
        </TableCell>
      ),
    },
  ]);

  //  functionality for property list drag and drop / hide and unhide
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  const handleDragStart = (index) => {
    dragItem.current = index;
  };

  const handleDragEnter = (index) => {
    dragOverItem.current = index;
  };

  const handleDrop = () => {
    const copiedItems = [...items];
    const draggedItem = copiedItems[dragItem.current];
    copiedItems.splice(dragItem.current, 1);
    copiedItems.splice(dragOverItem.current, 0, draggedItem);

    dragItem.current = null;
    dragOverItem.current = null;
    setItems(copiedItems);
  };

  const handleHideStatus = (id) =>
    setItems((pre) =>
      pre.map((i) => (i.id === id ? { ...i, hide: !i.hide } : i))
    );

  const handleSelectedOption = (option) => setSelectedOption(option);

  return {
    // states
    reportDataList: [],
    isLoading: false,
    optionDrawer,
    assignDrawer,
    exploreDrawer,
    assignAuditDrawer,

    selectedOption,
    selectedRowData,
    items,

    // functions
    handleDragStart,
    handleDragEnter,
    handleDrop,
    handleHideStatus,
    handleSelectedOption,
    optionToggleDrawer,
    assignToggleDrawer,
    exploreToggleDrawer,
    setSelectedRowData,
    assignAuditToggleDrawer,
  };
};
