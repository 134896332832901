import { useMemo, useState } from "react";

import {
  Box,
  Button,
  Icon,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Heading from "components/UI/phase-3/heading/Heading";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import chatBot from "images/chatbot.png";
import DisclosureTypeOne from "./disclosureType/DisclosureTypeOne";
import DisclosureTypeThree from "./disclosureType/DisclosureTypeThree";
import DisclosureTypeTwo from "./disclosureType/DisclosureTypeTwo";
import ChatThreadMain from "./chatThread/ChatThreadMain";
import InputField from "components/UI/phase-3/inputField/InputField";
import DocumentUpload from "components/UI/phase-3/documentUpload/DocumentUpload";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { submitEsgComplianceTaskMetric } from "actions/phase-3/task/esgCompliance/esgComplianceTaskAction";
import { useSelector } from "react-redux";
import useFormValidation from "customHooks/useFormValidation";

export default function ExploreDisclosure({ toggleExplore, selectedRowData }) {
  const {
    _id,
    metric_id,
    metric_name,
    main_topic_id,
    main_topic_name,
    submisson_id,
    survey_type,
    data_type,
    guidelines,
    rows,
    columns,
    submission_data,
    comment,
    file,
    approved,
    rejected,
    status,
  } = selectedRowData || {};

  const queryClient = useQueryClient();

  const { esgComplianceSelectedTask } = useSelector(
    (state) => state.esgComplianceTask
  );

  const { entity_id } = useSelector((state) => state.user);

  const [chatThreadOpen, setChatThreadOpen] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);

  const initialState = useMemo(
    () => ({
      data: submission_data,
      comment,
      file,
    }),
    [submission_data, comment, file]
  );

  const validationRules = useMemo(
    () => ({
      data: { required: true },
      comment: {
        required: false,
      },
      file: {
        required: false,
      },
    }),
    []
  );

  const { formData, errors, handleChange, validationErrors, setFormData } =
    useFormValidation(initialState, validationRules);

  // Mutation for creating a materiality assessment
  const { mutateAsync, isPending } = useMutation({
    mutationFn: submitEsgComplianceTaskMetric,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["esgComplianceAssessmentTaskDetails"]);
      setIsDataChanged(false); // Reset after successful submission
    },
    onError: (error) => {
      // Handle error
      console.error("Create failed:", error);
    },

    staleTime: Infinity,
    cacheTime: 0,
  });

  return (
    <>
      {/* heading */}
      <Heading
        text={main_topic_name}
        icon={
          <IconButton sx={{ marginRight: "3px" }} onClick={toggleExplore}>
            <KeyboardArrowLeft color="primary" size="small" />
          </IconButton>
        }
      >
        <Stack direction={"row"} gap={2}>
          {/* <Button
            variant="text"
            color="primary"
            onClick={() => setChatThreadOpen(true)}
          >
            View Chat Thread
          </Button> */}
          <LoadingButton
            variant="contained"
            color="primary"
            disabled={
              !isDataChanged || Object.keys(validationErrors).length > 0
            }
            loading={isPending}
            onClick={async () =>
              await mutateAsync({
                esg_compliance_id: esgComplianceSelectedTask?.esg_compliance_id,
                metric_id,
                organization_id: entity_id,
                submission_data: formData.data,
                comment: formData.comment,
                file: formData.file,
              })
            }
          >
            Submit
          </LoadingButton>
        </Stack>
      </Heading>

      {/* Bot suggestion heading */}
      {/* <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={2}
      >
        <Box
          sx={{
            backgroundColor: "#EBF1F8",
            borderRadius: "8px",
            width: "85%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 3,
            padding: "10px",
          }}
        >
          <img src={chatBot} alt="chatbot" />
          <Typography variant="body2">
            Impact grows bot is handy suggestion tool based on the raw data from
            exchange hub it can give you it give you data to enter in specific
            field. This data can also be edited before submitting.
          </Typography>
        </Box>
        <Button variant="text">View Suggestion</Button>
      </Stack> */}
      {/* disclosure question */}
      <Typography variant="subtitle1">{metric_name}</Typography>

      {/* Disclosure Body */}
      {survey_type === 1 && (
        <DisclosureTypeOne>
          <InputField
            heading={"Text Field"}
            rows={3}
            label="Enter Here"
            value={formData?.data}
            error={errors.data}
            name={"data"}
            handleChange={(e) => {
              handleChange(e);
              setIsDataChanged(true);
            }}
          />
        </DisclosureTypeOne>
      )}
      {survey_type === 2 && (
        <DisclosureTypeTwo
          typeTwoData={formData.data}
          columns={columns}
          rows={rows}
          setFormData={setFormData}
          handleCheckedDataChanged={() => setIsDataChanged(true)}
        />
      )}
      {survey_type === 3 && (
        <DisclosureTypeThree
          typeThreeData={formData.data}
          columns={columns}
          rows={rows}
          setFormData={setFormData}
          handleCheckedDataChanged={() => setIsDataChanged(true)}

        />
      )}

      {/* comments */}

      <InputField
        label="Comment"
        heading={"Comment"}
        value={formData?.comment}
        error={errors.comment}
        name={"comment"}
        handleChange={(e) => {
          handleChange(e);
          setIsDataChanged(true);
        }}
      />

      {/*  file upload */}

      <DocumentUpload
        value={formData?.file}
        error={errors.file}
        name={"file"}
        handleChange={(e) => {
          handleChange(e);
          setIsDataChanged(true);
        }}
      />

      {/* Drawers */}

      {/* chat thread drawer */}
      <ChatThreadMain
        hasDrawerOpen={chatThreadOpen}
        handleClose={() => setChatThreadOpen(false)}
      />
    </>
  );
}
