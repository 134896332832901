import React, { useEffect, useState } from "react";
import clsx from "clsx";
import Icon from "@mui/material/Icon";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Drawer from "@mui/material/Drawer";
import Collapse from "@mui/material/Collapse";

import DrawerListItem from "../listItem/drawerListItem";
import "../../../styles/drawer.css";
import logo from "../../../images/ImpactGrows Black corrected.svg";
import { Link, useLocation } from "react-router-dom";
import DrawerUseStyles from "./drawerStyles";
import navItems from "./drawerItem";
import { useDispatch } from "react-redux";
import { userLogout } from "actions/phase-3/auth/authAction";

function NavCustomDrawer({
  isOpen,
  setIsOpen,
  logOut,
  token,
  redirect,
  setRedirectValue,
  login,
}) {
  let location = useLocation();
  const classes = DrawerUseStyles();

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [tnc, setTnc] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const userType = "SA"; // localStorage.getItem("current_organisation_user_type");

  const handleDrawerToggle = () => {
    setOpen(!open);
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const currentPath = location.pathname;
    const selectedItem = navItems.find((item) => item.path === currentPath);
    if (selectedItem) {
      setSelectedIndex(selectedItem.index);
    }
    setTnc(
      currentPath === "/privacy_policy" ||
        currentPath === "/tnc" ||
        currentPath === "/faq"
    );
  }, [location.pathname]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      {!tnc && (
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <List className={classes.head}>
            <ListItem className={classes.head}>
              <ListItemIcon>
                <IconButton
                  className={classes.iconButton}
                  onClick={handleDrawerToggle}
                >
                  <Icon>{open ? "close" : "menu"}</Icon>
                </IconButton>
              </ListItemIcon>
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemIcon>
                {open &&
                  isOpen &&
                  login.selectedOrganization &&
                  login.selectedOrganization.domain_logo &&
                  login.selectedOrganization.domain_logo.after_login.logo && (
                    <div
                      style={
                        login.selectedOrganization &&
                        login.selectedOrganization.domain_logo.after_login.logo
                          ? login.selectedOrganization.domain_logo.after_login
                              .logo_styles
                          : JSON.parse(localStorage.getItem("logo_styles"))
                            ? JSON.parse(localStorage.getItem("logo_styles"))
                            : {}
                      }
                    >
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                        src={
                          login.selectedOrganization &&
                          login.selectedOrganization.domain_logo.after_login
                            .logo
                            ? login.selectedOrganization.domain_logo.after_login
                                .logo
                            : localStorage.getItem("logo") &&
                              localStorage.getItem("logo")
                        }
                        alt="logo"
                      />
                    </div>
                  )}
                {open &&
                  isOpen &&
                  login.selectedOrganization &&
                  login.selectedOrganization.domain_logo &&
                  !login.selectedOrganization.domain_logo.after_login.logo && (
                    <div>
                      <img
                        style={{
                          width: "70%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                        src={logo}
                        alt="logo"
                      />
                    </div>
                  )}
              </ListItemIcon>
            </ListItem>
          </List>

          <List
            className={classes.list2}

            sx={{
              "&::-webkit-scrollbar": {
                width: "3px", // Make scrollbar width small (4px) for minimalistic look
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#c1daed8f", // Subtle thumb color for the scrollbar
                borderRadius: "8px", // Rounded look
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "transparent", // Transparent background to show only the scrollbar line
              },
            }}
          >
            {navItems.map((item) => {
              if (item.userType && !item.userType.includes(userType))
                return null;

              return (
                <Link
                  key={item.index}
                  to={item.path}
                  className={
                    true
                      ? // login.isSubscriptionActive &&
                        // localStorage.getItem("status") !== "EXPIRED"
                        classes.link
                      : !item.disableStyle
                        ? classes.link
                        : classes.disabledLink
                  }
                >
                  <DrawerListItem
                    disabled={
                      !login.isSubscriptionActive &&
                      localStorage.getItem("status") === "EXPIRED" &&
                      item.disableStyle !== false
                    }
                    selected={selectedIndex === item.index}
                    className={classes.listitems}
                    onClick={() => {
                      setSelectedIndex(item.index);
                      if (item.isLogout) {
                        logOut(token);
                      } else if (redirect) {
                        setRedirectValue(false);
                      }
                    }}
                    button
                    data-tut={item.dataTut}
                  >
                    <ListItemIcon>
                      {open && <Icon fontSize="small">chevron_right</Icon>}
                      {item.icon && (
                        <Icon
                          component={"img"}
                          fontSize="small"
                          src={item.icon}
                        ></Icon>
                      )}
                    </ListItemIcon>
                    <Collapse timeout={0} in={open}>
                      {" "}
                      <ListItemText secondary={item.label} />
                    </Collapse>
                  </DrawerListItem>
                </Link>
              );
            })}
          </List>
          <Divider style={{ marginTop: "auto" }} />
          <List className={classes.list}>
            <Link to="/login">
              <DrawerListItem
                selected={selectedIndex === 10}
                className={classes.listitems}
                onClick={() => {
                  setSelectedIndex(10);
                  // logOut(token);
                  dispatch(userLogout());
                }}
                button
                name="logoutBtn"
              >
                <ListItemIcon>
                  <>
                    {open && <Icon fontSize="small">chevron_right</Icon>}
                    <Icon fontSize="small">logout</Icon>
                  </>
                </ListItemIcon>
                <Collapse timeout={0} in={open}>
                  {" "}
                  <ListItemText secondary="Logout" />
                </Collapse>
              </DrawerListItem>
            </Link>
          </List>
        </Drawer>
      )}
    </div>
  );
}

export default React.memo(NavCustomDrawer);
