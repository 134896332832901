import { Box, Tabs, Tab } from "@mui/material";
import ExcelMain from "./tabsMainScreen/ExcelMain";
import ApiIntegrationMain from "./tabsMainScreen/ApiIntegrationMain";
import useTabChanges from "customHooks/useTabChanges";
import DocumentsMain from "./tabsMainScreen/DocumentsMain";
import SurveyMain from "./tabsMainScreen/SurveyMain";

// components

// Array of tab details
const tabs = [
  { label: "Survey", component: <SurveyMain/> },
  { label: "Excel", component: <ExcelMain /> },
  { label: "API Integration", component: <ApiIntegrationMain /> },
  { label: "Documents", component: <DocumentsMain /> },
  { label: "Data Sets", component: <></> },
];

const DataHubScreens = () => {
  const { selectedTab, handleChange } = useTabChanges();

  return (
    <Box>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        aria-label="org details tabs"
      >
        {tabs.map((tab, index) => (
          <Tab key={index} label={tab.label} />
        ))}
      </Tabs>
      <Box mt={2}>{tabs[selectedTab].component}</Box>
    </Box>
  );
};

export default DataHubScreens;
