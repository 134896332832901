import React from "react";
import HightChartsReact from "highcharts-react-official";
import HighCharts from "highcharts/highcharts";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import graph from "../../../../../images/Zones.png";
import Heading from "components/UI/phase-3/heading/Heading";

import {
  Box,
  Button,
  Chip,
  Typography,
  TableCell,
  TableRow,
  Stack,
} from "@mui/material";
import DynamicTable, {
  textStyle,
} from "components/UI/phase-3/dynamicTable/DynamicTable";
import { RelavanceBar } from "components/widgets/materialityAssessmentLeft/materialityAssessmentLeft";
import { useMaterialityAnalysis } from "../APIs/HandleAnalysisApi";

import {
  RatingFive,
  RatingFour,
  RatingThree,
  RatingTwo,
  RatingOne,
} from "utils/MaterialityAssessmentUtils/materialityAssessmentUtils";
import { NoDataComponent } from "../../esgMaturity/shareSurvey/NoData";
import OptimizeInitialRender from "../../supplier/right/companySetup/OptimizeInitialRender";

const options = {
  chart: {
    type: "scatter",
    zoomType: "xy",

    plotBackgroundImage: graph,
    style: {
      fontFamily: "Poppins",

      backgroundColor: "#ddd",
    },
    width: null, // Increase the width of the chart
    height: "60%",
  },
  title: {
    // text: "Importance to company Vs Topic's impact on  Environment/ Social/ Governance",
    text: "",
  },
  credits: {
    enabled: false,
  },

  xAxis: {
    title: {
      text: "Importance to company",
    },
    min: 0.5,
    max: 5,
  },
  yAxis: {
    title: {
      text: "Topic's impact on  Environment/ Social/ Governance",
    },
    min: 0.5,
    max: 5,
    gridLineWidth: 0,
  },
  plotOptions: {
    scatter: {
      showInLegend: false,
      opacity: 0.8,
      jitter: {
        x: 0.04,
        y: 0.05,
      },
      marker: {
        radius: 8,
        symbol: "circle",
        fillColor: "#3374b9",
      },
      tooltip: {
        pointFormat:
          "Relevance: {point.x}<br/>Weightage: {point.y}<br/>Topic: {point.topic_name}",
        headerFormat: "",
      },
    },
  },
  series: [
    {
      data: [],
    },
  ],
};

function checkVote(vote) {
  switch (vote) {
    case 1:
      return <RatingOne />;
    case 2:
      return <RatingTwo />;
    case 3:
      return <RatingThree />;
    case 4:
      return <RatingFour />;
    case 5:
      return <RatingFive />;
    default:
      return;
  }
}

function checkText(vote) {
  switch (vote) {
    case 1:
      return "Not At All Important";
    case 2:
      return "Not Important";
    case 3:
      return "Neutral";
    case 4:
      return "Important";
    case 5:
      return "Very Important";
    default:
      return;
  }
}

function MaterialityAnalysisMain() {
  const { materialityTopicAnalysis } = useMaterialityAnalysis();

  const { table_data = [], graph_data = [] } = materialityTopicAnalysis || {};

  if (
    table_data.filter((m) => m.average_relevance === 0).length ===
    table_data.length
  ) {
    return <NoDataComponent mainTitle="No Topic Submission Found" />;
  }

  return (
    <Box sx={{ height: "100%" }}>
      <Heading size="16px" text="Materiality Assessment">
        <Button
          variant="contained"
          size="medium"
          startIcon={<ShareOutlinedIcon />}
        >
          Export Report
        </Button>
      </Heading>

      <Box
        sx={{
          overflow: "scroll",
          height: "85%",
        }}
      >
        <Typography variant="body2" color={"#676767"} marginTop={"5px"}>
          Build your sustainability foundation. Here, you'll view the map to
          your company's groups. This crucial step ensures data flows smoothly,
          enabling accurate measurement and informed decision-making.
        </Typography>
        <Box
          sx={{
            height: "95%",
            display: "flex",
          }}
        >
          <Box sx={{ width: "50%", height: "100%", overflow: "scroll" }}>
            <OptimizeInitialRender chunkSize={2}>
              {table_data.map((item) => (
                <RelavanceBar item={item} />
              ))}
            </OptimizeInitialRender>
          </Box>
          <Box
            sx={{
              height: "100%",
              width: "50%",
              padding: "10px",
              position: "relative",
            }}
          >
            {/* <Box
            sx={{
              width: "100%",
              height: "90%",
              maxWidth: "100%",
              maxHeight: "100%",
              position: "relative",
            }}
          > */}
            <HightChartsReact
              highcharts={HighCharts}
              options={{
                ...options,
                series: [{ data: graph_data }],
              }}
            />
            {/* </Box> */}
          </Box>
        </Box>
        <Box
          sx={{
            height: "95%",
            display: "flex",
            marginTop: "35px",
          }}
        >
          <DynamicTable
            headings={[
              "Sl.No.",
              "Material Topic Name",
              "Category of ESG",
              "Risk/Opportunity",
              "Relavance",
              "Risk/opportunity overview",
              "Approach",
              "Mitigation/Activities",
            ]}
          >
            <OptimizeInitialRender chunkSize={1}>
              {table_data?.map((rowData, rowIndex) => (
                <TableRow
                  key={rowIndex}
                  onClick={() => {}}
                  sx={{ borderBottom: "1px solid #EBF1F8" }}
                >
                  <TableCell style={textStyle("main")}>
                    {rowIndex + 1}
                  </TableCell>
                  <TableCell style={textStyle("main")}>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      sx={{ gap: 10 }}
                    >
                      {rowData?.topic_name || "-"}
                    </Box>
                  </TableCell>
                  <TableCell style={textStyle()}>
                    {rowData?.category_of_esg || "-"}
                  </TableCell>
                  <TableCell style={textStyle()}>
                    <Chip
                      sx={(theme) => ({
                        backgroundColor:
                          rowData.level === "Risk"
                            ? theme.palette.error.lighter
                            : theme.palette.secondary.lighter,
                        padding: "6px 10px",
                        fontSize: "12px",
                        color:
                          rowData.level === "Risk"
                            ? theme.palette.error.dark
                            : theme.palette.secondary.dark,
                        borderRadius: "80px",
                      })}
                      label={rowData.level}
                    />
                  </TableCell>
                  <TableCell style={textStyle()}>
                    <Stack
                      direction={"row"}
                      alingItems={"center"}
                      justifyContent={"flex-start"}
                      gap={1}
                    >
                      {checkVote(rowData.average_relevance)}
                      <Chip
                        sx={(theme) => ({
                          backgroundColor: theme.palette.primary.lighter,
                          padding: "6px 10px",
                          fontSize: "12px",
                          color: theme.palette.primary.dark,
                          borderRadius: "80px",
                        })}
                        label={checkText(rowData?.average_relevance)}
                      />
                    </Stack>
                  </TableCell>

                  <TableCell style={textStyle()}>
                    {rowData?.overview || "-"}
                  </TableCell>
                  <TableCell style={textStyle()}>
                    {rowData?.approach || "-"}
                  </TableCell>
                  <TableCell style={textStyle()}>
                    {rowData?.mitigation || "-"}
                  </TableCell>
                </TableRow>
              ))}
            </OptimizeInitialRender>
          </DynamicTable>
        </Box>
      </Box>
    </Box>
  );
}

export default MaterialityAnalysisMain;
