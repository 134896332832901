import { useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import requiredField from "components/UI/phase-3/inputField/requiredShow";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";

const SelectFrequency = ({ data, handleData }) => {
  const [formData, setFormData] = useState({
    date: null,
    month: null,
    year: null,
    repeat_freq: "",
    ends: "",
    endDate: null,
    endMonth: null,
    endYear: null,
  });


  return (
    <Box p={2}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {/* Section 1 */}
        <Box display={"flex"} gap={2}>
          {/* Date of receiving */}
          <Box>
            <Typography variant="body1" color="action.active" gutterBottom>
              Date of receiving {requiredField(!formData?.date)}
            </Typography>
            <DatePicker
              value={formData.date}
              views={["day"]}
              onChange={(newDate) => {
                setFormData((prev) => ({ ...prev, date: newDate }));
                handleData((pre) => ({
                  ...pre,
                  receiving_date: `${moment(newDate).format("DD")}-${moment(formData.month).format("MM")}-${moment(formData.year).format("YYYY")}`,
                }));
              }}
              renderInput={(params) => <TextField {...params} required />}
            />
          </Box>
          {/* Month */}
          <Box>
            <Typography variant="body1" color="action.active" gutterBottom>
              Month {requiredField(!formData?.month)}
            </Typography>
            <DatePicker
              value={formData?.month}
              views={["month"]}
              onChange={(newDate) => {
                setFormData((prev) => ({ ...prev, month: newDate }));
                handleData((pre) => ({
                  ...pre,
                  receiving_date: `${moment(formData.date).format("DD")}-${moment(newDate).format("MM")}-${moment(formData.year).format("YYYY")}`,
                }));
              }}
              renderInput={(params) => <TextField {...params} required />}
            />
          </Box>
          {/* Year */}
          <Box>
            <Typography variant="body1" color="action.active" gutterBottom>
              Year {requiredField(!formData?.year)}
            </Typography>
            <DatePicker
              value={formData?.year}
              views={["year"]}
              onChange={(newDate) => {
                setFormData((prev) => ({ ...prev, year: newDate }));
                handleData((pre) => ({
                  ...pre,
                  receiving_date: `${moment(formData.date).format("DD")}-${moment(formData.month).format("MM") || ""}-${moment(newDate).format("YYYY") || ""}`,
                }));
              }}
              renderInput={(params) => <TextField {...params} required />}
            />
          </Box>
        </Box>

        {/* Section 2 - Repeat Frequency */}
        <Box width={"50vw"} mt={3}>
          <Typography variant="body1" color="action.active" gutterBottom>
            Repeat Frequency {requiredField(!formData?.repeat_freq)}
          </Typography>
          <Select
            placeholder="Choose the required frequnecy"
            value={formData?.repeat_freq}
            onChange={(e) => {
              setFormData((prev) => ({
                ...prev,
                repeat_freq: e.target.value,
              }));
              handleData((pre) => ({
                ...pre,
                frequency: e.target.value,
              }));
            }}
            required
            fullWidth
          >
            {[
              { item: "Yearly", value: "YEARLY" },
              { item: "Half Yearly", value: "HALF_YEARLY" },
              { item: "Quaterly", value: "QUARTERLY" },
              { item: "Monthly", value: "MONTHLY" },
            ].map((item) => (
              <MenuItem key={item} value={item?.value}>
                {item?.item}
              </MenuItem>
            ))}
          </Select>
        </Box>

        {/* Section 3 - Ends */}
        <Box mt={3}>
          <Typography variant="body1" color="action.active" gutterBottom>
            Ends {requiredField(!formData?.ends)}
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup
              value={formData?.ends}
              onChange={(e) => {
                setFormData((prev) => ({ ...prev, ends: e.target.value }));
                handleData((pre) => ({
                  ...pre,
                  ends: e.target.value,
                }));
              }}
            >
              <FormControlLabel
                value="NEVER"
                control={<Radio />}
                label="Never"
              />
              <FormControlLabel value="ON" control={<Radio />} label="On" />
            </RadioGroup>
          </FormControl>
        </Box>

        {/* section 4 - Date of Ends */}

        {formData?.ends === "ON" && (
          <Box display={"flex"} gap={2}>
            {/* Date of ends */}
            <Box>
              <Typography variant="body1" color="action.active" gutterBottom>
                Date of Ends {requiredField(!formData?.endDate)}
              </Typography>
              <DatePicker
                value={formData.endDate}
                views={["day"]}
                onChange={(newDate) => {
                  setFormData((prev) => ({ ...prev, endDate: newDate }));
                  handleData((pre) => ({
                    ...pre,
                    ends_on_date: `${moment(newDate).format("DD")}-${moment(formData.endMonth).format("MM")}-${moment(formData.endYear).format("YYYY")}`,
                  }));
                }}
                renderInput={(params) => <TextField {...params} required />}
              />
            </Box>
            {/* Month */}
            <Box>
              <Typography variant="body1" color="action.active" gutterBottom>
                Month {requiredField(!formData?.endMonth)}
              </Typography>
              <DatePicker
                value={formData?.endMonth}
                views={["month"]}
                onChange={(newDate) => {
                  setFormData((prev) => ({ ...prev, endMonth: newDate }));
                  handleData((pre) => ({
                    ...pre,
                    ends_on_date: `${moment(formData.endDate).format("DD")}-${moment(newDate).format("MM")}-${moment(formData.endYear).format("YYYY")}`,
                  }));
                }}
                renderInput={(params) => <TextField {...params} required />}
              />
            </Box>
            {/* Year */}
            <Box>
              <Typography variant="body1" color="action.active" gutterBottom>
                Year {requiredField(!formData?.endYear)}
              </Typography>
              <DatePicker
                value={formData?.endYear}
                views={["year"]}
                onChange={(newDate) => {
                  setFormData((prev) => ({ ...prev, endYear: newDate }));
                  handleData((pre) => ({
                    ...pre,
                    ends_on_date: `${moment(formData.endDate).format("DD")}-${moment(formData.endMonth).format("MM") || ""}-${moment(newDate).format("YYYY") || ""}`,
                  }));
                }}
                renderInput={(params) => <TextField {...params} required />}
              />
            </Box>
          </Box>
        )}
      </LocalizationProvider>
    </Box>
  );
};

export default SelectFrequency;
