import { Box } from "@mui/material";
import LeftSectionLayout from "components/layout/twoSectionLayout/LeftSectionLayout";
import MainLayoutSection from "components/layout/twoSectionLayout/MainLayoutSection";
import RightSectionLayout from "components/layout/twoSectionLayout/RightSectionLayout";
import DefineAgainstTopicsLeft from "components/widgets/phase-3/esgStrategyAndPlanning/defineAgaintsTopics/left/DefineAgainstTopicsLeft";
import MaterialityQuestion from "components/widgets/phase-3/MaterialityMapping/assign/left/MaterialityQuestion";
import MaterialityTaskRight from "components/widgets/phase-3/Task/matariality/right/MaterialityTaskRight";

function MaterialityMain() {
  return (
    <Box sx={{ overflow: "hidden" }}>
      <Box
        sx={{ height: "83vh", overflow: "scroll", padding: "20px 5px 0px 5px" }}
      >
        <MainLayoutSection
          height="98%"
          left={
            <LeftSectionLayout hasShadow={false}>
              <DefineAgainstTopicsLeft from={"task"} />
            </LeftSectionLayout>
          }
          leftWidth="25%"
          right={
            <RightSectionLayout>
              <MaterialityTaskRight />
            </RightSectionLayout>
          }
          rightWidth="73%"
        />
      </Box>
    </Box>
  );
}

export default MaterialityMain;
