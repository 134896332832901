import Heading from "components/UI/phase-3/heading/Heading";
import { Box, Button, Stack } from "@mui/material";
import { NoDataComponent } from "components/widgets/phase-3/esgMaturity/shareSurvey/NoData";
import useMaterialityTopicsPreview from "../../APIs/HandleTopicPreview";
import MaterialityQuestion from "./MaterialityQuestion";
import OptimizeInitialRender from "components/widgets/phase-3/supplier/right/companySetup/OptimizeInitialRender";

function MaterialityQuestionPreview({ heading = "" }) {
  const { topics, hasMoreTopics, topicCategory, inputLabel, handleLoadMore } =
    useMaterialityTopicsPreview();

  return (
    <Box sx={{ height: "100%" }}>
      <Heading size="16px" text={heading} />

      <Box
        sx={{
          overflow: "scroll",
          height: "85%",
        }}
      >
        {topics?.length !== 0 ? (
          <OptimizeInitialRender chunkSize={1}>
            {topics.map((dataMain, index) => {
              return (
                <>
                  <MaterialityQuestion
                    index={index}
                    dataMain={dataMain}
                    hasEditable={false}
                    inputLabel={inputLabel}
                    topicCategory={topicCategory}
                  />
                </>
              );
            })}
          </OptimizeInitialRender>
        ) : (
          <NoDataComponent mainTitle={"Currently there are no surveys found"} />
        )}
        {hasMoreTopics && (
          <Stack alignItems={"center"}>
            <Button
              variant="contained"
              onClick={handleLoadMore}
              sx={{ marginTop: 2 }}
            >
              Load More
            </Button>
          </Stack>
        )}
      </Box>
    </Box>
  );
}

export default MaterialityQuestionPreview;
