import { useEffect, useState } from "react";

import { Box, Button, Chip, TableCell } from "@mui/material";
import DisclosureListing from "./DisclosureListing";
import ExploreDisclosure from "./ExploreDisclosure";
import TopicTable from "components/widgets/phase-3/esgCompliance/reportData/reportDataComponent/TopicTable";
import { textStyle } from "components/UI/phase-3/dynamicTable/DynamicTable";
import ChatThreadMain from "./chatThread/ChatThreadMain";

export default function ComplianceRight({
  esgComplianceAssessmentTask,
  selectedTab,
}) {
  const [exploreDisclosure, setExploreDisclosure] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const toggleExplore = () => {
    setExploreDisclosure(!exploreDisclosure);
  };

  useEffect(() => {
    setExploreDisclosure(false);
  }, [selectedTab]);

  const items = [
    {
      id: "1",
      text: "Section",
      hide: false,
      tableCell: ({ main_topic_name = "" }) => (
        <TableCell style={textStyle()}>{main_topic_name}</TableCell>
      ),
    },
    // {
    //   id: "2",
    //   text: "Code",
    //   hide: false,
    //   tableCell: (rowData, code) => (
    //     <TableCell style={textStyle()}>{code + 1}</TableCell>
    //   ),
    // },
    {
      id: "3",
      text: "Disclosure",
      hide: false,
      tableCell: ({ metric_name = "" }, code) => (
        <TableCell style={textStyle()}>{metric_name}</TableCell>
      ),
    },
    {
      id: "4",
      text: "Status",
      hide: false,
      tableCell: ({ status = "" }, code) => (
        <TableCell style={textStyle()}>
          <Chip
            sx={(theme) => ({
              backgroundColor:
                status !== "Pending"
                  ? theme.palette.primary.lighter
                  : theme.palette.warning.lighter,
              padding: "6px 10px",
              fontSize: "12px",
              color:
                status !== "Pending"
                  ? theme.palette.primary.dark
                  : theme.palette.warning.dark,
              borderRadius: "80px",
            })}
            label={status}
          />
        </TableCell>
      ),
    },
    // {
    //   id: "5",
    //   text: "Assigned Resource",
    //   hide: false,
    //   tableCell: ({ assigned_resource = "" }, code) => (
    //     <TableCell style={textStyle()}>
    //       <Chip
    //         sx={(theme) => ({
    //           backgroundColor: assigned_resource
    //             ? theme.palette.primary.lighter
    //             : theme.palette.warning.lighter,
    //           padding: "6px 10px",
    //           fontSize: "12px",
    //           color: assigned_resource
    //             ? theme.palette.primary.dark
    //             : theme.palette.warning.dark,
    //           borderRadius: "80px",
    //         })}
    //         label={
    //           assigned_resource ? `${assigned_resource} Assigned` : "Pending"
    //         }
    //       />
    //     </TableCell>
    //   ),
    // },
    // {
    //   id: "6",
    //   text: "Comments",
    //   hide: false,
    //   tableCell: ({ assigned_resource = "" }, code) => (
    //     <TableCell style={textStyle()}>-</TableCell>
    //   ),
    // },

    {
      id: "7",
      text: "Action",
      hide: false,
      tableCell: (rowData, code) => (
        <TableCell style={textStyle()}>
          <Button
            variant="text"
            size="small"
            onClick={() => {
              toggleExplore();
              setSelectedRowData(rowData);
              //   resetDetailState();
              // navigate(`/esg_compliance/${frameworkName}/${metric_name}`);
            }}
          >
            Explore
          </Button>
          {/* <IconButton>
            <IoMdMore />
          </IconButton> */}
        </TableCell>
      ),
    },
  ];

  // select explore compoenent base on selected Tab

  const ExploreComponent = {
    0: (
      <ExploreDisclosure
        selectedRowData={selectedRowData}
        toggleExplore={() => {
          toggleExplore(false);
          setSelectedRowData(null);
        }}
      />
    ),
    1: (
      <ChatThreadMain
      selectedRowData={selectedRowData}
        hasDrawerOpen={exploreDisclosure}
        handleClose={() => toggleExplore(false)}
      />
    ),
  };

  return (
    <Box sx={{ height: "98%", overflow: "scroll" }}>
      {exploreDisclosure ? (
        ExploreComponent[selectedTab]
      ) : (
        <TopicTable
          dynamicHeading={items.filter((i) => !i.hide).map((i) => i.text)}
          isLoading={false}
          reportDataList={esgComplianceAssessmentTask}
          height={"100%"}
          // ref={tableRef}
        >
          {items.filter((i) => !i.hide).map((i) => i.tableCell)}
        </TopicTable>
        // <DisclosureListing toggleExplore={toggleExplore} />
      )}
    </Box>
  );
}
