import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Avatar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setEsgStrategyTaskCategory } from "actions/phase-3/task/esgStrategyTask/esgStrategyTaskAction";

const dummyData = [
  {
    topic: "Activities",
  },
  {
    topic: "Key Performance Indicators ( KPI )",
  },
];
function EsgStrategyPlanningLeft() {
  const dispatch = useDispatch();
  const { selectedTopicCategory } = useSelector(
    (state) => state?.esgStrategyTask
  );

  return (
    <>
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          overflow: "scroll",
          height: "100%",
          "& ul": { padding: 0 },
        }}
        subheader={<li />}
      >
        {dummyData.map((sectionId, index) => (
          <li key={`section-${sectionId?.topic}`}>
            <ul>
              <ListItemButton
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "5px",
                }}
                selected={selectedTopicCategory === sectionId?.topic}
                onClick={() =>
                  dispatch(setEsgStrategyTaskCategory(sectionId?.topic))
                }
              >
                <ListItemIcon>
                  <Avatar sx={{ bgcolor: "#F6F6F6" }}>{index + 1}</Avatar>
                </ListItemIcon>
                <ListItemText primary={sectionId?.topic} />
              </ListItemButton>
            </ul>
          </li>
        ))}
      </List>
    </>
  );
}

export default EsgStrategyPlanningLeft;
