import { getEsgStrategyAssignedTaskDetails } from "actions/phase-3/task/esgStrategyTask/esgStrategyTaskAction";
import { useDispatch, useSelector } from "react-redux";

const useESgStrategyTaskRight = () => {
  const dispatch = useDispatch();
  const {
    esgStrategyFrequencyRange = {},
    esgStrategyTaskTopicList,
    selectedTopicCategory,
  } = useSelector((state) => state?.esgStrategyTask);

  const handleGetDetails = async (topic) => {
  
    let param;
    let url;
    if (selectedTopicCategory === "Activities") {
      param = {
        activity_id: topic?._id,
        frequency_id: topic?.frequency_range[0]?.frequency_id,
      };
      url = "get_assigned_activity_detaisls";
    } else {
      param = {
        kpi_id: topic?._id,
        frequency_id: topic?.frequency_range[0]?.frequency_id,
      };
      url = "get_assigned_kpi_detaisls";
    }

    const result = await dispatch(
      getEsgStrategyAssignedTaskDetails(param, url)
    );
    return result;
  };

  return {
    esgStrategyTaskTopicList,
    selectedTopicCategory,
    handleGetDetails,
  };
};

export default useESgStrategyTaskRight;
