import { useNavigate } from "react-router-dom";

import Heading from "components/UI/phase-3/heading/Heading";
import { Box, Button } from "@mui/material";
import PlusOneOutlined from "@mui/icons-material/Add";
import SurveyList from "components/widgets/phase-3/survey/surveyList/SurveyList";

function SurveyListing() {
  return (
    <Box>
      <Heading text="">
        <Button
          variant="contained"
          size="medium"
          startIcon={<PlusOneOutlined />}
        >
          Create Survey
        </Button>
      </Heading>

      <SurveyList />
    </Box>
  );
}

export default SurveyListing;
