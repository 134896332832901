import {
  Button,
  IconButton,
  Stack,
  TableCell,
  TableRow,
} from "@mui/material";
import DynamicTable, {
  textStyle,
} from "components/UI/phase-3/dynamicTable/DynamicTable";
import Heading from "components/UI/phase-3/heading/Heading";
import SearchTextField from "components/UI/phase-3/textfield/searchTextField";
import { IoMdMore } from "react-icons/io";
import { FaPlus } from "react-icons/fa";

function DocumentsMain() {
  return (
    <>
      {/* Heading */}
      <Heading text="">
        <Stack direction={"row"} alignItems={"center"} gap={2}>
          <SearchTextField sx={{ minWidth: "30em" }} />
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<FaPlus size={'12px'} />}
          >
            Fetch Data
          </Button>
        </Stack>
      </Heading>

      {/* main table */}

      <DynamicTable
        // hasCheckbox={true}
        headings={["Document Name", "Last Updated", "Action"]}
      >
        <TableRow
          sx={{ borderBottom: "1px solid #EBF1F8", cursor: "pointer" }}
          hover
        >
          <TableCell width={'70%'} style={textStyle("main")}>Esg Metrics</TableCell>
          <TableCell style={textStyle("")}>04 Aug 2022</TableCell>

          <TableCell style={textStyle("")}>
            <IconButton>
              <IoMdMore />
            </IconButton>
          </TableCell>
        </TableRow>
      </DynamicTable>
    </>
  );
}

export default DocumentsMain;
