import {
  getEsgStrategyPlanningAssessmentDetails,
  getEsgStrategyPlanningAssessmentList,
} from "actions/phase-3/esgstrategyPlanning/esgstrategyPlanningAction";
import { getMaterialityTopicYearBase } from "actions/phase-3/materialityAssessment/materialityAssessmentAction";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useEsgStrategyPlanningListAPIs = (
  keyword = "",
  limit = 5,
  page = 0
) => {
  const { entity_id } = useSelector((state) => state.user);
  const {
    esgStrategyPlanningAssessmentList,
    totalEsgStrategyPlanningAssessments,
  } = useSelector((state) => state?.esgStrategyPlanning);
  const totalPages = esgStrategyPlanningAssessmentList
    ? Math.ceil(totalEsgStrategyPlanningAssessments / limit)
    : 0;

  const dispatch = useDispatch();

  const fetchEsgStrategyPlanningList = async () => {
    const data = await dispatch(
      getEsgStrategyPlanningAssessmentList(
        entity_id,
        limit,
        (page - 1) * limit,
        keyword
      )
    );
  };

  const fetchEsgStrategyPlanningAssessmentDetails = async (
    esg_strategy_planning_assessment_id
  ) => {
    const data = await dispatch(
      getEsgStrategyPlanningAssessmentDetails(
        esg_strategy_planning_assessment_id
      )
    );
    data?.status && dispatch(getMaterialityTopicYearBase(entity_id, data?.result?.year_of_assessment))
    return data;
  };

  useEffect(() => {
    fetchEsgStrategyPlanningList(dispatch, keyword, limit, page);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, page]);

  return {
    // // states & data
    esgStrategyPlanningAssessmentList,
    totalEsgStrategyPlanningAssessments,
    totalPages,
    // // funcs
    // fetchMaterialityList,
    fetchEsgStrategyPlanningAssessmentDetails,
  };
};
