import React from "react";

import { Box, Button, Grid, Typography } from "@mui/material";
import Heading from "components/UI/phase-3/heading/Heading";
import { TopicBar } from "../left/component/TopicBar";
import {
  Environmental,
  Social,
  Governance,
  Business,
  Human,
} from "utils/MaterialityAssessmentUtils/materialityAssessmentUtils";
import { useMaterialityTopic } from "../../APIs/HandleTopicApi";
import OptimizeInitialRender from "components/widgets/phase-3/supplier/right/companySetup/OptimizeInitialRender";
const topicsMapping = [
  {
    component: Environmental,
    count: 2, // Replace with the appropriate variable
    label: "Environmental",
    gridProps: { item: true, xs: 6, md: 4 },
    id: "62317aa659ba6228342f20b9",
  },
  {
    component: Social,
    count: 0, // Replace with the appropriate variable
    label: "Social",
    gridProps: { item: true, xs: 6, sm: 3, md: 4 },
    id: "62317aa659ba6228342f20bb",
  },
  {
    component: Governance,
    count: 3, // Replace with the appropriate variable
    label: "Governance",
    gridProps: { item: true, xs: 6, sm: 3, md: 4 },
    id: "62317aa659ba6228342f20ca",
  },
  {
    component: Business,
    count: 5, // Replace with the appropriate variable
    label: "Business model",
    gridProps: { item: true, xs: 6, sm: 3, md: 4 },
    id: "62317aa659ba6228342f20c3",
  },
  {
    component: Human,
    count: 0, // Replace with the appropriate variable
    label: "Human capital",
    gridProps: { item: true, xs: 6, md: 4, lg: 4 },
    id: "62317aa659ba6228342f20be",
  },
];
const MaterialityMappingTopicRight = React.memo(() => {
  const {
    selectedMaterialityTopics,
    materialityMainTopics,
    handleRemoveTopic,
    handleRemoveAllTopics,
  } = useMaterialityTopic();
  const { parent_topic_count, topics = [] } = selectedMaterialityTopics || {};
  const labels = {
    0: "Environment",
    // 0: "Environmental",
    1: "Social Capital",
    2: "Leadership & Governance",
    3: "Business Model & Innovation",
    4: "Human Capital",
  };
  return (
    <Box sx={{ height: "100%" }}>
      <Heading text="Selected Topics" />

      <Grid container wrap="wrap" spacing={2}>
        {parent_topic_count &&
          topicsMapping.map((topic, index) => {
            const { component: Component, label, id, gridProps } = topic;
            const getCount = materialityMainTopics.find(
              (main) => main?.topic_name === labels[index]
            );

            return (
              <Grid {...gridProps}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Component className={{ fontSize: 46 }} />
                  <Box
                    sx={{
                      width: 2,
                      height: 22,
                      backgroundColor: "#9FA1AF",
                      borderRadius: 1,
                      margin: "0px 5px",
                    }}
                  ></Box>
                  <Typography
                    variant="body2"
                    sx={{
                      padding: "0px 8px",
                      color: "#9FA1AF",
                      fontWeight: "600",
                    }}
                  >
                    {parent_topic_count[getCount?._id] || 0}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{ overflowWrap: "anywhere", fontWeight: "400" }}
                  >
                    {label}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        <Grid item xs={6} md={4}>
          <Button
            variant="text"
            sx={(theme) => ({ color: theme.palette.error.main })}
            onClick={() => handleRemoveAllTopics()}
          >
            Remove All
          </Button>
        </Grid>
      </Grid>

      {topics?.length > 0 && (
        <Box
          sx={{
            overflow: "scroll",
            height: "70%",
          }}
        >
          <OptimizeInitialRender chunkSize={2}>
            {topics.map((topic, index) => (
              <Box key={index} sx={{ padding: "10px 0px" }}>
                <TopicBar
                  handleAddTopic={handleRemoveTopic}
                  topic={topic}
                  index={index}
                  source={"right"}
                  materialityMainTopics={materialityMainTopics}
                />
              </Box>
            ))}
          </OptimizeInitialRender>
        </Box>
      )}
      {topics?.length === 0 && (
        <Box
          sx={{
            width: "100%",
            height: "80%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="subtitle1">
            Select Topics From recommend to start
          </Typography>
        </Box>
      )}
    </Box>
  );
});

export default MaterialityMappingTopicRight;
