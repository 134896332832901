import { Box, ButtonBase, Grid, Typography } from "@mui/material";

// icons
import SourceAnswer from "images/phase-3/GPTWise/SourceAnswer.png";

// component imports
import ResourceHeader from "./CommonComps/ResourceHeader";

const truncateText = (text, maxLength) => {
  if (text?.length > maxLength) {
    return text.slice(0, maxLength) + "...";
  } else {
    return text;
  }
};

const SourcesBox = ({ sourceData }) => {
  const handleBoxClick = (url) => {
    // Check if the URL is absolute or relative
    const absoluteUrl = url.startsWith("http") ? url : "http://" + url;

    // Open the absolute URL in a new tab
    window.open(absoluteUrl, "_blank");
  };

  return (
    <Box display={"flex"} flexDirection={"column"} style={{ gap: 20 }}>
      {/* source header */}
      <ResourceHeader icon={SourceAnswer} title={"Source"} />

      {/* source cards box */}
      <Grid container spacing={3}>
        {sourceData?.map((source, index) => (
          <Grid key={index} item xs={12} sm={6} md={3} sx={{ marginRight: 5 }}>
            <ButtonBase
              focusRipple
              style={{
                height: 100,
                width: 300,
                padding: "0px 10px",
                borderRadius: 2,
              }}
              onClick={() => handleBoxClick(source?.URL)}
            >
              <Box
                style={{
                  height: "100%",
                  width: "100%",
                  textAlign: "left",
                  paddingTop: 5,
                }}
              >
                <Typography
                  style={{
                    color: "#0F0F0F",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "16px",
                  }}
                >
                  {truncateText(source?.Heading, 70)}
                </Typography>

                {/* url box */}
                <Box display={"flex"} style={{ gap: 10, marginTop: "10px" }}>
                  {/* url icon */}
                  <img
                    height="20"
                    width="20"
                    src={`https://www.google.com/s2/favicons?domain=${source?.URL}`}
                    alt={source?.URL}
                  />

                  <Typography
                    style={{
                      color: "#6C6C6C",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "16px",
                      wordBreak: "break-all",
                      overflowWrap: "break-word",
                    }}
                  >
                    {source?.URL}
                  </Typography>
                </Box>
              </Box>
            </ButtonBase>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
export default SourcesBox;
