import { Avatar } from "@mui/material";
import avatar from "images/phase-3/GPTWise/EsgWiseIconNew.svg";

const UserChatAnswerLayout = ({ isLoading, hideAvatar = false, children }) => {
  return (
    <div
      style={{
        display: "flex",
        padding: "5px 5px 10px 10px",
        marginLeft: hideAvatar && "80px",
      }}
    >
      {!hideAvatar && (
        <Avatar
          style={{
            backgroundColor: "white",
            width: "35px",
            color: "#3374B9",
            fontSize: "16px",
          }}
          src={avatar}
        />
      )}
      {children}
    </div>
  );
};

export default UserChatAnswerLayout;
