import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Card,
  CardContent,
  Icon,
  Stack,
  Typography,
} from "@mui/material";

import hirarchyIcon from "images/phase-3/orgstructure/hierachy.svg";
import hirarchyChildIcon from "images/phase-3/orgstructure/hierachy-child.svg";
import { GrLocation } from "react-icons/gr";

const RenderNode = ({ nodeDatum, onNodeClick }) => {
  const isParentNode = nodeDatum.children && nodeDatum.children.length > 0;

  const fields = [
    { label: "Units", value: nodeDatum.total_units },
    { label: "Assets", value: nodeDatum.total_assets },
    { label: "Suppliers", value: nodeDatum.total_suppliers },
  ].filter((field) => field.value !== undefined);

  return (
    <foreignObject width="295" height="360" x="-150" y="-180">
      <Card
        sx={{
          minWidth: 295,
          textAlign: "center",
          boxShadow: "0px 4px 8px -2px #15314E1A",
          border: "1px solid #cccccc80",
        }}
        onClick={onNodeClick}
      >
        <CardContent>
          <Box display={"flex"} gap={1} alignItems={"center"} mb={1}>
            <Stack
              minWidth={40}
              minHeight={40}
              alignItems={"center"}
              justifyContent={"center"}
              bgcolor={"primary.lighter"}
              borderRadius={0.5}
            >
              <img
                src={isParentNode ? hirarchyIcon : hirarchyChildIcon}
                alt="hirarchyIcon"
                style={{ width: 20, height: 20 }}
              />
            </Stack>

            <Typography variant="h4">
              {nodeDatum.entity_name || nodeDatum.name}
            </Typography>
          </Box>

          {nodeDatum?.location && (
            <Box display={"flex"} alignItems={"flex-start"} gap={1} mb={1}>
              <Icon sx={{ color: "primary.main" }}>
                <GrLocation size={18} />
              </Icon>
              <Typography
                variant="subtitle2"
                color={"text.faded"}
                fontWeight={600}
                textAlign={"left"}
              >
                {nodeDatum?.location?.street_name}
              </Typography>
            </Box>
          )}

          {/* Type */}
          <Stack gap={0.3} alignItems={"flex-start"} mb={1}>
            <Typography
              variant="subtitle2"
              color={"text.faded"}
              fontWeight={600}
            >
              Type
            </Typography>

            <Typography
              variant="body2"
              fontWeight={500}
              textTransform={"capitalize"}
            >
              {nodeDatum.type?.toLowerCase() || "Parent"}
            </Typography>
          </Stack>

          {/* Units/Assets/Supplier */}
          <Box display={"flex"} gap={2} mb={1}>
            {fields.map((field) => (
              <Stack gap={1} alignItems={"flex-start"}>
                <Typography
                  variant="subtitle2"
                  color={"text.faded"}
                  fontWeight={600}
                >
                  {field.label}
                </Typography>
                <Typography variant="body2" fontWeight={500}>
                  {field.value}
                </Typography>
              </Stack>
            ))}
          </Box>

          {/* Stakeholders */}
          <Box display={"flex"} gap={1} mb={1}>
            <Stack gap={1} alignItems={"flex-start"}>
              <Typography
                variant="subtitle2"
                color={"text.faded"}
                fontWeight={600}
              >
                Stakeholders
              </Typography>

              {/* Avatar group */}
              <AvatarGroup total={nodeDatum?.stakeholders?.length} max={4}>
                {nodeDatum?.stakeholders?.map((user) => (
                  <Avatar
                    key={user?._id}
                    alt={user?.profile_img?.name || "Avatar"}
                    src={user?.profile_img?.url}
                  />
                ))}
              </AvatarGroup>
            </Stack>
          </Box>

          <Stack alignItems={"flex-start"}>
            <Button variant={"text"} >
              View
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </foreignObject>
  );
};
export default RenderNode;
