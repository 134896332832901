import React from "react";

import DynamicDrawer from "components/UI/phase-3/Drawer/DynamicDrawer";
import { Box, Stack, Typography } from "@mui/material";
import Heading from "components/UI/phase-3/heading/Heading";
import InputField from "components/UI/phase-3/inputField/InputField";
import SelectAndOption from "components/UI/phase-3/selectAndOption/SelectAndOption";
import moment from "moment";
import useFormValidation from "customHooks/useFormValidation";
import { useDispatch } from "react-redux";
import { submitEsgStrategyAssignedTask } from "actions/phase-3/task/esgStrategyTask/esgStrategyTaskAction";
import Currency from "utils/currency/currency.json";
import DocumentUpload from "components/UI/phase-3/documentUpload/DocumentUpload";
const propertyObject = {
  Activities: [
    { key: "Activity Name", value: "title", type: false },
    { key: "Material Topic", value: "material_topic", type: false },
    { key: "Assigned By", value: "assigned_by", type: false },
    { key: "Start Date", value: "start_date", type: true },
    { key: "End Date", value: "due_date", type: true },
    { key: "Frequency", value: "frequency", type: false },
    { key: "Currency", value: "currency", type: false },
    { key: "Budget Amount", value: "budget", type: false },
  ],
  "Key Performance Indicators ( KPI )": [
    { key: "Name of the KPI", value: "title", type: false },
    { key: "Material Topic", value: "material_topic", type: false },
    { key: "Assigned By", value: "assigned_by", type: false },
    { key: "Baseline Value", value: "baseline", type: false },
    { key: "Target Value", value: "target", type: false },
    { key: "Unit", value: "unit", type: false },

    { key: "Start Date", value: "start_date", type: true },
    { key: "End Date", value: "due_date", type: true },
    { key: "Frequency", value: "frequency", type: false },
    { key: "Currency", value: "currency", type: false },
    { key: "Budget Amount", value: "budget", type: false },
  ],
};

const initialActivityState = {
  status: "In Progress",
  activity_update: "",
  comment: "",
  file: "",
};
const activityRule = {
  status: { required: true },
  activity_update: { required: true },
  comment: { required: true },
  file: { required: false },
};

const initialKpiState = {
  status: "In Progress",
  baseline: "",
  comment: "",
  file: "",
};
const kpiRules = {
  status: { required: true },
  baseline: { required: true },
  comment: { required: true },
  file: { required: false },
};

export const ViewTopicData = ({ property, data, hasDate }) => {
  const getCurrency = (code) => {
    const currency = Currency.find((c) => c?.code === code);
    return currency?.label;
  };
  return (
    <Stack
      sx={{ backgroundColor: "#F8F8F8", borderRadius: "8px" }}
      direction={"row"}
      alignItems={"center"}
      justifyContent={"flex-start"}
      //   gap={5}
      padding={2}
      mb={1}
    >
      <Typography sx={{ width: "20%" }} variant="body2">
        {property}
      </Typography>
      <Typography sx={{ width: "80%" }} variant="subtitle1">
        {hasDate
          ? moment(data).format("DD MMMM YYYY")
          : property === "Currency"
            ? getCurrency(data)
            : data}
      </Typography>
    </Stack>
  );
};

function ExploreTopic({ staticData = {}, open, handleClose, heading }) {
  const dispatch = useDispatch();

  const for_activity = heading === "Activities";

  const hasSubmissionData =
    Object.keys(staticData?.submission_data || {}).length > 0;

  const { formData, setFormData, errors, handleChange, validationErrors } =
    useFormValidation(
      for_activity
        ? hasSubmissionData
          ? staticData?.submission_data
          : initialActivityState
        : hasSubmissionData
          ? staticData?.submission_data
          : initialKpiState,
      for_activity ? activityRule : kpiRules,
      null,
      true
    );

  // add custom hook if we need to add more logic / functions/ states

  const finalSubmit = async () => {
    const handleId = for_activity
      ? { activity_id: staticData?.activity_id }
      : { kpi_id: staticData?.kpi_id };

    const response = await dispatch(
      submitEsgStrategyAssignedTask(
        {
          ...formData,
          ...handleId,
          frequency_id: staticData?.frequency_id,
        },
        for_activity ? "submit_activity_data" : "submit_kpi_data"
      )
    );
    response?.status && handleClose();
    response?.status &&
      setFormData(for_activity ? initialActivityState : initialKpiState);
  };

  return (
    <DynamicDrawer
      hasDrawerOpen={open}
      handleClose={() => {
        handleClose();
        setFormData(for_activity ? initialActivityState : initialKpiState);
      }}
      heading={heading}
      buttonText={"Save"}
      disableSubmit={Object.keys(validationErrors).length > 0}
      submitClick={finalSubmit}
    >
      <Box mt={3} width={"50vw"}>
        {propertyObject[heading].map(({ key, value, type }) => (
          <ViewTopicData
            property={key}
            data={staticData[value] || "-"}
            hasDate={type}
          />
        ))}
        <DocumentUpload viewOnly={true} value={staticData["file"] || ""} />

        <Heading text="Update Fields" />
        <SelectAndOption
          heading={"Update Progress"}
          options={["In Progress", "Completed"]}
          value={formData?.status}
          error={errors.status}
          name={"status"}
          handleChange={handleChange}
          width="30%"
        />
        <InputField
          value={for_activity ? formData?.activity_update : formData?.basline}
          error={for_activity ? errors.activity_update : errors?.basline}
          name={for_activity ? "activity_update" : "baseline"}
          handleChange={handleChange}
          heading={for_activity ? "Activity Update" : "baseline update"}
          label="Enter Details here"
          rows={3}
        />
        <InputField
          value={formData?.comment}
          error={errors.comment}
          name={"comment"}
          handleChange={handleChange}
          heading={`${heading} Comment`}
          label="Comment"
          rows={2}
        />
        <DocumentUpload
          value={formData?.file}
          error={errors.file}
          name={"file"}
          handleChange={handleChange}
        />
        {/* <DocumentUpload hasDocument={true} /> */}
      </Box>
    </DynamicDrawer>
  );
}

export default ExploreTopic;
