import { Box } from "@mui/material";
import EsgScoreLeft from "./EsgScoreLeft";
import EsgScoreRight from "./EsgScoreRight";
import { useSelector } from "react-redux";

function EsgScore() {
  const { esg_maturity_score } = useSelector((state) => state.esgMaturity);

  return (
    <Box style={{ display: "flex", gap: "30px" }}>
      <EsgScoreLeft esg_score={esg_maturity_score?.total_value} />
      <EsgScoreRight category={esg_maturity_score?.categories} />
    </Box>
  );
}

export default EsgScore;
