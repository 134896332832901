import {
  Box,
  Button,
  Typography,
  List,
} from "@mui/material";
import ShowStakeholder from "./Components/ShowStakeholder";

const ContributorsList = ({
  selectedStakeholders,
  handleAddSelection,
  handleRemoveSelection,
}) => {
  return (
    <Box width={"100%"} p={2}>
      {/* Contributors Add */}
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        mt={2}
      >
        <Typography variant="h4" color={"primary.darker"}>
          Auditor ({selectedStakeholders?.length})
        </Typography>

        <Button
          variant="outlined"
          color="secondary"
          onClick={handleAddSelection}
        >
          Add Selection to Auditor
        </Button>
      </Box>

      {/* Contributors Listing */}
      <Box mt={2}>
        <List>
          {selectedStakeholders.map((stakeholder) => (
            <ShowStakeholder
              key={stakeholder._id}
              stakeholder={stakeholder}
              isChecked={() =>
                selectedStakeholders.some((s) => s._id === stakeholder._id)
              }
              handleCheckboxChange={() => handleRemoveSelection(stakeholder)}
            />
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default ContributorsList;
