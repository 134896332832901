import { useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
  List,
} from "@mui/material";
import ShowStakeholder from "./Components/ShowStakeholder";

const ContributorsList = ({
  selectedStakeholders,
  handleAddSelection,
  handleRemoveSelection,
}) => {
  const [entityUnits, setEntityUnits] = useState(null);
  const [role, setRole] = useState(null);

  const entityUnitsOptions = ["Entity 1", "Unit 1"];
  const roleOptions = ["Role 1"];

  return (
    <Box width={"100%"} height={'50%'}  p={2}>
      {/* Top options */}
      <Box display="flex" gap={1}>
        <Autocomplete
          id="entity_units"
          options={entityUnitsOptions}
          value={entityUnits}
          onChange={(event, newValue) => setEntityUnits(newValue)}
          renderInput={(params) => (
            <TextField {...params} label="Select Entity/units" size="small" />
          )}
          fullWidth
        />
        <Autocomplete
          id="role"
          options={roleOptions}
          value={role}
          onChange={(event, newValue) => setRole(newValue)}
          renderInput={(params) => (
            <TextField {...params} label="Select by role" size="small" />
          )}
          fullWidth
        />
      </Box>

      {/* Contributors Add */}
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        mt={2}
      >
        <Typography variant="h4" color={"primary.darker"}>
          Contributors ({selectedStakeholders?.length})
        </Typography>

        <Button
          variant="outlined"
          color="secondary"
          onClick={() => handleAddSelection('contributor')}
        >
          Add Selection to Contributor
        </Button>
      </Box>

      {/* Contributors Listing */}
      <Box mt={2}>
        <List>
          {selectedStakeholders.map((stakeholder) => (
            <ShowStakeholder
              key={stakeholder._id}
              stakeholder={stakeholder}
              isChecked={() =>
                selectedStakeholders.some((s) => s._id === stakeholder._id)
              }
              handleCheckboxChange={() => handleRemoveSelection(stakeholder, 'contributor')}
            />
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default ContributorsList;
