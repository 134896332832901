import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getEsgStrategyPlanMonitoring } from "actions/phase-3/esgstrategyPlanning/esgstrategyPlanningAction";

export const useEsgStrategyPlanMonitoring = (forActivity = false) => {
    const [selectedIndex, setSelectedIndex] = useState(null)
  const { esgStrategyPlanningAssessmentDetail,planMonitoringList } = useSelector(
    (state) => state?.esgStrategyPlanning
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getEsgStrategyPlanMonitoring(
        esgStrategyPlanningAssessmentDetail?._id,
        esgStrategyPlanningAssessmentDetail?.materiality_assessment_id,
        0,
        5,
        forActivity
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {planMonitoringList, setSelectedIndex, selectedIndex};
};
