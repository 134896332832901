import {
  createMaterialityAssessment,
  setMaterialityAssessmentDetails,
  setOriginalMaterialityDetails,
  updateMaterialityAssessment,
} from "actions/phase-3/materialityAssessment/materialityAssessmentAction";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { getAllFrameWorkList } from "actions/phase-3/framework/frameWorkAction";

export const useMaterialityBasicDetail = () => {
  const { entity_id } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { materialityAssessmentDetails, originalMaterialityAssessmentDetails } =
    useSelector((state) => state?.materialityAssessment);

  // const { frameWorkList } = useSelector((state) => state?.frameWorV3);

  const { data: { result: frameWorkList = [] } = {} } = useQuery({
    queryKey: ["allFrameworks"],
    queryFn: getAllFrameWorkList,
    // these for know query that data is fresh and API not call until we manually fetch or invalidate it
    staleTime: Infinity,
    cacheTime: 0,
  });

  const getFrameWorkType = (id) =>
    frameWorkList.find((f) => f?._id === id)?.framework_type || "";

  function areAllFieldsNotEmpty(obj) {
    // Check if the object has no properties
    if (Object.keys(obj).length === 0) {
      return false;
    }

    // Check if all properties are not null, undefined, or empty string
    return Object.values(obj).every(
      (value) => value !== null && value !== undefined && value !== ""
    );
  }

  let disableButton = areAllFieldsNotEmpty(materialityAssessmentDetails);

  const hasChanges = (original, updated) => {
    return JSON.stringify(original) !== JSON.stringify(updated);
  };

  // Mutation for creating a materiality assessment
  const createMutation = useMutation({
    mutationFn: createMaterialityAssessment,
    onSuccess: () => {
      // Invalidate the query to refetch the assessment list after a successful create

      queryClient.invalidateQueries(["materialityAssessmentList"]);
    },
    onError: (error) => {
      // Handle error
      console.error("Create failed:", error);
    },
  });

  // Mutation for creating a materiality assessment
  const updateMutation = useMutation({
    mutationFn: updateMaterialityAssessment,
    onSuccess: () => {
      // Invalidate the query to refetch the assessment list after a successful create

      queryClient.invalidateQueries(["materialityAssessmentList"]);
    },
    onError: (error) => {
      // Handle error
      console.error("Create failed:", error);
    },
  });

  const handleBasicDetailsAPi = async () => {
    let response;

    if (materialityAssessmentDetails._id) {
     
      if (
        hasChanges(
          originalMaterialityAssessmentDetails,
          materialityAssessmentDetails
        )
      ) {
        dispatch(
          setMaterialityAssessmentDetails({
            ...materialityAssessmentDetails,
            entity_id,
            framework_type: getFrameWorkType(
              materialityAssessmentDetails?.framework_id
            ),
          })
        );
        dispatch(
          setOriginalMaterialityDetails({
            ...materialityAssessmentDetails,
            entity_id,
            framework_type: getFrameWorkType(
              materialityAssessmentDetails?.framework_id
            ),
          })
        );
        response = await updateMutation.mutateAsync({
          ...materialityAssessmentDetails,
          entity_id,
          framework_type: getFrameWorkType(
            materialityAssessmentDetails?.framework_id
          ),
        });
      } else {
        response = { status: true };
      }
    } else {
      // Call create API if _id is not present
      response = await createMutation.mutateAsync({
        ...materialityAssessmentDetails,
        entity_id,
        framework_type: getFrameWorkType(
          materialityAssessmentDetails?.framework_id
        ),
      });
    }

    return response;
  };

  // handleDisableCondition && handleBasicDetails(value);

  const handleMaterialityStep = (step) => {
    return step === 0 && handleBasicDetailsAPi();
  };

  return {
    handleMaterialityStep,
    disableButton,
    materialityAssessmentDetails,
    frameWorkList,
  };
};
