import { useState } from "react";

import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { getEsgComplianceTopicStructuredData } from "actions/phase-3/esgCompliance/esgComplianceAction";

export const useSelectActivity = (selectedTab, open) => {
  const { esgComlianceAssessmentDetail } = useSelector(
    (state) => state?.escCompliance
  );

  const { data: { result: topicStructuredData = [] } = {}, isLoading } =
    useQuery({
      queryKey: [
        "topicStructuredData",
        esgComlianceAssessmentDetail?.framework_id,
        "template_1",
      ],
      queryFn: () =>
        getEsgComplianceTopicStructuredData(
          esgComlianceAssessmentDetail?.framework_id,
          "template_1"
        ),
      enabled: open && !!esgComlianceAssessmentDetail?.framework_id,
      // these for know query that data is fresh and API not call until we manually fetch or invalidate it
      staleTime: Infinity,
      cacheTime: 0,
    });

  // const { esgStrategyPlanningAssessmentDetail, topicStructuredData } =
  //   useSelector((state) => state?.esgStrategyPlanning);

  const [selectedActivities, setSelectedActivities] = useState([]);

  const handleCheckboxChange = (activity) => {
    if (selectedActivities.includes(activity)) {
      setSelectedActivities((prev) => prev.filter((item) => item !== activity));
    } else {
      setSelectedActivities((prev) => [...prev, activity]);
    }
  };

  const selectAllActivities = () => {
    setSelectedActivities(topicStructuredData);
  };

  const resetActivites = () => {
    setSelectedActivities([]);
  };

  // useEffect(() => {
  //   open &&
  //     dispatch(
  //       getUnAssignedStrategyPlanTopic(
  //         esgStrategyPlanningAssessmentDetail?._id,
  //         esgStrategyPlanningAssessmentDetail?.materiality_assessment_id,
  //         selectedTab === 0 ? true : false
  //       )
  //     );
  // }, [selectedTab, open]);

  const disableComplete = selectedActivities?.length === 0;

  return {
    // states
    activities: topicStructuredData,
    selectedActivities,
    disableComplete,
    // functions
    handleCheckboxChange,
    selectAllActivities,
    resetActivites,
  };
};
