import { Box, Button, Typography, List } from "@mui/material";
import ShowStakeholder from "./Components/ShowStakeholder";

const ApproverList = ({
  selectedStakeholders,
  handleAddSelection,
  handleRemoveSelection,
}) => {
  return (
    <Box width={"100%"} height={"50%"} p={2}>
      {/* Contributors Add */}
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        mt={2}
      >
        <Typography variant="h4" color={"primary.darker"}>
          Approvers ({selectedStakeholders?.length})
        </Typography>

        <Button
          variant="outlined"
          color="secondary"
          onClick={() => handleAddSelection("approver")}
        >
          Add Selection to Approver
        </Button>
      </Box>

      {/* Contributors Listing */}
      <Box mt={2}>
        <List>
          {selectedStakeholders.map((stakeholder) => (
            <ShowStakeholder
              key={stakeholder._id}
              stakeholder={stakeholder}
              isChecked={() =>
                selectedStakeholders.some((s) => s._id === stakeholder._id)
              }
              handleCheckboxChange={() =>
                handleRemoveSelection(stakeholder, "approver")
              }
            />
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default ApproverList;
