import {
  SET_STRATEGY_TASK_LIST,
  SET_ESG_STRATEGY_SELECTED_TASK,
  SET_STRATEGY_FREQUENCY_RANGE,
  SET_STRATEGY_TASK_TOPIC_LIST,
  SET_CATEGORY_TOPIC
} from "constants/phase-3/task/esgStrategyPlanning/esgStrategyPlanningTaskConst";

const initial_state = {
  esgStrategyTaskList: [],
  esgStrategySelectedTask: null,
  esgStrategyFrequencyRange: {},
  esgStrategyTaskTopicList: [],
  selectedTopicCategory: "Activities",
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case SET_STRATEGY_TASK_LIST:
      return (state = {
        ...state,
        esgStrategyTaskList: action.payload,
      });

    case SET_ESG_STRATEGY_SELECTED_TASK:
      return (state = {
        ...state,
        esgStrategySelectedTask: action.payload,
      });

    case SET_STRATEGY_FREQUENCY_RANGE:
      return (state = {
        ...state,
        esgStrategyFrequencyRange: action.payload,
      });

    case SET_STRATEGY_TASK_TOPIC_LIST:
      return (state = {
        ...state,
        esgStrategyTaskTopicList: action.payload,
      });

    case SET_CATEGORY_TOPIC:
      return (state = {
        ...state,
        selectedTopicCategory: action.payload,
      });

    default:
      return state;
  }
}
