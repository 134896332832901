import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Chip,
  Select,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import DynamicDrawer from "components/UI/phase-3/Drawer/DynamicDrawer";
import ImageUplaod from "components/UI/phase-3/ImageUpload/ImageUplaod";

import { GrLocation } from "react-icons/gr";

// hooks
import useFormValidation from "customHooks/useFormValidation";
import { useDispatch, useSelector } from "react-redux";
import {
  createEntity,
  getParentEntity,
} from "actions/phase-3/orgDetails/orgDetailsAction";
import requiredField from "components/UI/phase-3/inputField/requiredShow";

const EntryDrawer = ({ open, onClose, fetchEntityList }) => {
  // redux
  const dispatch = useDispatch();
  const { parent_entity } = useSelector((state) => state.orgDetails);
  const { parent_id } = useSelector((state) => state.user);

  const [parentName, setParentName] = useState("");
  const [entryImage, setEntryImage] = useState(null);

  const initialState = {
    name: "",
    type: "",
    isin: "",
    street: "",
    city_state: "",
    coordinates: "",
  };

  const validationRules = {
    name: { maxCharacters: 30, required: true },
    type: { required: true },
    isin: { maxCharacters: 21, required: true },
    street: { required: true },
    city_state: { required: true },
    coordinates: { required: true },
  };

  const { formData, errors, handleChange, setFormData, hasErrors } =
    useFormValidation(initialState, validationRules);

  const handleChipClick = (type) => {
    setFormData((prev) => ({ ...prev, type }));
  };

  const disableSubmit =
    !formData.name ||
    !formData.type ||
    !formData.isin ||
    !formData.street ||
    !formData.city_state ||
    !formData.coordinates ||
    !entryImage ||
    hasErrors;

  const handleSubmit = async () => {
    // clear data
    if (!disableSubmit) {
      // dispatch action
      await dispatch(
        createEntity(
          parent_id,
          formData.name,
          formData.type,
          formData.isin,
          { street_name: formData.street, city_state: formData.city_state },
          formData.coordinates,
          { file: entryImage[0] },
          fetchEntityList
        )
      );

      setFormData(initialState);
      setParentName("");
      setEntryImage(null);
      onClose();
    }
  };

  useEffect(() => {
    if (open && !parent_entity) {
      dispatch(getParentEntity(parent_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <DynamicDrawer
      hasDrawerOpen={open}
      handleClose={onClose}
      heading={"Add Entity"}
      buttonText={"Add Entry"}
      submitClick={handleSubmit}
      disableSubmit={disableSubmit}
    >
      <Box display={"flex"} flexDirection={"column"} gap={2}>
        {/* Entity Name */}
        <Box>
          <Typography variant="body1" color="action.active" gutterBottom>
            Entity Name {requiredField(Boolean(errors.name) || !formData.name)}
          </Typography>
          <TextField
            name="name"
            placeholder="Enter Name"
            value={formData.name}
            onChange={handleChange}
            error={Boolean(errors.name)}
            helperText={errors.name}
            required
            fullWidth
          />
        </Box>

        {/* Entity Type */}
        <Box>
          <Typography variant="body1" color="action.active" gutterBottom>
            Entity Type {requiredField(Boolean(errors.type) || !formData.type)}
          </Typography>
          <Box display="flex" gap={1}>
            <Chip
              label="Subsidary"
              onClick={() => handleChipClick("Subsidary")}
              color={formData.type === "Subsidary" ? "primary" : "default"}
            />
            <Chip
              label="Joint Venture"
              onClick={() => handleChipClick("Joint Venture")}
              color={formData.type === "Joint Venture" ? "primary" : "default"}
            />
          </Box>
        </Box>

        {/* ISIN No. */}
        <Box>
          <Typography variant="body1" color="action.active" gutterBottom>
            ISIN No. {requiredField(Boolean(errors.isin) || !formData.isin)}
          </Typography>
          <TextField
            name="isin"
            value={formData.isin}
            onChange={handleChange}
            error={Boolean(errors.isin)}
            helperText={errors.isin}
            required
            fullWidth
          />
        </Box>

        {/* Location */}
        <Box>
          <Typography variant="body1" color="action.active" gutterBottom>
            Location{" "}
            {requiredField(
              Boolean(errors.street) ||
                !formData.street ||
                Boolean(errors.city_state) ||
                !formData.city_state
            )}
          </Typography>
          <TextField
            name="street"
            placeholder="Street name, building name"
            value={formData.street}
            onChange={handleChange}
            error={Boolean(errors.street)}
            helperText={errors.street}
            sx={{ mb: 2 }}
            required
            fullWidth
          />
          <TextField
            name="city_state"
            placeholder="City, state"
            value={formData.city_state}
            onChange={handleChange}
            error={Boolean(errors.city_state)}
            helperText={errors.city_state}
            required
            fullWidth
          />
        </Box>

        {/* GPS Coordinates */}
        <Box>
          <Typography variant="body1" color="action.active" gutterBottom>
            GPS Coordinates{" "}
            {requiredField(
              Boolean(errors.coordinates) || !formData.coordinates
            )}
          </Typography>
          <TextField
            name="coordinates"
            value={formData.coordinates}
            onChange={handleChange}
            error={Boolean(errors.coordinates)}
            helperText={errors.coordinates}
            sx={{ mb: 2 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ color: "primary.main" }}>
                  <GrLocation size={24} />
                </InputAdornment>
              ),
            }}
            required
            fullWidth
          />
        </Box>

        {/* Parent Name */}
        <Box>
          <Typography variant="body1" color="action.active" gutterBottom>
            Select Parent Name {requiredField(!parentName)}
          </Typography>
          <Select
            labelId="parent-name-label"
            name="parent_name"
            value={parentName}
            onChange={(e) => setParentName(e.target.value)}
            placeholder="Parent Name"
            fullWidth
            required
          >
            {[parent_entity?.entity_name]?.map((e) => (
              <MenuItem key={e} value={e}>
                {e}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Typography variant="body2" color={"text.faded"}>
          The entry in creation will be placed under this parent
        </Typography>

        {/* Image */}
        <ImageUplaod image={entryImage} setImage={setEntryImage} />
      </Box>
    </DynamicDrawer>
  );
};

export default EntryDrawer;
