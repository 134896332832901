import { useNavigate } from "react-router-dom";

import Heading from "components/UI/phase-3/heading/Heading";
import {
  Button,
  Stack,
  Box,
  Chip,
  IconButton,
  Pagination,
  TableCell,
  TableRow,
  Skeleton,
} from "@mui/material";
import PlusOneOutlined from "@mui/icons-material/Add";
import { NoDataComponent } from "components/widgets/phase-3/esgMaturity/shareSurvey/NoData";
import { usePaginationSearch } from "customHooks/usePaginationSearch";
import SearchTextField from "components/UI/phase-3/textfield/searchTextField";

import DynamicTable, {
  textStyle,
} from "components/UI/phase-3/dynamicTable/DynamicTable";
import { IoMdMore } from "react-icons/io";
import { useEsgComplinaceListAPIs } from "./APIs/EsgComplianceListApis";

function EsgComplianceListing() {
  const navigate = useNavigate();

  const {
    // states and data
    keyword,
    page,
    limit,
    // funcs
    handleSearch,
    handlePageChange,
  } = usePaginationSearch();

  const {
    esgComplianceAssessmentList,
    totalEsgComplianceAssessment,
    totalPages,
    isLoading,
    // funcs
    // fetchMaterialityList,/
    fetchEsgComplicnaAssessmentDetails,
    resetDetailState,
  } = useEsgComplinaceListAPIs(keyword, limit, page);
  return (
    <>
      {/* Heading */}
      <Heading text="">
        <Stack direction={"row"} gap={3}>
          <SearchTextField
            searchClick={handleSearch}
            sx={{ minWidth: "30em" }}
          />
          <Button
            variant="contained"
            size="medium"
            startIcon={<PlusOneOutlined />}
            onClick={() => {
              resetDetailState();
              navigate("/esg_compliance/New_Report");
            }}
          >
            New Report
          </Button>
        </Stack>
      </Heading>

      {/*  table body */}

      <DynamicTable
        headings={[
          "Name of the Assessment",
          "Year of Assessment",
          "Status",
          "Action",
        ]}
      >
        {esgComplianceAssessmentList.map((rowData, rowIndex) => (
          <TableRow
            key={rowIndex}
            //   className={classes.tableRowHover}
            onClick={async () => {
              const response = await fetchEsgComplicnaAssessmentDetails(
                rowData._id
              );

              // dispatch(setMaterialityAssessmentDetails(rowData));
              response &&
                navigate(
                  `/esg_compliance/${rowData.assessment_name.replace(/ /g, "_")}`
                );
            }}
            sx={{ borderBottom: "1px solid #EBF1F8", cursor: "pointer" }}
            hover
          >
            <TableCell style={textStyle("main")}>
              {rowData.assessment_name}
            </TableCell>

            <TableCell style={textStyle()}>
              {rowData.year_of_assessment}
            </TableCell>

            <TableCell style={textStyle()}>
              <Chip
                sx={(theme) => ({
                  backgroundColor: rowData.status
                    ? theme.palette.primary.lighter
                    : theme.palette.secondary.lighter,
                  padding: "6px 10px",
                  fontSize: "12px",
                  color: rowData.status
                    ? theme.palette.primary.dark
                    : theme.palette.secondary.dark,
                  borderRadius: "80px",
                })}
                label={rowData.status ? "Activate" : "In-Active"}
              />
            </TableCell>
            <TableCell style={textStyle()}>
              <IconButton>
                <IoMdMore />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </DynamicTable>

      {/* skeleton */}

      {isLoading && (
        <Stack gap={3} mt={5}>
          <Skeleton variant="rectangular" width={"100%"} height={30} />
          <Skeleton variant="rectangular" width={"100%"} height={30} />
          <Skeleton variant="rectangular" width={"100%"} height={30} />
        </Stack>
      )}

      {/* pagination */}
      {totalPages > 1 && (
        <Box mt={5} sx={{ position: "absolute", right: 10 }}>
          <Pagination
            count={Math.ceil(totalEsgComplianceAssessment / limit)}
            variant="outlined"
            page={page}
            onChange={handlePageChange}
          />
        </Box>
      )}
      {/* no data  */}

      {!isLoading && esgComplianceAssessmentList.length === 0 && (
        <NoDataComponent mainTitle={"No Framework Selected"} />
      )}
    </>
  );
}

export default EsgComplianceListing;
