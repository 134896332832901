import { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  InputAdornment,
  Chip,
  Autocomplete,
} from "@mui/material";
import DynamicDrawer from "components/UI/phase-3/Drawer/DynamicDrawer";
import ImageUplaod from "components/UI/phase-3/ImageUpload/ImageUplaod";

// hooks
import useFormValidation from "customHooks/useFormValidation";
import { GrLocation } from "react-icons/gr";
import requiredField from "components/UI/phase-3/inputField/requiredShow";
import { useDispatch, useSelector } from "react-redux";
import { usePaginationSearch } from "customHooks/usePaginationSearch";
import { useEntityListAPIs } from "components/screen/phase-3/OrgDetailsScreens/APIs/EntryListApis";
import { useUnitsAPIs } from "components/screen/phase-3/OrgDetailsScreens/APIs/UnitsApis";
import { createProductService } from "actions/phase-3/orgDetails/orgDetailsAction";

const ProductnServiceDrawer = ({ open, onClose, fetchProductService }) => {
  const dispatch = useDispatch();
  const [assetImage, setAssetImage] = useState(null);
  const [selectedTypeID, setSelectedTypeID] = useState("");

  const { parent_id } = useSelector((state) => state.user);

  const initialState = {
    name: "",
    product_service_id: "",
    product_or_service: "Product",
    type: "entity",
  };

  const validationRules = {
    name: { maxCharacters: 30, required: true },
    product_service_id: { maxCharacters: 21, required: true },
    product_or_service: { required: true },
    type: { required: true },
  };

  const { formData, errors, handleChange, setFormData, hasErrors } =
    useFormValidation(initialState, validationRules);

  // Handle search keyword and fetch entity list
  const { keyword, handleSearch } = usePaginationSearch(true);
  const { entity_list } = useEntityListAPIs(keyword, 50, 1, true, true);

  const { units_list } = useUnitsAPIs(keyword, 50, 1, true, true);

  const disableSubmit =
    !formData.name ||
    !formData.product_service_id ||
    !formData.product_or_service ||
    !assetImage ||
    hasErrors;

  const handleSubmit = async () => {
    if (!disableSubmit) {
      await dispatch(
        createProductService(
          parent_id,
          formData.name,
          formData.product_service_id,
          formData.product_or_service,
          formData.type,
          formData.type === "entity" ? selectedTypeID : undefined,
          formData.type === "unit" ? selectedTypeID : undefined,
          { file: assetImage[0] },
          fetchProductService
        )
      );

      // clear data
      setFormData(initialState);
      setAssetImage(null);
      setSelectedTypeID("");
      handleSearch("");
      onClose();
    }
  };

  const handleTypeClick = (type) => {
    setFormData((prev) => ({ ...prev, type }));
    setSelectedTypeID("");
  };

  const handleProductServiceClick = (product_or_service) => {
    setFormData((prev) => ({ ...prev, product_or_service }));
  };

  return (
    <DynamicDrawer
      hasDrawerOpen={open}
      handleClose={onClose}
      heading={"Add Product/Service"}
      buttonText={"Add Product/Service"}
      submitClick={handleSubmit}
      disableSubmit={disableSubmit}
    >
      <Box display={"flex"} flexDirection={"column"} gap={2}>
        {/* Product/service Name */}
        <Box>
          <Typography variant="body1" color="action.active" gutterBottom>
            Product/service Name{" "}
            {requiredField(Boolean(errors.name) || !formData.name)}
          </Typography>
          <TextField
            name="name"
            placeholder="Enter Name"
            value={formData.name}
            onChange={handleChange}
            error={Boolean(errors.name)}
            helperText={errors.name}
            required
            fullWidth
          />
        </Box>

        {/* Product/Service ID */}
        <Box>
          <Typography variant="body1" color="action.active" gutterBottom>
            Product/Service ID{" "}
            {requiredField(
              Boolean(errors.product_service_id) || !formData.product_service_id
            )}
          </Typography>
          <TextField
            name="product_service_id"
            value={formData.product_service_id}
            onChange={handleChange}
            error={Boolean(errors.product_service_id)}
            helperText={errors.product_service_id}
            required
            fullWidth
          />
        </Box>

        {/* Product or Serive Type */}
        <Box>
          <Typography variant="body1" color="action.active" gutterBottom>
            Select Product or Service{" "}
            {requiredField(!formData.product_or_service)}
          </Typography>
          <Box display="flex" gap={1}>
            <Chip
              label="Product"
              onClick={() => handleProductServiceClick("Product")}
              color={
                formData.product_or_service === "Product"
                  ? "primary"
                  : "default"
              }
            />
            <Chip
              label="Service"
              onClick={() => handleProductServiceClick("Service")}
              color={
                formData.product_or_service === "Service"
                  ? "primary"
                  : "default"
              }
            />
          </Box>
        </Box>

        {/* Entity or Units */}
        <Box>
          <Typography variant="body1" color="action.active" gutterBottom>
            Select Data Type {requiredField(!formData.type)}
          </Typography>
          <Box display="flex" gap={1}>
            <Chip
              label="entity"
              onClick={() => handleTypeClick("entity")}
              color={formData.type === "entity" ? "primary" : "default"}
            />
            <Chip
              label="unit"
              onClick={() => handleTypeClick("unit")}
              color={formData.type === "unit" ? "primary" : "default"}
            />
          </Box>
        </Box>

        {formData?.type === "entity" ? (
          <Box>
            {/* Entity ID */}
            <Typography variant="body1" color="action.active" gutterBottom>
              Select Entity ID {requiredField(!selectedTypeID)}
            </Typography>
            <Autocomplete
              options={entity_list?.entity_list || []} // Ensure options is an array
              getOptionLabel={(option) => option.entity_name}
              onInputChange={(event, value) => handleSearch(value)} // Update search keyword
              onChange={(event, newValue) =>
                setSelectedTypeID(newValue ? newValue?._id : "")
              } // Store selected entity's id
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search and select entity name"
                  fullWidth
                />
              )}
            />
          </Box>
        ) : (
          <Box>
            {/* Unit ID */}
            <Typography variant="body1" color="action.active" gutterBottom>
              Select Unit ID {requiredField(!selectedTypeID)}
            </Typography>
            <Autocomplete
              options={units_list?.unit_list || []} // Ensure options is an array
              getOptionLabel={(option) => option.unit_name}
              onInputChange={(event, value) => handleSearch(value)} // Update search keyword
              onChange={(event, newValue) =>
                setSelectedTypeID(newValue ? newValue?._id : "")
              } // Store selected entity's id
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search and select unit name"
                  fullWidth
                />
              )}
            />
          </Box>
        )}

        <Typography variant="body2" color={"text.faded"}>
          The unit in creation will be placed under this parent
        </Typography>

        {/* Image */}
        <ImageUplaod image={setAssetImage} setImage={setAssetImage} />
      </Box>
    </DynamicDrawer>
  );
};

export default ProductnServiceDrawer;
