import {
  SET_ESG_COMPLIANCE_ASSESSMENT_DETAILS,
  SET_ORIGINAL_ESG_COMPLIANCE_ASSESSMENT_DETAILS,
} from "constants/phase-3/esgCompliance/esgSComplianceConst";
import apiBolerplate from "utils/_helpers/apiBolerplate";

// ----------------- Payloads -----------------

export function setEsgComplianceAssessmentDetails(payload) {
  return {
    type: SET_ESG_COMPLIANCE_ASSESSMENT_DETAILS,
    payload: payload,
  };
}

export function setOriginalEsgComplianceDetails(payload) {
  return {
    type: SET_ORIGINAL_ESG_COMPLIANCE_ASSESSMENT_DETAILS,
    payload: payload,
  };
}

// ----------------- Actions -----------------

export async function getEsgComplianceAssessmentList(
  entity_id,
  limit,
  starting_after,
  keyword
) {
  const data = await apiBolerplate({
    url: "/esg_compliance/get_esg_compliances",
    bodyData: { entity_id, limit, starting_after, keyword },
    hideLoader: true,
    hideMsg: true,
  });

  return data; // Return the data for further use
}

export async function createEsgComplianceAssessment(basicDetail) {
  const data = await apiBolerplate({
    url: "/esg_compliance/create_esg_compliances",
    bodyData: { ...basicDetail },
    hideLoader: true,
    hideMsg: true,
  });

  return data; // Return the data for further use
}

export async function getEsgComplianceAssessmentDetails(esg_compliance_id) {
  const data = await apiBolerplate({
    url: "/esg_compliance/get_esg_compliance_details",
    bodyData: { esg_compliance_id },
    hideLoader: true,
    hideMsg: true,
  });

  return data; // Return the data for further use
}

export async function updateEsgComplianceAssessment(basicDetail) {
  const data = await apiBolerplate({
    url: "/esg_compliance/update_esg_compliance_details",
    bodyData: {
      ...basicDetail,
      esg_compliance_id: basicDetail?._id,
    },
    hideLoader: true,
    hideMsg: true,
  });

  return data; // Return the data for further use
}

export async function getEsgComplianceReportData(
  esg_compliance_id,
  framework_id,
  template_type,
  starting_after,
  limit
) {
  const data = await apiBolerplate({
    url: "/esg_compliance/get_report_data",
    bodyData: {
      esg_compliance_id,
      framework_id,
      template_type,
      starting_after,
      limit,
    },
    hideLoader: true,
    hideMsg: true,
  });

  return data; // Return the data for further use
}

export async function getEsgComplianceReportWidgetsCounts(
  esg_compliance_id,
  framework_id
) {
  const data = await apiBolerplate({
    url: "/esg_compliance/get_status_summery_for_report_data",
    bodyData: {
      esg_compliance_id,
      framework_id,
    },
    hideLoader: true,
    hideMsg: true,
  });

  return data; // Return the data for further use
}

export async function getEsgComplianceTopicStructuredData(
  framework_id,
  template_type
) {
  const data = await apiBolerplate({
    url: "/esg_compliance/get_structured_data",
    bodyData: {
      framework_id,
      template_type,
    },
    hideLoader: true,
    hideMsg: true,
  });

  return data; // Return the data for further use
}

export async function assignComplianceReportData(assignDetails) {
  const data = await apiBolerplate({
    url: "/assign_stakeholder/assign_in_esg_compliance",
    bodyData: {
      ...assignDetails,
    },
    hideLoader: true,
    hideMsg: true,
  });

  return data; // Return the data for further use
}

export async function submitEsgComplianceMetricRecord(submission_data) {
  const data = await apiBolerplate({
    url: "/esg_compliance/submit_record",
    bodyData: {
      ...submission_data,
    },
    hideLoader: true,
    hideMsg: true,
  });

  return data; // Return the data for further use
}

export async function esgComplianceMetricGetProgress(
  esg_compliance_id,
  metric_id
) {
  const data = await apiBolerplate({
    url: "/esg_compliance/get_progress",
    bodyData: {
      esg_compliance_id,
      metric_id,
    },
    hideLoader: true,
    hideMsg: true,
  });

  return data; // Return the data for further use
}
