import makeStyles from "@mui/styles/makeStyles";

const drawerWidth = 260;

const DrawerUseStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    transition: "none",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: "none",
    // transition: theme.transitions.create("width", {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),
  },
  drawerClose: {
    // transition: theme.transitions.create("width", {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
    transition: "none",
    overflowX: "hidden",
    marginLeft: 2,
    width: theme.spacing(8),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  listitems: {
    width: "90%",
    marginBottom: "8px",
    textWrap: "wrap",
  },
  list: {
    width: "90%",
    margin: "5%",
  },
  list2: {
    width: "90%",
    marginLeft: "5%",
    marginRight: "5%",
    marginTop: "auto",
    marginBottom: "auto",
    height: "70%",
    overflowY: "auto",
    overflowX: "hidden",
    scrollbarWidth:"2px"
  },
  head: {
    padding: 0,
    marginRight: "auto",
    marginLeft: "5px",
    marginBottom: "5px",
  },
  iconButton: { color: "#0873d0" },
  link: { textDecoration: "none" },
  disabledLink: { pointerEvents: "none" },
  img: { marginLeft: 12 },
}));

export default DrawerUseStyles;
