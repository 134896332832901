import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Heading from "components/UI/phase-3/heading/Heading";
import { useEsgStrategyPlanningTopic } from "../../APIs/HandleTopicApi";
import {
  Business,
  Environmental,
  Governance,
  Human,
  Social,
} from "utils/MaterialityAssessmentUtils/materialityAssessmentUtils";

export function getIconByParentName(parent_name) {
  switch (parent_name) {
    case "Social Capital":
      return <Social width={"50px"} />;
    case "Environmental":
      return <Environmental width={"50px"} />;
    case "Environment":
      return <Environmental width={"50px"} />;
    case "Leadership & Governance":
      return <Governance width={"50px"} />;
    case "Human Capital":
      return <Human width={"50px"} />;
    case "Business Model & Innovation":
      return <Business width={"50px"} />;
    default:
      return null; // Or you can return a default icon if needed
  }
}

function DefineAgainstTopicsLeft({ from = "strategy" }) {
  const {
    topics,
    handleSelectEsgTopic,
    esgSelectedTopic,
    materialityMainTopics,
  } = useEsgStrategyPlanningTopic(from);


  return (
    <Box sx={{ height: "100%" }}>
      <Heading
        text={"Materiality Topics"}
        size={"15px"}
        marginBottom={"8px"}
        bg
      />
      <Box sx={{ overflow: "scroll", height: "90%" }} p={0}>
        <List dense={false} component="nav" aria-label="main mailbox folders">
          {topics?.map((topic) => {
            let typeOfTopic = materialityMainTopics?.find(
              (main) => main._id + "" === topic.parent_id + ""
            );
           

            return (
              <ListItemButton
                selected={topic?.topic_id === esgSelectedTopic?.topic_id}
                key={topic?.topic_id}
                onClick={() => handleSelectEsgTopic(topic)}
              >
                <ListItemIcon>
                  {getIconByParentName(typeOfTopic?.topic_name)}
                </ListItemIcon>
                <ListItemText primary={topic?.topic_name} />
              </ListItemButton>
            );
          })}
        </List>
      </Box>
    </Box>
  );
}

export default DefineAgainstTopicsLeft;
