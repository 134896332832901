import { Box } from "@mui/material";
import GetStarted from "components/screen/phase-3/getStarted/GetStarted";
import Heading from "components/UI/phase-3/heading/Heading";
import { useEffect } from "react";
import { useSelector } from "react-redux";
// socket
import { connectSocket, socket } from "socket";
function GetStartedCont() {
  const { user_id } = useSelector((state) => state.user);

  // --------------- Socket Connect ---------------
  // useEffect to make connections with sockets
  useEffect(() => {
    if (user_id) connectSocket(user_id);

    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [user_id]);
  // -----------------------------------------------

  return (
    <Box width={"100%"} p={2}>
      {/* Heading */}
      <Heading text={"Onboarding"} size={"16px"} marginBottom={"30px"} />

      {/* Main Content */}
      <GetStarted />
    </Box>
  );
}

export default GetStartedCont;
