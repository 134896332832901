import { Box } from "@mui/material";
import OrganizationList from "./AssignComps/OrganizationList";
import ContributorsList from "./AssignComps/ContributorsList";

const AssignStakeholders = ({
  checkedStakeholders,
  handleCheckboxChange,
  selectedStakeholders,
  handleAddSelection,
  handleRemoveSelection,
}) => {
  return (
    <Box display="flex" flexDirection={'column'} height={'100%'}>
      {/* Organisation List */}
      <OrganizationList
        checkedStakeholders={checkedStakeholders}
        handleCheckboxChange={handleCheckboxChange}
      />

      {/* Contributors */}
      <ContributorsList
        selectedStakeholders={selectedStakeholders}
        handleAddSelection={handleAddSelection}
        handleRemoveSelection={handleRemoveSelection}
      />
    </Box>
  );
};

export default AssignStakeholders;
