import { SET_COMPLIANCE_SELECTED_TASK } from "constants/phase-3/task/esgCompliance/esgComplianceTaskConst";

const initial_state = {
  esgComplianceSelectedTask: null,
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case SET_COMPLIANCE_SELECTED_TASK:
      return (state = {
        ...state,
        esgComplianceSelectedTask: action.payload,
      });

    default:
      return state;
  }
}
