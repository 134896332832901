import { useNavigate } from "react-router-dom";

import Heading from "components/UI/phase-3/heading/Heading";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Pagination,
  Stack,
  TableCell,
  TableRow,
} from "@mui/material";
import DynamicTable, {
  textStyle,
} from "components/UI/phase-3/dynamicTable/DynamicTable";
import PlusOneOutlined from "@mui/icons-material/Add";
import { NoDataComponent } from "components/widgets/phase-3/esgMaturity/shareSurvey/NoData";
import { usePaginationSearch } from "customHooks/usePaginationSearch";
import SearchTextField from "components/UI/textfield/searchTextField";
import { useMaterialityListAPIs } from "./APIs/MaterialityMappingListApis";
import { IoMdMore } from "react-icons/io";

function MaterialityMappingListing() {
  const navigate = useNavigate();

  const {
    // states and data
    keyword,
    page,
    limit,
    // funcs
    handleSearch,
    handlePageChange,
  } = usePaginationSearch();

  const {
    // states & data
    materialityAssessmentList,
    totalPages,
    totalMaterialityAssessments,

    //function
    fetchMaterialityAssessmentDetails,
    resetDetailState,
  } = useMaterialityListAPIs(keyword, limit, page);

  return (
    <>
      {/* Heading */}
      <Heading text="">
        <Stack direction={"row"} gap={3}>
          <SearchTextField
            searchClick={handleSearch}
            sx={{ minWidth: "30em" }}
          />
          <Button
            variant="contained"
            size="medium"
            startIcon={<PlusOneOutlined />}
            onClick={() => {
              resetDetailState();
              navigate("/materiality_mapping/New_Assessment");
            }}
          >
            Materiality Mapping
          </Button>
        </Stack>
      </Heading>

      {/*  table body */}
      {materialityAssessmentList.length > 0 && (
        <DynamicTable
          headings={[
            "Name of the Assessment",
            "Year of Assessment",
            "Status",
            "Action",
          ]}
        >
          {materialityAssessmentList.map((rowData, rowIndex) => (
            <TableRow
              key={rowIndex}
              //   className={classes.tableRowHover}
              onClick={async () => {
                const response = await fetchMaterialityAssessmentDetails(
                  rowData._id
                );

                // dispatch(setMaterialityAssessmentDetails(rowData));
                response &&
                  navigate(
                    `/materiality_mapping/${rowData.assessment_name.replace(/ /g, "_")}`
                  );
              }}
              sx={{ borderBottom: "1px solid #EBF1F8", cursor: "pointer" }}
              hover
            >
              <TableCell style={textStyle("main")}>
                {rowData.assessment_name}
              </TableCell>

              <TableCell style={textStyle()}>
                {rowData.year_of_assessment}
              </TableCell>

              <TableCell style={textStyle()}>
                <Chip
                  sx={(theme) => ({
                    backgroundColor: rowData.status
                      ? theme.palette.primary.lighter
                      : theme.palette.secondary.lighter,
                    padding: "6px 10px",
                    fontSize: "12px",
                    color: rowData.status
                      ? theme.palette.primary.dark
                      : theme.palette.secondary.dark,
                    borderRadius: "80px",
                  })}
                  label={rowData.status ? "Activate" : "In-Active"}
                />
              </TableCell>
              <TableCell style={textStyle()}>
                <IconButton>
                  <IoMdMore />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </DynamicTable>
      )}

      {/* pagination */}
      {totalPages > 1 && (
        <Box mt={5} sx={{ position: "absolute", right: 10 }}>
          <Pagination
            count={Math.ceil(totalMaterialityAssessments / limit)}
            variant="outlined"
            page={page}
            onChange={handlePageChange}
          />
        </Box>
      )}

      {/* no data  */}

      {materialityAssessmentList.length === 0 && (
        <NoDataComponent mainTitle={"No Materiality Assessment Found"} />
      )}
    </>
  );
}

export default MaterialityMappingListing;
