import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getEsgComplianceAssessmentList,
  getEsgComplianceAssessmentDetails,
  setEsgComplianceAssessmentDetails,
  setOriginalEsgComplianceDetails,
} from "actions/phase-3/esgCompliance/esgComplianceAction";
import { useDispatch, useSelector } from "react-redux";

export const useEsgComplinaceListAPIs = (keyword = "", limit = 5, page = 0) => {
  const { entity_id } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  // React Query for fetching the materiality assessment list
  const {
    data: {
      result: esgComplianceAssessmentList = [],
      total: totalEsgComplianceAssessment = 0,
    } = {}, // Default to empty object and values
    isLoading,
  } = useQuery({
    queryKey: ["esgComplianceAssessmentList", entity_id, limit, page, keyword],
    queryFn: () =>
      getEsgComplianceAssessmentList(
        entity_id,
        limit,
        (page - 1) * limit,
        keyword
      ),

    enabled: !!entity_id,
    // these for know query that data is fresh and API not call until we manually fetch or invalidate it
    staleTime: Infinity,
    cacheTime: 0,
  });

  const resetDetailState = () => {
    dispatch(setEsgComplianceAssessmentDetails({}));
    dispatch(setOriginalEsgComplianceDetails({}));
  };

  const totalPages = esgComplianceAssessmentList
    ? Math.ceil(totalEsgComplianceAssessment / limit)
    : 0;

  // Mutation for creating a materiality assessment
  const { mutateAsync } = useMutation({
    mutationFn: getEsgComplianceAssessmentDetails,
    onSuccess: (data) => {
      dispatch(setEsgComplianceAssessmentDetails(data?.result));
      dispatch(setOriginalEsgComplianceDetails(data?.result));
    },
    onError: (error) => {
      // Handle error
      console.error("Create failed:", error);
    },
    staleTime: Infinity,
    cacheTime: 0,
  });

  const fetchEsgComplicnaAssessmentDetails = async (
    esgCompliance_assessment_id
  ) => {
    let response = await mutateAsync(esgCompliance_assessment_id);

    return response;
  };

  return {
    // states & data
    esgComplianceAssessmentList,
    totalEsgComplianceAssessment,
    totalPages,
    isLoading,
    // funcs
    // fetchMaterialityList,/
    resetDetailState,
    fetchEsgComplicnaAssessmentDetails,
  };
};
