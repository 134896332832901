import { Box, Stack, Button, Menu, MenuItem } from "@mui/material";
import CardWidgets from "../reportData/reportDataComponent/CardWidgets";
import Heading from "components/UI/phase-3/heading/Heading";
import SearchTextField from "components/UI/phase-3/textfield/searchTextField";
import { AiOutlineDownload } from "react-icons/ai";
import { IoEllipsisVerticalSharp } from "react-icons/io5";
import { FiUserPlus } from "react-icons/fi";
import { TbReportAnalytics } from "react-icons/tb";
import TopicTable from "../reportData/reportDataComponent/TopicTable";
import { useEsgComplianceApproveAudit } from "../APIs/HandleApproveApi";
import ExploreDrawerMain from "../reportData/exploreDrawer/ExploreDrawerMain";
import AssignDrawer from "../reportData/assignDrawer/AssignDrawer";
import OptionDrawer from "../reportData/reportDataComponent/OptionDrawer";
import React from "react";
import AssignAuditDrawer from "./assignAuditDrawer/AssignAuditDrawer";

function ApproveAuditMain() {
  const {
    // states
    reportDataList,
    isLoading,
    optionDrawer,
    assignDrawer,
    exploreDrawer,

    selectedOption,
    selectedRowData,
    items,
    assignAuditDrawer,

    // functions
    handleDragStart,
    handleDragEnter,
    handleDrop,
    handleHideStatus,
    handleSelectedOption,
    optionToggleDrawer,
    assignToggleDrawer,
    exploreToggleDrawer,
    setSelectedRowData,
    assignAuditToggleDrawer,
  } = useEsgComplianceApproveAudit();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ height: "100%" }}>
      {/* card widgets */}

      <CardWidgets />

      {/* heading section */}

      <Heading
        marginTop={15}
        text="Business Responsibality And Sustainability Reporting ( BRSR )"
      >
        <Stack direction={"row"} alignItems={"center"} gap={3}>
          <SearchTextField
            // searchClick={handleSearch}
            sx={{ minWidth: "20em" }}
          />
          <Button
            variant="text"
            size="small"
            startIcon={<AiOutlineDownload />}
            onClick={() => {
              //   resetDetailState();
              //   navigate("/esg_compliance/New_Report");
            }}
          >
            Download Summary
          </Button>
          <Button
            variant="text"
            size="small"
            startIcon={<IoEllipsisVerticalSharp />}
            onClick={optionToggleDrawer}
          >
            Options
          </Button>
          <Button
            disabled
            variant="text"
            size="small"
            startIcon={<TbReportAnalytics />}
            // onClick={optionToggleDrawer}
          >
            View Audit Report
          </Button>
          <Button
            variant="contained"
            size="small"
            startIcon={<FiUserPlus />}
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            Assign Disclosures
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                assignToggleDrawer();
              }}
            >
              Assign for Approval
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                assignAuditToggleDrawer();
              }}
            >
              Assign for Audit
            </MenuItem>
          </Menu>
        </Stack>
      </Heading>

      {/*  detail Table */}

      <TopicTable
        dynamicHeading={items.filter((i) => !i.hide).map((i) => i.text)}
        isLoading={isLoading}
        reportDataList={reportDataList}
      >
        {items.filter((i) => !i.hide).map((i) => i.tableCell)}
      </TopicTable>

      {/* option Drawer */}

      <OptionDrawer
        openDrawer={optionDrawer}
        toggleDrawer={optionToggleDrawer}
        selectedOption={selectedOption}
        handleSelectedOption={handleSelectedOption}
        tableHeadings={items}
        handleDragEnter={handleDragEnter}
        handleDragStart={handleDragStart}
        handleDrop={handleDrop}
        handleHideStatus={handleHideStatus}
      />

      {/* explore drawer */}

      <ExploreDrawerMain
        openDrawer={exploreDrawer}
        toggleDrawer={() => {
          exploreToggleDrawer();
          setSelectedRowData("");
        }}
        selectedRowData={selectedRowData}
      />

      {/* assign disclosure Drawer */}

      <AssignDrawer open={assignDrawer} onClose={assignToggleDrawer} />

      {/* assign Audit Drawer */}

      <AssignAuditDrawer
        open={assignAuditDrawer}
        onClose={assignAuditToggleDrawer}
      />
    </Box>
  );
}

export default ApproveAuditMain;
