import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  blueText: {
    fontWeight: "600",
    color: "#3374B9",
    padding: "10px 0px",
  },
  grid: { paddingTop: 20 },
  CustomTextfield: { width: "100%" },
  Box1: {
    borderBottom: "2px solid gray",
    cursor: "pointer",
  },
  Box2: { borderBottom: "2px solid #3374B9" },
  pointer: { cursor: "pointer" },
});

const typeThreeData = [
  {
    row: "Row 1",
    columns: [
      { column: "Column 1", data: "Data 1-1" },
      { column: "Column 2", data: "Data 1-2" },
      { column: "Column 3", data: "Data 1-3" },
    ],
  },
  {
    row: "Row 2",
    columns: [
      { column: "Column 1", data: "Data 2-1" },
      { column: "Column 2", data: "Data 2-2" },
      { column: "Column 3", data: "Data 2-3" },
    ],
  },
  {
    row: "Row 3",
    columns: [
      { column: "Column 1", data: "Data 3-1" },
      { column: "Column 2", data: "Data 3-2" },
      { column: "Column 3", data: "Data 3-3" },
    ],
  },
];

function DisclosureTypeThree({
  typeThreeData,
  columns = [],
  rows = [],
  setFormData,
  handleCheckedDataChanged
}) {
  const classes = useStyles();
  return (
    <>
      {/* {typeThreeData.length > 0 && ( */}
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{ minWidth: 150 }}></TableCell>
              {columns &&
                columns.map((column, index) => (
                  <TableCell
                    key={index}
                    align="center"
                    style={{ minWidth: 150 }}
                  >
                    {column}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, rowIndex) => {
              return (
                <TableRow key={rowIndex} hover role="checkbox">
                  <TableCell align="center">
                    <Typography>{row}</Typography>
                  </TableCell>
                  {columns &&
                    columns.map((col, colIndex) => {
                      return (
                        <TableCell key={colIndex} align="center">
                          <TextField
                            name={`Text_type3${colIndex}`}
                            size="small"
                            variant="outlined"
                            className={classes.CustomTextfield}
                            value={
                              typeThreeData?.[
                                `row-${rowIndex}_column-${colIndex}`
                              ] || ""
                            }
                            onChange={(e) => {
                              setFormData((prev) => {
                                // Create a new data object to store row and column index as keys
                                const updatedData = {
                                  ...prev.data, // Spread the previous data to keep other values
                                  [`row-${rowIndex}_column-${colIndex}`]:
                                    e.target.value, // Add or update the specific row and column key
                                };



                                // Return the updated state with the new data object
                                return {
                                  ...prev, // Spread the previous state to keep other values (if any)
                                  data: updatedData, // Update the data property with the new values
                                };
                              });

                              handleCheckedDataChanged() // call for data change

                            }}
                            // disabled
                          />
                        </TableCell>
                      );
                    })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* )} */}
    </>
  );
}

export default DisclosureTypeThree;
