import { useState } from "react";
import {
  Box,
  Stack,
  Typography,
  TextField,
  Select,
  MenuItem,
  IconButton,
  Avatar,
  useTheme,
} from "@mui/material";

// icons
import { RiBookLine } from "react-icons/ri";
import { LuPen } from "react-icons/lu";

const ProfileBasicDetails = ({ profileData }) => {
  const theme = useTheme();

  const [avatarUrl, setAvatarUrl] = useState(
    profileData?.company_image?.url || ""
  );
  const [avatarFile, setAvatarFile] = useState(null);

  const handleAvatarChange = (event) => {
    const file = event.target.files[0];
    if (
      file &&
      (file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg")
    ) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setAvatarUrl(e.target.result);
      };
      reader.readAsDataURL(file);
      setAvatarFile(file);
    } else {
      alert("Please select a valid image file (JPEG, PNG, JPG).");
    }
  };

  return (
    <Box my={2}>
      {/* Heading */}
      <Stack
        spacing={1}
        direction={"row"}
        alignItems={"center"}
        borderBottom={"1px solid"}
        borderColor={"primary.main"}
        p={1}
      >
        <RiBookLine size={20} color={theme.palette.primary.main} />
        <Typography variant="body2">Basic Details</Typography>
      </Stack>

      {/* Company Avatar */}
      <Box position="relative" display="inline-block" my={3}>
        <Avatar
          src={avatarUrl}
          alt={profileData?.company_image?.name}
          sx={{ width: 100, height: 100 }}
        />
        <IconButton
          size="small"
          sx={{
            position: "absolute",
            bottom: 0,
            right: 0,
            backgroundColor: theme.palette.background.paper,
            boxShadow: 1,
          }}
          component="label"
        >
          <input
            type="file"
            accept="image/jpeg, image/png, image/jpg"
            hidden
            onChange={handleAvatarChange}
          />
          <LuPen size={20} color={theme.palette.primary.main} />
        </IconButton>
      </Box>

      <Typography variant="h1" fontWeight={500} gutterBottom>
        {profileData?.entity_name}
      </Typography>

      {/* Industry and Sector */}
      <Box display={"flex"} gap={2} my={2}>
        {/* Industry */}
        <Box flex={1}>
          <Typography variant="subtitle2" color={"text.faded"} gutterBottom>
            Industry
          </Typography>
          <Select
            name="industry"
            value={profileData?.industry?.name}
            fullWidth
            variant="outlined"
            size="small"
            readOnly
          >
            <MenuItem value={profileData?.industry?.name}>
              {profileData?.industry?.name}
            </MenuItem>
          </Select>
        </Box>

        {/* Sector */}
        <Box flex={1}>
          <Typography variant="subtitle2" color={"text.faded"} gutterBottom>
            Sector
          </Typography>
          <Select
            name="sector"
            value={profileData?.sector?.name}
            fullWidth
            variant="outlined"
            size="small"
            readOnly
          >
            <MenuItem value={profileData?.sector?.name}>
              {profileData?.sector?.name}
            </MenuItem>
          </Select>
        </Box>
      </Box>

      {/* Company ID */}
      <Box my={2}>
        <Typography variant="subtitle2" color={"text.faded"} gutterBottom>
          Company ID
        </Typography>
        <TextField
          name="company_id"
          value={profileData?.isin}
          variant="outlined"
          size="small"
          fullWidth
          InputProps={{ readOnly: true }}
        />
      </Box>
    </Box>
  );
};

export default ProfileBasicDetails;
