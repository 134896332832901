import React from "react";
import { useDispatch, useSelector } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CardActions from "@mui/material/CardActions";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import Container from "@mui/material/Container";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";
import { Button, TextField } from "@mui/material";
import Chip from "@mui/material/Chip";
import ChartRenderer from "./ChartRenderedDashboard";
import DashboardItem from "../../UI/dataHubCalculatedCharts/component/DashboardItem";
import { addDashboardNewCharts } from "../../../actions/datahubActions/datahubActions";
import areaIcon from "../../../images/dashboardIcons/areaIcon.png";
import lineChartIcon from "../../../images/dashboardIcons/lineChartIcon.png";
import barChartIcon from "../../../images/dashboardIcons/barChartIcon.png";
import pieChartIcon from "../../../images/dashboardIcons/pieChartIcon.png";
import tableIcon from "../../../images/dashboardIcons/tableIcon.png";
import numberIcon from "../../../images/dashboardIcons/numberIcon.png";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  SearchIcon: { color: "#3374B9" },
});

const useStylesAccordian = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

function SimpleAccordion({ handleChartIsSelected }) {
  const { inactiveCharts } = useSelector((state) => state?.dataHub);

  const classes = useStylesAccordian();

  const preConfiguredCharts = inactiveCharts.filter(
    (c) => c.isCustom === false
  );
  const customCharts = inactiveCharts.filter((c) => c.isCustom === true);

  const ChartTypes = {
    line: lineChartIcon,
    area: areaIcon,
    bar: barChartIcon,
    pie: pieChartIcon,
    table: tableIcon,
    number: numberIcon,
  };

  return (
    <div className={classes.root}>
      <Accordion
        elevation={0}
        style={{ padding: "10px 10px 0px 10px", margin: "0px" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            pre configured Widget
          </Typography>
        </AccordionSummary>

        <AccordionDetails style={{ backgroundColor: "rgba(246, 246, 246, 1)" }}>
          <List style={{ width: "100%" }}>
            {preConfiguredCharts.map((chart, index) => (
              <ListItem
                button
                key={chart._id}
                onClick={() => handleChartIsSelected(true, chart)}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <img
                  alt=""
                  src={ChartTypes[chart?.vizState?.chartType]}
                  style={{ width: "3%", height: "3%", marginRight: "10px" }}
                />
                <ListItemText primary={chart.name} />
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>

      <Accordion
        elevation={0}
        style={{ padding: "10px 10px 0px 10px", margin: "0px" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            Custom configured Widget
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ backgroundColor: "rgba(246, 246, 246, 1)" }}>
          <List style={{ width: "100%" }}>
            {customCharts.map((chart, index) => (
              <ListItem
                button
                key={chart._id}
                onClick={() => handleChartIsSelected(true, chart)}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <img
                  alt=""
                  src={ChartTypes[chart?.vizState?.chartType]}
                  style={{ width: "3%", height: "3%", marginRight: "10px" }}
                />
                <ListItemText primary={chart.name} />
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default function AddChartsDrawer({
  openAddChartsDrawer,
  handleCloseAddChartDrawer,
  tabName,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { token, current_organisation } = useSelector((state) => state.login);
  const { selectedKpiType } = useSelector((state) => state.dataHub);
  const [chartSelected, setChartSelected] = React.useState({});

  const [hasSelectedChart, setHasSelectedChart] = React.useState(false);

  const handleChartIsSelected = (hasSelected, chart) => {
    setChartSelected(chart);
    setHasSelectedChart(hasSelected);
  };

  const handleAddWidgets = (id) => {
    dispatch(
      addDashboardNewCharts(
        token,
        id,
        true,
        current_organisation,
        tabName,
        selectedKpiType
      )
    );
    setChartSelected({});
    setHasSelectedChart(false);
    handleCloseAddChartDrawer();
  };

  // const list = (anchor) => (
  //   <div
  //     className={clsx(classes.list, {
  //       [classes.fullList]: anchor === "top" || anchor === "bottom",
  //     })}
  //     role="presentation"
  //     onClick={toggleDrawer(anchor, false)}
  //     onKeyDown={toggleDrawer(anchor, false)}
  //   >
  //     <List>
  //       {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
  //         <ListItem button key={text}>
  //           <ListItemIcon>
  //             {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
  //           </ListItemIcon>
  //           <ListItemText primary={text} />
  //         </ListItem>
  //       ))}
  //     </List>
  //     <Divider />
  //     <List>
  //       {["All mail", "Trash", "Spam"].map((text, index) => (
  //         <ListItem button key={text}>
  //           <ListItemIcon>
  //             {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
  //           </ListItemIcon>
  //           <ListItemText primary={text} />
  //         </ListItem>
  //       ))}
  //     </List>
  //   </div>
  // );

  return (
    <div>
      <Drawer
        PaperProps={{ style: { background: "#F6F6F6" } }}
        anchor="right"
        open={openAddChartsDrawer}
        onClose={handleCloseAddChartDrawer}
      >
        {!hasSelectedChart && (
          <>
            <Card
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                background: "#FFFFFF",
                boxShadow: "none",
                borderRadius: "5px",
                padding: "16px",
                height: "72px",
                width: "50vw",
              }}
            >
              <CardContent style={{ marginRight: "12px" }}>
                <Typography style={{ fontWeight: 500, fontSize: "16px" }}>
                  Widget Library
                </Typography>
              </CardContent>
              <CardActions onClick={handleCloseAddChartDrawer}>
                <CloseIcon style={{ color: "#3374B9", cursor: "pointer" }} />
              </CardActions>
            </Card>
            <div style={{ padding: "10px" }}>
              <TextField
                style={{ backgroundColor: "#FEFEFE", color: "#A1BFDF" }}
                size="small"
                type="text"
                label="Search"
                color="primary"
                variant="outlined"
                fullWidth={true}
                // value={searchText}
                // onChange={(e) => onSearchHandler(e)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon className={classes.SearchIcon} size="small" />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div style={{ width: "50vw" }}>
              <SimpleAccordion handleChartIsSelected={handleChartIsSelected} />
            </div>
          </>
        )}

        {hasSelectedChart && (
          <>
            <Card
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                background: "#FFFFFF",
                boxShadow: "none",
                borderRadius: "5px",
                padding: "20px 20px 20px 0px ",
                height: "72px",
                width: "50vw",
                borderBottom:
                  " 1px solid var(--colors-secoundary-secoundary-50, #EBF1F8)",
              }}
            >
              <CardContent
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                  cursor: "pointer",
                }}
              >
                <ArrowBackIcon
                  style={{ color: "rgba(51, 116, 185, 1)" }}
                  onClick={() => {
                    setHasSelectedChart(false);
                    setChartSelected({});
                  }}
                />
                <Typography style={{ fontWeight: 500, fontSize: "16px" }}>
                  {chartSelected?.name}
                </Typography>
              </CardContent>
              <CardActions onClick={handleCloseAddChartDrawer}>
                <CloseIcon style={{ color: "#3374B9", cursor: "pointer" }} />
              </CardActions>
            </Card>
            <Container
              maxWidth="md"
              style={{
                background: "#FFFFFF",
              }}
            >
              <div
                style={{
                  borderBottom:
                    " 1px solid var(--colors-secoundary-secoundary-50, #EBF1F8)",
                  padding: "1rem 0",
                }}
              >
                <Typography style={{ fontWeight: 500, fontSize: "16px" }}>
                  Preview Widget
                </Typography>
              </div>
              <div>
                <div style={{ height: "300px" }}>
                  <DashboardItem
                    // isEdit={isEdit}
                    chartId={chartSelected._id}
                    // tabType={TABS_TYPE[tabName]}
                    //   setSelectedhartData={setSelectedhartData}
                    onMoreClick={() => {
                      // setIsStaticChartKpiDrawerOpen(true);
                      // dispatch(setKpiDrawerState(true));
                      // setSelectedhartData(item);
                    }}
                    title={chartSelected.name}
                  >
                    <ChartRenderer
                      vizState={{
                        query: {
                          ...chartSelected?.vizState?.query,
                          timeDimensions:
                            chartSelected?.vizState?.query.hasOwnProperty(
                              "timeDimensions"
                            )
                              ? chartSelected?.vizState?.query?.timeDimensions
                                  .length > 0
                                ? [
                                    {
                                      dimension: `${
                                        chartSelected?.vizState?.query?.measures[0].split(
                                          "."
                                        )[0]
                                      }.date`,
                                      granularity: "quarter",
                                    },
                                  ]
                                : []
                              : [],
                          filters: [
                            {
                              member: `${
                                chartSelected?.vizState?.query?.measures[0].split(
                                  "."
                                )[0]
                              }.organisationid`,
                              operator: "equals",
                              values: [current_organisation],
                            },
                          ],
                        },
                        chartType: chartSelected?.vizState?.chartType,
                      }}
                      pivotConfig={chartSelected?.pivotConfig}
                      backgroundColor={
                        chartSelected?.chartColors?.backgroundColors
                      }
                      borderColor={chartSelected?.chartColors?.borderColor}
                      pieChartCustomize={chartSelected?.label}
                      // chartY_AxisCustomize={chartSelected?.chartY_AxisCustomize}
                      // baseline={chartSelected?.baseline}
                      // targetline={chartSelected?.targetline}
                    />
                  </DashboardItem>
                </div>
                <div style={{ marginTop: "1rem" }}>
                  <Typography
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      fontFamily: "Poppins",
                      color: "rgba(154, 154, 154, 1)",
                      marginBottom: "0.5rem",
                    }}
                  >
                    Topic
                  </Typography>

                  <Chip
                    label={chartSelected?.topic}
                    color="default"
                    style={{
                      borderRadius: "80px",
                      background:
                        "var(--im-grows-secoundary-secoundary-50, #EBF1F8)",

                      color:
                        "var(--im-grows-secoundary-secoundary-900, var(--colors-secoundary-secoundary-900, #15314E))",
                      textAlign: "center",

                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "16px",
                      letterSpacing: "0.06px",
                    }}
                  />
                </div>

                <div style={{ marginTop: "1rem" }}>
                  <Typography
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      fontFamily: "Poppins",
                      color: "rgba(154, 154, 154, 1)",
                      marginBottom: "0.5rem",
                    }}
                  >
                    Key Performance Indicator
                  </Typography>
                  <TextField
                    variant="outlined"
                    value={chartSelected?.kpi}
                    style={{ width: "100%" }}
                    disabled
                  />
                </div>

                <div style={{ marginTop: "1rem" }}>
                  <Typography
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      fontFamily: "Poppins",
                      color: "rgba(154, 154, 154, 1)",
                      marginBottom: "0.5rem",
                    }}
                  >
                    Widget Name
                  </Typography>
                  <TextField
                    variant="outlined"
                    value={chartSelected?.name}
                    style={{ width: "100%" }}
                    disabled
                  />
                </div>

                <div style={{ marginTop: "1rem" }}>
                  <Typography
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      fontFamily: "Poppins",
                      color: "rgba(154, 154, 154, 1)",
                      marginBottom: "0.5rem",
                    }}
                  >
                    Discription
                  </Typography>
                  <TextField
                    variant="outlined"
                    maxRows={5}
                    minRows={3}
                    multiline
                    value={chartSelected?.description}
                    style={{ width: "100%", color: "rgba(36, 36, 36, 1)" }}
                    disabled
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "1rem",
                    gap: "2rem",
                  }}
                >
                  <Button
                    style={{
                      color: "var(--colors-secoundary-secoundary-500, #3374B9)",
                    }}
                    disableRipple
                  >
                    Clear
                  </Button>
                  <Button
                    style={{
                      borderRadius: "var(--spacing-spacing-xs, 8px)",
                      background:
                        "var(--colors-secoundary-secoundary-500, #3374B9)",
                      color: "rgba(235, 241, 248, 1)",
                    }}
                    onClick={() => handleAddWidgets(chartSelected._id)}
                  >
                    Add to Dashboard
                  </Button>
                </div>
              </div>
            </Container>
          </>
        )}
      </Drawer>
    </div>
  );
}
