import {
  assignStrategyPlanTopic,
  getStructuredStakeholders,
} from "actions/phase-3/esgstrategyPlanning/esgstrategyPlanningAction";
import { useDispatch, useSelector } from "react-redux";

export const useFinalStepAPI = () => {
  const dispatch = useDispatch();
  const { entity_id } = useSelector((state) => state.user);
  const { esgStrategyPlanningAssessmentDetail } = useSelector(
    (state) => state?.esgStrategyPlanning
  );




  const handleAssignStrategy = async (
    assigned_user_id,
    assigned_against,
    task_ids,
    receiving_date,
    frequency,
    ends,
    ends_on_date
  ) => {
   
    const response = await dispatch(
      assignStrategyPlanTopic(
        entity_id,
        esgStrategyPlanningAssessmentDetail?._id,
        esgStrategyPlanningAssessmentDetail?.materiality_assessment_id,
        assigned_user_id,
        assigned_against,
        task_ids,
        receiving_date,
        frequency,
        ends,
        ends_on_date
      )
    );
    return response;
  };

  return {
    handleAssignStrategy,
  };
};
