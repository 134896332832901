import { useMutation, useQueryClient } from "@tanstack/react-query";
import { assignComplianceReportData } from "actions/phase-3/esgCompliance/esgComplianceAction";
import { useSelector } from "react-redux";

export const useFinalStepAPI = () => {
  const queryClient = useQueryClient();
  const { entity_id } = useSelector((state) => state.user);
  const { esgComlianceAssessmentDetail } = useSelector(
    (state) => state?.escCompliance
  );

  const handleAssignStrategy = useMutation({
    mutationFn: (data) => {
      
      return assignComplianceReportData({
        entity_id,
        esg_compliance_id: esgComlianceAssessmentDetail?._id,
        framework_id: esgComlianceAssessmentDetail?.framework_id,
        ...data,
      });
    },
    onSuccess: () => {
      // Invalidate the query to refetch the assessment list after a successful create

      queryClient.invalidateQueries(["reportsData"]);
      queryClient.invalidateQueries(["reportsDataWidgetCounts"]);
    },
    onError: (error) => {
      // Handle error
      console.error("Create failed:", error);
    },
  });

  return {
    handleAssignStrategy,
  };
};
