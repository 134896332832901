import { Box, CircularProgress, Skeleton, Typography } from "@mui/material";

export function UserChatAnswerLoading({
    chat,
    handleExpandMore,
    responseExpand = true,
  }) {
    return (
      <Box style={{ padding: "0px 10px", marginBottom: 20 }}>
        {chat?.isLoading && chat?.thinking && (
          <Box display={"flex"} alignItems={"center"}>
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: 400,
                color: "#15314E",
                padding: "15px",
              }}
            >
              Processing: {chat?.thinking}
            </Typography>
  
            <CircularProgress size={20} style={{ color: "#3374B9" }} />
          </Box>
        )}
        <Skeleton variant="text" width={"15vw"} height={50} animation="wave" />
        <Skeleton variant="text" width={"60vw"} height={25} animation="wave" />
        <Skeleton variant="text" width={"60vw"} height={30} animation="wave" />
        <Skeleton variant="text" width={"60vw"} height={35} animation="wave" />
      </Box>
    );
  }