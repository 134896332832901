import { useState, useMemo, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";

// -------------- main function --------------
const useFormValidation = (
  initialState,
  validationRules,
  actionCreator,
  hasChangeInitialState = false
) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});

  // use to know form that initial state has changed
  useEffect(() => {
    hasChangeInitialState && setFormData(initialState); // Update the form data when the initial state changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialState]);

  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      const error = validateField(name, value, validationRules[name]);

      setFormData((prev) => {
        if (Boolean(actionCreator)) {
          dispatch(actionCreator({ ...prev, [name]: value }));
        }

        return { ...prev, [name]: value };
      });
      setErrors((prev) => ({ ...prev, [name]: error }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validationRules]
  );

  const validationErrors = Object.keys(formData).reduce((acc, field) => {
    const error = validateField(field, formData[field], validationRules[field]);
    if (error) acc[field] = error;
    return acc;
  }, {});

  const handleSubmit = (e, callback) => {
    e.preventDefault();

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0 && Boolean(callback)) {
      callback();
    }
  };

  const hasErrors = useMemo(
    () => Object.keys(errors).some((key) => errors[key]),
    [errors]
  );

  return {
    formData,
    errors,
    handleChange,
    handleSubmit,
    setFormData,
    hasErrors,
    validationErrors,
  };
};

export default useFormValidation;

// -------------- helper functions --------------

// General validators
const validators = {
  email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  url: /^(https?:\/\/)?([\w-]+(\.[\w-]+)+\/?)([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/,
  phone: /\+?([\d|\(][\h|\(\d{3}\)|\.|\-|\d]{4,}\d)/,
};

const validatePassword = (value) => {
  const passwordErrors = [];
  if (value.length < 8) passwordErrors.push("Must be at least 8 characters");
  if (!/[a-z]/.test(value))
    passwordErrors.push("Must have at least one lowercase letter");
  if (!/[A-Z]/.test(value))
    passwordErrors.push("Must have at least one uppercase letter");
  if (!/[!@#$%^&*(),.?":{}|<>]/.test(value))
    passwordErrors.push("Must have at least one special character");
  if (!/[0-9]/.test(value))
    passwordErrors.push("Must have at least one number");
  if (/\s/.test(value)) passwordErrors.push("Must not contain spaces");
  return passwordErrors.length > 0 ? passwordErrors.join(", ") : "";
};

const validateField = (name, value, rules) => {
  if (!rules) return "";

  const { type, message, maxCharacters, minCharacters, pattern, required } =
    rules;

  if (required && !value)
    return (
      message ||
      `${name
        .split("_")
        .join(" ")
        .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
          letter.toUpperCase()
        )} is required`
    );

  if (type) {
    if (type === "password") return validatePassword(value);
    if (type === "phone") {
      if (!value.startsWith("+"))
        return "Phone number must include country code";
    }
    if (validators[type] && !validators[type].test(value))
      return message || `Invalid ${type}`;
  }

  if (maxCharacters && value.length > maxCharacters)
    return message || `Must be ${maxCharacters} characters or less`;
  if (minCharacters && value.length < minCharacters)
    return message || `Must be at least ${minCharacters} characters`;
  if (pattern && !pattern.test(value)) return message || "Invalid format";

  return "";
};
