import { SET_ALL_FRAME_WORK_LIST } from "constants/phase-3/frameWork/frameWorkConstant";

const initial_state = {
  frameWorkList: [],
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case SET_ALL_FRAME_WORK_LIST:
      return (state = {
        ...state,
        frameWorkList: action.payload,
      });

    default:
      return state;
  }
}

// ------------------- Important Key Points -------------------
