import { makeStyles } from "@mui/styles";

const ChatFieldStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFilledInput-input": {
      color: theme.palette.primary.main,
    },
    "&:hover .MuiFilledInput-input": {
      color: theme.palette.primary.main,
    },
    "& .MuiFilledInput-root.Mui-focused .MuiFilledInput-input": {
      color: theme.palette.primary.main,
    },
    "& .MuiInputLabel-filled": {
      color: theme.palette.primary.main,
      fontSize: "14px",
      paddingLeft: 5,
    },
    "&:hover .MuiInputLabel-filled": {
      color: theme.palette.primary.main,
    },
    "& .MuiInputLabel-filled.Mui-focused": {
      color: theme.palette.primary.main,
    },
    // Add styles for error state
    "& .MuiFormHelperText-root": {
      color: theme.palette.error.main,
    },
    "& .MuiInputLabel-root.Mui-error": {
      color: theme.palette.error.main,
    },
    "& .MuiFilledInput-root.Mui-error .MuiFilledInput-input": {
      color: theme.palette.error.main,
    },
  },
}));

export default ChatFieldStyles;
