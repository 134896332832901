import React, { useRef } from "react";

import { Stack, Typography, TextField } from "@mui/material";
import { MdOutlineAttachFile } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { HiArrowDownTray } from "react-icons/hi2";

function DocumentUpload({
  value,
  error,
  name,
  handleChange,
  viewOnly = false,
}) {
  const fileInputRef = useRef(null); // Create a ref to the file input

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0]; // Get the first selected file

    handleChange({
      target: {
        name,
        value: selectedFile,
      },
    });
  };

  const handleTextFieldClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger the file input dialog
    }
  };

  const downloadFile = (fileUrl, fileName) => {
    const link = document.createElement("a"); // Create a temporary anchor element
    link.href = fileUrl; // Set the href to the file URL
    link.download = fileName; // Set the desired file name for download
    document.body.appendChild(link); // Append the link to the body (not displayed)
    link.click(); // Programmatically click the link to trigger the download
    document.body.removeChild(link); // Clean up: remove the link
  };

  return (
    <>
      {viewOnly && value && (
        <Stack
          sx={{
            border: "1px solid  #E9E9E9",
            borderRadius: "8px",
            width: "45%",
          }}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          //   gap={5}
          padding={1}
          mb={1}
        >
          <MdOutlineAttachFile
            size={"1.5em"}
            style={{ color: "#3374B9", marginRight: "10px" }}
          />
          <Typography variant="body1">{value?.name || value}</Typography>
          <HiArrowDownTray
            size={"1.5em"}
            style={{ color: "#3374B9", marginLeft: "auto", cursor: "pointer" }}
            onClick={() => downloadFile(value?.url, value?.name)}
          />
        </Stack>
      )}
      {!viewOnly && (
        <Stack
          sx={(theme) => ({
            backgroundColor: !value && theme.palette.primary.lighter,

            color: !value && theme.palette.primary.dark,

            border: "1px solid  #E9E9E9",
            borderRadius: "8px",
            width: "45%",
          })}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          //   gap={5}
          padding={1}
          mb={1}
        >
          <MdOutlineAttachFile
            size={"1.5em"}
            style={{ color: "#3374B9", marginRight: "10px" }}
          />
          <TextField
            variant="standard"
            placeholder="Add Attachment"
            value={value ? value.name : ""} // Display file name if selected
            onClick={handleTextFieldClick} // Trigger file input on click
            error={Boolean(error)}
            helperText={error}
            size="small"
            readOnly // Prevent user from typing in the TextField
            sx={{ cursor: "pointer" }}
          />
          <input
            type="file"
            accept=".pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx" // Accept specific file types
            onChange={handleFileChange} // Handle file selection
            style={{ display: "none", cursor: "pointer" }} // Hide the file input
            ref={fileInputRef} // Assign the ref to the input
          />
          {value && (
            <IoMdClose
              onClick={(e) =>
                handleChange({
                  target: {
                    name,
                    value: "",
                  },
                })
              }
              size={"1.5em"}
              style={{
                cursor: "pointer",
                color: "#3374B9",
                marginLeft: "auto",
              }}
            />
          )}
        </Stack>
      )}
    </>
  );
}

export default DocumentUpload;
