import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Grid, Box } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { setLayoutActiveStep } from "actions/layout/layoutAction";
import LayoutHeader from "./LayoutHeader";
import LayoutStepper from "./LayoutStepper";
import LayoutButton from "./LayoutButton";

// function getStepContent(step, response) {
//     switch (step) {
//         case 0:
//             return <>"SurveyStep1</>;
//         case 1:
//             return <>"SurveyStep2</>;
//         case 2:
//             return <>"SurveyStep3</>;
//         // case 3:
//         //     return <SurveyStep4 response={response} />;
//         // case 4:
//         //     return <FinalSubmission />;
//         default:
//             return null;
//     }
// }

const MainLayoutStepper = ({
  steps,
  handleFinalStep,
  mainCrumb,
  stepContentMapping,
  heightAdjust = "100px",
  finalStepText,
  handleStepChangeApi,
  handleDisableButton,
  skipProceedAPI = false,
}) => {
  const location = useLocation();

  const dispatch = useDispatch();

  const { layoutActiveStep } = useSelector((state) => state.layout);

  // const { pathname } = location;


  const handleNext = async (hasSubmit) => {
    if (hasSubmit) {
      handleFinalStep && handleFinalStep();

      dispatch(setLayoutActiveStep(layoutActiveStep + 1));
    } else {
      try {
        if (layoutActiveStep === 0 && !skipProceedAPI) {
          // Wait for the API call to complete
          const result = await handleStepChangeApi(layoutActiveStep);
          // Dispatch the action to move to the next step after successful API call
          (result?.status || false) &&
            dispatch(setLayoutActiveStep(layoutActiveStep + 1));
        } else {
          dispatch(setLayoutActiveStep(layoutActiveStep + 1));
        }
      } catch (error) {
        // Handle any errors from the API call here
        console.error("API call failed", error);
      }
    }
  };

  const handleBack = () => {
    dispatch(setLayoutActiveStep(layoutActiveStep - 1));
  };

  const getStepContent = (step) => {
    const StepComponent = stepContentMapping[step];
    return StepComponent ? <StepComponent /> : null;
  };

  useEffect(() => {
    dispatch(setLayoutActiveStep(0));
  }, [location.pathname, dispatch]);

  return (
    <Grid
      container
      direction="column"
      style={{
        height: `calc(100vh - ${heightAdjust})`,
        position: "relative",
      }}
    >
      {mainCrumb && (
        <Grid item sx={{ padding: "10px" }}>
          <LayoutHeader mainCrumb={mainCrumb} />
        </Grid>
      )}
      <Grid item>
        <LayoutStepper steps={steps} activeStep={layoutActiveStep} />
      </Grid>
      <Grid item style={{ flexGrow: 1, overflowY: "auto", padding: 20 }}>
        {getStepContent(layoutActiveStep)}
      </Grid>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
        style={{
          position: "absolute",
          bottom: 0,
          right: 0,
          padding: 20,
          width: "calc(100% - 40px)",
        }} // Adjust padding as needed
      >
        <LayoutButton
          steps={steps}
          activeStep={layoutActiveStep}
          handleBack={handleBack}
          handleNext={handleNext}
          surveyCompleted={false}
          finalStepText={finalStepText}
          handleDisableButton={handleDisableButton}
        />
      </Box>
    </Grid>
  );
};
export default MainLayoutStepper;
