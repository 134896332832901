import React from "react";
import moment from "moment";
import { Box, Button, Chip, TableCell, TableRow } from "@mui/material";
import DynamicTable, {
  textStyle,
} from "components/UI/phase-3/dynamicTable/DynamicTable";
import { NoDataComponent } from "../../esgMaturity/shareSurvey/NoData";
import { usePaginationSearch } from "customHooks/usePaginationSearch";
import OverViewDrawer from "./overViewDrawer/OverViewDrawer";
import { useEsgStrategyPlanMonitoring } from "../APIs/HandlePlanMoitoringApi";

function ActivityTable() {
  const { openDrawer, toggleDrawer } = usePaginationSearch();
  const { planMonitoringList, setSelectedIndex, selectedIndex } =
    useEsgStrategyPlanMonitoring(true);
  return (
    <Box sx={{}}>
      <DynamicTable
        headings={[
          "Sl.no.",
          "Name of the Activity",
          "person assigned",
          "material topic",
          "Status",
          "Due Date",
          "Overview",
        ]}
      >
        {/* name: "tom",
      email: "tom@example.com",
      role: "dev",
      id: 12,
      isCheck: false, */}
        {planMonitoringList.map((rowData, rowIndex) => (
          <TableRow
            key={rowIndex}
            //   className={classes.tableRowHover}
            onClick={() => {
              // dispatch(setSelectedCorporate(rowData));
              // history.push(
              //   `/supplier_task/${rowData.company_name.replace(/ /g, "_")}`
              // );
            }}
            sx={{ borderBottom: "1px solid #EBF1F8" }}
          >
            <TableCell style={textStyle()}>{rowIndex + 1}</TableCell>
            <TableCell style={textStyle("main")}>
              <Box display={"flex"} alignItems={"center"} sx={{ gap: 10 }}>
                {rowData?.title}
              </Box>
            </TableCell>
            <TableCell style={textStyle()}>
              {rowData?.person_assigned || "-"}
            </TableCell>
            <TableCell style={textStyle()}>{rowData?.material_topic}</TableCell>

            <TableCell style={textStyle()}>
              <Chip
                sx={(theme) => ({
                  backgroundColor:
                    rowData.status === "In Progress"
                      ? theme.palette.primary.lighter
                      : theme.palette.secondary.lighter,
                  padding: "6px 10px",
                  fontSize: "12px",
                  color:
                    rowData.status === "In Progress"
                      ? theme.palette.primary.dark
                      : theme.palette.secondary.dark,
                  borderRadius: "80px",
                })}
                label={rowData.status}
              />
            </TableCell>
            <TableCell style={textStyle()}>
              {moment(rowData?.due_date).format("DD MMM")}
            </TableCell>
            <TableCell style={textStyle()}>
              <Button
                onClick={() => {
                  setSelectedIndex(rowIndex);
                  toggleDrawer();
                }}
                variant="text"
              >
                View
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </DynamicTable>

      {planMonitoringList?.length === 0 && (
        <NoDataComponent mainTitle={"No Activities Created"} />
      )}

      {/*  overview drawer */}

      <OverViewDrawer
        open={openDrawer}
        handleClose={() => {
          toggleDrawer();
          setSelectedIndex(null);
        }}
        source={"Activity"}
        data={planMonitoringList[selectedIndex] || {}}
      />
    </Box>
  );
}

export default ActivityTable;
