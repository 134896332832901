import { Box, MenuItem, TextField, Autocomplete, Avatar } from "@mui/material";
import React from "react";
import Heading from "../heading/Heading";

function SelectAndOption({
  heading,
  label = "Select Option",
  marginTop = "10px",
  marginBottom = "20px",
  options = ["option1", "option2", "option3"],
  width = "100%",
  value,
  handleChange,
  error,
  name,
  valueKey = "value", // Property name for value when options is an array of objects
  labelKey = "label", // Property name for label when options is an array of objects
  autocompleteEnabled = false, // Whether to enable autocomplete
  imgSrc = false,
}) {

  return (
    <Box
      style={{
        marginTop: marginTop,
        marginBottom: marginBottom,
        width,
      }}
    >
      <Heading text={heading} size="13px" marginBottom="10px" color="#999" />

      {autocompleteEnabled ? (
        <Autocomplete
          options={options}
          getOptionLabel={(option) =>
            typeof option === "object" ? option[labelKey] : option
          }
          renderOption={(props, option) => (
            <li {...props}>
              {imgSrc && (
                <Avatar
                  alt={"image"}
                  src={option[imgSrc] || ""}
                  style={{ marginRight: 8, width: "20px", height: "20px" }}
                />
              )}
              {option[labelKey]}
            </li>
          )}
          onChange={(event, newValue) => {
            handleChange({
              target: {
                name,
                value:
                  typeof newValue === "object"
                    ? newValue !== null
                      ? newValue[valueKey]
                      : ""
                    : newValue,
              },
            });
          }}
          value={options.find(
            (option) =>
              (typeof option === "object" ? option[valueKey] : option) === value
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={label}
              error={Boolean(error)}
              helperText={error}
              fullWidth
              variant="outlined"
              size="small"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    {imgSrc && value && (
                      <Avatar
                        alt="selected"
                        src={
                          options.find(
                            (option) =>
                              (typeof option === "object"
                                ? option[valueKey]
                                : option) === value
                          )?.[imgSrc] || ""
                        }
                        style={{
                          marginRight: 8,
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    )}
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      ) : (
        <TextField
          disabled={options?.length === 0}
          name={name}
          select
          label={label}
          value={value}
          onChange={handleChange}
          error={Boolean(error)}
          helperText={error}
          fullWidth
          variant="outlined"
          size="small"
          InputLabelProps={{
            style: {
              fontSize: "14px",
            },
          }}
          InputProps={{
            style: {
              padding: "0px",
            },
          }}
        >
          {options.map((item) => (
            <MenuItem
              key={typeof item === "object" ? item[valueKey] : item}
              value={typeof item === "object" ? item[valueKey] : item}
              style={{ fontSize: "0.875rem", padding: "7px 15px" }}
            >
              {typeof item === "object" ? item[labelKey] : item}
            </MenuItem>
          ))}
        </TextField>
      )}
    </Box>
  );
}
export default SelectAndOption;
