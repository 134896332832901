import { Chip, Stack, Box, TableCell, TableRow } from "@mui/material";
import Heading from "components/UI/phase-3/heading/Heading";
import DynamicTable, {
  textStyle,
} from "components/UI/phase-3/dynamicTable/DynamicTable";
import useESgStrategyTaskRight from "../API/HandleEsgStrategyTaskRightApi";
import { NoDataComponent } from "components/widgets/phase-3/esgMaturity/shareSurvey/NoData";

export default function TaskListing({ toggleExplore }) {
  const { esgStrategyTaskTopicList, selectedTopicCategory, handleGetDetails } =
    useESgStrategyTaskRight();
  return (
    <Stack sx={{ height: "100%" }}>
      {/* heading */}
      <Heading text={selectedTopicCategory} />

      {/* main content */}
      <Box sx={{ height: "98%", overflow: "scroll" }}>
        {esgStrategyTaskTopicList.length > 0 && (
          <DynamicTable
            headings={[
              { name: "Sl.No.", align: "left" },
              { name: "Name Of The Topic", align: "left" },
              { name: "Status", align: "right" },
            ]}
          >
            {esgStrategyTaskTopicList.map((rowData, rowIndex) => (
              <TableRow
                key={rowIndex}
                //   className={classes.tableRowHover}
                onClick={async () => {
                  const response = await handleGetDetails(rowData);
                  response?.status &&
                    toggleExplore(response?.result, selectedTopicCategory);
                  // dispatch(setSelectedCorporate(rowData));
                  // navigate(
                  //   `/task/esg_strategy/${rowData.name?.replaceAll(" ", "_")}`
                  // );
                }}
                sx={{
                  borderBottom: "1px solid #EBF1F8",
                  cursor: "pointer",
                }}
                hover
              >
                <TableCell align="left" style={textStyle()}>
                  {rowIndex + 1}
                </TableCell>
                <TableCell align="left" style={textStyle("main")}>
                  {rowData.title}
                </TableCell>

                <TableCell align="right" style={textStyle()}>
                  <Chip
                    sx={(theme) => ({
                      backgroundColor: !rowData.is_completed
                        ? theme.palette.primary.lighter
                        : theme.palette.secondary.lighter,
                      padding: "6px 10px",
                      fontSize: "12px",
                      color: !rowData.is_completed
                        ? theme.palette.primary.dark
                        : theme.palette.secondary.dark,
                      borderRadius: "80px",
                    })}
                    label={rowData.is_completed ? "Completed" : "In Progress"}
                  />
                </TableCell>
              </TableRow>
            ))}
          </DynamicTable>
        )}
        {esgStrategyTaskTopicList.length === 0 && (
          <NoDataComponent
            mainTitle={`No ${selectedTopicCategory} topic Assigned`}
          />
        )}
      </Box>

      {/* action button */}

      {/* <Stack
        direction={"row"}
        justifyContent={"flex-end"}
        alignItems={"center"}
        gap={1}
        mt={2}
      >
        <Button
          variant="contained"
          // disabled={Object.keys(validationErrors).length > 0}
          // onClick={() => handleSubmitData(formData)}
        >
          Submit
        </Button>
      </Stack> */}
    </Stack>
  );
}
