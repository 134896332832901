import {
  Button,
  Chip,
  Icon,
  Stack,
  TableCell,
  TableRow,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import DynamicTable, {
  textStyle,
} from "components/UI/phase-3/dynamicTable/DynamicTable";
import Heading from "components/UI/phase-3/heading/Heading";
import SearchTextField from "components/UI/phase-3/textfield/searchTextField";
import { LuFileSpreadsheet } from "react-icons/lu";

function ExcelMain() {
  const theme = useTheme();
  return (
    <>
      {/* Heading */}
      <Heading text="">
        <Stack direction={"row"} alignItems={"center"} gap={2}>
          <SearchTextField sx={{ minWidth: "30em" }}/>
          <Button variant="text" color="primary" size="small">
            Download Sheet
          </Button>
          <Button variant="text" color="primary" size="small">
            Upload Sheet
          </Button>
        </Stack>
      </Heading>

      {/* main table */}

      <DynamicTable
        // hasCheckbox={true}
        headings={[
          "Type",
          "Spreadsheet Name",
          "Duration Of Data",
          "Uploaded By",
          "Date Of Uploading",
          "Tags",
          "Upload Status",
          "Action",
        ]}
      >
        <TableRow
          sx={{ borderBottom: "1px solid #EBF1F8", cursor: "pointer" }}
          hover
        >
          <TableCell style={textStyle("")}>
            <Icon>
              <LuFileSpreadsheet
                style={{ color: theme.palette.primary.main }}
              />
            </Icon>
          </TableCell>
          <TableCell style={textStyle("main")}>Esg Metrics</TableCell>
          <TableCell style={textStyle("")}>05 Aug 2022 - 13 May 2023</TableCell>
          <TableCell style={textStyle("")}>Saad Ansari</TableCell>
          <TableCell style={textStyle("")}>04 Aug 2022</TableCell>
          <TableCell style={textStyle("")}>3+ tags</TableCell>
          <TableCell style={textStyle("")}>
            <Chip
              sx={(theme) => ({
                backgroundColor: false
                  ? theme.palette.primary.lighter
                  : theme.palette.warning.lighter,
                padding: "6px 10px",
                fontSize: "12px",
                color: false
                  ? theme.palette.primary.dark
                  : theme.palette.warning.dark,
                borderRadius: "80px",
              })}
              label={false ? "Completed" : "In-Progress"}
            />
          </TableCell>
          <TableCell style={textStyle("")}>
            <Button variant="text" color="primary" size="small">
              Publish
            </Button>
          </TableCell>
        </TableRow>
      </DynamicTable>
    </>
  );
}

export default ExcelMain;
