import { useEffect, useState } from "react";
import { useEsgStrategyPlanMonitoring } from "../../../APIs/HandlePlanMoitoringApi";
import { getUnAssignedStrategyPlanTopic } from "actions/phase-3/esgstrategyPlanning/esgstrategyPlanningAction";
import { useDispatch, useSelector } from "react-redux";

export const useSelectActivity = (selectedTab, open) => {
  const dispatch = useDispatch();

  const { esgStrategyPlanningAssessmentDetail, unAssignedStrategyTopics } =
    useSelector((state) => state?.esgStrategyPlanning);

  const [selectedActivities, setSelectedActivities] = useState([]);

  const handleCheckboxChange = (activity) => {
    if (selectedActivities.includes(activity)) {
      setSelectedActivities((prev) => prev.filter((item) => item !== activity));
    } else {
      setSelectedActivities((prev) => [...prev, activity]);
    }
  };

  const selectAllActivities = () => {
    setSelectedActivities(unAssignedStrategyTopics);
  };

  const resetActivites = () => {
    setSelectedActivities([]);
  };


  useEffect(() => {
    open &&
      dispatch(
        getUnAssignedStrategyPlanTopic(
          esgStrategyPlanningAssessmentDetail?._id,
          esgStrategyPlanningAssessmentDetail?.materiality_assessment_id,
          selectedTab === 0 ? true : false
        )
      );
  }, [selectedTab, open]);

  const disableComplete = selectedActivities?.length === 0;

  return {
    // states
    activities: unAssignedStrategyTopics,
    selectedActivities,
    disableComplete,
    // functions
    handleCheckboxChange,
    selectAllActivities,
    resetActivites,
  };
};
