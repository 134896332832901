import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";

export default function EditInsightWidget({
  handleUpdateEditInsight,
  selectedWidget,
  openEditInsight,
  handleCloseEditInsight,
}) {
  const [updatedInsight, setUpdatedInsight] = React.useState(selectedWidget);
  const [companyLogoURI, setCompanyLogoURI] = React.useState("");
  const [insightLogo, setInsightLogo] = React.useState("");


  const handleUpdateInsight = (event) => {
    const targetName = event.target.name;
    const updatedValue = event.target.value;

    setUpdatedInsight((preValue) => ({
      ...preValue,
      [targetName]: updatedValue,
    }));
  };

  const handleUploadCompanyLogo = (e) => {
    const reader = new FileReader();

    const file = e.target.files[0];
    setInsightLogo(file);
    reader.onloadend = () => {
      setUpdatedInsight((preValue) => ({ ...preValue, logo: reader.result }));
      // setCompanyLogoURI(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleClearCompanyLogo = () => {
    // removeFile(login.token, assessment_id, 1, basicInfo.companyLogoURI, "logo");
    setInsightLogo("");
    setUpdatedInsight((preValue) => ({ ...preValue, logo: "" }));

    // setCompanyLogoURI("");
  };

  return (
    <div>
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={openEditInsight}
        onClose={handleCloseEditInsight}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Edit Insights</DialogTitle>
        <DialogContent>
          <Box my={2}>
            <Typography>Insight Logo</Typography>
          </Box>
          <Box my={2}>
            {updatedInsight?.logo === "" || updatedInsight?.logo === null ? (
              <>
                <input
                  id="company-logo"
                  type="file"
                  onChange={(event) => handleUploadCompanyLogo(event)}
                  style={{ display: "none" }}
                  cursor="pointer"
                  accept=".png, .jpg, .jpeg"
                />
                <label htmlFor="company-logo">
                  <div
                    style={{
                      border: "2px dashed #DADBE6",
                      width: 120,
                      height: 120,
                      borderRadius: 5,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      "&:hover": {
                        "& $addIcon": {
                          color: "#3374B9",
                        },
                      },
                    }}
                  >
                    <AddCircleOutlineIcon
                      name="addLogoIcon"
                      fontSize="large"
                      style={{ color: "#DADBE6" }}
                    />
                  </div>
                </label>
              </>
            ) : (
              <div
                style={{
                  border: "2px solid #DADBE6",
                  width: 120,
                  height: 120,
                  borderRadius: 5,
                  zIndex: 1,
                  "& img": {
                    width: "100%",
                    height: "100%",
                  },
                  position: "relative",
                }}
              >
                <img
                  src={updatedInsight?.logo}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  alt="company-logo"
                />
                <IconButton
                  onClick={handleClearCompanyLogo}
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    padding: 2,
                  }}
                  size="large"
                >
                  <CancelIcon style={{ color: "gray" }} fontSize="small" />
                </IconButton>
              </div>
            )}
          </Box>
          <TextField
            // autoFocus
            margin="dense"
            id="Title"
            label="Title"
            value={updatedInsight?.heading}
            // fullWidth
            variant="outlined"
            name="heading"
            onChange={handleUpdateInsight}
          />
          <TextField
            // autoFocus
            margin="dense"
            id="Content"
            label="Content"
            fullWidth
            defaultValue={updatedInsight?.content}
            multiline
            variant="outlined"
            name="content"
            onChange={handleUpdateInsight}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditInsight} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleUpdateEditInsight(updatedInsight, insightLogo)}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
