import { useMemo, useState } from "react";

import { Stack } from "@mui/material";

import InputField from "components/UI/phase-3/inputField/InputField";
import DisclosureTypeOne from "components/widgets/phase-3/Task/compliance/right/disclosureType/DisclosureTypeOne";
import DisclosureTypeThree from "components/widgets/phase-3/Task/compliance/right/disclosureType/DisclosureTypeThree";
import DisclosureTypeTwo from "components/widgets/phase-3/Task/compliance/right/disclosureType/DisclosureTypeTwo";
import useFormValidation from "customHooks/useFormValidation";
import { submitEsgComplianceMetricRecord } from "actions/phase-3/esgCompliance/esgComplianceAction";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";

function RecordMain({
  data: { guidelines, survey_type, columns, rows, _id, recorded_data = "" },
}) {
  const [isDataChanged, setIsDataChanged] = useState(false);
  const { esgComlianceAssessmentDetail } = useSelector(
    (state) => state?.escCompliance
  );
  const queryClient = useQueryClient();

  const initialState = useMemo(
    () => ({
      data: recorded_data?.submission_data || "",
    }),
    [recorded_data?.submission_data]
  );

  const validationRules = useMemo(
    () => ({
      data: { required: true },
    }),
    []
  );

  const { formData, errors, handleChange, validationErrors, setFormData } =
    useFormValidation(initialState, validationRules);

  const { mutateAsync, isPending } = useMutation({
    mutationFn: submitEsgComplianceMetricRecord,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["reportsData"]);
      setIsDataChanged(false); // Reset after successful submission
    },
    onError: (error) => {
      // Handle error
      console.error("Create failed:", error);
    },

    staleTime: Infinity,
    cacheTime: 0,
  });

  console.log(guidelines, survey_type, columns, rows, _id, recorded_data);

  return (
    <Stack mt={1} justifyContent={"space-between"}>
      {/* Disclosure Body */}
      {survey_type === 1 && (
        <DisclosureTypeOne>
          <InputField
            heading={"Text Field"}
            rows={3}
            label="Enter Here"
            value={formData?.data}
            error={errors.data}
            name={"data"}
            handleChange={(e) => {
              handleChange(e);
              setIsDataChanged(true);
            }}
          />
        </DisclosureTypeOne>
      )}
      {survey_type === 2 && (
        <DisclosureTypeTwo
          typeTwoData={formData.data}
          columns={columns}
          rows={rows}
          setFormData={setFormData}
          handleCheckedDataChanged={() => setIsDataChanged(true)}
        />
      )}
      {survey_type === 3 && (
        <DisclosureTypeThree
          typeThreeData={formData.data}
          columns={columns}
          rows={rows}
          setFormData={setFormData}
          handleCheckedDataChanged={() => setIsDataChanged(true)}
        />
      )}
      <Stack direction={"row"} justifyContent={"flex-end"} mt={3}>
        <LoadingButton
          variant="contained"
          color="primary"
          disabled={!isDataChanged || Object.keys(validationErrors).length > 0}
          loading={isPending}
          onClick={async () =>
            await mutateAsync({
              esg_compliance_id: esgComlianceAssessmentDetail?._id,
              metric_id: _id,
              submission_data: formData.data,
            })
          }
        >
          Submit Record
        </LoadingButton>
      </Stack>
    </Stack>
  );
}

export default RecordMain;
