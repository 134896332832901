export const SET_MATERIALITY_ASSESSMENT_LIST =
  "SET_MATERIALITY_ASSESSMENT_LIST";
export const SET_MATERIALITY_ASSESSMENT_DETAILS =
  "SET_MATERIALITY_ASSESSMENT_DETAILS";
export const SET_ORIGINAL_MATERIALITY_ASSESSMENT_DETAILS =
  "SET_ORIGINAL_MATERIALITY_ASSESSMENT_DETAILS";
export const SET_MATERIALITY_MAIN_TOPIC = "SET_MATERIALITY_MAIN_TOPIC";
export const SET_MATERIALITY_TOPIC = "SET_MATERIALITY_TOPIC";
export const SET_SELECTED_MATERIALITY_TOPIC = "SET_SELECTED_MATERIALITY_TOPIC";
export const SET_TOPIC_LIMIT = "SET_TOPIC_LIMIT";
export const SET_MATERIALITY_ASSESSMENT_YEAR_LIST =
  "SET_MATERIALITY_ASSESSMENT_YEAR_LIST";

export const SET_MATERIALITY_ASSIGNED_USERS = "SET_MATERIALITY_ASSIGNED_USERS";
export const SET_MATERIALITY_TOPIC_ANALYSIS = "SET_MATERIALITY_TOPIC_ANALYSIS"
