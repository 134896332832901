import { useEffect, useState } from "react";
import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getTaskMaturityQues,
  submitSurveyAns,
} from "actions/phase-3/task/maturity/maturityAction";
import SingleQuestions from "components/widgets/phase-3/survey/QuestionsBodies/SingleQuestions";
import { NoDataComponent } from "components/widgets/phase-3/esgMaturity/shareSurvey/NoData";

const ESGMaturityTask = () => {
  const dispatch = useDispatch();
  const { entity_id } = useSelector((state) => state.user);
  const { task_maturity_ques } = useSelector((state) => state.maturityTask);
  const { submissions } = task_maturity_ques;

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [addedComments, setAddedComments] = useState({});
  const [previousAnswers, setPreviousAnswers] = useState({});
  const [previousComments, setPreviousComments] = useState({});

  useEffect(() => {
    dispatch(getTaskMaturityQues(entity_id));
  }, [dispatch, entity_id]);

  useEffect(() => {
    if (submissions?.length > 0) {
      // Populate the answers and comments from the submissions
      const initialAnswers = {};
      const initialComments = {};

      submissions.forEach((submission) => {
        initialAnswers[submission.question_id] = submission.value;
        initialComments[submission.question_id] = submission.comment;
      });

      setSelectedAnswers(initialAnswers);
      setAddedComments(initialComments);
      setPreviousAnswers(initialAnswers);
      setPreviousComments(initialComments);

      // Set the current question index to start after the last submitted question
      setCurrentQuestionIndex(submissions.length);
    }
  }, [submissions]);

  const handleOptChange = (questionId, selectedValue) => {
    setSelectedAnswers({
      ...selectedAnswers,
      [questionId]: selectedValue,
    });
  };

  const handleCmtChange = (questionId, comment) => {
    setAddedComments({
      ...addedComments,
      [questionId]: comment,
    });
  };

  const handleNext = () => {
    const currentQuestion = task_maturity_ques.questions[currentQuestionIndex];
    const selectedValue = selectedAnswers[currentQuestion?._id];
    const previousAnswer = previousAnswers[currentQuestion?._id];
    const previousComment = previousComments[currentQuestion?._id];
    const comment = addedComments[currentQuestion?._id];

    const addPrevAns = () => {
      setPreviousAnswers({
        ...previousAnswers,
        [currentQuestion?._id]: selectedValue,
      });
      setPreviousComments({
        ...previousComments,
        [currentQuestion?._id]: comment,
      });
    };

    const moveNext = () => {
      // if (currentQuestionIndex < task_maturity_ques.questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      // }
    };

    if (selectedValue !== previousAnswer || comment !== previousComment) {
      dispatch(
        submitSurveyAns({
          entity_id,
          question_id: currentQuestion?._id,
          value: selectedValue,
          comment,
          addPrevAns,
          moveNext,
        })
      );
    } else {
      moveNext();
    }
  };

  const handleBack = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  if (!task_maturity_ques || !task_maturity_ques?.questions) {
    return <Typography>No Available Questions</Typography>;
  }

  const currentQuestion = task_maturity_ques.questions[currentQuestionIndex];

  if (submissions?.length === 40 && currentQuestionIndex === 40 || currentQuestionIndex === 40) {
    return <NoDataComponent mainTitle={"Maturity Survey Completed"} buttonTex={"Redo Survey"} handleButtonClick={() => setCurrentQuestionIndex(0)}  />;
  }

  return (
    <Box width={"100%"} height={"100%"}>
      <Stack alignItems={"center"} justifyContent={"center"} p={2}>
        <Paper variant="elevation" style={{ position: "relative" }}>
          <Box width={"90vw"} height={"70vh"} p={2} overflow="auto">
            <Typography variant="h2" fontWeight={600} gutterBottom>
              Survey Section
            </Typography>

            <Box p={2} my={2}>
              <SingleQuestions
                quesNo={currentQuestionIndex + 1}
                question={currentQuestion?.name || ""}
                opts={currentQuestion?.ans || []}
                submittedAns={selectedAnswers[currentQuestion?._id]}
                optChange={(selectedOption) =>
                  handleOptChange(currentQuestion?._id, selectedOption)
                }
                comment={addedComments[currentQuestion?._id]}
                commentChange={(comment) =>
                  handleCmtChange(currentQuestion?._id, comment)
                }
                showComment
              />
            </Box>
          </Box>

          <Box
            display={"flex"}
            gap={2}
            alignItems={"center"}
            justifyContent={"flex-end"}
            p={2}
            sx={{
              position: "sticky",
              bottom: 0,
              backgroundColor: "background.paper",
              borderTop: "1px solid",
              borderColor: "divider",
            }}
          >
            <Button
              variant={"text"}
              size={"large"}
              onClick={handleBack}
              disabled={currentQuestionIndex === 0}
            >
              Back
            </Button>
            <Button
              variant={"contained"}
              size={"large"}
              onClick={handleNext}
              disabled={
                !selectedAnswers[currentQuestion?._id] ||
                currentQuestionIndex === task_maturity_ques.questions.length
              }
            >
              {currentQuestionIndex === task_maturity_ques.questions.length - 1
                ? "Submit"
                : "Submit & Next"}
            </Button>
          </Box>
        </Paper>
      </Stack>
    </Box>
  );
};

export default ESGMaturityTask;
