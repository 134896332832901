import {
  createEsgComplianceAssessment,
  setEsgComplianceAssessmentDetails,
  setOriginalEsgComplianceDetails,
  updateEsgComplianceAssessment,
} from "actions/phase-3/esgCompliance/esgComplianceAction";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllFrameWorkList,
  getFilteredFrameWorkList,
} from "actions/phase-3/framework/frameWorkAction";

export const useEsgComplianceBasicDetail = () => {
  const { entity_id } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  // esgComlianceAssessmentDetail: {},
  // originalEsgComlianceAssessmentDetail: {},
  const { esgComlianceAssessmentDetail, originalEsgComlianceAssessmentDetail } =
    useSelector((state) => state?.escCompliance);

  // const { frameWorkList } = useSelector((state) => state?.frameWorV3);


  const data = queryClient.getQueryData(["allFrameworks"]);

  

  const { data: { result: frameWorkList = [] } = {} } = useQuery({
    queryKey: ["allFrameworks"],
    queryFn: getAllFrameWorkList,
    enabled: !data,
    // these for know query that data is fresh and API not call until we manually fetch or invalidate it
    staleTime: Infinity,
    cacheTime: 0,
  });

  const { data: { result: filteredFrameWorkList = [] } = {} } = useQuery({
    queryKey: [
      "filteredFrameworks",
      esgComlianceAssessmentDetail?.country,
      entity_id,
    ],
    queryFn: () =>
      getFilteredFrameWorkList(
        esgComlianceAssessmentDetail?.country,
        entity_id
      ),
    enabled: !!esgComlianceAssessmentDetail?.country,
    // these for know query that data is fresh and API not call until we manually fetch or invalidate it
    staleTime: Infinity,
    cacheTime: 0,
  });

  const getFrameWorkType = (id) =>
    frameWorkList.find((f) => f?._id === id)?.framework_type || "";

  function areAllFieldsNotEmpty(obj) {
    // Check if the object has no properties
    if (Object.keys(obj).length === 0) {
      return false;
    }

    // Check if all properties are not null, undefined, or empty string
    return Object.values(obj).every(
      (value) => value !== null && value !== undefined && value !== ""
    );
  }

  let disableButton = areAllFieldsNotEmpty(esgComlianceAssessmentDetail);

  const hasChanges = (original, updated) => {
    return JSON.stringify(original) !== JSON.stringify(updated);
  };

  // Mutation for creating a materiality assessment
  const createMutation = useMutation({
    mutationFn: createEsgComplianceAssessment,
    onSuccess: () => {
      // Invalidate the query to refetch the assessment list after a successful create

      queryClient.invalidateQueries(["esgComplianceAssessmentList"]);
    },
    onError: (error) => {
      // Handle error
      console.error("Create failed:", error);
    },
  });

  // Mutation for creating a materiality assessment
  const updateMutation = useMutation({
    mutationFn: updateEsgComplianceAssessment,
    onSuccess: () => {
      // Invalidate the query to refetch the assessment list after a successful create

      queryClient.invalidateQueries(["esgComplianceAssessmentList"]);
    },
    onError: (error) => {
      // Handle error
      console.error("Create failed:", error);
    },
  });

  const handleBasicDetailsAPi = async () => {
    let response;

    if (esgComlianceAssessmentDetail._id) {
      if (
        hasChanges(
          originalEsgComlianceAssessmentDetail,
          esgComlianceAssessmentDetail
        )
      ) {
        dispatch(
          setEsgComplianceAssessmentDetails({
            ...esgComlianceAssessmentDetail,
            entity_id,
            framework_type: getFrameWorkType(
              esgComlianceAssessmentDetail?.framework_id
            ),
          })
        );
        dispatch(
          setOriginalEsgComplianceDetails({
            ...esgComlianceAssessmentDetail,
            entity_id,
            framework_type: getFrameWorkType(
              esgComlianceAssessmentDetail?.framework_id
            ),
          })
        );
        response = await updateMutation.mutateAsync({
          ...esgComlianceAssessmentDetail,
          entity_id,
          framework_type: getFrameWorkType(
            esgComlianceAssessmentDetail?.framework_id
          ),
        });
      } else {
        response = { status: true };
      }
    } else {
      // Call create API if _id is not present
      response = await createMutation.mutateAsync({
        ...esgComlianceAssessmentDetail,
        entity_id,
        framework_type: getFrameWorkType(
          esgComlianceAssessmentDetail?.framework_id
        ),
      });

      if (response?.status) {
        dispatch(
          setEsgComplianceAssessmentDetails({
            ...esgComlianceAssessmentDetail,
            entity_id,
            framework_type: getFrameWorkType(
              esgComlianceAssessmentDetail?.framework_id
            ),
            _id: response?.result,
          })
        );
        dispatch(
          setOriginalEsgComplianceDetails({
            ...esgComlianceAssessmentDetail,
            entity_id,
            framework_type: getFrameWorkType(
              esgComlianceAssessmentDetail?.framework_id
            ),
            _id: response?.result,
          })
        );
      }
    }

    return response;
  };

  // handleDisableCondition && handleBasicDetails(value);

  const handleEsgComplianceStep = (step) => {
    return step === 0 && handleBasicDetailsAPi();
  };

  return {
    handleEsgComplianceStep,
    disableButton,
    esgComlianceAssessmentDetail,
    frameWorkList,
    filteredFrameWorkList,
  };
};
