import { useState, useMemo } from "react";

import { Box, Button } from "@mui/material";

// components
import DynamicDrawer from "components/UI/phase-3/Drawer/DynamicDrawer";
import LayoutStepper from "components/layout/stepperLayout/LayoutStepper";
import {
  AssignStakeholders,
  SelectActivity,
  SelectFrequency,
} from "./DrawerSteps";
import { useSelectActivity } from "./APIs/SelectActivityApi";
import { useAssignStakeholders } from "./APIs/AssignStakeholdersApi";
import useFormValidation from "customHooks/useFormValidation";
import { useFinalStepAPI } from "./APIs/finalAssign";

const AssignDrawer = ({ open, onClose, selectedTab }) => {
  const steps = ["Select Activity", "Assign Stakeholders", "Select Frequency"];

  const [activeStep, setActiveStep] = useState(0);

  const initialAssessmentState = useMemo(
    () => ({
      receiving_date: null,
      frequency: "",
      ends: "",
      ends_on_date: null,
    }),
    []
  );

  const validationRules = useMemo(
    () => ({
      receiving_date: { required: true },
      frequency: { required: true },
      ends: { required: true },
      ends_on_date: { required: false },
    }),
    []
  );

  // hooks APIs
  const {
    activities,
    selectedActivities,
    disableComplete,
    handleCheckboxChange,
    resetActivites,
    selectAllActivities,
  } = useSelectActivity(selectedTab,open); // Step 1 API
  const {
    checkedStakeholders,
    selectedStakeholders,
    disableStakeholder,
    handleCheckboxChange: handleStakeholders,
    handleAddSelection,
    handleRemoveSelection,
    resetStakeHolderSelection,
  } = useAssignStakeholders(); // Step 2 API

  const { formData, setFormData, validationErrors } = useFormValidation(
    initialAssessmentState,
    validationRules
  );

  const { handleAssignStrategy } = useFinalStepAPI();


  const assignedAgainst = ["ACTIVITY", "KPI"];

  // Function to handle the "Complete List" button logic
  const handleCompleteList = async () => {
    if (activeStep === steps.length - 1) {
      const result = await handleAssignStrategy(
        selectedStakeholders[0]?._id,
        assignedAgainst[selectedTab],
        selectedActivities.map((task) => task?._id),
        formData?.receiving_date,
        formData?.frequency,
        formData?.ends,
        formData?.ends_on_date || ""
      ); // Reset to first step if last step is reached
      result?.status && resetFunction();
    } else {
      setActiveStep((prevStep) => prevStep + 1); // Move to next step
    }
  };

  // Function to handle going back to the previous step
  const handleBack = () => {
    setActiveStep((prevStep) => (prevStep > 0 ? prevStep - 1 : prevStep));
  };

  const renderStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <SelectActivity
            activities={activities}
            selectedActivities={selectedActivities}
            handleCheckboxChange={handleCheckboxChange}
          />
        );
      case 1:
        return (
          <AssignStakeholders
            checkedStakeholders={checkedStakeholders}
            handleCheckboxChange={handleStakeholders}
            selectedStakeholders={selectedStakeholders}
            handleAddSelection={handleAddSelection}
            handleRemoveSelection={handleRemoveSelection}
          />
        );
      case 2:
        return <SelectFrequency data={formData} handleData={setFormData} />;
      default:
        return null;
    }
  };

  // Render action buttons based on the active step
  const renderActionButtons = () => {
    if (activeStep === 0) {
      return (
        <>
          <Button onClick={() => resetActivites()}>Clear Selection</Button>
          <Button onClick={() => selectAllActivities()}>Select All</Button>
          <Button
            variant="contained"
            onClick={handleCompleteList}
            disabled={disableComplete}
          >
            Complete List
          </Button>
        </>
      );
    } else if (activeStep === 1) {
      return (
        <>
          <Button onClick={handleBack}>Back</Button>
          <Button
            variant="contained"
            onClick={handleCompleteList}
            disabled={disableStakeholder}
          >
            Assign to Stakeholder
          </Button>
        </>
      );
    } else if (activeStep === 2) {
      return (
        <>
          <Button
            onClick={() => {
              handleBack();
              setFormData(initialAssessmentState);
            }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            onClick={handleCompleteList}
            disabled={Object.keys(validationErrors).length > 0}
          >
            Set Frequency
          </Button>
        </>
      );
    }
    return null;
  };

  function resetFunction() {
    onClose();
    setActiveStep(0);
    resetActivites();
    resetStakeHolderSelection();
    setFormData(initialAssessmentState);
  }

  return (
    <DynamicDrawer
      hasDrawerOpen={open}
      handleClose={resetFunction}
      heading={"Assign Data"}
      centerHead={
        <Box width={"50%"}>
          <LayoutStepper steps={steps} activeStep={activeStep} />
        </Box>
      }
      hasCustomActionButton={
        <Box width={"100%"}>
          <Box display={"flex"} justifyContent={"flex-end"} gap={2}>
            {renderActionButtons()}
          </Box>
        </Box>
      }
      hasDefaultActionButton={false}
      sx={{ px: 0 }}
    >
      <Box
        overflow={"hidden"}
        sx={{ width: { xs: "90vw", sm: "80vw", md: "70vw" } }}
      >
        {/* Main Render */}
        {renderStepContent(activeStep)}
      </Box>
    </DynamicDrawer>
  );
};

export default AssignDrawer;
