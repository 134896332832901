import { Box, Stack, Card, Typography } from "@mui/material";
import { FiUserPlus, FiUserCheck, FiUserMinus } from "react-icons/fi";



const detailCard = {
  total_assigned: {
    title: "Total Assigned",

    points: 24,
    icon: (
      <FiUserPlus
        size={30}
        style={{
          backgroundColor: "#EBF1F8",
          borderRadius: "30px",
          padding: "4px",
          color: "#3374B9",
        }}
      />
    ),
  },
  total_submitted: {
    title: "Total Submitted",
    points: 24,
    icon: (
      <FiUserCheck
        size={30}
        style={{
          backgroundColor: "#EBF1F8",
          borderRadius: "30px",
          padding: "4px",
          color: "#3374B9",
        }}
      />
    ),
  },
  total_pending: {
    title: "Total Pending",
    points: 24,
    icon: (
      <FiUserMinus
        size={30}
        style={{
          backgroundColor: "#F9EEED",
          borderRadius: "30px",
          padding: "4px",
          color: "#C45446",
        }}
      />
    ),
  },
  total_approved: {
    title: "Total Approved",
    points: 24,
    icon: (
      <FiUserCheck
        size={30}
        style={{
          backgroundColor: "#EBF6EF",
          borderRadius: "30px",
          padding: "4px",
          color: "#32A05F",
        }}
      />
    ),
  },
  total_rejected: {
    title: "Total Rejected",
    points: 24,
    icon: (
      <FiUserCheck
        size={30}
        style={{
          backgroundColor: "#BBBBBB",
          borderRadius: "30px",
          padding: "4px",
          color: "#242424",
        }}
      />
    ),
  },
};

export const DetailCard = ({ title, points, icon }) => (
  <Card variant="outlined" sx={{ flex: 1, height: "98%", padding: "3px" }}>
    {icon && <Box sx={{ position: "absolute", right: 10, top: 5 }}>{icon}</Box>}
    <Stack
      alignItems={"center"}
      justifyContent={"center"}
      gap={2}
      sx={{ height: "100%" }}
    >
      <Typography variant="h4" color={"primary"}>
        {title}
      </Typography>

      <Typography variant="h1" color={"primary"}>
        {points || "-"}
      </Typography>
    </Stack>
  </Card>
);

function ComplianceHeader({ widgetsData }) {
  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        gap={2}
        sx={{ height: "100px" }}
      >
        {Object.keys(widgetsData).map((key) => (
          <DetailCard
            title={detailCard[key]?.title}
            points={widgetsData[key]}
            icon={detailCard[key]?.icon}
          />
        ))}
      </Stack>
    </>
  );
}

export default ComplianceHeader;
