import io from "socket.io-client";
import UNIVERSAL from "config/config";

let socket;

// connecting to socket io server from backend
const connectSocket = (user_id) => {
  socket = io(new URL(UNIVERSAL.BASEURL).origin, {
    query: `user_id=${user_id}`,
  });
};

export { socket, connectSocket };
