import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMaterialityTopicsAnalysis } from "actions/phase-3/materialityAssessment/materialityAssessmentAction";

export const useMaterialityAnalysis = () => {
  const dispatch = useDispatch();
  const { entity_id } = useSelector((state) => state.user);

  const { materialityAssessmentDetails, materialityTopicAnalysis } =
    useSelector((state) => state?.materialityAssessment);

  useEffect(() => {
    dispatch(
      getMaterialityTopicsAnalysis(
        entity_id,
        materialityAssessmentDetails?._id,
        materialityAssessmentDetails?.framework_id
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    materialityTopicAnalysis,
  };
};
