import apiBolerplate from "utils/_helpers/apiBolerplate";
import {
  SET_STRATEGY_TASK_LIST,
  SET_STRATEGY_FREQUENCY_RANGE,
  SET_STRATEGY_TASK_TOPIC_LIST,
  SET_ESG_STRATEGY_SELECTED_TASK,
  SET_CATEGORY_TOPIC,
} from "constants/phase-3/task/esgStrategyPlanning/esgStrategyPlanningTaskConst";

// ----------------- Payloads -----------------
export function setEsgStrategyTaskList(payload) {
  return {
    type: SET_STRATEGY_TASK_LIST,
    payload: payload,
  };
}

export function setEsgStrategySelectedTask(payload) {
  return {
    type: SET_ESG_STRATEGY_SELECTED_TASK,
    payload: payload,
  };
}

export function setEsgStrategyFrequencyRange(payload) {
  return {
    type: SET_STRATEGY_FREQUENCY_RANGE,
    payload: payload,
  };
}

export function setEsgStrategyTaskTopicList(payload) {
  return {
    type: SET_STRATEGY_TASK_TOPIC_LIST,
    payload: payload,
  };
}

export function setEsgStrategyTaskCategory(payload) {
  return {
    type: SET_CATEGORY_TOPIC,
    payload: payload,
  };
}

// ----------------- Actions -----------------

export function getEsgStrategyPlanningTaskList(entity_id) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/tasks/get_assigned_esg_strategy_assessment",
      bodyData: { entity_id },
      callback: (data) => {
        dispatch(setEsgStrategyTaskList(data?.result));
      },
    });

    return data; // Return the data for further use
  };
}

export function getEsgStrategyFrequencyRange(
  esg_strategy_planning_assessment_id,
  materiality_assessment_id,
  frequency
) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/esg_strategy_plan_task/get_frequency_range",
      bodyData: {
        esg_strategy_planning_assessment_id,
        materiality_assessment_id,
        frequency,
      },
      callback: (data) => {
        dispatch(setEsgStrategyFrequencyRange(data?.result));
      },
    });

    return data; // Return the data for further use
  };
}

export function getEsgStrategyAssignedTasks(
  esg_strategy_planning_assessment_id,
  materiality_assessment_id,
  frequency_vaue,
  frequency_year,
  for_activity
) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/esg_strategy_plan_task/get_assigned_tasks",
      bodyData: {
        esg_strategy_planning_assessment_id,
        materiality_assessment_id,
        frequency_vaue,
        frequency_year,
        for_activity,
      },
      callback: (data) => {
        dispatch(setEsgStrategyTaskTopicList(data?.result));
      },
    });

    return data; // Return the data for further use
  };
}

export function getEsgStrategyAssignedTaskDetails(params, url) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: `/esg_strategy_plan_task/${url}`,
      bodyData: {
        ...params,
      },
      callback: (data) => {
        // dispatch(setEsgStrategyTaskTopicList(data?.result));
      },
    });

    return data; // Return the data for further use
  };
}

export function submitEsgStrategyAssignedTask({ file, ...params }, url) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: `/esg_strategy_plan_task/${url}`,
      files: { file },
      bodyData: {
        ...params,
      },
      callback: (data) => {
        dispatch(
          getEsgStrategyAssignedTaskDetails(
            params,
            params?.activity_id
              ? "get_assigned_activity_detaisls"
              : "get_assigned_kpi_detaisls"
          )
        );
      },
    });

    return data; // Return the data for further use
  };
}
