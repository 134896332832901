import React, { useEffect, useState } from "react";
import CustomTextfield from "../../../UI/textfield/textfield";
import { InputAdornment, MenuItem, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import areaIcon from "../../../../images/dashboardIcons/areaIcon.png";
import lineChartIcon from "../../../../images/dashboardIcons/lineChartIcon.png";
import barChartIcon from "../../../../images/dashboardIcons/barChartIcon.png";
import pieChartIcon from "../../../../images/dashboardIcons/pieChartIcon.png";
import tableIcon from "../../../../images/dashboardIcons/tableIcon.png";
import numberIcon from "../../../../images/dashboardIcons/numberIcon.png";
import CustomCheckBox from "../../../UI/checkBox/checkBox";
import ThreeColorPallet from "../../../UI/colorPallets/ThreeColorPallet";
import ColorCubes from "../../../UI/colorPallets/ColorCubes";
import ColorBorders from "../../../UI/colorPallets/ColorBorders";
import { useDispatch, useSelector } from "react-redux";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import useDebounce from "../../../../customHooks/useDebouncec";
import {
  hexToHsva,
  hsvaToRgbaString,
  rgbStringToHsva,
  rgbaToHsva,
} from "@uiw/react-color";
import CustomButton from "../../../UI/button/button";
import { setChartData } from "../../../../actions/datahubActions/datahubActions";
import BasicData from "./builderComps/BasicData";
import PivotConfigTransferList from "./PivotSettingSlider";
import AxisLabelComponents from "./AxislabelsComponents";

function Builder({
  selectedTab,
  availableMeasures,
  measures,
  chartTitle,
  setChartTitle,
  setChartDescription,
  chartDescription,
  updateMeasures,
  dimensions,
  availableDimensions,
  updateDimensions,
  chartType,
  updateChartType,
  updateOrder,
  orderMembers,
  pivotConfig,
  setLimit,
  setPivotConfiq,
  updatePivotConfig,
  backgroundColor,
  setBackGroundColor,
  borderColor,
  setBorderColor,
  query,
  updateQuery,
  timeDimensions,
  availableTimeDimensions,
  updateTimeDimensions,
  axisLabels,
  setAxisLabels,
}) {
  // const { createChart } = useSelector((state) => state.dataHub);

  const MeasureList = React.memo(() => {
    const rowHeights = new Array(availableMeasures.length)
      .fill(true)
      .map(() => 60 + Math.round(Math.random() * 50));

    const getItemSize = (index) => rowHeights[index];
    ///////////////////
    const sizeMap = React.useRef({});
    const setSize = React.useCallback((index, size) => {
      sizeMap.current = { ...sizeMap.current, [index]: size };
    }, []);
    const getSize = (index) => {
      return sizeMap.current[index] || 60;
    };

    const Row = ({ index, style, setSize }) => {
      // const { text } = message;
      // const { setSize, windowWidth } = React.useContext(ChatContext);
      const root = React.useRef();
      React.useEffect(() => {
        setSize(index, root.current.getBoundingClientRect().height);
      }, [index]);

      const currentMeasure = availableMeasures[index];
      const previousMeasure = index > 0 ? availableMeasures[index - 1] : null;

      if (!currentMeasure) {
        return null; // If the data is unavailable, return null to avoid rendering
      }

      const showTitle =
        index === 0 ||
        (previousMeasure &&
          currentMeasure.title.replace(currentMeasure.shortTitle, "") !==
            previousMeasure.title.replace(previousMeasure.shortTitle, ""));

      const handleCheckBoxChange = () => {
        if (measures.map((ms) => ms.name).includes(currentMeasure.name)) {
          let index = measures
            .map((ms) => ms.name)
            .indexOf(currentMeasure.name);
          // measures.findIndex(currentMeasure)
          updateMeasures.remove(measures[index]);
        } else {
          updateMeasures.add(currentMeasure);
        }
        //  measures.length === 0
        //           ? updateMeasures.add(currentMeasure)
        //           : updateMeasures.remove(measures[0]);

        // if (dimensions.length > 0) {
        //   updateMeasures.remove(dimensions[0]);
        // }
        // updateMeasures.add(currentMeasure);

        // if (dimensions.length === 0) {
        //   updateMeasures.add(currentMeasure);
        // } else {
        //   if (dimensions[0].name !== currentMeasure.name) {
        //     updateMeasures.remove(dimensions[0]);
        //     updateMeasures.add(currentMeasure);
        //   }
        // }
      };

      return (
        <div style={style}>
          <div
            ref={root}
            key={currentMeasure.title}
            style={{ display: "flex", flexDirection: "column" }}
          >
            {showTitle && (
              <Typography
                variant="inherit"
                style={{
                  textWrap: "wrap",
                  padding: "2px 12px",
                  color: "#00000073",
                }}
              >
                {currentMeasure.title.replace(currentMeasure.shortTitle, "")}
              </Typography>
            )}
            <div>
              <CustomCheckBox
                key={currentMeasure.name + index}
                name={currentMeasure.name + index}
                color="secondary"
                checked={measures
                  .map((m) => m.name)
                  .includes(currentMeasure.name)}
                onChange={handleCheckBoxChange}
                style={{ marginLeft: 10 }}
              />
              <Typography
                variant="inherit"
                style={{ textWrap: "wrap", marginBottom: "5px", marginLeft: 2 }}
              >
                {currentMeasure?.shortTitle}
              </Typography>
            </div>
          </div>
        </div>
      );
    };
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
        <Typography style={{ fontSize: 13, fontWeight: 400, color: "#AFAFAF" }}>
          Measure Value is the chart’s Measure based on the Etc Etc... Select
          from the given list.
        </Typography>
        <CustomTextfield
          name="SusTeamMsg"
          variant="outlined"
          fullWidth
          minRows={3}
          size="small"
          label="Search Measure Value"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon fontSize="small" color="disabled" />
              </InputAdornment>
            ),
          }}
          // value={basicInfo.sustainibilityTeamMessage}
          // onChange={(e) =>
          //   setSustainibilityTeamMessage(e.target.value)
          // }
        />
        <div
          className="scroll"
          style={{ display: "flex", flexDirection: "column", height: "78vh" }}
        >
          {/* <AutoSizer>
            {({ height, width }) => ( */}

          <List
            height={550} // Adjust height as per your requirements
            itemCount={availableMeasures.length}
            itemSize={getSize} // Adjust itemSize as per your requirements
            width={"100%"}
          >
            {({ index, style }) => (
              <Row index={index} style={style} setSize={setSize} />
            )}
          </List>
          {/* )} */}
          {/* </AutoSizer> */}
        </div>
      </div>
    );
  });

  function getCurrentFYDates() {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1; // getMonth() returns 0-11, so add 1

    let startDate, endDate;

    if (month < 4) {
      // If the current month is January to March, the financial year starts in the previous year.
      startDate = new Date(year - 1, 3, 1); // April 1st of the previous year
      endDate = new Date(year, 2, 31); // March 31st of the current year
    } else {
      startDate = new Date(year, 3, 1); // April 1st of the current year
      endDate = new Date(year + 1, 2, 31); // March 31st of the current year
    }

    return { startDate, endDate };
  }

  function getCurrentFinancialYear() {
    const { startDate, endDate } = getCurrentFYDates();
    return `${startDate.getFullYear()}-${endDate.getFullYear()}`;
  }

  function transformShortTitle(shortTitle) {
    if (!shortTitle) return "";

    let newTitle = shortTitle;

    // Check if the shortTitle contains "cfy_"
    if (shortTitle.includes("cfy_")) {
      const currentFY = getCurrentFinancialYear();

      // Replace "cfy_" with the current financial year
      newTitle = newTitle.replace("cfy_", `${currentFY} `);
    }

    // Remove all underscores from the string
    newTitle = newTitle.replace(/_/g, " ");

    return newTitle;
  }

  const Measure = React.memo(() => {
    const [searchText, setSearchText] = useState("");

    const relevantMeasure =
      availableMeasures.filter(
        (d) =>
          d.name.split(".")[0] ===
            (measures.length > 0 && measures[0].name.split(".")[0]) &&
          d.isVisible
      ).length > 0
        ? availableMeasures.filter(
            (d) =>
              d.name.split(".")[0] ===
                (measures.length > 0 && measures[0].name.split(".")[0]) &&
              d.isVisible
          )
        : availableMeasures;

    const [filterMeasure, setFilterMeasure] = useState(relevantMeasure);

    useEffect(() => {
      const filterBaseOnText = relevantMeasure.filter(
        ({ title, shortTitle }, i) =>
          shortTitle.toLowerCase().includes(searchText.toLowerCase())
      );

      if (searchText) {
        setFilterMeasure(filterBaseOnText);
      } else {
        setFilterMeasure(relevantMeasure);
      }
    }, [searchText]);

    const Row = ({ index, style }) => {
      const currentMeasure = filterMeasure[index];
      const previousMeasure = index > 0 ? filterMeasure[index - 1] : null;
      if (!currentMeasure) {
        return null; // If the data is unavailable, return null to avoid rendering
      }

      const showTitle =
        index === 0 ||
        (previousMeasure &&
          currentMeasure.title.replace(currentMeasure.shortTitle, "") !==
            previousMeasure.title.replace(previousMeasure.shortTitle, ""));

      const handleCheckBoxChange = () => {
        if (measures.map((ms) => ms.name).includes(currentMeasure.name)) {
          let index = measures
            .map((ms) => ms.name)
            .indexOf(currentMeasure.name);
          // measures.findIndex(currentMeasure)
          updateMeasures.remove(measures[index]);
        } else {
          updateMeasures.add(currentMeasure);
        }
        //  measures.length === 0
        //           ? updateMeasures.add(currentMeasure)
        //           : updateMeasures.remove(measures[0]);

        // if (dimensions.length > 0) {
        //   updateMeasures.remove(dimensions[0]);
        // }
        // updateMeasures.add(currentMeasure);

        // if (dimensions.length === 0) {
        //   updateMeasures.add(currentMeasure);
        // } else {
        //   if (dimensions[0].name !== currentMeasure.name) {
        //     updateMeasures.remove(dimensions[0]);
        //     updateMeasures.add(currentMeasure);
        //   }
        // }
      };

      const transformedTitle = transformShortTitle(currentMeasure?.shortTitle);

      return (
        <div style={style}>
          <div
            key={currentMeasure.title}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            {/* {showTitle && (
              <Typography
                variant="inherit"
                style={{
                  textWrap: "wrap",
                  padding: "2px 12px",
                  color: "#00000073",
                }}
              >
                {currentMeasure.title.replace(currentMeasure.shortTitle, "")}
              </Typography>
            )} */}
            <CustomCheckBox
              key={currentMeasure.name + index}
              name={currentMeasure.name + index}
              color="secondary"
              checked={measures
                .map((m) => m.name)
                .includes(currentMeasure.name)}
              onChange={handleCheckBoxChange}
              style={{ marginLeft: 10 }}
            />
            <div>
              <Typography
                variant="inherit"
                style={{ textWrap: "wrap", marginBottom: "5px", marginLeft: 2 }}
              >
                {transformedTitle}
              </Typography>
            </div>
          </div>
        </div>
      );
    };

    return (
      <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
        <Typography style={{ fontSize: 13, fontWeight: 400, color: "#AFAFAF" }}>
          Measure Value is the chart’s Measure based on the Etc Etc... Select
          from the given list.
        </Typography>
        <CustomTextfield
          name="SusTeamMsg"
          variant="outlined"
          fullWidth
          minRows={3}
          size="small"
          label="Search Measure Value"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon fontSize="small" color="disabled" />
              </InputAdornment>
            ),
          }}
          value={searchText.trimStart()}
          onChange={(e) => setSearchText(e.target.value.trimStart())}
        />
        <div
          className="scroll"
          style={{ display: "flex", flexDirection: "column", height: "78vh" }}
        >
          <List
            height={550} // Adjust height as per your requirements
            itemCount={filterMeasure.length}
            itemSize={70} // Adjust itemSize as per your requirements
            width={"100%"}
          >
            {Row}
          </List>
        </div>
      </div>
    );
  });

  const Dimention = React.memo(() => {
    const [searchText, setSearchText] = useState("");

    const relevantDimension = availableDimensions.filter(
      (d) =>
        d.name.split(".")[0] ===
          (measures.length > 0 && measures[0].name.split(".")[0]) && d.isVisible
    );

    const [filterMeasure, setFilterMeasure] = useState(relevantDimension);

    useEffect(() => {
      const filterBaseOnText = relevantDimension.filter(
        ({ title, shortTitle }, i) =>
          shortTitle.toLowerCase().includes(searchText.toLowerCase())
      );
      if (searchText) {
        setFilterMeasure(filterBaseOnText);
      } else {
        setFilterMeasure(relevantDimension);
      }
    }, [searchText]);

    // const Row = ({ index, style }) => {
    //   const currentDimension = filterMeasure[index];
    //   const previousDimension = index > 0 ? filterMeasure[index - 1] : null;

    //   const showTitle =
    //     index === 0 ||
    //     (previousDimension &&
    //       currentDimension?.title
    //         .replace(currentDimension?.shortTitle, "")
    //         ?.trim() !==
    //         previousDimension?.title
    //           .replace(previousDimension?.shortTitle, "")
    //           ?.trim());

    //   const handleCheckBoxChange = () => {
    //     // dimensions.length === 0
    //     //           ? updateDimensions.add(currentDimension)
    //     //           : updateDimensions.remove(dimensions[0]);
    //     if (dimensions.map((ms) => ms.name).includes(currentDimension?.name)) {
    //       let index = dimensions
    //         .map((ms) => ms.name)
    //         .indexOf(currentDimension.name);
    //       // dimensions.findIndex(currentDimension)
    //       updateDimensions.remove(dimensions[index]);
    //     } else {
    //       updateDimensions.add(currentDimension);
    //     }
    //   };

    //   const transformedTitle = transformShortTitle(
    //     currentDimension.title.replace(currentDimension.shortTitle, "")
    //   );

    //   return (
    //     currentDimension?.isVisible && (
    //       <div
    //         style={{
    //           ...style,
    //         }}
    //       >
    //         <div
    //           key={currentDimension.title}
    //           style={{
    //             display: "flex",
    //             flexDirection: "column",
    //           }}
    //         >
    //           {showTitle && (
    //             <Typography
    //               variant="inherit"
    //               style={{
    //                 textWrap: "wrap",
    //                 padding: "2px 12px",
    //                 color: "#00000073",
    //                 marginBottom: "10px",
    //               }}
    //             >
    //               {transformedTitle}
    //             </Typography>
    //           )}
    //           <div
    //             style={{
    //               display: "flex",
    //               justifyContent: "flex-start",
    //               alignItems: "center",
    //             }}
    //           >
    //             <CustomCheckBox
    //               key={currentDimension.name + index}
    //               name={currentDimension.name + index}
    //               color="secondary"
    //               checked={dimensions
    //                 .map((m) => m.name)
    //                 .includes(currentDimension.name)}
    //               onChange={handleCheckBoxChange}
    //               style={{ marginLeft: 10 }}
    //             />
    //             <div>
    //               <Typography
    //                 variant="inherit"
    //                 style={{
    //                   textWrap: "wrap",
    //                   marginBottom: "5px",
    //                   marginLeft: 2,
    //                 }}
    //               >
    //                 {currentDimension.shortTitle}
    //               </Typography>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     )
    //   );
    // };

    return (
      <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
        <Typography style={{ fontSize: 13, fontWeight: 400, color: "#AFAFAF" }}>
          Dimension Value is the chart’s dimension based on the Etc Etc...
          Select from the given list.
        </Typography>
        <CustomTextfield
          name="SusTeamMsg"
          variant="outlined"
          fullWidth
          rows={3}
          size="small"
          label="Search Dimention Value"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon fontSize="small" color="disabled" />
              </InputAdornment>
            ),
          }}
          value={searchText.trimStart()}
          onChange={(e) => setSearchText(e.target.value.trimStart())}
        />
        <div
          className="scroll"
          style={{
            display: "flex",
            flexDirection: "column",
            height: "75vh",
          }}
        >
          {/* <List
            height={300} // Adjust height as per your requirements
            itemCount={filterMeasure.length}
            itemSize={100} // Adjust itemSize as per your requirements
            width={"100%"}
          >
            {Row}
          </List> */}
          {filterMeasure.map((currentDimension, index, array) => {
            // const currentDimension = filterMeasure[index];

            const handleCheckBoxChange = () => {
              // dimensions.length === 0
              //           ? updateDimensions.add(currentDimension)
              //           : updateDimensions.remove(dimensions[0]);
              if (
                dimensions.map((ms) => ms.name).includes(currentDimension?.name)
              ) {
                let index = dimensions
                  .map((ms) => ms.name)
                  .indexOf(currentDimension.name);
                // dimensions.findIndex(currentDimension)
                updateDimensions.remove(dimensions[index]);
              } else {
                updateDimensions.add(currentDimension);
              }
            };

            const transformedTitle = transformShortTitle(
              currentDimension.title.replace(currentDimension.shortTitle, "")
            );

            return (
              <div
                // key={currentDimension.title}
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {index === 0 && (
                  <Typography
                    variant="inherit"
                    style={{
                      textWrap: "wrap",
                      padding: "2px 12px",
                      color: "#00000073",
                      marginBottom: "10px",
                    }}
                  >
                    {transformedTitle}
                  </Typography>
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <CustomCheckBox
                    key={currentDimension.name + index}
                    name={currentDimension.name + index}
                    color="secondary"
                    checked={dimensions
                      .map((m) => m.name)
                      .includes(currentDimension.name)}
                    onChange={handleCheckBoxChange}
                    style={{ marginLeft: 10 }}
                  />
                  <div>
                    <Typography
                      variant="inherit"
                      style={{
                        textWrap: "wrap",
                        marginBottom: "5px",
                        marginLeft: 2,
                      }}
                    >
                      {currentDimension.shortTitle}
                    </Typography>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  });

  const ChartType = () => {
    const ChartTypes = [
      { name: "line", title: "Line Chart", icon: lineChartIcon },
      { name: "area", title: "Area Chart", icon: areaIcon },
      { name: "bar", title: "Bar Chart", icon: barChartIcon },
      { name: "pie", title: "Pie Chart", icon: pieChartIcon },
      { name: "table", title: "Table Chart", icon: tableIcon },
      { name: "number", title: "Number Chart", icon: numberIcon },
    ];

    return (
      <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
        <Typography style={{ fontSize: 13, fontWeight: 400, color: "#AFAFAF" }}>
          Dimension Value is the chart’s dimension based on the Etc Etc...
          Select from the given list.
        </Typography>
        <CustomTextfield
          name="SusTeamMsg"
          variant="outlined"
          fullWidth
          minRows={3}
          size="small"
          label="Search Chart Type"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon fontSize="small" color="disabled" />
              </InputAdornment>
            ),
          }}
          // value={basicInfo.sustainibilityTeamMessage}
          // onChange={(e) =>
          //   setSustainibilityTeamMessage(e.target.value)
          // }
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
            gap: 10,
          }}
        >
          {ChartTypes.map((chart, i) => (
            <div
              style={{
                width: "45%",
                height: "68%",
                border: "1px solid #EBF1F8",
                borderRadius: 1,
              }}
              name={chart.name}
              key={chart.name + i}
            >
              <div
                style={{
                  display: "flex",
                  alignContent: "center",
                  position: "relative",
                  justifyContent: "center",
                  backgroundColor: "#FBFBFB",
                }}
              >
                <img
                  alt=""
                  src={chart.icon}
                  style={{ width: "45%", height: "50%", marginTop: "8%" }}
                />
                <CustomCheckBox
                  name={chart.name}
                  key={chart.name + i}
                  color="secondary"
                  style={{ position: "absolute", right: 0, color: "" }}
                  onClick={() => updateChartType(chart.name)}
                  checked={chartType === chart.name}
                />
              </div>
              <Typography
                style={{ fontSize: 14, fontWeight: 500, padding: "5px 15px" }}
              >
                {chart.title}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    );
  };
  const Colors = React.memo(() => {
    const stdColors = [
      "#D6DFA1",
      "#A1DFAF",
      "#A1BFDF",
      "#8EF2A4",
      "#F28E8E",
      "#8EBEF2",
      "#8EF2E6",
      "#BAF28E",
      "#908EF2",
    ];
    const [bgColor, setBgColor] = useState([]);
    const [brdrColor, setBrdColor] = useState([]);

    useDebounce(
      () => {
        setBackGroundColor(bgColor.map((b) => hsvaToRgbaString(hexToHsva(b))));
      },
      1500,
      [bgColor]
    );

    useDebounce(
      () => {
        setBorderColor(brdrColor.map((b) => hsvaToRgbaString(hexToHsva(b))));
      },
      1500,
      [brdrColor]
    );
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
        <Typography style={{ fontSize: 13, fontWeight: 400, color: "#AFAFAF" }}>
          Chart colors can be for different version to project your data.
        </Typography>
        <CustomTextfield
          name="SusTeamMsg"
          variant="outlined"
          fullWidth
          minRows={3}
          size="small"
          label="Search Color Type"
          style={{
            fontSize: 10,
          }}
          classes={{
            label: {
              fontSize: 10,
            },
          }}
          // value={basicInfo.sustainibilityTeamMessage}
          // onChange={(e) =>
          //   setSustainibilityTeamMessage(e.target.value)
          // }
        />

        <div style={{ borderBottom: "1px solid #EBF1F8" }}>
          <Typography
            style={{
              fontSize: 14,
              fontWeight: 500,
              padding: "10px 5px",
              backgroundColor: "#FBFBFB",
            }}
          >
            Previously Used colors
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            // flexDirection: "column",
            justifyContent: "space-around",
            // flexWrap: "wrap",
            gap: 10,
          }}
        >
          <ThreeColorPallet
            index={0}
            checked={
              JSON.stringify(["#D6DFA1", "#A1DFAF", "#A1BFDF"]) ===
                JSON.stringify(bgColor) ||
              JSON.stringify(["#D6DFA1", "#A1DFAF", "#A1BFDF"]) ===
                JSON.stringify(rgbStringToHsva(backgroundColor))
                ? true
                : false
            }
            handleCheckBox={() => setBgColor(["#D6DFA1", "#A1DFAF", "#A1BFDF"])}
            colors={["#D6DFA1", "#A1DFAF", "#A1BFDF"]}
            text={"Color pallete 1"}
          />
          <ThreeColorPallet
            index={1}
            checked={false}
            handleCheckBox={() => setBgColor(["#8EF2A4", "#F28E8E", "#8EBEF2"])}
            colors={["#8EF2A4", "#F28E8E", "#8EBEF2"]}
            text={"Color pallete 2"}
          />
          <ThreeColorPallet
            index={2}
            checked={false}
            handleCheckBox={() => setBgColor(["#8EF2E6", "#BAF28E", "#908EF2"])}
            colors={["#8EF2E6", "#BAF28E", "#908EF2"]}
            text={"Color pallete 3"}
          />
        </div>
        <div style={{ borderBottom: "1px solid #EBF1F8" }}>
          <Typography
            style={{
              fontSize: 14,
              fontWeight: 500,
              padding: "10px 5px",
              backgroundColor: "#FBFBFB",
            }}
          >
            Standard Color Type
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            // flexDirection: "column",
            justifyContent: "space-around",
            // flexWrap: "wrap",
            gap: 10,
          }}
        >
          <ThreeColorPallet
            index={0}
            checked={false}
            handleCheckBox={() => setBgColor(["#A1BFDF", "#6697CC", "#224468"])}
            colors={["#A1BFDF", "#6697CC", "#224468"]}
            text={"Monocromotatic"}
          />
          <ThreeColorPallet
            index={1}
            checked={false}
            handleCheckBox={() => setBgColor(["#A1BFDF", "#F28E8E", "#0A141E"])}
            colors={["#A1BFDF", "#F28E8E", "#0A141E"]}
            text={"Complimentary"}
          />
          <ThreeColorPallet
            index={2}
            checked={false}
            handleCheckBox={() => setBgColor(["#E22828", "#267EDC", "#4FD72D"])}
            colors={["#E22828", "#267EDC", "#4FD72D"]}
          />
          {/* <div
            style={{
              width: "35%",
              height: "68%",
              border: "1px solid #EBF1F8",
              borderRadius: 8,
              paddingTop:8,
            }}
          >
            <div
              style={{
                display: "flex",
                alignContent: "center",
                position: "relative",
                justifyContent: "space-evenly",
                backgroundColor: "#FBFBFB",
              }}
            >
              <div
                name="ColorPallet"
                style={{
                  width: "50px",
                  height: "70px",
                  border: "1px solid blue",
                  borderRadius: "10px 10px 0px 0px",
                }}
              />
              <div
                name="ColorPallet"
                style={{
                  width: "50px",
                  height: "70px",
                  border: "1px solid blue",
                  borderRadius: "10px 10px 0px 0px",
                }}
              />
              <div
                name="ColorPallet"
                style={{
                  width: "50px",
                  height: "70px",
                  border: "1px solid blue",
                  borderRadius: "10px 10px 0px 0px",
                }}
              />
              <CustomCheckBox
                color="secondary"
                style={{ position: "absolute", right: 5,top:'-5px', color: "" }}
              />
            </div>
            <Typography
              style={{ fontSize: 14, fontWeight: 500, padding: "5px 15px" }}
            >
              Colour Pallet 1
            </Typography>
          </div> */}
        </div>
        <div style={{ borderBottom: "1px solid #EBF1F8" }}>
          <Typography
            style={{
              fontSize: 14,
              fontWeight: 500,
              padding: "10px 5px",
              backgroundColor: "#FBFBFB",
            }}
          >
            Background Colors
          </Typography>
          {/* <CustomButton
            onClick={() => {
              setBackGroundColor(hsvaToRgbaString(hexToHsva(bgColor)));
            }}
          >
            + Add
          </CustomButton> */}
        </div>
        <div style={{ display: "flex", flexWrap: "wrap", gap: 4 }}>
          {stdColors.map((color) => (
            <ColorCubes
              color={color}
              selected={bgColor.includes(color)}
              onClick={() => {
                setBgColor((prev) => {
                  const arr = [...prev];
                  !arr.includes(color)
                    ? arr.push(color)
                    : arr.splice(arr.indexOf(color), 1);
                  return arr;
                });
              }}
            />
          ))}
        </div>
        <div style={{ borderBottom: "1px solid #EBF1F8" }}>
          <Typography
            style={{
              fontSize: 14,
              fontWeight: 500,
              padding: "10px 5px",
              backgroundColor: "#FBFBFB",
            }}
          >
            Border Colors
          </Typography>
        </div>
        <div style={{ display: "flex", flexWrap: "wrap", gap: 4 }}>
          {[
            "#D6DFA1",
            "#A1DFAF",
            "#A1BFDF",
            "#8EF2A4",
            "#F28E8E",
            "#8EBEF2",
            "#8EF2E6",
            "#BAF28E",
            "#908EF2",
          ].map((color) => (
            <ColorBorders
              color={color}
              selected={brdrColor.includes(color)}
              onClick={() => {
                setBrdColor((prev) => {
                  const arr = [...prev];
                  !arr.includes(color)
                    ? arr.push(color)
                    : arr.splice(arr.indexOf(color), 1);
                  return arr;
                });
              }}
            />
          ))}
        </div>
      </div>
    );
  });

  const ChartOrders = React.memo(() => {
    const [selectedOrder, setSelectedOrder] = React.useState("");
    const [selectedMeasure, setSelectedMeasure] = React.useState("");
    const handleChangeMeasures = (event) => {
      const value = event.target.value;

      setSelectedMeasure(event.target.value);
      value !== "none"
        ? updateOrder.update([
            [orderMembers[0]?.id, value === "none" ? "desc" : value],
          ])
        : updateOrder.update([
            [
              orderMembers[1]?.id,
              selectedOrder === "none" || selectedOrder === ""
                ? "asc"
                : selectedOrder,
            ],
          ]);
    };
    const handleChangeDimension = (event) => {
      const value = event.target.value;
      setSelectedOrder(event.target.value);

      value !== "none"
        ? updateOrder.update([
            [orderMembers[1]?.id, value === "none" ? "asc" : value],
          ])
        : updateOrder.update([
            [
              orderMembers[0]?.id,
              selectedMeasure === "none" || selectedMeasure === ""
                ? "desc"
                : selectedMeasure,
            ],
          ]);
    };
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
        <Typography style={{ fontSize: 13, fontWeight: 400, color: "#AFAFAF" }}>
          Choose chart order.
        </Typography>
        <CustomTextfield
          variant="outlined"
          fullWidth
          minRows={3}
          size="small"
          select
          label="Measure Chart Order"
          value={orderMembers[0]?.order}
          onChange={handleChangeMeasures}
        >
          <MenuItem value="none">
            <em>None</em>
          </MenuItem>
          <MenuItem value={"asc"}>Ascending Order</MenuItem>
          <MenuItem value={"desc"}>Descending Order</MenuItem>
          {/* {["Assending", "Descending"].map((numb, i) => (
            <MenuItem value={numb} key={i}>
              {numb}
            </MenuItem>
          ))} */}
        </CustomTextfield>

        <CustomTextfield
          variant="outlined"
          fullWidth
          minRows={3}
          size="small"
          select
          label="Dimention Chart Order"
          value={orderMembers[1]?.order}
          onChange={handleChangeDimension}
        >
          <MenuItem value="none">
            <em>None</em>
          </MenuItem>
          <MenuItem value={"asc"}>Ascending Order</MenuItem>
          <MenuItem value={"desc"}>Descending Order</MenuItem>
        </CustomTextfield>
      </div>
    );
  });

  const SetLimit = React.memo(() => {
    const [value, setValue] = React.useState(0);

    const handleChange = (e) => {
      setValue(Number(e.target.value));
    };

    useDebounce(
      () => {
        value >= 0 && setLimit(value);
        setPivotConfiq({ ...pivotConfig, limit: value });

        // updatePivotConfig.update({ ...pivotConfig, limit: value });
        updateQuery({
          limit: value,
        });
      },
      2000,
      [value]
    );

    return (
      <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
        <Typography style={{ fontSize: 13, fontWeight: 400, color: "#AFAFAF" }}>
          Select a Set Limit between 2 - 10 the number of points to be plotted.
        </Typography>
        <CustomTextfield
          variant="outlined"
          fullWidth
          size="small"
          select
          label="Set Limit"
          value={query?.limit || 10000}
          onChange={handleChange}
        >
          {[2, 3, 4, 5, 6, 7, 8, 9, 10, query?.limit || 10000].map(
            (numb, i) => (
              <MenuItem value={numb} key={i}>
                {numb}
              </MenuItem>
            )
          )}
        </CustomTextfield>
      </div>
    );
  });

  const PivotConfig = () => {
    return (
      <PivotConfigTransferList
        xAxis={pivotConfig?.x}
        yAxis={pivotConfig?.y}
        pivotConfig={pivotConfig}
        updatePivotConfig={updatePivotConfig}
      />
    );
  };

  const AxisLabels = () => {
    return (
      <AxisLabelComponents
        axisLabels={axisLabels}
        setAxisLabels={setAxisLabels}
      />
    );
  };

  return (
    <div
      style={{
        border: "1px solid #EBF1F8",
        width: "45%",
        padding: 8,
        height: "88vh",
      }}
    >
      {selectedTab === 1 && (
        <BasicData
          setChartTitle={setChartTitle}
          chartTitle={chartTitle}
          setChartDescription={setChartDescription}
          chartDescription={chartDescription}
        />
      )}
      {selectedTab === 2 && <Measure />}
      {selectedTab === 3 && <Dimention />}
      {selectedTab === 4 && <ChartType />}
      {selectedTab === 5 && <Colors />}
      {selectedTab === 6 && <ChartOrders />}
      {selectedTab === 7 && <SetLimit />}
      {selectedTab === 8 && <PivotConfig />}
      {selectedTab === 9 && <AxisLabels />}
    </div>
  );
}

export default React.memo(Builder);
