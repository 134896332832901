import { Button, Stack, Box, Skeleton } from "@mui/material";
import Heading from "components/UI/phase-3/heading/Heading";
import SearchTextField from "components/UI/phase-3/textfield/searchTextField";
import { AiOutlineDownload } from "react-icons/ai";
import { IoEllipsisVerticalSharp } from "react-icons/io5";
import { FiUserPlus } from "react-icons/fi";

import CardWidgets from "./reportDataComponent/CardWidgets";
import TopicTable from "./reportDataComponent/TopicTable";
import OptionDrawer from "./reportDataComponent/OptionDrawer";
import AssignDrawer from "./assignDrawer/AssignDrawer";
import ExploreDrawerMain from "./exploreDrawer/ExploreDrawerMain";
import { useEsgComplianceReportData } from "../APIs/HandleReportMainApi";

function ReportMain() {
  const {
    // states
    frameWorkName,
    reportDataList,
    isLoading,
    optionDrawer,
    assignDrawer,
    exploreDrawer,

    selectedOption,
    selectedRowData,
    items,
    reportDataWidgetCounts,
    isWidgetsLoading,
    isFetchingNextPage,
    hasNextPage,
    // functions
    handleDragStart,
    handleDragEnter,
    handleDrop,
    handleHideStatus,
    handleSelectedOption,
    optionToggleDrawer,
    assignToggleDrawer,
    exploreToggleDrawer,
    setSelectedRowData,

    // ref
    tableRef,
  } = useEsgComplianceReportData();


  return (
    <Box sx={{ height: "100%" }}>
      {/* card widgets */}

      <CardWidgets
        reportDataWidgetCounts={reportDataWidgetCounts}
        isWidgetsLoading={isWidgetsLoading}
      />

      {/* heading section */}

      <Heading marginTop={15} text={frameWorkName}>
        <Stack direction={"row"} alignItems={"center"} gap={3}>
          {/* <SearchTextField
            // searchClick={handleSearch}
            sx={{ minWidth: "20em" }}
          /> */}
          <Button
            variant="text"
            size="small"
            startIcon={<AiOutlineDownload />}
            onClick={() => {
              //   resetDetailState();
              //   navigate("/esg_compliance/New_Report");
            }}
          >
            Download Summary
          </Button>
          <Button
            variant="text"
            size="small"
            startIcon={<IoEllipsisVerticalSharp />}
            onClick={optionToggleDrawer}
          >
            Options
          </Button>
          <Button
            variant="contained"
            size="small"
            startIcon={<FiUserPlus />}
            onClick={assignToggleDrawer}
          >
            Assign User
          </Button>
        </Stack>
      </Heading>

      {/*  detail Table */}

      <TopicTable
        dynamicHeading={items.filter((i) => !i.hide).map((i) => i.text)}
        isLoading={isLoading}
        reportDataList={reportDataList}
        ref={tableRef}
      >
        {items.filter((i) => !i.hide).map((i) => i.tableCell)}
      </TopicTable>
      {isFetchingNextPage && (
        <Skeleton variant="rectangular" width={"100%"} height={30} />
      )}
      {!hasNextPage && <p>No more data to load.</p>}

      {/* option Drawer */}

      <OptionDrawer
        openDrawer={optionDrawer}
        toggleDrawer={optionToggleDrawer}
        selectedOption={selectedOption}
        handleSelectedOption={handleSelectedOption}
        tableHeadings={items}
        handleDragEnter={handleDragEnter}
        handleDragStart={handleDragStart}
        handleDrop={handleDrop}
        handleHideStatus={handleHideStatus}
      />

      {/* explore drawer */}

      <ExploreDrawerMain
        openDrawer={exploreDrawer}
        toggleDrawer={() => {
          exploreToggleDrawer();
          setSelectedRowData("");
        }}
        selectedRowData={selectedRowData}
      />

      {/* assign Drawer */}

      <AssignDrawer open={assignDrawer} onClose={assignToggleDrawer} />
    </Box>
  );
}

export default ReportMain;
