import { Box, Tab, Tabs } from "@mui/material";
import DynamicDrawer from "components/UI/phase-3/Drawer/DynamicDrawer";
import useTabChanges from "customHooks/useTabChanges";
import RecordMain from "./record/RecordMain";
import OverviewMain from "./overview/OverviewMain";

const tabs = [
  {
    label: "Record",
    component: (guidelines, survey_type, columns, rows, _id, recorded_data) => (
      <RecordMain
        data={{ guidelines, survey_type, columns, rows, _id, recorded_data }}
      />
    ),
  },
  {
    label: "Overview",
    component: (
      guidelines,
      survey_type,
      columns,
      rows,
      _id,
      recorded_data,
      status
    ) => <OverviewMain status={status} _id={_id} />,
  },
  { label: "Bookmarks", component: () => "" },
  { label: "submission", component: () => "" },
  { label: "Team Chat", component: () => "" },
  { label: "Esg Wise", component: () => "" },
];

function ExploreDrawerMain({ openDrawer, toggleDrawer, selectedRowData }) {
  const { handleChange, selectedTab } = useTabChanges();
  const {
    guidelines,
    survey_type,
    columns,
    rows,
    _id,
    recorded_data = "",
    status,
  } = selectedRowData || {};
  return (
    <DynamicDrawer
      hasDrawerOpen={openDrawer}
      handleClose={(e) => {
        toggleDrawer();
        handleChange(e, 0);
      }}
      heading={selectedRowData?.metric_name || ""}
      hasDefaultActionButton={false}
      hasSecondaryHeader={
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          aria-label="define-against-topic"
        >
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab.label} />
          ))}
        </Tabs>
      }
    >
      <Box width={"90vw"}>
        {tabs[selectedTab]?.component(
          guidelines,
          survey_type,
          columns,
          rows,
          _id,
          recorded_data,
          status
        )}
      </Box>
    </DynamicDrawer>
  );
}

export default ExploreDrawerMain;
