import React from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";

import CustomButton from "../../UI/button/button";
import { Button } from "@mui/material";
// import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 20,
  },
  button: {
    marginRight: theme.spacing(2),
    minWidth: "100px",
    textTransform: "capitalize",
  },
}));

export default function LayoutButton({
  activeStep,
  handleBack,
  handleNext,
  steps,
  surveyCompleted,
  finalStepText = "Submit Response",
  handleDisableButton,
}) {
  const classes = useStyles();
  const history = useNavigate();

  //   const { completedQuestions, questionStatus } = useSelector(
  //     (state) => state.surveyListing
  //   );
  const goBack = () => {
    history.goBack(); // This function takes you back to the previous route
  };

  return (
    <div className={classes.root}>
      {activeStep < steps.length && (
        <CustomButton
          disabled={activeStep === 0 || surveyCompleted}
          onClick={handleBack}
          className={classes.button}
          color="primary"
          // style={{ color: "#3374B9" }}
        >
          Previous
        </CustomButton>
      )}

      {activeStep < steps.length && (
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            handleNext(activeStep === steps.length - 1 ? true : false)
          }
          className={classes.button}
          disabled={!handleDisableButton}

          //   disabled={
          // (activeStep !== steps.length - 1
          //   ? false
          //   : questionStatus?.no_of_completed_questions !==
          //     questionStatus?.total_no_of_questions) ||
          // : selectedAssignSurvey?.is_default
          // ? questionStatus?.no_of_completed_questions !==
          //   questionStatus?.total_no_of_questions
          // : completedQuestions.length === 0

          // surveyCompleted
          //   }
          // style={{ background: "#3374B9" }}
        >

          {activeStep === steps.length - 1 ? finalStepText : "Proceed"}
        </Button>
      )}
      {activeStep === steps.length && (
        <CustomButton
          variant="contained"
          color="primary"
          onClick={() =>
            handleNext(activeStep === steps.length - 1 ? true : false)
          }
          className={classes.button}
          // style={{ background: "#3374B9" }}
        >
          Go to Survey listing
        </CustomButton>
      )}
    </div>
  );
}
