import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  InputAdornment,
  Autocomplete,
  Chip,
} from "@mui/material";
import DynamicDrawer from "components/UI/phase-3/Drawer/DynamicDrawer";
import ImageUplaod from "components/UI/phase-3/ImageUpload/ImageUplaod";

// hooks
import useFormValidation from "customHooks/useFormValidation";
import { GrLocation } from "react-icons/gr";
import requiredField from "components/UI/phase-3/inputField/requiredShow";
import { usePaginationSearch } from "customHooks/usePaginationSearch";
import { useEntityListAPIs } from "components/screen/phase-3/OrgDetailsScreens/APIs/EntryListApis";
import { useUnitsAPIs } from "components/screen/phase-3/OrgDetailsScreens/APIs/UnitsApis";
import {
  createSuppliers,
  getParentEntity,
} from "actions/phase-3/orgDetails/orgDetailsAction";
import { useDispatch, useSelector } from "react-redux";

const SuppliersDrawer = ({ open, onClose, fetchSuppliers }) => {
  const dispatch = useDispatch();
  const [parentName, setParentName] = useState("");
  const [assetImage, setAssetImage] = useState(null);
  const [selectedTypeID, setSelectedTypeID] = useState("");

  const { parent_entity } = useSelector((state) => state.orgDetails);
  const { parent_id } = useSelector((state) => state.user);

  const initialState = {
    name: "",
    supplier_id: "",
    type: "entity",
    street: "",
    city_state: "",
    coordinates: "",
  };

  const validationRules = {
    name: { maxCharacters: 30, required: true },
    supplier_id: { maxCharacters: 21, required: true },
    type: { required: true },
    street: { required: true },
    city_state: { required: true },
    coordinates: { required: true },
  };

  const { formData, errors, handleChange, setFormData, hasErrors } =
    useFormValidation(initialState, validationRules);

  // Handle search keyword and fetch entity list
  const { keyword, handleSearch } = usePaginationSearch(true);
  const { entity_list } = useEntityListAPIs(keyword, 50, 1, true, true);

  const { units_list } = useUnitsAPIs(keyword, 50, 1, true, true);

  const disableSubmit =
    !formData.name ||
    !formData.supplier_id ||
    !formData.type ||
    !selectedTypeID ||
    !formData.street ||
    !formData.city_state ||
    !formData.coordinates ||
    !parentName ||
    !assetImage ||
    hasErrors;

  const handleSubmit = async () => {
    if (!disableSubmit) {
      // dispatch action
      await dispatch(
        createSuppliers(
          parent_id,
          formData.name,
          formData.supplier_id,
          formData.type,
          formData.type === "entity" ? selectedTypeID : undefined,
          formData.type === "unit" ? selectedTypeID : undefined,
          { street_name: formData.street, city_state: formData.city_state },
          formData.coordinates,
          { file: assetImage[0] },
          fetchSuppliers
        )
      );

      // clear data
      setTimeout(() => {
        setFormData(initialState);
        setParentName("");
        setSelectedTypeID("");
        setAssetImage(null);
        handleSearch("");
        onClose();
      }, 500);
    }
  };

  const handleTypeClick = (type) => {
    setFormData((prev) => ({ ...prev, type }));
    setSelectedTypeID("");
  };

  useEffect(() => {
    if (open && !parent_entity) {
      dispatch(getParentEntity(parent_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <DynamicDrawer
      hasDrawerOpen={open}
      handleClose={onClose}
      heading={"Add Supplier"}
      buttonText={"Add Supplier"}
      submitClick={handleSubmit}
      disableSubmit={disableSubmit}
    >
      <Box display={"flex"} flexDirection={"column"} gap={2}>
        {/* Supplier Name */}
        <Box>
          <Typography variant="body1" color="action.active" gutterBottom>
            Supplier Name{" "}
            {requiredField(Boolean(errors.name) || !formData.name)}
          </Typography>
          <TextField
            name="name"
            placeholder="Enter Name"
            value={formData.name}
            onChange={handleChange}
            error={Boolean(errors.name)}
            helperText={errors.name}
            required
            fullWidth
          />
        </Box>

        {/* Supplier ID */}
        <Box>
          <Typography variant="body1" color="action.active" gutterBottom>
            Supplier ID{" "}
            {requiredField(
              Boolean(errors.supplier_id) || !formData.supplier_id
            )}
          </Typography>
          <TextField
            name="supplier_id"
            value={formData.supplier_id}
            onChange={handleChange}
            error={Boolean(errors.supplier_id)}
            helperText={errors.supplier_id}
            required
            fullWidth
          />
        </Box>

        {/* Entity or Units */}
        <Box>
          <Typography variant="body1" color="action.active" gutterBottom>
            Select Data Type {requiredField(!formData.type)}
          </Typography>
          <Box display="flex" gap={1}>
            <Chip
              label="entity"
              onClick={() => handleTypeClick("entity")}
              color={formData.type === "entity" ? "primary" : "default"}
            />
            <Chip
              label="unit"
              onClick={() => handleTypeClick("unit")}
              color={formData.type === "unit" ? "primary" : "default"}
            />
          </Box>
        </Box>

        {formData?.type === "entity" ? (
          <Box>
            {/* Entity ID */}
            <Typography variant="body1" color="action.active" gutterBottom>
              Select Entity ID {requiredField(!selectedTypeID)}
            </Typography>
            <Autocomplete
              options={entity_list?.entity_list || []} // Ensure options is an array
              getOptionLabel={(option) => option.entity_name}
              onInputChange={(event, value) => handleSearch(value)} // Update search keyword
              onChange={(event, newValue) =>
                setSelectedTypeID(newValue ? newValue?._id : "")
              } // Store selected entity's id
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search and select entity name"
                  fullWidth
                />
              )}
            />
          </Box>
        ) : (
          <Box>
            {/* Unit ID */}
            <Typography variant="body1" color="action.active" gutterBottom>
              Select Unit ID {requiredField(!selectedTypeID)}
            </Typography>
            <Autocomplete
              options={units_list?.unit_list || []} // Ensure options is an array
              getOptionLabel={(option) => option.unit_name}
              onInputChange={(event, value) => handleSearch(value)} // Update search keyword
              onChange={(event, newValue) =>
                setSelectedTypeID(newValue ? newValue?._id : "")
              } // Store selected entity's id
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search and select unit name"
                  fullWidth
                />
              )}
            />
          </Box>
        )}

        {/* Location */}
        <Box>
          <Typography variant="body1" color="action.active" gutterBottom>
            Location{" "}
            {requiredField(
              Boolean(errors.street) ||
                Boolean(errors.city_state) ||
                !formData.street ||
                !formData.city_state
            )}
          </Typography>
          <TextField
            name="street"
            placeholder="Street name, building name"
            value={formData.street}
            onChange={handleChange}
            error={Boolean(errors.street)}
            helperText={errors.street}
            sx={{ mb: 2 }}
            required
            fullWidth
          />
          <TextField
            name="city_state"
            placeholder="City, state"
            value={formData.city_state}
            onChange={handleChange}
            error={Boolean(errors.city_state)}
            helperText={errors.city_state}
            required
            fullWidth
          />
        </Box>

        {/* GPS Coordinates */}
        <Box>
          <Typography variant="body1" color="action.active" gutterBottom>
            GPS Coordinates{" "}
            {requiredField(
              Boolean(errors.coordinates) || !formData.coordinates
            )}
          </Typography>
          <TextField
            name="coordinates"
            value={formData.coordinates}
            onChange={handleChange}
            error={Boolean(errors.coordinates)}
            helperText={errors.coordinates}
            sx={{ mb: 2 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ color: "primary.main" }}>
                  <GrLocation size={24} />
                </InputAdornment>
              ),
            }}
            required
            fullWidth
          />
        </Box>

        {/* Parent Name */}
        <Box>
          <Typography variant="body1" color="action.active" gutterBottom>
            Select Parent Name {requiredField(!parentName)}
          </Typography>
          <Select
            labelId="parent-name-label"
            name="parent_name"
            value={parentName}
            onChange={(e) => setParentName(e.target.value)}
            placeholder="Parent Name"
            fullWidth
            required
          >
            {[parent_entity?.entity_name]?.map((e) => (
              <MenuItem key={e} value={e}>
                {e}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Typography variant="body2" color={"text.faded"}>
          The unit in creation will be placed under this parent
        </Typography>

        {/* Image */}
        <ImageUplaod image={setAssetImage} setImage={setAssetImage} />
      </Box>
    </DynamicDrawer>
  );
};

export default SuppliersDrawer;
