import { useState, useRef, useEffect } from "react";

import { Button, TableCell, Chip } from "@mui/material";
import { textStyle } from "components/UI/phase-3/dynamicTable/DynamicTable";

import { useSelector } from "react-redux";
import {
  useQueryClient,
  useQuery,
  useInfiniteQuery,
} from "@tanstack/react-query";
import {
  getEsgComplianceReportData,
  getEsgComplianceReportWidgetsCounts,
} from "actions/phase-3/esgCompliance/esgComplianceAction";

export const useDrawer = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => setIsOpen((prev) => !prev);

  return { isOpen, toggleDrawer };
};

export const useEsgComplianceReportData = () => {
  const tableRef = useRef(null);
  const qyeryClient = useQueryClient();
  const { esgComlianceAssessmentDetail } = useSelector(
    (state) => state?.escCompliance
  );

  const frameworkData = qyeryClient.getQueryData(["allFrameworks"]);

  const frameWorkName =
    frameworkData?.result.find(
      (f) => f?._id === esgComlianceAssessmentDetail?.framework_id
    )?.framework_name || "";

  // First drawer for "Option"
  const { isOpen: optionDrawer, toggleDrawer: optionToggleDrawer } =
    useDrawer();

  // Second drawer for "Assign"
  const { isOpen: assignDrawer, toggleDrawer: assignToggleDrawer } =
    useDrawer();

  // Third drawer for "Explore"
  const { isOpen: exploreDrawer, toggleDrawer: exploreToggleDrawer } =
    useDrawer();

  //  get main table data

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: [
        "reportsData",
        esgComlianceAssessmentDetail?._id,
        esgComlianceAssessmentDetail?.framework_id,
        "template_1",
      ],
      queryFn: ({ pageParam = 0 }) =>
        getEsgComplianceReportData(
          esgComlianceAssessmentDetail?._id,
          esgComlianceAssessmentDetail?.framework_id,
          "template_1",
          pageParam, // Use pageParam for pagination
          10
        ),
      enabled: !!esgComlianceAssessmentDetail?._id,
      getNextPageParam: (lastPage, allPages) => {
        // Logic to determine if more data exists
        const morePagesExist = lastPage.result.length === 10; // Adjust based on your page size
        return morePagesExist ? allPages.length * 10 : false; // Increment by page size (5 in this case)
      },
      // these for know query that data is fresh and API not call until we manually fetch or invalidate it
      staleTime: Infinity,
      cacheTime: 0,
    });

  // to combine all result

  const reportDataList = data?.pages?.flatMap((page) => page.result) || [];

  const {
    data: { result: reportDataWidgetCounts = [] } = {},
    isLoading: isWidgetsLoading,
  } = useQuery({
    queryKey: [
      "reportsDataWidgetCounts",
      esgComlianceAssessmentDetail?._id,
      esgComlianceAssessmentDetail?.framework_id,
    ],
    queryFn: () =>
      getEsgComplianceReportWidgetsCounts(
        esgComlianceAssessmentDetail?._id,
        esgComlianceAssessmentDetail?.framework_id
      ),
    enabled: !!esgComlianceAssessmentDetail?._id,
    // these for know query that data is fresh and API not call until we manually fetch or invalidate it
    staleTime: Infinity,
    cacheTime: 0,
  });

  const [selectedOption, setSelectedOption] = useState("");
  const [selectedRowData, setSelectedRowData] = useState("");

  const [items, setItems] = useState([
    {
      id: "1",
      text: "Section",
      hide: false,
      tableCell: ({ main_topic_name = "" }) => (
        <TableCell style={textStyle()}>{main_topic_name}</TableCell>
      ),
    },
    {
      id: "2",
      text: "Code",
      hide: false,
      tableCell: (rowData, code) => (
        <TableCell style={textStyle()}>{code + 1}</TableCell>
      ),
    },
    {
      id: "3",
      text: "Disclosure",
      hide: false,
      tableCell: ({ metric_name = "" }, code) => (
        <TableCell style={textStyle()}>{metric_name}</TableCell>
      ),
    },
    {
      id: "4",
      text: "Status",
      hide: false,
      tableCell: ({ status = "" }, code) => (
        <TableCell style={textStyle()}>
          <Chip
            sx={(theme) => ({
              backgroundColor:
                status !== "In Progress"
                  ? theme.palette.primary.lighter
                  : theme.palette.warning.lighter,
              padding: "6px 10px",
              fontSize: "12px",
              color:
                status !== "In Progress"
                  ? theme.palette.primary.dark
                  : theme.palette.warning.dark,
              borderRadius: "80px",
            })}
            label={status}
          />
        </TableCell>
      ),
    },
    {
      id: "5",
      text: "Assigned Resource",
      hide: false,
      tableCell: ({ assigned_resource = "" }, code) => (
        <TableCell style={textStyle()}>
          <Chip
            sx={(theme) => ({
              backgroundColor: assigned_resource
                ? theme.palette.primary.lighter
                : theme.palette.warning.lighter,
              padding: "6px 10px",
              fontSize: "12px",
              color: assigned_resource
                ? theme.palette.primary.dark
                : theme.palette.warning.dark,
              borderRadius: "80px",
            })}
            label={
              assigned_resource ? `${assigned_resource} Assigned` : "Pending"
            }
          />
        </TableCell>
      ),
    },
    {
      id: "6",
      text: "Comments",
      hide: false,
      tableCell: ({ assigned_resource = "" }, code) => (
        <TableCell style={textStyle()}>-</TableCell>
      ),
    },

    {
      id: "7",
      text: "Action",
      hide: false,
      tableCell: (rowData, code) => (
        <TableCell style={textStyle()}>
          <Button
            variant="text"
            size="small"
            onClick={() => {
              exploreToggleDrawer();
              setSelectedRowData(rowData);
              //   resetDetailState();
              // navigate(`/esg_compliance/${frameworkName}/${metric_name}`);
            }}
          >
            Explore
          </Button>
          {/* <IconButton>
            <IoMdMore />
          </IconButton> */}
        </TableCell>
      ),
    },
  ]);

  //  functionality for property list drag and drop / hide and unhide
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  const handleDragStart = (index) => {
    dragItem.current = index;
  };

  const handleDragEnter = (index) => {
    dragOverItem.current = index;
  };

  const handleDrop = () => {
    const copiedItems = [...items];
    const draggedItem = copiedItems[dragItem.current];
    copiedItems.splice(dragItem.current, 1);
    copiedItems.splice(dragOverItem.current, 0, draggedItem);

    dragItem.current = null;
    dragOverItem.current = null;
    setItems(copiedItems);
  };

  const handleHideStatus = (id) =>
    setItems((pre) =>
      pre.map((i) => (i.id === id ? { ...i, hide: !i.hide } : i))
    );

  const handleSelectedOption = (option) => setSelectedOption(option);

  // Scroll event handler to detect when the user reaches the bottom
  const handleScroll = () => {
    if (
      tableRef.current &&
      tableRef.current.scrollTop + tableRef.current.clientHeight >=
      tableRef.current.scrollHeight - 200 // Adjust buffer as needed
    ) {
      // Check if there's more data to load
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    }
  };


  // Attach the scroll event listener when the component mounts
  useEffect(() => {
    const tableElement = tableRef.current;

    if (tableElement) {
      tableElement.addEventListener("scroll", handleScroll);
    }
  
    return () => {
      if (tableElement) {
        tableElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [hasNextPage, isFetchingNextPage, fetchNextPage,isLoading]);

  return {
    // states
    frameWorkName,
    reportDataList,
    isLoading,
    optionDrawer,
    assignDrawer,
    exploreDrawer,

    selectedOption,
    selectedRowData,
    items,
    reportDataWidgetCounts,
    isWidgetsLoading,
    isFetchingNextPage,
    hasNextPage,

    // functions
    handleDragStart,
    handleDragEnter,
    handleDrop,
    handleHideStatus,
    handleSelectedOption,
    optionToggleDrawer,
    assignToggleDrawer,
    exploreToggleDrawer,
    setSelectedRowData,

    // ref
    tableRef
  };
};
