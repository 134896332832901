import { Box } from "@mui/material";
import GenericChatTopCards from "./GenericChatTopCards";

const GPTNewChat = ({ selectedCard, setSelectedCard }) => {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        overflow: "scroll",
      }}
    >
      {/* Cards */}
      <GenericChatTopCards
        selectedCard={selectedCard}
        setSelectedCard={setSelectedCard}
      />
    </Box>
  );
};
export default GPTNewChat;
