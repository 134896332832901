import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Typography,

} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

export const textStyle = (type) => {
  let weight = 400;
  if (type === "main") {
    weight = 500;
  }

  return {
    color: "#15314E",
    fontWeight: weight,
    fontSize: "14px",
    lineHeight: "20px",
  };
};

export const statusStyle = (status) => {
  let colorToUse = "#8C7038";
  let bgColorToUse = "#FFEFD0";

  if (status === "complete") {
    colorToUse = "#5BB37F";
    bgColorToUse = "#EBF6EF";
  }

  return {
    width: "max-content",
    minWidth: "9em",
    height: "32px",
    borderRadius: 20,
    textTransform: "capitalize",
    padding: "8px 12px 8px 12px",
    display: "flex",
    alignItems: "center",
    color: colorToUse,
    backgroundColor: bgColorToUse,
    gap: "4px",
  };
};

export const useTableStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHead: {},
  heading: {
    // display: "flex",
    // alignItems: "center",
    // gap: 1,
  },
  headingText: {
    fontSize: "14px",
    lineHeight: "20px",
  },
  tableRowHover: {
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#EBF1F8",
      cursor: "pointer",
    },
  },
});

const DynamicTable = ({
  headings = [],
  hasCheckbox = false,
  checkboxClick,
  stickyHeader = false ,
  children,
}) => {
  const classes = useTableStyles();
  const [sortKey, setSortKey] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSort = (index) => {
    const clickedHeading = headings[index];
    if (clickedHeading.showSort) {
      setSortKey(index);
      setSortOrder(sortKey === index && sortOrder === "asc" ? "desc" : "asc");
    }
  };

  const handleCheckboxClick = () => {
    if (Boolean(checkboxClick)) {
      checkboxClick();
    }
  };

  const ariaLabel =  stickyHeader ? "sticky table" :  "dynamic table"

  return (
    <Table stickyHeader={stickyHeader} className={classes.table} aria-label={ariaLabel}>
      <TableHead className={classes.tableHead}>
        <TableRow>
          {hasCheckbox && (
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                inputProps={{
                  "aria-label": "select all",
                }}
                onClick={handleCheckboxClick}
              />
            </TableCell>
          )}
          {headings?.length > 0 &&
            headings?.map((heading, index) => (
              <TableCell align={heading?.align || "inherit"} key={index}>
                {/* <Box className={classes.heading}> */}
                <Typography
                  className={classes.headingText}
                  fontWeight={600}
                  color={"primary.darker"}
                >
                  {heading?.name || heading}
                </Typography>
                {heading.showSort && (
                  <ExpandMoreRoundedIcon
                    onClick={() => handleSort(index)}
                    style={{
                      color: sortKey === index ? "#3374B9" : "#3374B9",
                      transform:
                        sortKey === index && sortOrder === "asc"
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                    }}
                  />
                )}
                {/* </Box> */}
              </TableCell>
            ))}
        </TableRow>
      </TableHead>
      <TableBody>{children}</TableBody>
    </Table>
  );
};

export default DynamicTable;
