import React, { useEffect, useState, useRef } from "react";
import { Box, TextField, IconButton } from "@mui/material";
import { BsPaperclip } from "react-icons/bs";
import { IoArrowUp } from "react-icons/io5";
import ChatFieldStyles from "./ChatFieldStyles";
import { useDispatch, useSelector } from "react-redux";
import { getGptAnswer } from "actions/phase-3/gptWise/gptWiseAction";

function GPTChatField({ selectedCard }) {
  const classes = ChatFieldStyles();
  const dispatch = useDispatch();
  const { selectedChat, followUpQues } = useSelector((state) => state.gptWise);

  // states
  const [question, setQuestion] = useState("");
  const [touched, setTouched] = useState(false);
  const textFieldRef = useRef(null);

  const handleQuestion = (e) => {
    setQuestion(e.target.value);
    if (e.target.value.trim() !== "") {
      setTouched(true);
    }
  };

  const handleSend = () => {
    if (question?.trim() === "") {
      setTouched(true);
    } else if (question) {
      dispatch(getGptAnswer({ question, selectedChat }));
      setTouched(false);
      setQuestion("");
    }
  };

  // -------------- useEffects --------------
  useEffect(() => {
    if (selectedCard) {
      if (selectedCard === "discover") {
        setQuestion("Discover the ESG Details of [Company Name]");
      } else if (selectedCard === "engage") {
        setQuestion("Engage in getting to know about ESG");
      } else {
        setQuestion("Compare ESG KPI's of [Company A] and [Company B]");
      }

      if (textFieldRef?.current) {
        textFieldRef?.current.focus();
      }
    }
  }, [selectedCard]);

  useEffect(() => {
    setQuestion(followUpQues);
  }, [followUpQues]);

  // ----------------------------------------

  return (
    <Box>
      <TextField
        className={classes.root}
        id="chat-field"
        label="Ask Question about the company and based on the above metrics"
        variant="filled"
        value={question}
        onChange={handleQuestion}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.keyCode === 13) {
            handleSend();
          }
        }}
        inputRef={textFieldRef}
        InputProps={{
          endAdornment: (
            <Box display="flex" alignItems="center" gap={1}>
              <RenderIcons child={<BsPaperclip size={20} />} />
              <RenderIcons
                child={<IoArrowUp size={20} />}
                onClick={handleSend}
              />
            </Box>
          ),
          sx: { borderRadius: 1.5 },
        }}
        error={!question && touched}
        helperText={!question && touched && "Please input a question first"}
        fullWidth
      />
    </Box>
  );
}

export default GPTChatField;

const RenderIcons = ({ child, ...props }) => {
  return (
    <IconButton
      sx={{
        width: "1.5em",
        height: "1.5em",
        bgcolor: "#FEFEFE",
        borderRadius: 0.5,
        color: "primary.main",
        "&:hover": {
          bgcolor: "#F0F0F0",
        },
      }}
      {...props}
    >
      {child}
    </IconButton>
  );
};
